<div #slider class="neo-slider position-relative" [style.background-color]="getBackground()">
  <div class="leyenda-slider-izq" *ngIf="visibility !== 'left' && x > 0">
    <button mat-button color="primary">{{ leftText }}</button>
  </div>
  <div
    (swipeleft)="onSwipeLeft($event)"
    (swipeRight)="onSwipeRight($event)"
    (panstart)="onPanStart($event)"
    (panmove)="onPan($event)"
    (panend)="onPan($event)"
  >
    <div [@sliderAnimator]="visibility" class="position-relative slideable" [style.left.px]="x">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="leyenda-slider-der" *ngIf="visibility !== 'right' && x < 0">
    <button mat-button color="primary">{{ rightText }}</button>
  </div>
</div>
