import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfiguracionCuestionariosDTO implements IEntityDTO {

   envioDiario: boolean;
   notificarDesaprobados: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.envioDiario != null) { this.envioDiario = jsonObj.envioDiario; }
      if (jsonObj.notificarDesaprobados != null) { this.notificarDesaprobados = jsonObj.notificarDesaprobados; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
