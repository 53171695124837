import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateFn, UrlTree } from '@angular/router';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export const UserTypeGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);

  if (headerService.IsLogged()) {
    if (next.data.expectedType) {
      if (headerService.userLogged.userType === next.data.expectedType) {
        return true;
      } else {
        return router.parseUrl('/home');
      }
    } else if (next.data.expectedTypes) {
      if (next.data.expectedTypes.includes(headerService.userLogged.userType)) {
        return true;
      } else {
        return router.parseUrl('/home');
      }
    } else {
      return true;
    }
  } else {
    headerService.dispose();
    return router.parseUrl('/login');
  }
};
