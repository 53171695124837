export const colors = {
  primary: '#379ff8',
  color_green: '#4fc421',
  color_gray_5: '#6e6e6e',
  color_gray_7: '#8b8b8b',
  color_warning: '#ee7e3c',
  color_violet: '#6e267a',
  color_green_2: '#76ac30',
  color_yellow: '#fde14f',
  color_red: '#ff667c',
};
