import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EmpresaBasicoDTO } from 'src/app/ModelDTO/DTO/empresaBasico.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { HorasTrabajadasPorLegajoDTO } from 'src/app/ModelDTO/DTO/horasTrabajadasPorLegajo.DTO';


export class ResumenHorasTrabajadasDTO implements IEntityDTO {

   fechaDesde: Date;
   fechaHasta: Date;
   cantidadDias = 0;
   feriados: Array<Date>;
   empresa: EmpresaBasicoDTO;
   oficina: OficinaDTO;
   legajos: Array<HorasTrabajadasPorLegajoDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.empresa = new EmpresaBasicoDTO();
      this.oficina = new OficinaDTO();
      this.legajos = new Array<HorasTrabajadasPorLegajoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaDesde != null) { this.fechaDesde = new Date(jsonObj.fechaDesde); }
      if (jsonObj.fechaHasta != null) { this.fechaHasta = new Date(jsonObj.fechaHasta); }
      if (jsonObj.cantidadDias != null) { this.cantidadDias = jsonObj.cantidadDias; }
      if (jsonObj.feriados != null) { this.feriados = jsonObj.feriados; }
      if (jsonObj.empresa != null) { this.empresa.PrepareDTO(jsonObj.empresa); }
      if (jsonObj.oficina != null) { this.oficina.PrepareDTO(jsonObj.oficina); }
      if (jsonObj.legajos != null) { for (const item of jsonObj.legajos) { const itemDTO = new HorasTrabajadasPorLegajoDTO(); itemDTO.PrepareDTO(item); this.legajos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
