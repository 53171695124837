import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { RoleBasicDTO, RoleBasicModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalCategoriaModelDTO } from 'src/app/ModelDTO/personalCategoria.ModelDTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { PersonalSubcategoriaModelDTO } from 'src/app/ModelDTO/personalSubcategoria.ModelDTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { PuestoModelDTO } from 'src/app/ModelDTO/puesto.ModelDTO';
import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { CurrencyModelDTO } from 'src/app/ModelDTO/currency.ModelDTO';
import { PersonalCambioEstadoBasicoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoBasico.DTO';
import { PersonalCambioEstadoBasicoModelDTO } from 'src/app/ModelDTO/personalCambioEstadoBasico.ModelDTO';
import { EmpresaBasicoDTO } from 'src/app/ModelDTO/DTO/empresaBasico.DTO';
import { EmpresaBasicoModelDTO } from 'src/app/ModelDTO/empresaBasico.ModelDTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { OficinaModelDTO } from 'src/app/ModelDTO/oficina.ModelDTO';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { ModalidadContractualModelDTO } from 'src/app/ModelDTO/modalidadContractual.ModelDTO';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';


export class PersonalLegajoModelDTO extends EntityModelDTO<PersonalLegajoDTO> {

   private perfilModel: RoleBasicModelDTO;
   private perfilSubscribe: Subscription;
   private categoriaContratacionModel: PersonalCategoriaModelDTO;
   private categoriaContratacionSubscribe: Subscription;
   private subcategoriaContratacionModel: PersonalSubcategoriaModelDTO;
   private subcategoriaContratacionSubscribe: Subscription;
   private currencyModel: CurrencyModelDTO;
   private currencySubscribe: Subscription;
   private estadoActualModel: PersonalCambioEstadoBasicoModelDTO;
   private estadoActualSubscribe: Subscription;
   private empresaModel: EmpresaBasicoModelDTO;
   private empresaSubscribe: Subscription;
   private oficinaModel: OficinaModelDTO;
   private oficinaSubscribe: Subscription;
   private modalidadModel: ModalidadContractualModelDTO;
   private modalidadSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalLegajoDTO) {
      super(entityDTO);
   }

   public static getTipoContratacion(): string[] {
      return EntityModelDTO.getEnumArray(TipoContratacion);
   }

   public static getTipoDocumento(): string[] {
      return EntityModelDTO.getEnumArray(TipoDocumento);
   }

   public static getTipoGenero(): string[] {
      return EntityModelDTO.getEnumArray(TipoGenero);
   }

   public static getEstadoCivil(): string[] {
      return EntityModelDTO.getEnumArray(EstadoCivil);
   }

   public static getRelationshipPositionEnum(): string[] {
      return EntityModelDTO.getEnumArray(RelationshipPositionEnum);
   }

   public setEntityDTO(entityDTO: PersonalLegajoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.perfilModel = new RoleBasicModelDTO(this.entityDTO.perfil);
      this.perfilSubscribe = this.perfilModel.changed.subscribe((changed) => this.changed.next(changed));
      this.categoriaContratacionModel = new PersonalCategoriaModelDTO(this.entityDTO.categoriaContratacion);
      this.categoriaContratacionSubscribe = this.categoriaContratacionModel.changed.subscribe((changed) => this.changed.next(changed));
      this.subcategoriaContratacionModel = new PersonalSubcategoriaModelDTO(this.entityDTO.subcategoriaContratacion);
      this.subcategoriaContratacionSubscribe = this.subcategoriaContratacionModel.changed.subscribe((changed) => this.changed.next(changed));
      this.currencyModel = new CurrencyModelDTO(this.entityDTO.currency);
      this.currencySubscribe = this.currencyModel.changed.subscribe((changed) => this.changed.next(changed));
      this.estadoActualModel = new PersonalCambioEstadoBasicoModelDTO(this.entityDTO.estadoActual);
      this.estadoActualSubscribe = this.estadoActualModel.changed.subscribe((changed) => this.changed.next(changed));
      this.empresaModel = new EmpresaBasicoModelDTO(this.entityDTO.empresa);
      this.empresaSubscribe = this.empresaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.oficinaModel = new OficinaModelDTO(this.entityDTO.oficina);
      this.oficinaSubscribe = this.oficinaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.modalidadModel = new ModalidadContractualModelDTO(this.entityDTO.modalidad);
      this.modalidadSubscribe = this.modalidadModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.perfilModel.dispose();
      this.perfilSubscribe.unsubscribe();
      this.categoriaContratacionModel.dispose();
      this.categoriaContratacionSubscribe.unsubscribe();
      this.subcategoriaContratacionModel.dispose();
      this.subcategoriaContratacionSubscribe.unsubscribe();
      this.currencyModel.dispose();
      this.currencySubscribe.unsubscribe();
      this.estadoActualModel.dispose();
      this.estadoActualSubscribe.unsubscribe();
      this.empresaModel.dispose();
      this.empresaSubscribe.unsubscribe();
      this.oficinaModel.dispose();
      this.oficinaSubscribe.unsubscribe();
      this.modalidadModel.dispose();
      this.modalidadSubscribe.unsubscribe();
   }

   get PerfilModel(): RoleBasicModelDTO { return this.perfilModel; }
   get Perfil(): RoleBasicDTO { return this.perfilModel.getEntityDTO(); }
   set Perfil(value: RoleBasicDTO) { this.notifyChange(() => { this.entityDTO.perfil = value; this.perfilModel.setEntityDTO(value); }); }

   get FechaIngreso(): Date { return this.entityDTO.fechaIngreso; }
   set FechaIngreso(value: Date) { this.notifyChangeDTO('fechaIngreso', value); }

   get FechaSalida(): Date { return this.entityDTO.fechaSalida; }
   set FechaSalida(value: Date) { this.notifyChangeDTO('fechaSalida', value); }

   get CategoriaContratacionModel(): PersonalCategoriaModelDTO { return this.categoriaContratacionModel; }
   get CategoriaContratacion(): PersonalCategoriaDTO { return this.categoriaContratacionModel.getEntityDTO(); }
   set CategoriaContratacion(value: PersonalCategoriaDTO) { this.notifyChange(() => { this.entityDTO.categoriaContratacion = value; this.categoriaContratacionModel.setEntityDTO(value); }); }

   get SubcategoriaContratacionModel(): PersonalSubcategoriaModelDTO { return this.subcategoriaContratacionModel; }
   get SubcategoriaContratacion(): PersonalSubcategoriaDTO { return this.subcategoriaContratacionModel.getEntityDTO(); }
   set SubcategoriaContratacion(value: PersonalSubcategoriaDTO) { this.notifyChange(() => { this.entityDTO.subcategoriaContratacion = value; this.subcategoriaContratacionModel.setEntityDTO(value); }); }

   get Puestos(): Array<PuestoDTO> { return this.entityDTO.puestos; }
   set Puestos(value: Array<PuestoDTO>) { this.notifyChangeDTO('puestos', value); }

   get RepresentanteGremial(): boolean { return this.entityDTO.representanteGremial; }
   set RepresentanteGremial(value: boolean) { this.notifyChangeDTO('representanteGremial', value); }

   get InicioRepresentacionGremial(): Date { return this.entityDTO.inicioRepresentacionGremial; }
   set InicioRepresentacionGremial(value: Date) { this.notifyChangeDTO('inicioRepresentacionGremial', value); }

   get FinRepresentacionGremial(): Date { return this.entityDTO.finRepresentacionGremial; }
   set FinRepresentacionGremial(value: Date) { this.notifyChangeDTO('finRepresentacionGremial', value); }

   get EmailEmpresa(): string { return this.entityDTO.emailEmpresa; }
   set EmailEmpresa(value: string) { this.notifyChangeDTO('emailEmpresa', value); }

   get Antiguedad(): number { return this.entityDTO.antiguedad; }
   set Antiguedad(value: number) { this.notifyChangeDTO('antiguedad', value); }

   get SueldoBruto(): number { return this.entityDTO.sueldoBruto; }
   set SueldoBruto(value: number) { this.notifyChangeDTO('sueldoBruto', value); }

   get CurrencyModel(): CurrencyModelDTO { return this.currencyModel; }
   get Currency(): CurrencyDTO { return this.currencyModel.getEntityDTO(); }
   set Currency(value: CurrencyDTO) { this.notifyChange(() => { this.entityDTO.currency = value; this.currencyModel.setEntityDTO(value); }); }

   get EstadoActualModel(): PersonalCambioEstadoBasicoModelDTO { return this.estadoActualModel; }
   get EstadoActual(): PersonalCambioEstadoBasicoDTO { return this.estadoActualModel.getEntityDTO(); }
   set EstadoActual(value: PersonalCambioEstadoBasicoDTO) { this.notifyChange(() => { this.entityDTO.estadoActual = value; this.estadoActualModel.setEntityDTO(value); }); }

   get EmpresaModel(): EmpresaBasicoModelDTO { return this.empresaModel; }
   get Empresa(): EmpresaBasicoDTO { return this.empresaModel.getEntityDTO(); }
   set Empresa(value: EmpresaBasicoDTO) { this.notifyChange(() => { this.entityDTO.empresa = value; this.empresaModel.setEntityDTO(value); }); }

   get OficinaModel(): OficinaModelDTO { return this.oficinaModel; }
   get Oficina(): OficinaDTO { return this.oficinaModel.getEntityDTO(); }
   set Oficina(value: OficinaDTO) { this.notifyChange(() => { this.entityDTO.oficina = value; this.oficinaModel.setEntityDTO(value); }); }

   get Legajo(): string { return this.entityDTO.legajo; }
   set Legajo(value: string) { this.notifyChangeDTO('legajo', value); }

   get Contratacion(): string { return TipoContratacion[this.entityDTO.contratacion]; }
   set Contratacion(value: string) { this.notifyChangeDTO('contratacion', TipoContratacion[value]); }

   get ModalidadModel(): ModalidadContractualModelDTO { return this.modalidadModel; }
   get Modalidad(): ModalidadContractualDTO { return this.modalidadModel.getEntityDTO(); }
   set Modalidad(value: ModalidadContractualDTO) { this.notifyChange(() => { this.entityDTO.modalidad = value; this.modalidadModel.setEntityDTO(value); }); }

   get EsSupervisor(): boolean { return this.entityDTO.esSupervisor; }
   set EsSupervisor(value: boolean) { this.notifyChangeDTO('esSupervisor', value); }

   get EsAdmin(): number { return this.entityDTO.esAdmin; }
   set EsAdmin(value: number) { this.notifyChangeDTO('esAdmin', value); }

   get InternNumber(): number { return this.entityDTO.internNumber; }
   set InternNumber(value: number) { this.notifyChangeDTO('internNumber', value); }

   get TieneCertificado(): boolean { return this.entityDTO.tieneCertificado; }
   set TieneCertificado(value: boolean) { this.notifyChangeDTO('tieneCertificado', value); }

   get Image(): string { return this.entityDTO.image; }
   set Image(value: string) { this.notifyChangeDTO('image', value); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Apellido(): string { return this.entityDTO.apellido; }
   set Apellido(value: string) { this.notifyChangeDTO('apellido', value); }

   get NombreCompleto(): string { return this.entityDTO.nombreCompleto; }
   set NombreCompleto(value: string) { this.notifyChangeDTO('nombreCompleto', value); }

   get Dni(): string { return this.entityDTO.dni; }
   set Dni(value: string) { this.notifyChangeDTO('dni', value); }

   get TipoDocumento(): string { return TipoDocumento[this.entityDTO.tipoDocumento]; }
   set TipoDocumento(value: string) { this.notifyChangeDTO('tipoDocumento', TipoDocumento[value]); }

   get FechaNacimiento(): Date { return this.entityDTO.fechaNacimiento; }
   set FechaNacimiento(value: Date) { this.notifyChangeDTO('fechaNacimiento', value); }

   get Genero(): string { return TipoGenero[this.entityDTO.genero]; }
   set Genero(value: string) { this.notifyChangeDTO('genero', TipoGenero[value]); }

   get Cuil(): string { return this.entityDTO.cuil; }
   set Cuil(value: string) { this.notifyChangeDTO('cuil', value); }

   get Telefonos(): string { return this.entityDTO.telefonos; }
   set Telefonos(value: string) { this.notifyChangeDTO('telefonos', value); }

   get Email(): string { return this.entityDTO.email; }
   set Email(value: string) { this.notifyChangeDTO('email', value); }

   get ConfirmarEmail(): boolean { return this.entityDTO.confirmarEmail; }
   set ConfirmarEmail(value: boolean) { this.notifyChangeDTO('confirmarEmail', value); }

   get Edad(): number { return this.entityDTO.edad; }
   set Edad(value: number) { this.notifyChangeDTO('edad', value); }

   get Peso(): number { return this.entityDTO.peso; }
   set Peso(value: number) { this.notifyChangeDTO('peso', value); }

   get Altura(): number { return this.entityDTO.altura; }
   set Altura(value: number) { this.notifyChangeDTO('altura', value); }

   get CircunferenciaCabeza(): number { return this.entityDTO.circunferenciaCabeza; }
   set CircunferenciaCabeza(value: number) { this.notifyChangeDTO('circunferenciaCabeza', value); }

   get TallePantalon(): string { return this.entityDTO.tallePantalon; }
   set TallePantalon(value: string) { this.notifyChangeDTO('tallePantalon', value); }

   get TalleCalzado(): string { return this.entityDTO.talleCalzado; }
   set TalleCalzado(value: string) { this.notifyChangeDTO('talleCalzado', value); }

   get TalleCamisa(): string { return this.entityDTO.talleCamisa; }
   set TalleCamisa(value: string) { this.notifyChangeDTO('talleCamisa', value); }

   get MovilidadPropia(): boolean { return this.entityDTO.movilidadPropia; }
   set MovilidadPropia(value: boolean) { this.notifyChangeDTO('movilidadPropia', value); }

   get DisponibilidadTraslado(): boolean { return this.entityDTO.disponibilidadTraslado; }
   set DisponibilidadTraslado(value: boolean) { this.notifyChangeDTO('disponibilidadTraslado', value); }

   get OficinaNombre(): string { return this.entityDTO.oficinaNombre; }
   set OficinaNombre(value: string) { this.notifyChangeDTO('oficinaNombre', value); }

   get EstadoCivil(): string { return EstadoCivil[this.entityDTO.estadoCivil]; }
   set EstadoCivil(value: string) { this.notifyChangeDTO('estadoCivil', EstadoCivil[value]); }

   get FamiliaresACargo(): number { return this.entityDTO.familiaresACargo; }
   set FamiliaresACargo(value: number) { this.notifyChangeDTO('familiaresACargo', value); }

   get UltimoSueldo(): number { return this.entityDTO.ultimoSueldo; }
   set UltimoSueldo(value: number) { this.notifyChangeDTO('ultimoSueldo', value); }

   get GrupoSanguineo(): string { return this.entityDTO.grupoSanguineo; }
   set GrupoSanguineo(value: string) { this.notifyChangeDTO('grupoSanguineo', value); }

   get NroCuenta(): string { return this.entityDTO.nroCuenta; }
   set NroCuenta(value: string) { this.notifyChangeDTO('nroCuenta', value); }

   get Alias(): string { return this.entityDTO.alias; }
   set Alias(value: string) { this.notifyChangeDTO('alias', value); }

   get RelationshipPosition(): string { return RelationshipPositionEnum[this.entityDTO.relationshipPosition]; }
   set RelationshipPosition(value: string) { this.notifyChangeDTO('relationshipPosition', RelationshipPositionEnum[value]); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
