import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class PartnerRequestDTO implements IEntityDTO {

   userEmail = '';
   userPhone = '';
   description = '';
   name = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.userEmail != null) { this.userEmail = jsonObj.userEmail; }
      if (jsonObj.userPhone != null) { this.userPhone = jsonObj.userPhone; }
      if (jsonObj.description != null) { this.description = jsonObj.description; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
