import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoRecurrencia } from 'src/app/ModelDTO/DTO/tipoRecurrencia.ENUM';
import { AddresseesDTO } from 'src/app/ModelDTO/DTO/addressees.DTO';
import { TipoPlanificacionMensual } from 'src/app/ModelDTO/DTO/tipoPlanificacionMensual.ENUM';
import { DayOfWeek } from 'src/app/ModelDTO/DTO/dayOfWeek.ENUM';


export class PlanificacionDTO implements IEntityDTO {

   tipo: TipoRecurrencia;
   destinatarios: AddresseesDTO;
   customEvaluators: AddresseesDTO;
   diasVencimiento: number;
   fechaUnica: Date;
   incluirFeriados: boolean;
   fechaInicio: Date;
   fechaFinRecurrencia: Date;
   incluirSabado: boolean;
   incluirDomingo: boolean;
   domingo: boolean;
   lunes: boolean;
   martes: boolean;
   miercoles: boolean;
   jueves: boolean;
   viernes: boolean;
   sabado: boolean;
   tipoPlanificacionMensual: TipoPlanificacionMensual;
   diaSemana: DayOfWeek;
   numeroDiaSemana: number;
   diaParticular: number;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.destinatarios = new AddresseesDTO();
      this.customEvaluators = new AddresseesDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipo != null) { this.tipo = jsonObj.tipo as TipoRecurrencia; }
      if (jsonObj.destinatarios != null) { this.destinatarios.PrepareDTO(jsonObj.destinatarios); }
      if (jsonObj.customEvaluators != null) { this.customEvaluators.PrepareDTO(jsonObj.customEvaluators); }
      if (jsonObj.diasVencimiento != null) { this.diasVencimiento = jsonObj.diasVencimiento; }
      if (jsonObj.fechaUnica != null) { this.fechaUnica = new Date(jsonObj.fechaUnica); }
      if (jsonObj.incluirFeriados != null) { this.incluirFeriados = jsonObj.incluirFeriados; }
      if (jsonObj.fechaInicio != null) { this.fechaInicio = new Date(jsonObj.fechaInicio); }
      if (jsonObj.fechaFinRecurrencia != null) { this.fechaFinRecurrencia = new Date(jsonObj.fechaFinRecurrencia); }
      if (jsonObj.incluirSabado != null) { this.incluirSabado = jsonObj.incluirSabado; }
      if (jsonObj.incluirDomingo != null) { this.incluirDomingo = jsonObj.incluirDomingo; }
      if (jsonObj.domingo != null) { this.domingo = jsonObj.domingo; }
      if (jsonObj.lunes != null) { this.lunes = jsonObj.lunes; }
      if (jsonObj.martes != null) { this.martes = jsonObj.martes; }
      if (jsonObj.miercoles != null) { this.miercoles = jsonObj.miercoles; }
      if (jsonObj.jueves != null) { this.jueves = jsonObj.jueves; }
      if (jsonObj.viernes != null) { this.viernes = jsonObj.viernes; }
      if (jsonObj.sabado != null) { this.sabado = jsonObj.sabado; }
      if (jsonObj.tipoPlanificacionMensual != null) { this.tipoPlanificacionMensual = jsonObj.tipoPlanificacionMensual as TipoPlanificacionMensual; }
      if (jsonObj.diaSemana != null) { this.diaSemana = jsonObj.diaSemana as DayOfWeek; }
      if (jsonObj.numeroDiaSemana != null) { this.numeroDiaSemana = jsonObj.numeroDiaSemana; }
      if (jsonObj.diaParticular != null) { this.diaParticular = jsonObj.diaParticular; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
