import { Input, NgZone, OnDestroy, ViewChild, Component, OnInit } from '@angular/core';
import { EMPTY, mergeMap, Observable } from 'rxjs';
import {
  PersonalLegajoBasicoDTO,
  PersonalLegajoBasicoDTO as PersonalLegajoBasicoInterfaceDTO,
} from '@api/interfaces/personal-legajo-basico.interface';
import { quillModuloMinimal } from 'src/app/shared/shared-functions';
import { QuillEditorComponent } from 'ngx-quill';
// eslint-disable-next-line import/extensions
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-mention';
import { IQuillMention } from 'src/app/app-common/comentarios/quill-mention.interface';
import { RoleIdEnum } from 'src/app/shared/enums';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { PersonalLegajoDTO } from '@api/interfaces/personal-legajo.interface';
import { LegajoDTO } from '@api/interfaces/legajo.interface';
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { ComentarioVisibilidad } from '@api/enums/comentario-visibilidad.enum';
import { PersonalEntityService } from 'src/app/shared/services/entities/personal-entity.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { IComentarios } from './icomentarios';

@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss'],
})
export class ComentariosComponent implements OnInit, OnDestroy {
  @Input() public id: number;
  @Input() public comentarioHandler: IComentarios;
  @Input() public showFileSelector = false;
  @Input() public canBeNotified: (PersonalLegajoBasicoDTO | PersonalLegajoDTO | LegajoDTO | PersonalLegajoBasicoInterfaceDTO)[] | undefined;
  @Input() public cantBeNotifiedKey = 'NO_NOTIFICATION_DEFAULT';

  @ViewChild(QuillEditorComponent) public editor: QuillEditorComponent;

  public comentario: ComentarioDTO = {
    id: undefined,
    fecha: undefined,
    visibilidad: undefined,
    stars: undefined,
  };
  public privadoDefault = false;
  public titulo = '';
  public placeholder = '';
  public conPadding = false;
  public textoBotonEnviar = '';
  public filesToAdd: Array<File> = [];

  public quillModule: unknown;

  constructor(
    private personalService: PersonalEntityService,
    private organigramaService: OrganigramaService,
    private snackBar: SnackBarService,
    private zone: NgZone,
  ) {
    window.addEventListener('mention-clicked', this.mentionClickHandler, false);
  }

  public ngOnInit(): void {
    this.titulo = this.comentarioHandler.getTitle();
    this.conPadding = this.comentarioHandler.needPadding();
    this.privadoDefault = this.comentarioHandler.isPrivateDefault();
    this.initForm();
    this.placeholder = this.comentarioHandler.getPlaceHolder(this.comentario.visibilidad);

    this.quillModule = {
      ...quillModuloMinimal,
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        minChars: 3,
        isolateCharacter: true,
        dataAttributes: ['role'],
        onSelect: (item, insertItem): void => {
          const editor = this.editor.quillEditor;
          insertItem(item);
          // necessary because quill-mention triggers changes as 'api' instead of 'user'
          editor.insertText(editor.getLength() - 1, '', 'user');
          this.zone.run(() => {
            this.checkMentionAlert(item);
          });
        },
        source: async (searchTerm: string, renderList): Promise<void> => {
          const teammates = await this.searchTeammate(searchTerm);
          renderList(teammates);
        },
      },
    };
  }

  public ngOnDestroy(): void {
    window.removeEventListener('mention-clicked', this.mentionClickHandler, false);
  }

  public selectRating(rating: number): void {
    this.comentario.stars = rating;
  }

  public enviarMensaje = (): Observable<void> => {
    if (this.comentario?.mensaje) {
      const formData = new FormData();
      formData.append('json', JSON.stringify(this.comentario));
      this.filesToAdd
        .filter((x) => x.size > 0)
        .forEach((x) => {
          formData.append(x.name, x);
        });
      return this.comentarioHandler.sendMessage(this.id, formData).pipe(
        mergeMap(() => {
          this.initForm();
          return EMPTY;
        }),
      );
    }
    return EMPTY;
  };

  public toggleVisibilidad(): void {
    if (this.comentario.visibilidad === ComentarioVisibilidad.Publico) {
      this.comentario.visibilidad = ComentarioVisibilidad.Privado;
      this.privadoDefault = true;
      this.placeholder = this.comentarioHandler.getPlaceHolder(this.comentario.visibilidad);
      if (this.comentario?.mensaje?.includes('data-role="3"')) {
        // Colaboradores mencionados
        const canNotified = this.canBeNotified.some((x) => {
          const dataId = `data-id="${x.id}"`;
          return this.comentario?.mensaje?.includes(dataId);
        });
        if (!canNotified) {
          this.snackBar.showInfo('COMMENTS.NO_NOTIFICATION_PRIVATE_ALL');
        }
      }
    } else if (this.comentario.visibilidad === ComentarioVisibilidad.Privado) {
      this.comentario.visibilidad = ComentarioVisibilidad.Publico;
      this.privadoDefault = false;
      this.placeholder = this.comentarioHandler.getPlaceHolder(this.comentario.visibilidad);
    }
  }

  private initForm(): void {
    this.filesToAdd = [];
    this.comentario = {
      id: this.comentario.id,
      fecha: this.comentario.fecha,
      visibilidad: this.privadoDefault === true ? ComentarioVisibilidad.Privado : ComentarioVisibilidad.Publico,
      stars: this.comentario.stars,
    };
  }

  private readonly mentionClickHandler = async (event: any): Promise<void> => {
    const id = Number(event.value?.id);
    await this.organigramaService.openPersonalDetailsById(id, false);
  };

  private async searchTeammate(term: string): Promise<IQuillMention[]> {
    const filteredTeamamtes = await this.personalService.getEntities(term, true);
    return filteredTeamamtes?.map((x) => ({
      id: x.id.toString(),
      value: x.nombreCompleto,
      role: x.esAdmin.toString(),
    }));
  }

  private checkMentionAlert(item: IQuillMention): void {
    if (
      this.comentario.visibilidad === ComentarioVisibilidad.Privado &&
      Number(item.role) === RoleIdEnum.Colaborador &&
      !this.canBeNotified.some((x) => x.id === Number(item.id))
    ) {
      this.snackBar.showInfo('COMMENTS.NO_NOTIFICATION_PRIVATE', { interpolateParams: { name: item.value } });
    } else if (
      Number(item.role) === RoleIdEnum.Colaborador &&
      this.canBeNotified !== undefined &&
      !this.canBeNotified.some((x) => x.id === Number(item.id))
    ) {
      this.snackBar.showInfo(`COMMENTS.${this.cantBeNotifiedKey}`, { interpolateParams: { name: item.value } });
    }
  }
}
