import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoCuestionarioPregunta } from 'src/app/ModelDTO/DTO/tipoCuestionarioPregunta.ENUM';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';


export class SurveyResultDTO implements IEntityDTO {

   title = '';
   type: TipoCuestionarioPregunta;
   answers: Array<CuestionarioRespuestaDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.answers = new Array<CuestionarioRespuestaDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.title != null) { this.title = jsonObj.title; }
      if (jsonObj.type != null) { this.type = jsonObj.type as TipoCuestionarioPregunta; }
      if (jsonObj.answers != null) { for (const item of jsonObj.answers) { const itemDTO = new CuestionarioRespuestaDTO(); itemDTO.PrepareDTO(item); this.answers.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
