import { Injectable } from '@angular/core';
import { InformacionModelDTO } from 'src/app/ModelDTO';
import { CategoriaInformacionDTO, PersonalLegajoBasicoDTO, TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO';
import { SortData } from 'src/app/shared/interfaces';
import { extensionArchivo } from 'src/app/shared/shared-functions';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { DocumentacionSharedService } from './documentacion-shared.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentacionSharedViewModel {
  public desde: Date = new Date(1990, 12);
  public hasta: Date = new Date(2100, 12);
  public endReached = false;
  // TODO: remove categoria
  public categoria: CategoriaInformacionDTO;
  public misDocumentos: InformacionModelDTO[];
  public documentosPublicos: InformacionModelDTO[];
  public documentosEmpresa: InformacionModelDTO[];
  // Booleano para ocultar razgos que no quiero que se vean en el modal de colaboradores
  public modalPerfilShared: boolean;

  constructor(
    private headerService: HeaderAppService,
    private documentacionSharedService: DocumentacionSharedService,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.desde.setMonth(this.desde.getMonth() - 3);
    this.modalPerfilShared = false;
    this.limpiarDocumentos();
    this.limpiarCategoria();
  }

  public async filtrar(
    teammate: PersonalLegajoBasicoDTO,
    categoria: CategoriaInformacionDTO,
    pageNumber: number,
    pageSize: number,
    sortData: SortData,
    period?: Date,
    modalityContractual?: number,
    name?: string,
    office: number = 0,
    businessName: number = 0,
  ): Promise<void> {
    if (categoria.id > 0) {
      if (this.desde && this.hasta) {
        if (!this.endReached) {
          let newData: InformacionModelDTO[] = [];
          if (categoria.visualizacion === TipoCategoriaDocumento.Colaborador) {
            newData = await this.documentacionSharedService.obtenerDocumentacionUsuario(
              pageNumber,
              pageSize,
              this.desde,
              this.hasta,
              period,
              categoria.id,
              teammate?.id ?? 0,
              sortData.sortActive,
              sortData.isAsc,
              modalityContractual,
              name,
              office,
              businessName,
            );

            this.misDocumentos = [...this.misDocumentos, ...newData];
          } else if (categoria.visualizacion === TipoCategoriaDocumento.P1ublico) {
            newData = await this.documentacionSharedService.obtenerProcedimientos(
              pageNumber,
              pageSize,
              this.desde,
              this.hasta,
              period,
              categoria.id,
              sortData.sortActive,
              sortData.isAsc,
              name,
            );

            this.documentosPublicos = [...this.documentosPublicos, ...newData];
          } else if (categoria.visualizacion === TipoCategoriaDocumento.Empresa) {
            newData = await this.documentacionSharedService.obtenerDocumentacionEmpresa(
              pageNumber,
              pageSize,
              this.desde,
              this.hasta,
              period,
              categoria.id,
              sortData.sortActive,
              sortData.isAsc,
              name,
            );

            this.documentosEmpresa = [...this.documentosEmpresa, ...newData];
          }

          if (newData.length < pageSize) {
            this.endReached = true;
          }
        }
      }
    }
  }

  public extension(archivo: string): string {
    return extensionArchivo(archivo);
  }

  public limpiarCategoria(): void {
    this.categoria = new CategoriaInformacionDTO();
  }

  private limpiarDocumentos(): void {
    this.misDocumentos = [];
    this.documentosPublicos = [];
    this.documentosEmpresa = [];
  }
}
