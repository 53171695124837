import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoFeedbackDTO } from 'src/app/ModelDTO/DTO/tipoFeedback.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalFeedbackDTO implements IEntityDTO {

   personal: PersonalLegajoBasicoDTO;
   primerCargo = '';
   totalRecibidos = 0;
   totalEnviados = 0;
   totalFeedback = 0;
   observacion = '';
   fecha: Date;
   fechaCarga: Date;
   tipo: TipoFeedbackDTO;
   responsable: PersonalLegajoBasicoDTO;
   adjuntos: Array<AttachmentUaalooDTO>;
   attachmentConNuevosFirmantes: AttachmentConNuevosFirmantesDTO;
   comentarios: Array<ComentarioDTO>;
   reactions: string | undefined;
   currentReaction: string | undefined;
   cantidadComentarios = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personal = new PersonalLegajoBasicoDTO();
      this.tipo = new TipoFeedbackDTO();
      this.responsable = new PersonalLegajoBasicoDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
      this.attachmentConNuevosFirmantes = new AttachmentConNuevosFirmantesDTO();
      this.comentarios = new Array<ComentarioDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personal != null) { this.personal.PrepareDTO(jsonObj.personal); }
      if (jsonObj.primerCargo != null) { this.primerCargo = jsonObj.primerCargo; }
      if (jsonObj.totalRecibidos != null) { this.totalRecibidos = jsonObj.totalRecibidos; }
      if (jsonObj.totalEnviados != null) { this.totalEnviados = jsonObj.totalEnviados; }
      if (jsonObj.totalFeedback != null) { this.totalFeedback = jsonObj.totalFeedback; }
      if (jsonObj.observacion != null) { this.observacion = jsonObj.observacion; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.tipo != null) { this.tipo.PrepareDTO(jsonObj.tipo); }
      if (jsonObj.responsable != null) { this.responsable.PrepareDTO(jsonObj.responsable); }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.attachmentConNuevosFirmantes != null) { this.attachmentConNuevosFirmantes.PrepareDTO(jsonObj.attachmentConNuevosFirmantes); }
      if (jsonObj.comentarios != null) { for (const item of jsonObj.comentarios) { const itemDTO = new ComentarioDTO(); itemDTO.PrepareDTO(item); this.comentarios.push(itemDTO); } }
      if (jsonObj.cantidadComentarios != null) { this.cantidadComentarios = jsonObj.cantidadComentarios; }
      if (jsonObj.reactions != null) {
        this.reactions = jsonObj.reactions;
      }
      if (jsonObj.currentReaction != null) {
        this.currentReaction = jsonObj.currentReaction;
      }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
