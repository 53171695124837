import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';


export class ConfiguracionFirmaDigitalPosicionModelDTO extends EntityModelDTO<ConfiguracionFirmaDigitalPosicionDTO> {


   public constructor(protected entityDTO: ConfiguracionFirmaDigitalPosicionDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: ConfiguracionFirmaDigitalPosicionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get IncluirFoto(): boolean { return this.entityDTO.incluirFoto; }
   set IncluirFoto(value: boolean) { this.notifyChangeDTO('incluirFoto', value); }

   get Pagina(): number { return this.entityDTO.pagina; }
   set Pagina(value: number) { this.notifyChangeDTO('pagina', value); }

   get PosicionX(): number { return this.entityDTO.posicionX; }
   set PosicionX(value: number) { this.notifyChangeDTO('posicionX', value); }

   get PosicionY(): number { return this.entityDTO.posicionY; }
   set PosicionY(value: number) { this.notifyChangeDTO('posicionY', value); }

   get Escala(): number { return this.entityDTO.escala; }
   set Escala(value: number) { this.notifyChangeDTO('escala', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
