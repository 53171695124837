<div class="comentarios-lista" *ngIf="comentarios.length > 0; else sinDatos">
  <div class="d-flex flex-row flex-fill cursor-pointer virtual-row" *ngFor="let commentExtended of comentarios">
    <app-comment
      [commentExtended]="commentExtended"
      [comentarioHandler]="comentarioHandler"
      [quillModule]="quillModule"
      (edit)="edit($event)"
      (save)="saveEditThrottle($event)"
      (delete)="deleteThrottle($event)"
    ></app-comment>
  </div>
</div>
<ng-template #sinDatos>
  <app-warning-card [delay]="500" [default]="true" [mensaje]="textoSinData"></app-warning-card>
</ng-template>
