import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WeekNumber } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/administrador-planificacion-cuestionario.component';
import { TypeMonthlyPlanningComponent } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/typeMonthlyPlanning.component';

@Component({
  selector: 'app-particular-day',
  templateUrl: './particular-day.component.html',
  styleUrls: ['./particular-day.component.scss'],
})
export class ParticularDayComponent implements TypeMonthlyPlanningComponent {
  @Input() planningForm: UntypedFormGroup;
  @Input() weekNumber: WeekNumber[];
}
