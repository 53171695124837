import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfertaComponent } from 'src/app/public/oferta/oferta.component';

const routes: Routes = [{ path: 'oferta/:token', component: OfertaComponent, data: { title: 'GENERAL.RECRUITMENT' } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
