import { Injectable } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { SancionesService } from 'src/app/layout/administrador/sanciones/sanciones.service';
import { LegajoDTO, PersonalDTO, SancionDTO } from 'src/app/ModelDTO/DTO';
import { sortSancionFechaCarga } from 'src/app/shared/shared-functions';

@Injectable({
  providedIn: 'root',
})
export class UserSancionesSharedViewModel {
  public desde: Date;
  public hasta: Date;
  public personalSelected: PersonalDTO;
  public usuarioString: string;
  public sancionesFiltradas: SancionDTO[];
  public perfilEmpleado: boolean;
  public sancionesRecibidas: SancionDTO[];

  constructor(
    private headerService: HeaderAppService,
    private sancionesService: SancionesService,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.personalSelected = new PersonalDTO();
    this.personalSelected.legajo = new LegajoDTO();
    this.personalSelected.legajo.id = this.headerService.personalLegajoId;
    this.desde = new Date();
    this.hasta = new Date();
    this.desde.setMonth(this.desde.getMonth() - 12);
    this.sancionesFiltradas = [];
    this.perfilEmpleado = false;
  }

  public async filtrar(): Promise<void> {
    if (this.desde && this.hasta) {
      this.sancionesFiltradas = await this.sancionesService.obtenerSanciones(
        this.personalSelected.legajo.id,
        false,
        this.desde,
        this.hasta,
      );
    }
    this.sancionesRecibidas = sortSancionFechaCarga(this.sancionesFiltradas);
  }

  public async obtenerSanciones(personalId: number = undefined): Promise<void> {
    const idPersonalLegajo = personalId ?? this.headerService.personalLegajoId;
    const personalSanciones = await this.sancionesService.obtenerSanciones(idPersonalLegajo, true, this.desde, this.hasta);
    this.sancionesRecibidas = sortSancionFechaCarga(personalSanciones);
  }
}
