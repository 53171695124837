import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalCapacitacionDTO } from 'src/app/ModelDTO/DTO/personalCapacitacion.DTO';
import { EstadoCapacitacion } from 'src/app/ModelDTO/DTO/estadoCapacitacion.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoCapacitacionModelDTO } from 'src/app/ModelDTO/tipoCapacitacion.ModelDTO';
import { TipoResultado } from 'src/app/ModelDTO/DTO/tipoResultado.ENUM';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';


export class PersonalCapacitacionModelDTO extends EntityModelDTO<PersonalCapacitacionDTO> {

   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;
   private personalLegajoModel: PersonalLegajoBasicoModelDTO;
   private personalLegajoSubscribe: Subscription;
   private capacitacionModel: TipoCapacitacionModelDTO;
   private capacitacionSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalCapacitacionDTO) {
      super(entityDTO);
   }

   public static getEstadoCapacitacion(): string[] {
      return EntityModelDTO.getEnumArray(EstadoCapacitacion);
   }

   public static getTipoResultado(): string[] {
      return EntityModelDTO.getEnumArray(TipoResultado);
   }

    protected getStringFromEstadoCapacitacion(enums: EstadoCapacitacion): Array<string> {
        if (enums) {
            const arrays = new Array<string>();
            for (let i = 0; i <= 31; i = i++) {
                const pow = Math.pow(2, i);
                if ((enums & pow) !== 0) {
                    arrays.push(EstadoCapacitacion[pow]);
                }
            }
            return arrays;
        } else {
            return undefined;
        }
    }

    protected getFlagFromEstadoCapacitacionString(strings: Array<string>): EstadoCapacitacion {
        let flags: EstadoCapacitacion;
        strings.forEach(element => {
            const enumVal: EstadoCapacitacion = EstadoCapacitacion[element];
            flags |= enumVal;
        });
        return flags;
    }

   public setEntityDTO(entityDTO: PersonalCapacitacionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.personalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personalLegajo);
      this.personalLegajoSubscribe = this.personalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.capacitacionModel = new TipoCapacitacionModelDTO(this.entityDTO.capacitacion);
      this.capacitacionSubscribe = this.capacitacionModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
      this.personalLegajoModel.dispose();
      this.personalLegajoSubscribe.unsubscribe();
      this.capacitacionModel.dispose();
      this.capacitacionSubscribe.unsubscribe();
   }

   get Estado(): string { return EstadoCapacitacion[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', EstadoCapacitacion[value]); }

   get EstadoArray(): Array<string> { return this.getStringFromEstadoCapacitacion(this.entityDTO.estado); }
   set EstadoArray(value: Array<string>) { this.notifyChangeDTO('estado', this.getFlagFromEstadoCapacitacionString(value)); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get PersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.personalLegajoModel; }
   get PersonalLegajo(): PersonalLegajoBasicoDTO { return this.personalLegajoModel.getEntityDTO(); }
   set PersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personalLegajo = value; this.personalLegajoModel.setEntityDTO(value); }); }

   get CapacitacionModel(): TipoCapacitacionModelDTO { return this.capacitacionModel; }
   get Capacitacion(): TipoCapacitacionDTO { return this.capacitacionModel.getEntityDTO(); }
   set Capacitacion(value: TipoCapacitacionDTO) { this.notifyChange(() => { this.entityDTO.capacitacion = value; this.capacitacionModel.setEntityDTO(value); }); }

   get Resultado(): string { return TipoResultado[this.entityDTO.resultado]; }
   set Resultado(value: string) { this.notifyChangeDTO('resultado', TipoResultado[value]); }

   get FechaPactada(): Date { return this.entityDTO.fechaPactada; }
   set FechaPactada(value: Date) { this.notifyChangeDTO('fechaPactada', value); }

   get CapacitacionEfectiva(): Date { return this.entityDTO.capacitacionEfectiva; }
   set CapacitacionEfectiva(value: Date) { this.notifyChangeDTO('capacitacionEfectiva', value); }

   get VencimientoAproximado(): Date { return this.entityDTO.vencimientoAproximado; }
   set VencimientoAproximado(value: Date) { this.notifyChangeDTO('vencimientoAproximado', value); }

   get UltimoCambio(): Date { return this.entityDTO.ultimoCambio; }
   set UltimoCambio(value: Date) { this.notifyChangeDTO('ultimoCambio', value); }

   get CodigoDeReferencia(): string { return this.entityDTO.codigoDeReferencia; }
   set CodigoDeReferencia(value: string) { this.notifyChangeDTO('codigoDeReferencia', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Motivo(): string { return this.entityDTO.motivo; }
   set Motivo(value: string) { this.notifyChangeDTO('motivo', value); }

   get ConSolicitud(): boolean { return this.entityDTO.conSolicitud; }
   set ConSolicitud(value: boolean) { this.notifyChangeDTO('conSolicitud', value); }

   get Automatica(): boolean { return this.entityDTO.automatica; }
   set Automatica(value: boolean) { this.notifyChangeDTO('automatica', value); }

   get SiguienteCapacitacionGenerada(): boolean { return this.entityDTO.siguienteCapacitacionGenerada; }
   set SiguienteCapacitacionGenerada(value: boolean) { this.notifyChangeDTO('siguienteCapacitacionGenerada', value); }

   get Adjuntos(): Array<AttachmentUaalooDTO> { return this.entityDTO.adjuntos; }
   set Adjuntos(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('adjuntos', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }

}
