import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { RelacionFamiliar } from 'src/app/ModelDTO/DTO/relacionFamiliar.ENUM';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';


export class FamiliarDTO implements IEntityDTO {

   fechaNacimiento: Date;
   aCargo: boolean;
   discapacidad: boolean;
   pep: boolean;
   relacion: RelacionFamiliar;
   nombreCompleto = '';
   dni = '';
   domicilio: DomicilioDTO;
   telefonos = '';
   email = '';
   comentario = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.domicilio = new DomicilioDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaNacimiento != null) { this.fechaNacimiento = new Date(jsonObj.fechaNacimiento); }
      if (jsonObj.aCargo != null) { this.aCargo = jsonObj.aCargo; }
      if (jsonObj.discapacidad != null) { this.discapacidad = jsonObj.discapacidad; }
      if (jsonObj.pep != null) { this.pep = jsonObj.pep; }
      if (jsonObj.relacion != null) { this.relacion = jsonObj.relacion as RelacionFamiliar; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.dni != null) { this.dni = jsonObj.dni; }
      if (jsonObj.domicilio != null) { this.domicilio.PrepareDTO(jsonObj.domicilio); }
      if (jsonObj.telefonos != null) { this.telefonos = jsonObj.telefonos; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.comentario != null) { this.comentario = jsonObj.comentario; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
