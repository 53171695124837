import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NameNumberDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AreaConPersonalDTO } from 'src/app/ModelDTO/DTO/areaConPersonal.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalAreaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getPersonalAreasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalAreas/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalAreasIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalAreas/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getPersonalAreas(): Promise<Array<PersonalAreaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalAreas/'));
         const resJson = res.data;
         const resDTO = new Array<PersonalAreaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalAreaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deletePersonalAreasId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personalAreas/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getPersonalAreasId(id: number): Promise<PersonalAreaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalAreas/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalAreaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePersonalAreasId(id: number, personalAreaDTO: PersonalAreaDTO): Promise<PersonalAreaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personalAreas/' + id, personalAreaDTO));
         const resDTO = new PersonalAreaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalAreas(personalAreaDTO: PersonalAreaDTO): Promise<PersonalAreaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalAreas/', personalAreaDTO));
         const resDTO = new PersonalAreaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalAreasIdSector(id: number, personalSectorDTO: PersonalSectorDTO): Promise<PersonalAreaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalAreas/' + id + '/sector', personalSectorDTO));
         const resDTO = new PersonalAreaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalAreasTotalPersonas(): Promise<Array<NameNumberDetailDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalAreas/totalPersonas'));
         const resJson = res.data;
         const resDTO = new Array<NameNumberDetailDTO>();
         for (const item of resJson) {
            const itemDTO = new NameNumberDetailDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalAreasConPersonal(): Promise<Array<AreaConPersonalDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalAreas/conPersonal'));
         const resJson = res.data;
         const resDTO = new Array<AreaConPersonalDTO>();
         for (const item of resJson) {
            const itemDTO = new AreaConPersonalDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
