/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { PersonalDTO } from 'src/app/api/interfaces/personal.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonalLegajoByIdBackendService {

   constructor(protected http: HttpClient) { }


   public getPersonalLegajoIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/personal/legajo/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getPersonalLegajoId(id: number, basic: boolean = false): Observable<PersonalDTO> {
      return this.http.get<PersonalDTO>(Constants.apiURL + '/personal/legajo/' + id + '?basic=' + basic).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }
}
