import { Component, computed, Inject, Signal } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { DetalleEmpleadoModalComponent } from 'src/app/layout/administrador/colaboradores/nomina/detalle-empleado-modal/detalle-empleado-modal.component';
import { PersonalDTO, RelationshipPositionEnum } from 'src/app/ModelDTO/DTO';
import {
  featureColaboradorDatosContacto,
  featureReducedTeammateProfileAccess,
  featureOnlyAccessOwnTeam,
  featureCanAccessTeammateIdentifier,
} from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';

@Component({
  selector: 'app-detalle-persona-contacto-modal',
  templateUrl: './detalle-persona-contacto-modal.component.html',
})
export class DetallePersonaContactoModalComponent {
  public $verDatosContacto: Signal<boolean>;
  public $canSeeCompleteProfile: Signal<boolean>;
  public $canAccessIdentifier: Signal<boolean>;

  public openProfileCmd: ICommand = new Command(() => this.openProfile(), new BehaviorSubject(true), true);

  private $canOnlySeeTeam: Signal<boolean>;
  private $canShowFullTeammateProfile: Signal<boolean>;

  constructor(
    private dialogRef: MatDialogRef<DetallePersonaContactoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public personal: PersonalDTO,
    private featureFlagService: FeatureFlagService,
    private headerService: HeaderAppService,
    private matDialog: MatDialog,
  ) {
    const { $flags } = this.featureFlagService;
    this.$verDatosContacto = computed(() => FeatureFlagHelper.featureOn(featureColaboradorDatosContacto, $flags().flags));
    this.$canOnlySeeTeam = computed(() => FeatureFlagHelper.featureOn(featureOnlyAccessOwnTeam, $flags().flags));
    this.$canShowFullTeammateProfile = computed(() => FeatureFlagHelper.featureOff(featureReducedTeammateProfileAccess, $flags().flags));
    this.$canAccessIdentifier = computed(() => FeatureFlagHelper.featureOn(featureCanAccessTeammateIdentifier, $flags().flags));

    this.$canSeeCompleteProfile = computed(() => {
      const isSubordinateOfSupervisor = this.$canOnlySeeTeam()
        ? this.personal.relationshipPosition === RelationshipPositionEnum.Superior
        : false;

      return (
        this.$canShowFullTeammateProfile() && ((this.headerService.modoEmpresa() && !this.$canOnlySeeTeam()) || isSubordinateOfSupervisor)
      );
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private async openProfile(): Promise<void> {
    this.matDialog.closeAll();
    this.matDialog.open(DetalleEmpleadoModalComponent, {
      panelClass: 'flex-modal',
      width: '90%',
      height: '90%',
      disableClose: false,
      data: { teammateId: this.personal.id },
    });
  }
}
