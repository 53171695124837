/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { CommentExtended } from 'src/app/app-common/comentarios/commentExtended';
import { EMPTY, map, Observable, of, tap } from 'rxjs';
import { IComentarios } from './icomentarios';

export abstract class ComentarioBase implements IComentarios {
  public sendMessage(id: number, mensaje: FormData): Observable<void> {
    return EMPTY;
  }

  public init(id: number, idPersonalLegajo: number, isAdmin: boolean): Observable<CommentExtended[]> {
    const comments = this.onInit(id);
    return comments.pipe(
      map((commentsArray) =>
        commentsArray.map((comment) => {
          const extendedComment: CommentExtended = {
            ...comment,
            canEdit: comment.personalLegajo.id === idPersonalLegajo,
            canDelete: comment.personalLegajo.id === idPersonalLegajo || isAdmin,
            inEdition: undefined,
          };
          return extendedComment;
        }),
      ),
    );
  }

  public onInit(id: number): Observable<ComentarioDTO[]> {
    return null;
  }

  public isPrivateDefault(): boolean {
    return false;
  }
  public showPrivate(): boolean {
    return true;
  }
  public getTitle(): string {
    return null;
  }
  public needPadding(): boolean {
    return false;
  }
  public getPlaceHolder(tipoVisibilidad?: number): string {
    return null;
  }
  public getNoDataString(): string {
    return null;
  }
  public editMessage(id: number, mensaje: ComentarioDTO): Observable<ComentarioDTO> {
    return EMPTY;
  }
  public deleteMessage(id: number, mensaje: ComentarioDTO): Observable<void> {
    return EMPTY;
  }
  public canEditMessage(): boolean {
    return true;
  }
  public canDeleteMessage(): boolean {
    return true;
  }

  public allowStars(): boolean {
    return false;
  }
}
