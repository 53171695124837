import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SolicitudAusenciaDTO } from 'src/app/ModelDTO/DTO/solicitudAusencia.DTO';
import { SortAbsenceRequest } from 'src/app/ModelDTO/DTO/sortAbsenceRequest.ENUM';
import { AuditLogEntryDTO, DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class SolicitudAusenciaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getSolicitudAusenciaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudAusencia/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertSolicitudAusencia(solicitudAusenciaDTO: FormData): Promise<SolicitudAusenciaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/solicitudAusencia/', solicitudAusenciaDTO));
         const resDTO = new SolicitudAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudAusenciaId(id: number, solicitudAusenciaDTO: FormData): Promise<SolicitudAusenciaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudAusencia/' + id, solicitudAusenciaDTO));
         const resDTO = new SolicitudAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudAusenciaPorSolicitudId(id: number): Promise<SolicitudAusenciaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudAusencia/porSolicitud/' + id));
         if (!res) { return null; }
         const resDTO = new SolicitudAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudAusenciaIdEstado(id: number, solicitudAusenciaDTO: SolicitudAusenciaDTO): Promise<SolicitudAusenciaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudAusencia/' + id + '/estado', solicitudAusenciaDTO));
         const resDTO = new SolicitudAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudAusenciaFiltradas(fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, estado: number = 0, personal: number = 0, sortActive: SortAbsenceRequest = undefined, isAsc: boolean = false): Promise<Array<SolicitudAusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudAusencia/filtradas' + '?fechaDesde=' + fechaDesde?.toISOString() +
            '&fechaHasta=' + fechaHasta?.toISOString() +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&estado=' + estado +
            '&personal=' + personal +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<SolicitudAusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new SolicitudAusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getSolicitudAusenciaIdPersonalAusencia(idPersonalAusencia: number): Promise<SolicitudAusenciaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudAusencia/teammate-absence/' + idPersonalAusencia));
         if (!res) { return null; }
         const resDTO = new SolicitudAusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteSolicitudAusenciaId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/solicitudAusencia/' + id));

   }

}
