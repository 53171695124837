import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-question-type-header',
  templateUrl: './question-type-header.component.html',
  styleUrls: ['./question-type-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionTypeHeaderComponent {
  @Input() public position: number;
  @Input() public title: string;
  @Input() public mandatory: boolean;
  @Input() public answerCommentEnabled: boolean;
  @Output() public commentClicked = new EventEmitter<void>();

  public onCommentClicked(): void {
    this.commentClicked.emit();
  }
}
