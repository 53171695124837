import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NotificationToPersonalsDTO } from 'src/app/ModelDTO/DTO/notificationToPersonals.DTO';
import { AddresseesDTO } from 'src/app/ModelDTO/DTO/addressees.DTO';
import { AddresseesModelDTO } from 'src/app/ModelDTO/addressees.ModelDTO';
import { CategoriaInformacionDTO } from 'src/app/ModelDTO/DTO/categoriaInformacion.DTO';
import { CategoriaInformacionModelDTO } from 'src/app/ModelDTO/categoriaInformacion.ModelDTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { ConfiguracionFirmaDigitalPosicionModelDTO } from 'src/app/ModelDTO/configuracionFirmaDigitalPosicion.ModelDTO';
import { NotificationPriority } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NotificationState } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class NotificationToPersonalsModelDTO extends EntityModelDTO<NotificationToPersonalsDTO> {

   private destinatariosModel: AddresseesModelDTO;
   private destinatariosSubscribe: Subscription;
   private carpetaModel: CategoriaInformacionModelDTO;
   private carpetaSubscribe: Subscription;
   private posicionFirmaColaboradorModel: ConfiguracionFirmaDigitalPosicionModelDTO;
   private posicionFirmaColaboradorSubscribe: Subscription;
   private posicionFirmaEmpresaModel: ConfiguracionFirmaDigitalPosicionModelDTO;
   private posicionFirmaEmpresaSubscribe: Subscription;

   public constructor(protected entityDTO: NotificationToPersonalsDTO) {
      super(entityDTO);
   }

   public static getNotificationPriority(): string[] {
      return EntityModelDTO.getEnumArray(NotificationPriority);
   }

   public static getNotificationState(): string[] {
      return EntityModelDTO.getEnumArray(NotificationState);
   }

   public setEntityDTO(entityDTO: NotificationToPersonalsDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.destinatariosModel = new AddresseesModelDTO(this.entityDTO.destinatarios);
      this.destinatariosSubscribe = this.destinatariosModel.changed.subscribe((changed) => this.changed.next(changed));
      this.carpetaModel = new CategoriaInformacionModelDTO(this.entityDTO.carpeta);
      this.carpetaSubscribe = this.carpetaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.posicionFirmaColaboradorModel = new ConfiguracionFirmaDigitalPosicionModelDTO(this.entityDTO.posicionFirmaColaborador);
      this.posicionFirmaColaboradorSubscribe = this.posicionFirmaColaboradorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.posicionFirmaEmpresaModel = new ConfiguracionFirmaDigitalPosicionModelDTO(this.entityDTO.posicionFirmaEmpresa);
      this.posicionFirmaEmpresaSubscribe = this.posicionFirmaEmpresaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.destinatariosModel.dispose();
      this.destinatariosSubscribe.unsubscribe();
      this.carpetaModel.dispose();
      this.carpetaSubscribe.unsubscribe();
      this.posicionFirmaColaboradorModel.dispose();
      this.posicionFirmaColaboradorSubscribe.unsubscribe();
      this.posicionFirmaEmpresaModel.dispose();
      this.posicionFirmaEmpresaSubscribe.unsubscribe();
   }

   get DestinatariosModel(): AddresseesModelDTO { return this.destinatariosModel; }
   get Destinatarios(): AddresseesDTO { return this.destinatariosModel.getEntityDTO(); }
   set Destinatarios(value: AddresseesDTO) { this.notifyChange(() => { this.entityDTO.destinatarios = value; this.destinatariosModel.setEntityDTO(value); }); }

   get CarpetaModel(): CategoriaInformacionModelDTO { return this.carpetaModel; }
   get Carpeta(): CategoriaInformacionDTO { return this.carpetaModel.getEntityDTO(); }
   set Carpeta(value: CategoriaInformacionDTO) { this.notifyChange(() => { this.entityDTO.carpeta = value; this.carpetaModel.setEntityDTO(value); }); }

   get SolicitarFirma(): boolean { return this.entityDTO.solicitarFirma; }
   set SolicitarFirma(value: boolean) { this.notifyChangeDTO('solicitarFirma', value); }

   get FirmarAhora(): boolean { return this.entityDTO.firmarAhora; }
   set FirmarAhora(value: boolean) { this.notifyChangeDTO('firmarAhora', value); }

   get NotificarFirma(): boolean { return this.entityDTO.notificarFirma; }
   set NotificarFirma(value: boolean) { this.notifyChangeDTO('notificarFirma', value); }

   get PosicionFirmaColaboradorModel(): ConfiguracionFirmaDigitalPosicionModelDTO { return this.posicionFirmaColaboradorModel; }
   get PosicionFirmaColaborador(): ConfiguracionFirmaDigitalPosicionDTO { return this.posicionFirmaColaboradorModel.getEntityDTO(); }
   set PosicionFirmaColaborador(value: ConfiguracionFirmaDigitalPosicionDTO) { this.notifyChange(() => { this.entityDTO.posicionFirmaColaborador = value; this.posicionFirmaColaboradorModel.setEntityDTO(value); }); }

   get PosicionFirmaEmpresaModel(): ConfiguracionFirmaDigitalPosicionModelDTO { return this.posicionFirmaEmpresaModel; }
   get PosicionFirmaEmpresa(): ConfiguracionFirmaDigitalPosicionDTO { return this.posicionFirmaEmpresaModel.getEntityDTO(); }
   set PosicionFirmaEmpresa(value: ConfiguracionFirmaDigitalPosicionDTO) { this.notifyChange(() => { this.entityDTO.posicionFirmaEmpresa = value; this.posicionFirmaEmpresaModel.setEntityDTO(value); }); }

   get UserName(): string { return this.entityDTO.userName; }
   set UserName(value: string) { this.notifyChangeDTO('userName', value); }

   get Priority(): string { return NotificationPriority[this.entityDTO.priority]; }
   set Priority(value: string) { this.notifyChangeDTO('priority', NotificationPriority[value]); }

   get NotificationType(): number { return this.entityDTO.notificationType; }
   set NotificationType(value: number) { this.notifyChangeDTO('notificationType', value); }

   get State(): string { return NotificationState[this.entityDTO.state]; }
   set State(value: string) { this.notifyChangeDTO('state', NotificationState[value]); }

   get CreationDateTime(): Date { return this.entityDTO.creationDateTime; }
   set CreationDateTime(value: Date) { this.notifyChangeDTO('creationDateTime', value); }

   get OpenDateTime(): Date { return this.entityDTO.openDateTime; }
   set OpenDateTime(value: Date) { this.notifyChangeDTO('openDateTime', value); }

   get ReadDateTime(): Date { return this.entityDTO.readDateTime; }
   set ReadDateTime(value: Date) { this.notifyChangeDTO('readDateTime', value); }

   get DisableUnderstanding(): boolean { return this.entityDTO.disableUnderstanding; }
   set DisableUnderstanding(value: boolean) { this.notifyChangeDTO('disableUnderstanding', value); }

   get UnderstoodDateTime(): Date { return this.entityDTO.understoodDateTime; }
   set UnderstoodDateTime(value: Date) { this.notifyChangeDTO('understoodDateTime', value); }

   get NotUnderstood(): boolean { return this.entityDTO.notUnderstood; }
   set NotUnderstood(value: boolean) { this.notifyChangeDTO('notUnderstood', value); }

   get Title(): string { return this.entityDTO.title; }
   set Title(value: string) { this.notifyChangeDTO('title', value); }

   get NotShowInMenu(): boolean { return this.entityDTO.notShowInMenu; }
   set NotShowInMenu(value: boolean) { this.notifyChangeDTO('notShowInMenu', value); }

   get Details(): string { return this.entityDTO.details; }
   set Details(value: string) { this.notifyChangeDTO('details', value); }

   get ServiceNotify(): string { return this.entityDTO.serviceNotify; }
   set ServiceNotify(value: string) { this.notifyChangeDTO('serviceNotify', value); }

   get Service(): string { return this.entityDTO.service; }
   set Service(value: string) { this.notifyChangeDTO('service', value); }

   get ServiceId(): number { return this.entityDTO.serviceId; }
   set ServiceId(value: number) { this.notifyChangeDTO('serviceId', value); }

   get ServiceId2(): number { return this.entityDTO.serviceId2; }
   set ServiceId2(value: number) { this.notifyChangeDTO('serviceId2', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
