import { Directive, EventEmitter, Output } from '@angular/core';

import { CompleterItem } from '../components/completer-item';

export interface CompleterList {
  search(term: string): void;
  open(): void;
  isOpen(open: boolean): void;
  clear(): void;
}

export interface CompleterDropdown {
  clear(): void;
  selectCurrent(): void;
  nextRow(): void;
  prevRow(): void;
  highlightRow(index: number | null): void;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ctrCompleter]',
})
export class CtrCompleterDirective {
  @Output() public selected = new EventEmitter<CompleterItem | null>();
  @Output() public highlighted = new EventEmitter<CompleterItem | null>();
  @Output() public opened = new EventEmitter<boolean>();
  @Output() public dataSourceChange = new EventEmitter<void>();

  private list: CompleterList;
  private dropdown: CompleterDropdown | null;
  private hasItemHighlighted = false;
  private hasItemSelected = false;
  private cancelItemBlur = false;
  private isComponentOpen = false;
  private highlightIndex: number | null;

  public registerList(list: CompleterList): void {
    this.list = list;
  }

  public registerDropdown(dropdown: CompleterDropdown | null): void {
    this.dropdown = dropdown;
  }

  public onHighlighted(item: CompleterItem | null): void {
    this.highlighted.emit(item);
    this.hasItemHighlighted = !!item;
  }

  public onSelected(item: CompleterItem, clearList = true): void {
    this.selected.emit(item);
    if (item) {
      this.hasItemSelected = true;
    }
    if (clearList) {
      this.clear();
    }
  }

  public onDataSourceChange(): void {
    if (this.hasSelected) {
      this.selected.emit(null);
      this.hasItemSelected = false;
    }
    this.dataSourceChange.emit();
  }

  public search(term: string): void {
    if (this.hasItemSelected) {
      this.selected.emit(null);
      this.hasItemSelected = false;
    }
    if (this.list) {
      this.list.search(term);
    }
  }

  public clear(): void {
    this.hasItemHighlighted = false;
    this.isOpen = false;

    if (this.dropdown) {
      this.dropdown.clear();
    }

    if (this.list) {
      this.list.clear();
    }
  }

  public selectCurrent(): void {
    if (this.dropdown) {
      this.dropdown.selectCurrent();
    }
  }

  public nextRow(): void {
    if (this.dropdown) {
      this.dropdown.nextRow();
    }
  }

  public prevRow(): void {
    if (this.dropdown) {
      this.dropdown.prevRow();
    }
  }

  public hasHighlighted(): boolean {
    return this.hasItemHighlighted;
  }

  public cancelBlur(cancel: boolean): void {
    this.cancelItemBlur = cancel;
  }

  public isCancelBlur(): boolean {
    return this.cancelItemBlur;
  }

  public open(): void {
    if (!this.isComponentOpen) {
      this.isOpen = true;
      this.list.open();
    }
  }

  public get isOpen(): boolean {
    return this.isComponentOpen;
  }

  public set isOpen(open: boolean) {
    this.isComponentOpen = open;
    this.opened.emit(this.isComponentOpen);
    if (this.list) {
      this.list.isOpen(open);
    }
  }

  public get autoHighlightIndex(): number {
    return this.highlightIndex;
  }

  public set autoHighlightIndex(index: number | null) {
    this.highlightIndex = index;
    if (this.dropdown) {
      this.dropdown.highlightRow(this.highlightIndex);
    }
  }

  public get hasSelected(): boolean {
    return this.hasItemSelected;
  }
}
