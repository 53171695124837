import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { RecaptchaService } from './recaptcha.service';
import { RecaptchaWebService } from './recaptcha-web.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, RecaptchaV3Module],
})
export class RecaptchaModule {
  static forRoot(): ModuleWithProviders<RecaptchaModule> {
    return {
      ngModule: RecaptchaModule,
      providers: [
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: '6Lf0eaYZAAAAAMzSxYbLX9ZQB5oMmhcvm61qmgjD',
        },
        { provide: RecaptchaService, useClass: RecaptchaWebService },
      ],
    };
  }
}
