import { Injectable, Inject } from '@angular/core';
import { DataDTO, NameDetailDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CategoriaCantidadDTO } from 'src/app/ModelDTO/DTO/categoriaCantidad.DTO';
import { CategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/categoriaCapacitacion.DTO';
import { SubcategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/subcategoriaCapacitacion.DTO';
import { NameDetailDTO as NameDetailInterfaceDTO } from 'src/app/api/interfaces/name-detail.interface';

@Injectable({
   providedIn: 'root'
})
export class CategoriaCapacitacionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCategoriaCapacitacionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/categoriaCapacitacion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCategoriaCapacitacionIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/categoriaCapacitacion/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getCategoriaCapacitacion(): Promise<Array<CategoriaCapacitacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/categoriaCapacitacion/'));
         const resJson = res.data;
         const resDTO = new Array<CategoriaCapacitacionDTO>();
         for (const item of resJson) {
            const itemDTO = new CategoriaCapacitacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteCategoriaCapacitacionId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/categoriaCapacitacion/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getCategoriaCapacitacionId(id: number): Promise<CategoriaCapacitacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/categoriaCapacitacion/' + id));
         if (!res) { return null; }
         const resDTO = new CategoriaCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCategoriaCapacitacionId(id: number, categoriaCapacitacionDTO: CategoriaCapacitacionDTO): Promise<CategoriaCapacitacionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/categoriaCapacitacion/' + id, categoriaCapacitacionDTO));
         const resDTO = new CategoriaCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertCategoriaCapacitacion(categoriaCapacitacionDTO: CategoriaCapacitacionDTO): Promise<CategoriaCapacitacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/categoriaCapacitacion/', categoriaCapacitacionDTO));
         const resDTO = new CategoriaCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertCategoriaCapacitacionIdSubcategoria(id: number, subcategoriaCapacitacionDTO: SubcategoriaCapacitacionDTO): Promise<CategoriaCantidadDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/categoriaCapacitacion/' + id + '/subcategoria', subcategoriaCapacitacionDTO));
         const resDTO = new CategoriaCantidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
