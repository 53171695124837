import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

export interface BackgroundPhotoProps {
  centralImgSrc: string;
  backgroundColor?: string;
  backgroundImgSrc: string;
  title?: string;
  showEditButton?: boolean;
  userName?: string;
}

@Component({
  selector: 'app-background-photo',
  standalone: true,
  imports: [CircleImageComponent, MatIconModule, CommonModule, MatButtonModule],
  templateUrl: './background-photo.component.html',
  styleUrls: ['./background-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundPhotoComponent {
  @Output() public edit = new EventEmitter();

  @Input() public set props(value: BackgroundPhotoProps) {
    this.properties = value;
    this.generateBackgroundImg()
      .then(() => {
        this.changeDetector.markForCheck();
      })
      .catch();
  }

  public properties: BackgroundPhotoProps;
  public backgroundImg = '';

  constructor(private changeDetector: ChangeDetectorRef) {}

  public async generateBackgroundImg(): Promise<void> {
    // Crear un elemento canvas
    const canvas = document.createElement('canvas');
    canvas.width = 711;
    canvas.height = 250;
    const ctx = canvas.getContext('2d');

    // Rellenar el fondo con el color
    ctx.fillStyle = this.properties.backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Si se proporcionó una imagen de fondo, cargar y dibujar
    if (this.properties.backgroundImgSrc) {
      const imageElement = new Image();
      imageElement.crossOrigin = 'Anonymous';
      await new Promise((resolve, reject) => {
        imageElement.onload = (): void => {
          ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
          resolve(imageElement.src);
        };
        imageElement.onerror = reject;
        imageElement.src = this.properties.backgroundImgSrc;
      });
    }

    // Si se proporcionó una imagen central, cargar y dibujar
    if (this.properties.centralImgSrc) {
      const centralImgElement = new Image();
      centralImgElement.crossOrigin = 'Anonymous';
      await new Promise((resolve, reject) => {
        centralImgElement.onload = (): void => {
          const circleDiameter = 132; // Diámetro del círculo
          const dx = (canvas.width - circleDiameter) / 2;
          const dy = (canvas.height - circleDiameter) / 2;

          // Dibujar un círculo y usarlo como clip
          ctx.beginPath();
          ctx.arc(dx + circleDiameter / 2, dy + circleDiameter / 2, circleDiameter / 2, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.clip();

          // Dibujar la imagen central dentro del clip circular
          ctx.drawImage(centralImgElement, dx, dy, circleDiameter, circleDiameter);

          // Restaurar el contexto para eliminar el clip
          ctx.restore();

          resolve(centralImgElement.src);
        };
        centralImgElement.onerror = reject;
        centralImgElement.src = this.properties.centralImgSrc;
      });
    } else if (this.properties.userName) {
      const split = (this.properties.userName as string).split(' ');
      const initials = split[0].substring(0, 1) + (split.length > 1 ? split[1].substring(0, 1) : '');

      const dx = canvas.width / 2;
      const dy = canvas.height / 2;

      ctx.font = '30px Roboto, sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = 'white';

      ctx.fillText(initials, dx, dy);
    }

    // Convertir el canvas a una imagen en formato Base64
    this.backgroundImg = canvas.toDataURL('image/png');
  }

  public editEvent(): void {
    this.edit.emit();
  }
}
