<div class="d-flex flex-column flex-grow-1 gap-1">
  <form [formGroup]="planningForm">
    <mat-form-field class="w-33 flex-fill" appearance="outline">
      <mat-label>Día particular</mat-label>
      <input
        #diaParticular
        matInput
        type="number"
        id="diaParticular"
        name="diaParticular"
        formControlName="particularDay"
        min="1"
        max="31"
      />
    </mat-form-field>
  </form>
</div>
