import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class CandidatoPostuladoUserValidationDTO implements IEntityDTO {

   email = '';
   offerToken = '';
   reCaptchaToken = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.offerToken != null) { this.offerToken = jsonObj.offerToken; }
      if (jsonObj.reCaptchaToken != null) { this.reCaptchaToken = jsonObj.reCaptchaToken; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
