import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TaskElementType } from 'src/app/ModelDTO/DTO/taskElementType.ENUM';
import { TaskGoalInformationDTO } from 'src/app/ModelDTO/DTO/taskGoalInformation.DTO';
import { TaskStatus } from 'src/app/ModelDTO/DTO/taskStatus.ENUM';
import { TaskTriggerDTO } from 'src/app/ModelDTO/DTO/taskTrigger.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class TaskElementDTO implements IEntityDTO {

   tasks: Array<TaskElementDTO>;
   triggers: Array<TaskTriggerDTO>;
   taskGoalInformation: TaskGoalInformationDTO;
   taskType: TaskElementType;
   status: TaskStatus;
   title = '';
   body = '';
   created: Date;
   due: Date;
   completed: Date;
   forceOrder: boolean;
   position = 0;
   creator: PersonalLegajoBasicoDTO;
   owner: PersonalLegajoBasicoDTO;
   forcedOwner: PersonalLegajoBasicoDTO;
   target: PersonalLegajoBasicoDTO;
   expiresIn: number;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.tasks = new Array<TaskElementDTO>();
      this.triggers = new Array<TaskTriggerDTO>();
      this.creator = new PersonalLegajoBasicoDTO();
      this.owner = new PersonalLegajoBasicoDTO();
      this.forcedOwner = new PersonalLegajoBasicoDTO();
      this.target = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tasks != null) { for (const item of jsonObj.tasks) { const itemDTO = new TaskElementDTO(); itemDTO.PrepareDTO(item); this.tasks.push(itemDTO); } }
      if (jsonObj.triggers != null) { for (const item of jsonObj.triggers) { const itemDTO = new TaskTriggerDTO(); itemDTO.PrepareDTO(item); this.triggers.push(itemDTO); } }
      if (jsonObj.taskGoalInformation != null) { 
         this.taskGoalInformation = new TaskGoalInformationDTO();
         this.taskGoalInformation.PrepareDTO(jsonObj.taskGoalInformation); 
      }
      if (jsonObj.taskType != null) { this.taskType = jsonObj.taskType as TaskElementType; }
      if (jsonObj.status != null) { this.status = jsonObj.status as TaskStatus; }
      if (jsonObj.title != null) { this.title = jsonObj.title; }
      if (jsonObj.body != null) { this.body = jsonObj.body; }
      if (jsonObj.created != null) { this.created = new Date(jsonObj.created); }
      if (jsonObj.due != null) { this.due = new Date(jsonObj.due); }
      if (jsonObj.completed != null) { this.completed = new Date(jsonObj.completed); }
      if (jsonObj.forceOrder != null) { this.forceOrder = jsonObj.forceOrder; }
      if (jsonObj.position != null) { this.position = jsonObj.position; }
      if (jsonObj.creator != null) { this.creator.PrepareDTO(jsonObj.creator); }
      if (jsonObj.owner != null) { this.owner.PrepareDTO(jsonObj.owner); }
      if (jsonObj.forcedOwner != null) { this.forcedOwner.PrepareDTO(jsonObj.forcedOwner); }
      if (jsonObj.target != null) { this.target.PrepareDTO(jsonObj.target); }
      if (jsonObj.expiresIn != null) { this.expiresIn = jsonObj.expiresIn; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
