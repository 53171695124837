import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO/tipoEntrega.DTO';
import { TipoEntregaFlatDTO } from 'src/app/ModelDTO/DTO/tipoEntregaFlat.DTO';

@Injectable({
   providedIn: 'root'
})
export class TipoEntregaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getTipoEntregaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoEntregaIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoEntrega/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/pdf' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getTipoEntrega(): Promise<Array<TipoEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/'));
         const resJson = res.data;
         const resDTO = new Array<TipoEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoEntregaId(id: number): Promise<TipoEntregaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoEntrega/' + id));
         if (!res) { return null; }
         const resDTO = new TipoEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateTipoEntregaId(id: number, tipoEntregaDTO: TipoEntregaDTO): Promise<TipoEntregaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tipoEntrega/' + id, tipoEntregaDTO));
         const resDTO = new TipoEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertTipoEntrega(tipoEntregaDTO: TipoEntregaDTO): Promise<TipoEntregaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tipoEntrega/', tipoEntregaDTO));
         const resDTO = new TipoEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getTipoEntregaAutoComplete(contiene: string, pageSize: number): Promise<Array<TipoEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<TipoEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoEntregaPersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Promise<Array<TipoEntregaFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/personal/' + idPersonalLegajo));
         const resJson = res.data;
         const resDTO = new Array<TipoEntregaFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoEntregaFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoEntregaConvenioIDCONVENIO(idConvenio: number): Promise<Array<TipoEntregaFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/convenio/' + idConvenio));
         const resJson = res.data;
         const resDTO = new Array<TipoEntregaFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoEntregaFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoEntregaFullpersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Promise<Array<TipoEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoEntrega/fullpersonal/' + idPersonalLegajo));
         const resJson = res.data;
         const resDTO = new Array<TipoEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteTipoEntregaId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/tipoEntrega/' + id));

   }

}
