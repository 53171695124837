import { ChangeDetectorRef, Directive, Host, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import type { EmbeddedViewRef, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CtrCompleterDirective } from './ctr-completer';
import type { CompleterList } from './ctr-completer';
import { CompleterData } from '../services/completer-data';
import { CompleterItem } from '../components/completer-item';
import { MIN_SEARCH_LENGTH, PAUSE, CLEAR_TIMEOUT, isNil } from '../globals';

export class CtrListContext {
  constructor(
    public results: CompleterItem[] | null,
    public searching: boolean,
    public searchInitialized: boolean,
    public isOpen: boolean,
  ) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ctrList]',
})
export class CtrListDirective implements OnInit, CompleterList {
  @Input() public ctrListMinSearchLength = MIN_SEARCH_LENGTH;
  @Input() public ctrListPause = PAUSE;
  @Input() public ctrListAutoMatch = false;
  @Input() public ctrListAutoHighlight = false;
  @Input() public ctrListDisplaySearching = true;

  private completerData: CompleterData;
  // private results: CompleterItem[] = [];
  private term: string | null = null;
  // private searching = false;
  private searchTimer: Subscription | null = null;
  private clearTimer: Subscription | null = null;
  private ctx = new CtrListContext([], false, false, false);
  private initValue: any = null;
  private viewRef: EmbeddedViewRef<CtrListContext> | null = null;

  constructor(
    @Host() private completer: CtrCompleterDirective,
    private templateRef: TemplateRef<CtrListContext>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.completer.registerList(this);
    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, new CtrListContext([], false, false, false));
  }

  @Input('ctrList')
  public set dataService(newService: CompleterData) {
    this.completerData = newService;
    this.dataServiceSubscribe();
  }

  @Input('ctrListInitialValue')
  public set initialValue(value: any) {
    if (this.completerData && typeof this.completerData.convertToItem === 'function') {
      setTimeout(() => {
        if (value) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const initialItem = this.completerData.convertToItem!(value);
          if (initialItem) {
            this.completer.onSelected(initialItem, false);
          }
        }
      });
    } else if (!this.completerData) {
      this.initValue = value;
    }
  }

  public search(term: string): void {
    if (!isNil(term) && term.length >= this.ctrListMinSearchLength && this.term !== term) {
      if (this.searchTimer) {
        this.searchTimer.unsubscribe();
        this.searchTimer = null;
      }
      if (!this.ctx.searching) {
        if (this.ctrListDisplaySearching) {
          this.ctx.results = [];
        }
        this.ctx.searching = true;
        this.ctx.searchInitialized = true;
        this.refreshTemplate();
      }
      if (this.clearTimer) {
        this.clearTimer.unsubscribe();
      }
      this.searchTimer = timer(this.ctrListPause)
        .pipe(take(1))
        .subscribe(() => {
          this.searchTimerComplete(term);
        });
    } else if (!isNil(term) && term.length < this.ctrListMinSearchLength) {
      this.clear();
      this.term = '';
    }
  }

  public clear(): void {
    if (this.searchTimer) {
      this.searchTimer.unsubscribe();
    }
    this.clearTimer = timer(CLEAR_TIMEOUT)
      .pipe(take(1))
      .subscribe(() => {
        this.clearData();
      });
  }

  public open(): void {
    if (!this.ctx.searchInitialized) {
      this.search('');
    }
    this.refreshTemplate();
  }

  public isOpen(open: boolean): void {
    this.ctx.isOpen = open;
  }

  private clearData(): void {
    if (this.searchTimer) {
      this.searchTimer.unsubscribe();
      this.searchTimer = null;
    }
    if (this.dataService) {
      this.dataService.cancel();
    }

    this.viewContainer.clear();
    this.viewRef = null;
  }

  private searchTimerComplete(term: string) {
    // Begin the search
    if (isNil(term) || term.length < this.ctrListMinSearchLength) {
      this.ctx.searching = false;
      return;
    }
    this.term = term;
    this.completerData.search(term);
  }

  private refreshTemplate() {
    // create the template if it doesn't exist
    if (!this.viewRef) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, this.ctx);
    } else if (!this.viewRef.destroyed) {
      // refresh the template
      this.viewRef!.context.isOpen = this.ctx.isOpen;
      this.viewRef!.context.results = this.ctx.results;
      this.viewRef!.context.searching = this.ctx.searching;
      this.viewRef!.context.searchInitialized = this.ctx.searchInitialized;
      this.viewRef.detectChanges();
    }
    this.cd.markForCheck();
  }

  private getBestMatchIndex() {
    if (!this.ctx.results || !this.term) {
      return null;
    }

    // First try to find the exact term
    let bestMatch = this.ctx.results.findIndex((item) => item.title.toLowerCase() === this.term!.toLocaleLowerCase());
    // If not try to find the first item that starts with the term
    if (bestMatch < 0) {
      bestMatch = this.ctx.results.findIndex((item) => item.title.toLowerCase().startsWith(this.term!.toLocaleLowerCase()));
    }
    // If not try to find the first item that includes the term
    if (bestMatch < 0) {
      bestMatch = this.ctx.results.findIndex((item) => item.title.toLowerCase().includes(this.term!.toLocaleLowerCase()));
    }

    return bestMatch < 0 ? null : bestMatch;
  }

  private dataServiceSubscribe() {
    if (this.completerData) {
      this.completerData
        .pipe(
          catchError((err: unknown) => {
            console.error(err);
            console.error('Unexpected error in dataService: errors should be handled by the dataService Observable');
            return [];
          }),
        )
        .subscribe((results) => {
          this.ctx.searchInitialized = true;
          this.ctx.searching = false;
          this.ctx.results = results;

          if (
            this.ctrListAutoMatch &&
            results &&
            results.length === 1 &&
            results[0].title &&
            !isNil(this.term) &&
            results[0].title.toLocaleLowerCase() === this.term!.toLocaleLowerCase()
          ) {
            // Do automatch
            this.completer.onSelected(results[0]);
            return;
          }

          if (this.initValue) {
            this.initialValue = this.initValue;
            this.initValue = null;
          }

          this.refreshTemplate();

          if (this.ctrListAutoHighlight) {
            this.completer.autoHighlightIndex = this.getBestMatchIndex();
          }
        });

      if (this.completerData.dataSourceChange) {
        this.completerData.dataSourceChange.subscribe(() => {
          this.term = null;
          this.ctx.searchInitialized = false;
          this.ctx.searching = false;
          this.ctx.results = [];
          this.refreshTemplate();
          this.completer.onDataSourceChange();
        });
      }
    }
  }
}
