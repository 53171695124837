import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FeatureDTO } from 'src/app/ModelDTO/DTO/feature.DTO';

@Injectable({
   providedIn: 'root'
})
export class FeatureServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFeatureIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feature/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFeature(): Promise<Array<FeatureDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feature/'));
         const resJson = res.data;
         const resDTO = new Array<FeatureDTO>();
         for (const item of resJson) {
            const itemDTO = new FeatureDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
