import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ICommand, Command } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectAndCropComponentData {
  event: any;
  aspectRatio: number;
  resizeW: number;
  resizeH: number;
  previewW: number;
  previewH: number;
}

@Component({
  selector: 'app-select-and-crop',
  templateUrl: './select-and-crop.component.html',
  styleUrls: ['./select-and-crop.component.scss'],
})
export class SelectAndCropComponent implements OnInit {
  @Input() public aspRatio = 1 / 1;
  @Input() public resizeWidth = 350;
  @Input() public resizeHeight = 350;

  @Input() public widthPreview = 200;
  @Input() public heightPreview = 200;

  private puedoGuardar = new BehaviorSubject(false);

  public aceptarCmd: ICommand = new Command(() => this.aceptar(), this.puedoGuardar, false);
  public cancelarCmd: ICommand = new Command(() => this.cancelar(), new BehaviorSubject(true), false);
  public croppedImage = null;
  public imageChangedEvent: any = '';
  public loading = true;

  constructor(
    private dialogRef: MatDialogRef<SelectAndCropComponent>,
    private neoModalService: NgxNeoModalMatService,
    @Inject(MAT_DIALOG_DATA) public data: SelectAndCropComponentData,
  ) {}

  public ngOnInit(): void {
    this.imageChangedEvent = this.data.event;
    if (this.data.aspectRatio) {
      this.aspRatio = this.data.aspectRatio;
    }
    if (this.data.resizeW && this.data.resizeH) {
      this.resizeHeight = this.data.resizeH;
      this.resizeWidth = this.data.resizeW;
    }

    if (this.data.previewW && this.data.previewH) {
      this.heightPreview = this.data.previewH;
      this.widthPreview = this.data.previewW;
    }
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.puedoGuardar.next(true);
  }

  public imageLoaded(): void {
    this.loading = false;
  }

  public loadImageFailed(): void {
    this.loading = false;
    this.neoModalService.error('DOCUMENTS.IMAGE_DROP_ERROR');
  }

  public async aceptar(): Promise<void> {
    this.dialogRef.close(this.croppedImage);
  }

  public cancelar(): void {
    this.dialogRef.close();
  }
}
