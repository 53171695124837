import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AttachmentUaalooDTO, EventoDTO, EventSummaryDTO } from 'src/app/ModelDTO/DTO';
import { EventoServiceBackend } from 'src/app/ServiceBackend';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ModalEventoDetalleComponent } from 'src/app/app-common/modal-evento-detalle/modal-evento-detalle.component';

@Injectable({
  providedIn: 'root',
})
export class EventosService {
  public small: boolean;
  public get EventoACrear(): EventoDTO {
    return this.eventoACrear;
  }
  public set EventoACrear(evento: EventoDTO) {
    this.eventoACrear = evento;
  }

  private eventoACrear: EventoDTO;
  constructor(
    private eventosBackend: EventoServiceBackend,
    private headerService: HeaderAppService,
    private dialog: MatDialog,
  ) {
    this.headerService.smallScreen$.subscribe((small) => {
      this.small = small;
    });
  }

  public async obtenerPorId(id: number): Promise<EventoDTO> {
    return this.eventosBackend.getEventoId(id);
  }

  public obtenerEntreFechas(
    numeroDePagina: number,
    cantidadDeEventosAMostrar: number,
    desde: Date,
    hasta: Date,
  ): Observable<EventSummaryDTO[]> {
    return this.eventosBackend.getEventoFiltrado(desde, hasta, numeroDePagina, cantidadDeEventosAMostrar);
  }

  public async obtenerProximos(): Promise<EventSummaryDTO[]> {
    const res = await this.eventosBackend.getEventoProximos();
    return res;
  }

  public async agregarEvento(formData: FormData, imageEntityDTO: ImageEntityDTO): Promise<EventoDTO> {
    const res = await this.eventosBackend.insertEvento(formData);
    if (res && imageEntityDTO.image) {
      await this.eventosBackend.insertEventoIdImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async editarEvento(eventoId: number, evento: FormData, imageEntityDTO: ImageEntityDTO): Promise<EventoDTO> {
    const res = await this.eventosBackend.updateEventoId(eventoId, evento);
    if (res && imageEntityDTO && imageEntityDTO.image) {
      await this.eventosBackend.insertEventoIdImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async eliminarEvento(eventoDTO: EventoDTO): Promise<void> {
    await this.eventosBackend.deleteEventoId(eventoDTO.id);
  }
  public async obtenerAdjuntos(id: number): Promise<Array<AttachmentUaalooDTO>> {
    return this.eventosBackend.getEventoIdAdjuntos(id);
  }

  public openEventModal(eventoDTO: EventoDTO): void {
    this.dialog.open(ModalEventoDetalleComponent, {
      data: { evento: eventoDTO },
      width: '700px',
      height: '95%',
      hasBackdrop: true,
      disableClose: false,
      panelClass: 'no-padding',
    });
  }

  public async openEventById(idEvent: number): Promise<void> {
    const event = await this.obtenerPorId(idEvent);
    this.openEventModal(event);
  }
}
