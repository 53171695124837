import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NivelEstudioDTO } from 'src/app/ModelDTO/DTO/nivelEstudio.DTO';

@Injectable({
   providedIn: 'root'
})
export class NivelEstudioServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getNivelEstudioIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/nivelEstudio/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getNivelEstudioIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/nivelEstudio/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getNivelEstudio(): Promise<Array<NivelEstudioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/nivelEstudio/'));
         const resJson = res.data;
         const resDTO = new Array<NivelEstudioDTO>();
         for (const item of resJson) {
            const itemDTO = new NivelEstudioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertNivelEstudio(nivelEstudioDTO: NivelEstudioDTO): Promise<NivelEstudioDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/nivelEstudio/', nivelEstudioDTO));
         const resDTO = new NivelEstudioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateNivelEstudioId(id: number, nivelEstudioDTO: NivelEstudioDTO): Promise<NivelEstudioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/nivelEstudio/' + id, nivelEstudioDTO));
         const resDTO = new NivelEstudioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteNivelEstudioId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/nivelEstudio/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getNivelEstudioId(id: number): Promise<NivelEstudioDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/nivelEstudio/' + id));
         if (!res) { return null; }
         const resDTO = new NivelEstudioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
