<div class="d-flex flex-column">
  <app-question-type-header
    [position]="position"
    [title]="data.texto"
    [mandatory]="data.obligatoria"
    [answerCommentEnabled]="answerCommentEnabled"
    (commentClicked)="onCommentClicked()"
  />

  <mat-radio-group
    class="d-flex flex-column text-wrap gap-1"
    [(ngModel)]="opcionSingle"
    (ngModelChange)="respuestaChange(data.respuesta, $event)"
    [disabled]="!editionMode"
  >
    <mat-radio-button
      *ngFor="let opcion of data.opciones"
      color="primary"
      [value]="opcion"
      class="flex-fill"
      [disabled]="!editionMode && (modoAdministrador || estadoCuestionario === cuestionarioTerminado)"
    >
      <div [ngClass]="{ 'highlighted-opacity': opcion.texto === previousResult?.name }">
        {{ opcion.texto | enumTranslate: 'ENUM.PERFORMANCE.LEVELS' : { default: opcion.texto } }}
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>
