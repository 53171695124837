import { CompetenceEvaluatorDTO } from 'src/app/ModelDTO/DTO/competenceEvaluator.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';


export class CompetenceEvaluationTypeDTO implements IEntityDTO {

   type = '';
   shortDescription = '';
   description = '';
   evaluators: Array<CompetenceEvaluatorDTO>;
   id = 0;

   constructor() {
      this.evaluators = new Array<CompetenceEvaluatorDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.type != null) { this.type = jsonObj.type; }
      if (jsonObj.shortDescription != null) { this.shortDescription = jsonObj.shortDescription; }
      if (jsonObj.description != null) { this.description = jsonObj.description; }
      if (jsonObj.evaluators != null) { for (const item of jsonObj.evaluators) { const itemDTO = new CompetenceEvaluatorDTO(); itemDTO.PrepareDTO(item); this.evaluators.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
