import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, timeout } from 'rxjs';
import { SHOW_LOADING } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NgxNeoLoaderService } from 'src/app/shared/lib/ngx-neo-loader/ngx-neo-loader.service';

const TIMEOUT = 60000;
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: NgxNeoLoaderService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showLoading = req.context.get(SHOW_LOADING);

    let loaderSub: number = null;

    if (showLoading) {
      if (req.method === 'GET') {
        loaderSub = this.loaderService.show(500, false);
      } else {
        loaderSub = this.loaderService.show();
      }
    }
    return next.handle(req).pipe(
      timeout(TIMEOUT),
      catchError((error: any, caught: Observable<HttpEvent<any>>) => this.loaderService.onCatch(error, caught, loaderSub)),
      finalize(() => {
        this.loaderService.hide(loaderSub);
      }),
    );
  }
}
