<div class="d-flex flex-column flex-grow-1">
  <form [formGroup]="planningForm">
    <div>
      <mat-slide-toggle
        class="my-2 wrap-content"
        color="primary"
        formControlName="rightNow"
        [matTooltip]="'FORMS.SEND_RIGHT_NOW_TOOLTIP' | translate"
      >
        {{ 'FORMS.SEND_RIGHT_NOW' | translate }}
      </mat-slide-toggle>
    </div>
    <mat-form-field appearance="outline">
      <mat-label> {{ 'FORMS.SENDING_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="singleDate" [min]="minDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
</div>
