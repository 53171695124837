export class CompletitudItemModel {
  public campo: string;
  public valor: any;
  public completado = false;

  constructor(campo: string, valor: any) {
    this.campo = campo;
    this.valor = valor;
  }
}
