import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-include-holidays-saturday-sunday',
  templateUrl: './include-holidays-saturday-sunday.component.html',
  styleUrls: ['./include-holidays-saturday-sunday.component.scss'],
})
export class IncludeHolidaysSaturdaySundayComponent {
  @Input() public form: UntypedFormGroup;
}
