import { CompetenceEvaluationTypeDTO } from 'src/app/ModelDTO/DTO/competenceEvaluationType.DTO';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { EstadoEncuesta } from 'src/app/ModelDTO/DTO/estadoEncuesta.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PlanificacionDTO } from 'src/app/ModelDTO/DTO/planificacion.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EncuestaEvaluatorDTO } from './encuestaEvaluator.DTO';


export class EncuestaDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   estado: EstadoEncuesta;
   creador: PersonalLegajoBasicoDTO;
   planificacion: PlanificacionDTO;
   cuestionario: CuestionarioDTO;
   isAnonymous: boolean;
   resultsAvailableForParticipants: boolean;
   onlyCreatorCanEdit: boolean;
   evaluation: CompetenceEvaluationTypeDTO;
   evaluators: Array<EncuestaEvaluatorDTO>;
   equitableDistribution: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoBasicoDTO();
      this.planificacion = new PlanificacionDTO();
      this.cuestionario = new CuestionarioDTO();
      this.evaluation = new CompetenceEvaluationTypeDTO();
      this.evaluators = new Array<EncuestaEvaluatorDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as EstadoEncuesta; }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.planificacion != null) { this.planificacion.PrepareDTO(jsonObj.planificacion); }
      if (jsonObj.cuestionario != null) { this.cuestionario.PrepareDTO(jsonObj.cuestionario); }
      if (jsonObj.isAnonymous != null) { this.isAnonymous = jsonObj.isAnonymous; }
      if (jsonObj.resultsAvailableForParticipants != null) { this.resultsAvailableForParticipants = jsonObj.resultsAvailableForParticipants; }
      if (jsonObj.onlyCreatorCanEdit != null) { this.onlyCreatorCanEdit = jsonObj.onlyCreatorCanEdit; }
      if (jsonObj.evaluation != null) { this.evaluation.PrepareDTO(jsonObj.evaluation); }
      if (jsonObj.evaluators != null) { for (const item of jsonObj.evaluators) { const itemDTO = new EncuestaEvaluatorDTO(); itemDTO.PrepareDTO(item); this.evaluators.push(itemDTO); } }
      if (jsonObj.equitableDistribution != null) { this.equitableDistribution = jsonObj.equitableDistribution; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
