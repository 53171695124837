import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class EmpresaBasicoDTO implements IEntityDTO {

   name = '';
   telefono = '';
   artPoliza = '';
   artNombre = '';
   artPrivado = '';
   artPublico = '';
   razonSocial = '';
   identificadorFiscal = '';
   sucursal = 0;
   uaalooImage = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.telefono != null) { this.telefono = jsonObj.telefono; }
      if (jsonObj.artPoliza != null) { this.artPoliza = jsonObj.artPoliza; }
      if (jsonObj.artNombre != null) { this.artNombre = jsonObj.artNombre; }
      if (jsonObj.artPrivado != null) { this.artPrivado = jsonObj.artPrivado; }
      if (jsonObj.artPublico != null) { this.artPublico = jsonObj.artPublico; }
      if (jsonObj.razonSocial != null) { this.razonSocial = jsonObj.razonSocial; }
      if (jsonObj.identificadorFiscal != null) { this.identificadorFiscal = jsonObj.identificadorFiscal; }
      if (jsonObj.sucursal != null) { this.sucursal = jsonObj.sucursal; }
      if (jsonObj.uaalooImage != null) { this.uaalooImage = jsonObj.uaalooImage; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
