<h1 mat-dialog-title class="d-flex flew-row justify-content-between">
  <span>{{ ausenciaModel?.Ausencia.nombre }} </span>
  <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
</h1>

<div mat-dialog-content>
  <app-detalle-ausencia [ausenciaModel]="ausenciaModel"></app-detalle-ausencia>
  <mat-divider class="mt-2 mb-2"></mat-divider>
  <h4 class="gray-text-muted">{{ 'GENERAL.COMMENTS' | translate }}</h4>
  <app-comentarios
    [id]="ausenciaModel.Id"
    [comentarioHandler]="comentarioHandler"
    [canBeNotified]="[convertToJson(ausenciaModel.PersonalLegajo)]"
    cantBeNotifiedKey="NO_NOTIFICATION_TEAMMATE_ABSENCE"
  ></app-comentarios>
</div>
<div
  mat-dialog-actions
  align="end"
  [class.justify-content-between]="vistaEmpresa$ | async"
  [class.justify-content-end]="(vistaEmpresa$ | async) === false"
>
  <button
    mat-stroked-button
    color="danger"
    [command]="eliminarCmd"
    *ngIf="(vistaEmpresa$ | async) && ausenciaModel?.getEntityDTO().estado !== estadosSolicitud.Rechazado"
  >
    {{ 'TIMEOFF.DELETE_ABSENCE' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="addDocumentation()" *ngIf="vistaEmpresa$ | async">
    {{ 'TIMEOFF.ADD_DOCUMENTATION' | translate }}
  </button>
</div>
