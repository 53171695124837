import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO';
import { PreguntaOpcionDTO } from 'src/app/ModelDTO/DTO/preguntaOpcion.DTO';
import { TypeQuestionComponent } from 'src/app/shared/forms/cuestionario-tipo-pregunta/typeQuestion.component';

@Component({
  selector: 'app-question-no-option',
  templateUrl: './question-no-option.component.html',
  styleUrls: ['./question-no-option.component.scss'],
})
export class QuestionNoOptionComponent implements TypeQuestionComponent {
  @Input() quizQuestionForm: UntypedFormGroup;
  @Input() optionEdit: PreguntaOpcionDTO;
  @Input() questionEdit: CuestionarioPreguntaDTO;
  @Input() withEvaluation: boolean;
}
