import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';

export class PersonalPuestoFlatDTO implements IEntityDTO {

  puestoId = 0;
  puestoOculto: boolean;
  puestoCargoId = 0;
  puestoCargoNombre = '';
  puestoSectorId = 0;
  puestoSectorNombre = '';
  puestoAreaId = 0;
  puestoAreaNombre = '';
  puestoSupervisorId = 0;
  puestoSupervisorImage = '';
  puestoSupervisorNombreCompleto = '';
  activo: boolean;
  oficinaNombre = '';
  image = '';
  nombreCompleto = '';
  id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.puestoId != null) { this.puestoId = jsonObj.puestoId; }
      if (jsonObj.puestoCargoId != null) { this.puestoCargoId = jsonObj.puestoCargoId; }
      if (jsonObj.puestoCargoNombre != null) { this.puestoCargoNombre = jsonObj.puestoCargoNombre; }
      if (jsonObj.puestoSectorId != null) { this.puestoSectorId = jsonObj.puestoSectorId; }
      if (jsonObj.puestoSectorNombre != null) { this.puestoSectorNombre = jsonObj.puestoSectorNombre; }
      if (jsonObj.puestoAreaId != null) { this.puestoAreaId = jsonObj.puestoAreaId; }
      if (jsonObj.puestoAreaNombre != null) { this.puestoAreaNombre = jsonObj.puestoAreaNombre; }
      if (jsonObj.puestoSupervisorId != null) { this.puestoSupervisorId = jsonObj.puestoSupervisorId; }
      if (jsonObj.puestoSupervisorImage != null) { this.puestoSupervisorImage = jsonObj.puestoSupervisorImage; }
      if (jsonObj.puestoSupervisorNombreCompleto != null) { this.puestoSupervisorNombreCompleto = jsonObj.puestoSupervisorNombreCompleto; }
      if (jsonObj.activo != null) { this.activo = jsonObj.activo; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.oficinaNombre != null) { this.oficinaNombre = jsonObj.oficinaNombre; }
      if (jsonObj.puestoOculto != null) { this.puestoOculto = jsonObj.puestoOculto; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
