import { OnInit, Input, Component } from '@angular/core';
import { PersonalEntregaModelDTO } from 'src/app/ModelDTO';
import { EstadoEntrega, PersonalEntregaDTO, TipoDevolucionEntrega } from 'src/app/ModelDTO/DTO';

@Component({
  selector: 'app-cambio-estado-entrega',
  templateUrl: './cambio-estado-entrega.component.html',
})
export class CambioEstadoEntregaComponent implements OnInit {
  @Input() public entregaModel: PersonalEntregaModelDTO = new PersonalEntregaModelDTO(new PersonalEntregaDTO());

  public estadosEntrega: Array<string>;
  public estadoAnterior: string;

  constructor() {
    this.estadosEntrega = new Array<string>();
    this.estadosEntrega = PersonalEntregaModelDTO.getEstadoEntrega();
    let index = this.estadosEntrega.findIndex((x) => x === EstadoEntrega[EstadoEntrega.No_definido]);
    this.estadosEntrega.splice(index, 1);
    index = this.estadosEntrega.findIndex((x) => x === EstadoEntrega[EstadoEntrega.Solicitud_pendiente]);
    this.estadosEntrega.splice(index, 1);
    index = this.estadosEntrega.findIndex((x) => x === EstadoEntrega[EstadoEntrega.Pendiente]);
    this.estadosEntrega.splice(index, 1);
  }

  public ngOnInit(): void {
    this.estadoAnterior = this.entregaModel.Estado;

    if (
      this.entregaModel.Entrega.tipoDevolucion === TipoDevolucionEntrega.Reposicion ||
      this.entregaModel.Entrega.tipoDevolucion === TipoDevolucionEntrega.Sin_devolucion
    ) {
      const index = this.estadosEntrega.findIndex((x) => x === EstadoEntrega[EstadoEntrega.Devuelto]);
      this.estadosEntrega.splice(index, 1);
    }
  }
}
