import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'timepad',
})
@Injectable()
export class TimePadPipe implements PipeTransform {
  transform(value: string) {
    const [hours, minutes] = value.split(':');
    if (hours && minutes) {
      return `${hours.padStart(2, '0')}:${minutes.padEnd(2, '0')}`;
    } else {
      throw new Error('Unexpected time format (hh:mm] expexted');
    }
  }
}
