import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ComentarioVisibilidad } from 'src/app/ModelDTO/DTO/comentarioVisibilidad.ENUM';


export class ComentarioDTO implements IEntityDTO {

   personalLegajo: PersonalLegajoBasicoDTO;
   adjuntos: Array<AttachmentUaalooDTO>;
   fecha: Date;
   mensaje = '';
   visibilidad: ComentarioVisibilidad;
   fechaEditado: Date;
   stars = 0;
   reactions = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personalLegajo = new PersonalLegajoBasicoDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.mensaje != null) { this.mensaje = jsonObj.mensaje; }
      if (jsonObj.visibilidad != null) { this.visibilidad = jsonObj.visibilidad as ComentarioVisibilidad; }
      if (jsonObj.fechaEditado != null) { this.fechaEditado = new Date(jsonObj.fechaEditado); }
      if (jsonObj.stars != null) { this.stars = jsonObj.stars; }
      if (jsonObj.reactions != null) { this.reactions = jsonObj.reactions; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
