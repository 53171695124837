import { Directive, ElementRef, Host, HostListener, Input, Renderer2 } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { CompleterItem } from '../components/completer-item';
import { CtrDropdownDirective, CtrRowItem } from './ctr-dropdown';
import type { CtrRowElement } from './ctr-dropdown';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ctrRow]',
})
export class CtrRowDirective implements CtrRowElement, OnDestroy {
  private selected = false;
  private rowIndex: number;
  private item: CompleterItem;

  constructor(private el: ElementRef, private renderer: Renderer2, @Host() private dropdown: CtrDropdownDirective) {}

  public ngOnDestroy(): void {
    if (this.rowIndex) {
      this.dropdown.unregisterRow(this.rowIndex);
    }
  }

  @Input()
  set ctrRow(index: number) {
    this.rowIndex = index;
    this.dropdown.registerRow(new CtrRowItem(this, this.rowIndex));
  }

  @Input()
  set dataItem(item: CompleterItem) {
    this.item = item;
  }

  @HostListener('click') public onClick(): void {
    this.dropdown.onSelected(this.item);
  }

  @HostListener('mouseenter') public onMouseEnter(): void {
    this.dropdown.highlightRow(this.rowIndex);
  }

  @HostListener('mousedown') public onMouseDown(): void {
    this.dropdown.rowMouseDown();
  }

  public setHighlighted(selected: boolean): void {
    this.selected = selected;
    const highlightClass = 'completer-selected-row';
    if (this.selected) {
      this.renderer.addClass(this.el.nativeElement, highlightClass);
    } else {
      this.renderer.removeClass(this.el.nativeElement, highlightClass);
    }
  }

  public getNativeElement(): void {
    return this.el.nativeElement;
  }

  public getDataItem(): CompleterItem {
    return this.item;
  }
}
