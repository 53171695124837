import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { FichajeNovedadDTO } from 'src/app/ModelDTO/DTO/fichajeNovedad.DTO';
import { FichajeNovedadVariosDTO } from 'src/app/ModelDTO/DTO/fichajeNovedadVarios.DTO';
import { NewsFormatDTO } from 'src/app/ModelDTO/DTO/newsFormat.DTO';

@Injectable({
   providedIn: 'root'
})
export class FichajeNovedadServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFichajesIdNovedad(id: number): Promise<FichajeBasicoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/fichajes/' + id + '/novedad'));
         if (!res) { return null; }
         const resDTO = new FichajeBasicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateFichajesIdNovedad(id: number, fichajeNovedadDTO: FichajeNovedadDTO): Promise<FichajeNovedadDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/fichajes/' + id + '/novedad', fichajeNovedadDTO));
         const resDTO = new FichajeNovedadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateFichajesNovedadesIDTURNOSUPERVISOR(iDTURNOSUPERVISOR: number, fichajeNovedadVariosDTO: FichajeNovedadVariosDTO): Promise<Array<FichajeBasicoDTO>> {

         const res = await lastValueFrom(this.http.put<DataDTO>(Constants.apiURL + '/fichajes/novedades/' + iDTURNOSUPERVISOR, fichajeNovedadVariosDTO));
         const resJson = res.data;
         const resDTO = new Array<FichajeBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new FichajeBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }


   public async getFichajesNewsFormat(): Promise<Array<NewsFormatDTO>> {
       const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/fichajes/news-format'));
       const resJson = res.data;
       const resDTO = new Array<NewsFormatDTO>();
       for (const item of resJson) {
          const itemDTO = new NewsFormatDTO();
          itemDTO.PrepareDTO(item);
          resDTO.push(itemDTO);
       }
       return resDTO;
 }

}
