import { signal } from '@angular/core';
import { EmpresaDTO } from '@api/interfaces/empresa.interface';

export class CompanyInformationService {
  private $companyInformationState = signal<EmpresaDTO>(undefined);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public $companyInformation = this.$companyInformationState.asReadonly();

  public updateComanyInformation(information: EmpresaDTO): void {
    this.$companyInformationState.set(information);
  }
}
