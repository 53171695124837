import { PersonalFeedbackResumenDTO } from 'src/app/ModelDTO/DTO/personalFeedbackResumen.DTO';
import { Injectable } from '@angular/core';
import { PersonalLegajoServiceBackend, FeedbackServiceBackend } from 'src/app/ServiceBackend';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO';
import { FeedbackDTO, PersonalDTO, PersonalFeedbackDTO, PersonalLegajoBasicoDTO, TipoFeedbackDTO } from 'src/app/ModelDTO/DTO';
import { TipoFeedbackServiceBackend } from 'src/app/ServiceBackend/tipoFeedback.ServiceBackend';
import { PersonalFeedbackModel } from './personalFeedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public personalFeedbackSelected: PersonalLegajoBasicoDTO;

  public get indiceSeleccionado(): number {
    return this.pIndiceSeleccionado;
  }

  public get PersonalSeleccionado(): PersonalLegajoBasicoModelDTO {
    return this.pPersonalFeedbackSeleccionado;
  }

  public get PersonalAlta(): PersonalDTO {
    return this.personalAlta;
  }

  public set indiceSeleccionado(value: number) {
    this.pIndiceSeleccionado = value;
  }

  public set PersonalSeleccionado(value: PersonalLegajoBasicoModelDTO) {
    this.pPersonalFeedbackSeleccionado = value;
  }

  public set PersonalAlta(p: PersonalDTO) {
    this.personalAlta = p;
  }

  private pIndiceSeleccionado: number;

  private pPersonalFeedbackSeleccionado: PersonalLegajoBasicoModelDTO;
  private personalAlta: PersonalDTO;

  constructor(
    private personalFeedback: PersonalLegajoServiceBackend,
    private feedbackServiceBackend: FeedbackServiceBackend,
    private tipoFeedbackServiceBackend: TipoFeedbackServiceBackend,
  ) {}

  public async obtenerTipoFeedbackPersonal(id: number): Promise<PersonalFeedbackResumenDTO[]> {
    const res = await this.personalFeedback.getPersonalLegajosIdFeedbackResumen(id);
    return res;
  }

  public async obtenerPersonalConFeedback(pageNumber: number, pageSize: number): Promise<PersonalFeedbackModel[]> {
    const arrayPersonalModel: PersonalFeedbackModel[] = [];
    const res = await this.feedbackServiceBackend.getFeedbackConFeedback(pageNumber, pageSize);
    res.forEach((x) => {
      const personalModel = new PersonalFeedbackModel(x);
      arrayPersonalModel.push(personalModel);
    });
    return arrayPersonalModel;
  }

  public async obtenerTiposDeFeedback(): Promise<Array<TipoFeedbackDTO>> {
    const res = await this.tipoFeedbackServiceBackend.getTipoFeedback();
    return res;
  }

  public async obtenerFeedbacks(idPersonalLegajo: number, todas: boolean, fechaDesde: Date, fechaHasta: Date): Promise<Array<FeedbackDTO>> {
    const res = await this.feedbackServiceBackend.getFeedbackPersonalLegajoId(idPersonalLegajo, fechaDesde, fechaHasta, todas);
    return res;
  }

  public async agregarFeedback(idPersonalLegajo: number, formFeedback: FormData): Promise<FeedbackDTO> {
    const res = await this.feedbackServiceBackend.insertFeedbackPersonalLegajoId(idPersonalLegajo, formFeedback);
    return res;
  }

  public async eliminarFeedback(feedback: FeedbackDTO): Promise<void> {
    await this.feedbackServiceBackend.deleteFeedbackId(feedback.id);
  }

  public async obtenerFeedback(idFeedback: number): Promise<PersonalFeedbackDTO> {
    const res = await this.feedbackServiceBackend.getFeedbackId(idFeedback);
    return res;
  }

  public async getAllFeedbacks(pageNumber: number, pageSize: number): Promise<PersonalFeedbackModel[]> {
    const arrayPersonalModel: PersonalFeedbackModel[] = [];
    const res = await this.feedbackServiceBackend.getFeedback(pageNumber, pageSize);
    res.forEach((x) => {
      const personalModel = new PersonalFeedbackModel(x);
      arrayPersonalModel.push(personalModel);
    });
    return arrayPersonalModel;
  }

  public dispose(): void {
    this.pIndiceSeleccionado = null;
  }
}
