import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';
import { UserDTO, UserModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { ConfiguracionFirmaDigitalPosicionModelDTO } from 'src/app/ModelDTO/configuracionFirmaDigitalPosicion.ModelDTO';


export class AttachmentConNuevosFirmantesModelDTO extends EntityModelDTO<AttachmentConNuevosFirmantesDTO> {

   private attachmentModel: AttachmentUaalooModelDTO;
   private attachmentSubscribe: Subscription;
   private solicitanteModel: UserModelDTO;
   private solicitanteSubscribe: Subscription;
   private posicionFirmaModel: ConfiguracionFirmaDigitalPosicionModelDTO;
   private posicionFirmaSubscribe: Subscription;

   public constructor(protected entityDTO: AttachmentConNuevosFirmantesDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: AttachmentConNuevosFirmantesDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.attachmentModel = new AttachmentUaalooModelDTO(this.entityDTO.attachment);
      this.attachmentSubscribe = this.attachmentModel.changed.subscribe((changed) => this.changed.next(changed));
      this.solicitanteModel = new UserModelDTO(this.entityDTO.solicitante);
      this.solicitanteSubscribe = this.solicitanteModel.changed.subscribe((changed) => this.changed.next(changed));
      this.posicionFirmaModel = new ConfiguracionFirmaDigitalPosicionModelDTO(this.entityDTO.posicionFirma);
      this.posicionFirmaSubscribe = this.posicionFirmaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.attachmentModel.dispose();
      this.attachmentSubscribe.unsubscribe();
      this.solicitanteModel.dispose();
      this.solicitanteSubscribe.unsubscribe();
      this.posicionFirmaModel.dispose();
      this.posicionFirmaSubscribe.unsubscribe();
   }

   get AttachmentModel(): AttachmentUaalooModelDTO { return this.attachmentModel; }
   get Attachment(): AttachmentUaalooDTO { return this.attachmentModel.getEntityDTO(); }
   set Attachment(value: AttachmentUaalooDTO) { this.notifyChange(() => { this.entityDTO.attachment = value; this.attachmentModel.setEntityDTO(value); }); }

   get SolicitanteModel(): UserModelDTO { return this.solicitanteModel; }
   get Solicitante(): UserDTO { return this.solicitanteModel.getEntityDTO(); }
   set Solicitante(value: UserDTO) { this.notifyChange(() => { this.entityDTO.solicitante = value; this.solicitanteModel.setEntityDTO(value); }); }

   get NuevosFirmantes(): Array<PersonalLegajoBasicoDTO> { return this.entityDTO.nuevosFirmantes; }
   set NuevosFirmantes(value: Array<PersonalLegajoBasicoDTO>) { this.notifyChangeDTO('nuevosFirmantes', value); }

   get NotificarPorEmail(): boolean { return this.entityDTO.notificarPorEmail; }
   set NotificarPorEmail(value: boolean) { this.notifyChangeDTO('notificarPorEmail', value); }

   get IncluirSolicitante(): boolean { return this.entityDTO.incluirSolicitante; }
   set IncluirSolicitante(value: boolean) { this.notifyChangeDTO('incluirSolicitante', value); }

   get PosicionFirmaModel(): ConfiguracionFirmaDigitalPosicionModelDTO { return this.posicionFirmaModel; }
   get PosicionFirma(): ConfiguracionFirmaDigitalPosicionDTO { return this.posicionFirmaModel.getEntityDTO(); }
   set PosicionFirma(value: ConfiguracionFirmaDigitalPosicionDTO) { this.notifyChange(() => { this.entityDTO.posicionFirma = value; this.posicionFirmaModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
