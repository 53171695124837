import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';

export class PublicLinkDTO implements IEntityDTO {
  name = '';
  link = '';
  fullPath = '';
  expired: Date;
  id = 0;
  cacheStamp = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.name != null) {
      this.name = jsonObj.name;
    }
    if (jsonObj.link != null) {
      this.link = jsonObj.link;
    }
    if (jsonObj.fullPath != null) {
      this.fullPath = jsonObj.fullPath;
    }
    if (jsonObj.expired != null) {
      this.expired = new Date(jsonObj.expired);
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.cacheStamp != null) {
      this.cacheStamp = jsonObj.cacheStamp;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
