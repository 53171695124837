import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FeriadoDTO } from 'src/app/ModelDTO/DTO/feriado.DTO';

@Injectable({
   providedIn: 'root'
})
export class FeriadoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFeriadosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feriados/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

   public async getFeriadosIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {
         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/feriados/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/pdf' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getFeriados(): Promise<Array<FeriadoDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/feriados/'));
         const resJson = res.data;
         const resDTO = new Array<FeriadoDTO>();
         for (const item of resJson) {
            const itemDTO = new FeriadoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

   public async insertFeriados(feriadoDTO: FeriadoDTO): Promise<FeriadoDTO> {
         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/feriados/', feriadoDTO));
         const resDTO = new FeriadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public async updateFeriadosId(id: number, feriadoDTO: FeriadoDTO): Promise<FeriadoDTO> {
         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/feriados/' + id, feriadoDTO));
         const resDTO = new FeriadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public async deleteFeriadosId(id: number): Promise<void> {
         await lastValueFrom(this.http.delete(Constants.apiURL + '/feriados/' + id));
   }

   public async getFeriadosId(id: number): Promise<FeriadoDTO> {
         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/feriados/' + id));
         if (!res) { return null; }
         const resDTO = new FeriadoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public async insertFeriadosCargarFeriadosPublicosANIO(anio: number): Promise<void> {
         await lastValueFrom(this.http.post(Constants.apiURL + '/feriados/cargarFeriadosPublicos/' + anio, null));
   }

}
