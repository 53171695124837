import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { CategoriaInformacionDTO } from 'src/app/ModelDTO/DTO/categoriaInformacion.DTO';
import { InformacionTipoDTO } from 'src/app/ModelDTO/DTO/informacionTipo.DTO';
import { TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO/tipoCategoriaDocumento.ENUM';


export class InformacionMasivaDTO implements IEntityDTO {

   informaciones: Array<InformacionDTO>;
   cargada: boolean;
   personal: PersonalLegajoBasicoDTO;
   creador: PersonalLegajoBasicoDTO;
   editedBy: PersonalLegajoBasicoDTO;
   titulo = '';
   cuerpo = '';
   fechaCreacion: Date;
   categoria: CategoriaInformacionDTO;
   tipo: InformacionTipoDTO;
   visibilidad: TipoCategoriaDocumento;
   generated: boolean;
   lastEdition: Date;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.informaciones = new Array<InformacionDTO>();
      this.personal = new PersonalLegajoBasicoDTO();
      this.creador = new PersonalLegajoBasicoDTO();
      this.editedBy = new PersonalLegajoBasicoDTO();
      this.categoria = new CategoriaInformacionDTO();
      this.tipo = new InformacionTipoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.informaciones != null) { for (const item of jsonObj.informaciones) { const itemDTO = new InformacionDTO(); itemDTO.PrepareDTO(item); this.informaciones.push(itemDTO); } }
      if (jsonObj.cargada != null) { this.cargada = jsonObj.cargada; }
      if (jsonObj.personal != null) { this.personal.PrepareDTO(jsonObj.personal); }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.editedBy != null) { this.editedBy.PrepareDTO(jsonObj.editedBy); }
      if (jsonObj.titulo != null) { this.titulo = jsonObj.titulo; }
      if (jsonObj.cuerpo != null) { this.cuerpo = jsonObj.cuerpo; }
      if (jsonObj.fechaCreacion != null) { this.fechaCreacion = new Date(jsonObj.fechaCreacion); }
      if (jsonObj.categoria != null) { this.categoria.PrepareDTO(jsonObj.categoria); }
      if (jsonObj.tipo != null) { this.tipo.PrepareDTO(jsonObj.tipo); }
      if (jsonObj.visibilidad != null) { this.visibilidad = jsonObj.visibilidad as TipoCategoriaDocumento; }
      if (jsonObj.generated != null) { this.generated = jsonObj.generated; }
      if (jsonObj.lastEdition != null) { this.lastEdition = new Date(jsonObj.lastEdition); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
