import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';


export class FichajeManualDTO implements IEntityDTO {

   personalLegajo: PersonalLegajoBasicoDTO;
   fechaIngreso: Date;
   fechaSalida: Date;
   observacionIngreso = '';
   observacionSalida = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personalLegajo = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.fechaSalida != null) { this.fechaSalida = new Date(jsonObj.fechaSalida); }
      if (jsonObj.observacionIngreso != null) { this.observacionIngreso = jsonObj.observacionIngreso; }
      if (jsonObj.observacionSalida != null) { this.observacionSalida = jsonObj.observacionSalida; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
