import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfiguracionNotificacionesDTO implements IEntityDTO {

   cumpleanios: boolean;
   altaColaboradores: boolean;
   aniversarios: boolean;
   vencimientoDeContratos: boolean;
   cargaDeDocumentos: boolean;
   administradoresSoloRecibenSolicitudesSiSonResponsablesDirectos: boolean;
   supervisoresRecibenSolicitudesDeTodoSuArea: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cumpleanios != null) { this.cumpleanios = jsonObj.cumpleanios; }
      if (jsonObj.altaColaboradores != null) { this.altaColaboradores = jsonObj.altaColaboradores; }
      if (jsonObj.aniversarios != null) { this.aniversarios = jsonObj.aniversarios; }
      if (jsonObj.vencimientoDeContratos != null) { this.vencimientoDeContratos = jsonObj.vencimientoDeContratos; }
      if (jsonObj.cargaDeDocumentos != null) { this.cargaDeDocumentos = jsonObj.cargaDeDocumentos; }
      if (jsonObj.administradoresSoloRecibenSolicitudesSiSonResponsablesDirectos != null) { this.administradoresSoloRecibenSolicitudesSiSonResponsablesDirectos = jsonObj.administradoresSoloRecibenSolicitudesSiSonResponsablesDirectos; }
      if (jsonObj.supervisoresRecibenSolicitudesDeTodoSuArea != null) { this.supervisoresRecibenSolicitudesDeTodoSuArea = jsonObj.supervisoresRecibenSolicitudesDeTodoSuArea; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
