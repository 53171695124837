import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FichajeErrorDTO } from 'src/app/ModelDTO/DTO/fichajeError.DTO';
import { FichajeNovedadDTO } from 'src/app/ModelDTO/DTO/fichajeNovedad.DTO';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoEstadoFichaje } from 'src/app/ModelDTO/DTO/tipoEstadoFichaje.ENUM';


export class FichajeDTO implements IEntityDTO {

   fotoIngreso = '';
   fotoArchivoIngreso: FileDBDTO;
   fotoSalida = '';
   fotoArchivoSalida: FileDBDTO;
   geoLocalizacionIngreso = '';
   geoLocalizacionSalida = '';
   hash = '';
   isHashedValid: boolean;
   erroresIngreso: Array<FichajeErrorDTO>;
   erroresSalida: Array<FichajeErrorDTO>;
   novedad: FichajeNovedadDTO;
   cambios: Array<AuditLogEntryDTO>;
   personalLegajo: PersonalLegajoBasicoDTO;
   observacionIngreso = '';
   observacionSalida = '';
   fechaIngresoFront: Date;
   fechaIngreso: Date;
   fechaSalidaFront: Date;
   fechaSalida: Date;
   tieneErroresIngreso: boolean;
   tieneErroresSalida: boolean;
   estadoFichaje: TipoEstadoFichaje;
   edito: boolean;
   fechaIngresoAjustada: Date;
   fechaSalidaAjustada: Date;
   horasTrabajadas = '';
   id = 0;

   constructor() {
      this.fotoArchivoIngreso = new FileDBDTO();
      this.fotoArchivoSalida = new FileDBDTO();
      this.erroresIngreso = new Array<FichajeErrorDTO>();
      this.erroresSalida = new Array<FichajeErrorDTO>();
      this.novedad = new FichajeNovedadDTO();
      this.cambios = new Array<AuditLogEntryDTO>();
      this.personalLegajo = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fotoIngreso != null) { this.fotoIngreso = jsonObj.fotoIngreso; }
      if (jsonObj.fotoArchivoIngreso != null) { this.fotoArchivoIngreso.PrepareDTO(jsonObj.fotoArchivoIngreso); }
      if (jsonObj.fotoSalida != null) { this.fotoSalida = jsonObj.fotoSalida; }
      if (jsonObj.fotoArchivoSalida != null) { this.fotoArchivoSalida.PrepareDTO(jsonObj.fotoArchivoSalida); }
      if (jsonObj.geoLocalizacionIngreso != null) { this.geoLocalizacionIngreso = jsonObj.geoLocalizacionIngreso; }
      if (jsonObj.geoLocalizacionSalida != null) { this.geoLocalizacionSalida = jsonObj.geoLocalizacionSalida; }
      if (jsonObj.hash != null) { this.hash = jsonObj.hash; }
      if (jsonObj.isHashedValid != null) { this.isHashedValid = jsonObj.isHashedValid; }
      if (jsonObj.erroresIngreso != null) { for (const item of jsonObj.erroresIngreso) { const itemDTO = new FichajeErrorDTO(); itemDTO.PrepareDTO(item); this.erroresIngreso.push(itemDTO); } }
      if (jsonObj.erroresSalida != null) { for (const item of jsonObj.erroresSalida) { const itemDTO = new FichajeErrorDTO(); itemDTO.PrepareDTO(item); this.erroresSalida.push(itemDTO); } }
      if (jsonObj.novedad != null) { this.novedad.PrepareDTO(jsonObj.novedad); }
      if (jsonObj.cambios != null) { for (const item of jsonObj.cambios) { const itemDTO = new AuditLogEntryDTO(); itemDTO.PrepareDTO(item); this.cambios.push(itemDTO); } }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.observacionIngreso != null) { this.observacionIngreso = jsonObj.observacionIngreso; }
      if (jsonObj.observacionSalida != null) { this.observacionSalida = jsonObj.observacionSalida; }
      if (jsonObj.fechaIngresoFront != null) { this.fechaIngresoFront = new Date(jsonObj.fechaIngresoFront); }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.fechaSalidaFront != null) { this.fechaSalidaFront = new Date(jsonObj.fechaSalidaFront); }
      if (jsonObj.fechaSalida != null) { this.fechaSalida = new Date(jsonObj.fechaSalida); }
      if (jsonObj.tieneErroresIngreso != null) { this.tieneErroresIngreso = jsonObj.tieneErroresIngreso; }
      if (jsonObj.tieneErroresSalida != null) { this.tieneErroresSalida = jsonObj.tieneErroresSalida; }
      if (jsonObj.estadoFichaje != null) { this.estadoFichaje = jsonObj.estadoFichaje as TipoEstadoFichaje; }
      if (jsonObj.edito != null) { this.edito = jsonObj.edito; }
      if (jsonObj.fechaIngresoAjustada != null) { this.fechaIngresoAjustada = new Date(jsonObj.fechaIngresoAjustada); }
      if (jsonObj.fechaSalidaAjustada != null) { this.fechaSalidaAjustada = new Date(jsonObj.fechaSalidaAjustada); }
      if (jsonObj.horasTrabajadas != null) { this.horasTrabajadas = jsonObj.horasTrabajadas; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
