import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationDTO } from '@api/interfaces/notification.interface';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TaskBackendService } from '@api/services/task-backend.service';
import { Observable, map } from 'rxjs';
import { PersonalLegajoByIdBackendService } from '@api/services/personal-legajo-by-id-backend.service';
import { MatButtonModule } from '@angular/material/button';
import { NotificationBackendService } from '@api/services/notification-backend.service';

export interface ModalGoalChangedData {
  notification: NotificationDTO;
  titleKey: string;
  acceptAction: () => void;
}

@Component({
  selector: 'app-modal-goal-changed',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatDialogModule],
  templateUrl: './modal-goal-changed.component.html',
  styleUrls: ['./modal-goal-changed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGoalChangedComponent {
  public goalBody$: Observable<string>;
  public editorName$: Observable<string>;
  public changes$: Observable<string>;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ModalGoalChangedData,
    private dialogRef: MatDialogRef<ModalGoalChangedComponent>,
    private notificationService: NotificationBackendService,
    private taskBackendService: TaskBackendService,
    private personalBackendService: PersonalLegajoByIdBackendService,
    private translateService: TranslateService,
  ) {
    const goalId = this.data.notification.serviceId;
    const editorId = this.data.notification.serviceId2;
    this.goalBody$ = this.taskBackendService.getTasksGoalsId(goalId).pipe(map((goal) => goal.body));
    this.editorName$ = this.personalBackendService.getPersonalLegajoId(editorId).pipe(map((editor) => editor.nombreCompleto));
    this.changes$ = this.notificationService
      .getUserNotificationsId(this.data.notification.id)
      .pipe(map((notification) => notification.details));
    this.title = this.translateService.instant(this.data.titleKey);
  }

  public redirectToGoal(): void {
    this.data.acceptAction();
    this.dialogRef.close();
  }
}
