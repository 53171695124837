import { ErrorHandler, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { b64DecodeUnicode } from 'src/app/shared/lib/ngx-neo-components-mat/public_api';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { MatDialog } from '@angular/material/dialog';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogData,
} from 'src/app/shared/lib/ngx-neo-frontend-mat/components/server-error-dialog/server-error-dialog.component';
import { StatusPageService } from 'src/app/shared/status-page/status-page.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ConnectionStatusService } from 'src/app/shared/services/connectionStatus.service';
import { urlAPI } from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { LAST_RELOAD } from 'src/app/shared/localStorageConstants';

export class AppErrorHandlerService implements ErrorHandler {
  private authenticationService: AuthenticationService;
  private router: Router;
  private cordovaService: CordovaService;
  private headerService: HeaderAppService;
  private zone: NgZone;
  private dialogService: MatDialog;
  private neoModalService: NgxNeoModalMatService;
  private statusPageService: StatusPageService;
  private snackBar: SnackBarService;
  private connectionStatusService: ConnectionStatusService;
  private translateService: TranslateService;

  private excludedErrors = [
    'Error retrieving icon', // MatIcons
    'Server returned handshake error', // SingalR
  ];

  constructor() {
    this.loadServices();
  }

  public async handleError(wrapperError: any): Promise<void> {
    // If it fails because there are new javascript bundles, then we reload the page
    if (/Loading chunk.*/.test(wrapperError.message ?? '') || /Loading chunk.*/.test(wrapperError ?? '')) {
      this.handleChunkError();
    }

    const originalError = wrapperError.rejection ? wrapperError.rejection : wrapperError;

    if (!environment.production) {
      console.error(originalError);
    }

    // There are some specific errors that we don't even want to show
    if (this.excludedErrors.some((msj) => originalError?.message?.includes(msj))) {
      return;
    }

    // We don't want to send HTTP errors to Sentry, so we send any other
    if (originalError.status === undefined) {
      Sentry.captureException(originalError);
    }

    const fromOurAPI = originalError.url?.includes(urlAPI);

    if (fromOurAPI) {
      // Backend connection issues
      if (originalError.status === 0 || originalError.status === 404) {
        this.connectionStatusService.updateConnectionStatus(false);

        this.statusPageService.checkStatus().subscribe((status) => {
          if (this.statusPageService.systemDown(status)) {
            this.zone.run(() => this.router.navigate(['/maintenance']));
          }
        });
        return;
      }

      // Token refresh required
      if (originalError.status === 410) {
        await this.authenticationService.refreshToken();
        this.authenticationService.reloadPage();
        return;
      }

      // Login redirection required
      if (
        originalError.message?.includes('Unauthorized') ||
        originalError.message?.includes('connection being closed') ||
        (originalError.status && originalError.status === 401)
      ) {
        if (!this.router.url.startsWith('/login') && !this.router.url.startsWith('/demo') && !this.router.url.startsWith('/ios-landing')) {
          const authResponseDTO = this.authenticationService?.authResponseDTO;
          this.headerService.dispose();
          if (this.cordovaService.isIOSApp) {
            this.zone.run(() => this.router.navigate(['/ios-landing']));
          } else if (authResponseDTO?.userName === 'demo@naaloo.com') {
            this.zone.run(() => this.router.navigate(['/demo']));
          } else {
            this.zone.run(() => this.router.navigate(['/login']));
          }
          return;
        }
      }
    }

    // We decide whether we want to inform the user about the error
    this.handleErrorDialog(originalError);
  }

  private loadServices(): void {
    this.headerService = inject(HeaderAppService);
    this.zone = inject(NgZone);
    this.dialogService = inject(MatDialog);
    this.authenticationService = inject(AuthenticationService);
    this.router = inject(Router);
    this.cordovaService = inject(CordovaService);
    this.neoModalService = inject(NgxNeoModalMatService);
    this.statusPageService = inject(StatusPageService);
    this.snackBar = inject(SnackBarService);
    this.connectionStatusService = inject(ConnectionStatusService);
    this.translateService = inject(TranslateService);
  }

  private handleChunkError(): void {
    const now = new Date();
    const lastReload = localStorage.getItem(LAST_RELOAD);
    localStorage.setItem(LAST_RELOAD, now.toISOString());
    if (lastReload) {
      const reload = new Date(lastReload);
      // diff is in ms
      const dateDiff = (now.getTime() - reload.getTime()) / 1000;
      if (dateDiff > 60) {
        this.reloadPage();
      } else {
        console.error('Loading chunk failed');
      }
    } else {
      this.reloadPage();
    }
  }

  private handleErrorDialog(error: any): void {
    let errorMessage: string;

    if (error instanceof TimeoutError) {
      this.snackBar.showError('GENERAL.CONNECTION_SLOW');
    } else if (error.headers) {
      if (error.status === 504) {
        // 504: Gateway Timeout
        this.snackBar.showError('GENERAL.CONNECTION_UNSTABLE');
      } else if (error.status >= 500) {
        this.zone.run(() => {
          this.serverErrorDialog(this.translateService.instant('GENERAL.CONNECTION_SERVER_ERROR'));
        });
      } else {
        errorMessage = error?.statusText;
      }
    } else {
      errorMessage = error.message ? error.message : error.toString();
    }

    if (errorMessage) {
      // Backend encoded messages
      if (errorMessage.startsWith('data:txt;base64,')) {
        errorMessage = b64DecodeUnicode(errorMessage.substring(16));
      }

      this.zone.run(async () => {
        await this.neoModalService.warning(errorMessage);
      });
    }
  }

  private reloadPage(): void {
    if ((window as any)?.cordova) {
      window.location.href = 'index.html';
    } else {
      window.location.reload();
    }
  }

  private serverErrorDialog(errorMessage: string): void {
    this.dialogService.open(ServerErrorDialogComponent, {
      hasBackdrop: true,
      width: '640px',
      height: '360px',
      panelClass: 'no-border-dialog',
      data: {
        errorMessage,
      } as ServerErrorDialogData,
    });
  }
}
