import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';
import { DateTime } from 'luxon';
import { ComponentCacheKeys, ROUTE_CACHED_ENDPOINTS } from 'src/app/core/component-route-reuse-strategy/route-keys';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

interface RouteCacheData {
  component: DetachedRouteHandle;
  validUntil: DateTime;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentRouteCacheService {
  public get routeStore(): Map<ComponentCacheKeys, RouteCacheData> {
    return this.routeStoreMap;
  }

  private readonly routeStoreMap = new Map<ComponentCacheKeys, RouteCacheData>();

  constructor(authService: AuthenticationService) {
    authService.loggedEvent$.subscribe(() => {
      // we need to clear the cache when the user logs in
      // because angular saves component cache con route leave
      // and logout will triger that after cache clear on logout event
      this.clearCache();
    });
  }

  public save(key: ComponentCacheKeys, component: DetachedRouteHandle): void {
    const existingCache = this.routeStoreMap.get(key);

    // Angular llama con null al store cuando el attach es true, en ese caso lo tengo que actualizar sin incrementar el tiempo de cache
    const validUntil =
      existingCache && component === null ? existingCache.validUntil : DateTime.now().plus({ seconds: ROUTE_CACHED_ENDPOINTS[key] });

    this.routeStoreMap.set(key, { component, validUntil });
  }

  public validCache(key: ComponentCacheKeys): boolean {
    const item = this.routeStoreMap.get(key);
    if (!item) {
      return false;
    }

    return item.validUntil >= DateTime.now();
  }

  public removeComponentCache(key: ComponentCacheKeys): void {
    this.routeStoreMap.delete(key);
  }

  public clearCache(): void {
    this.routeStoreMap.clear();
  }
}
