/* eslint-disable @typescript-eslint/member-ordering */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { computed, ElementRef, Injectable, OnDestroy, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ComponentRouteCacheService } from 'src/app/core/component-route-reuse-strategy/component-route-cache.service';
import { GlobalSettingsService } from 'src/app/layout/administrador/configuracion/configuracion-sistema/global-settings.service';
import { PerfilEmpresaService } from 'src/app/layout/administrador/perfil-empresa/perfil-empresa.service';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoServiceBackend, PersonalServiceBackend } from 'src/app/ServiceBackend';
import { BreakpointLG, BreakpointMD, BreakpointSM, noProfileImage } from 'src/app/shared/constants';
import { ScreenSize } from 'src/app/shared/interfaces/screen-size';
import { MobileSidebarService } from 'src/app/shared/lib/ngx-neo-components-mat/public_api';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';
import { RoleDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/role.DTO';
import {
  AuthenticationService,
  BeforeBackArgs,
  CordovaService,
  FileDBDTO,
  FilesServiceBackend,
  NamedBlobDTO,
  NativePushNotificationService,
  PushService,
  UserDTO,
  UserLanguage,
  UsersServiceBackend,
  UserTypes,
} from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { CURRENT_USER_WEB, NEO_COMPONENT_ASYNC, SCROLL } from 'src/app/shared/localStorageConstants';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';
import { BreakpointUtils } from 'src/app/shared/shared-functions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeaderAppService implements OnDestroy {
  public get personalLegajoId(): number {
    return this.userLogged?.userTypeId ?? 0;
  }

  private currentUserImageSubject = new BehaviorSubject<string>(noProfileImage);
  public currentUserImage$ = this.currentUserImageSubject.asObservable().pipe(map((image) => (image?.length ? image : noProfileImage)));

  private $userLogged = signal<UserDTO | null>(null);

  public $user = computed(() => {
    const user = this.$userLogged();
    return {
      user,
      isRoleAdministrative: user?.role?.userType === UserTypes.Administrative,
      isRoleAdministrator: user?.role?.userType === UserTypes.Administrator,
    };
  });

  public userLogged: UserDTO;

  // Mantengo id del usuarioAnterior
  public idLastUser: number;

  public componentSelected: string;

  public beforeBack: (beforeBackArgs: BeforeBackArgs) => void;

  public hideReturn$ = new BehaviorSubject<boolean>(true);

  private requestLoad = new Subject<void>();
  public requestLoad$ = this.requestLoad.asObservable();

  private loadComplete = new Subject<void>();
  public loadComplete$ = this.loadComplete.asObservable();

  private subscriptions = new Subscription();

  /** auto scroll section */
  public scrolleableComponent: ElementRef;
  public scrollSavedActivated = false;
  public currentElement: string;

  public loggedOut$ = new Subject();

  public isModalOpen: boolean;

  public permissionRequestInProgress = false;

  public get isUserTypesUser(): boolean {
    return this.userLogged && this.userLogged.userType === UserTypes.User;
  }

  public get isUserTypesAdministrator(): boolean {
    return this.userLogged && this.userLogged.userType === UserTypes.Administrator;
  }

  public get isRoleAdministrator(): boolean {
    return this.userLogged && this.userLogged.role.userType === UserTypes.Administrator;
  }

  public get isRoleAdministrative(): boolean {
    return this.userLogged && this.userLogged.role.userType === UserTypes.Administrative;
  }

  public get isSupport(): boolean {
    return this.userLogged.id === 2;
  }

  public get isNewUser(): boolean {
    if (!this.authenticationService.authResponseDTO?.firstLogin) {
      return false;
    }
    const firstLogin = new Date(this.authenticationService.authResponseDTO.firstLogin);
    const diff = DateTime.now().diff(DateTime.fromJSDate(firstLogin));

    return diff?.as('days') < 7;
  }

  public get IsTerminal(): boolean {
    return this.userLogged?.role?.id === 50;
  }

  public get currentLanguage(): string {
    return UserLanguage[this.userLogged?.language]?.replace('_', '-');
  }

  public get tenant(): string {
    return this.authenticationService.authResponseDTO?.tenant;
  }

  private vistaEmpresaSubject = new BehaviorSubject<boolean>(undefined);
  public vistaEmpresa$ = this.vistaEmpresaSubject.asObservable();

  private smallScreen = new BehaviorSubject<boolean>(BreakpointUtils.initBreakpointObserver(this.breakpointObserver));
  public smallScreen$ = this.smallScreen.asObservable();

  private mediumScreen = new BehaviorSubject<boolean>(BreakpointUtils.initBreakpointObserverMedium(this.breakpointObserver));
  public mediumScreen$ = this.mediumScreen.asObservable();

  private largeScreen = new BehaviorSubject<boolean>(BreakpointUtils.initBreakpointObserverLarge(this.breakpointObserver));
  public largeScreen$ = this.largeScreen.asObservable();

  public $screenSize: Signal<ScreenSize>;

  private outdatedApp = new Subject<boolean>();
  public outdatedApp$ = this.outdatedApp.asObservable();

  private pageTitleSubject = new BehaviorSubject<string>('');
  public pageTitle$ = this.pageTitleSubject.asObservable();

  private loadMoreSubject = new Subject();
  public loadMore$ = this.loadMoreSubject.asObservable();

  private videoAskAdded = false;

  private employeeBasicFileSubject = new BehaviorSubject<PersonalLegajoBasicoDTO | null>(null);
  public readonly employeeBasicFile$: Observable<PersonalLegajoBasicoDTO | null> = this.employeeBasicFileSubject.asObservable();

  private get videoElement(): HTMLElement {
    return document.getElementsByClassName('videoask-embed__button--nnvG1')?.[0] as HTMLElement;
  }
  constructor(
    protected router: Router,
    protected location: Location,
    protected pushService: PushService,
    protected authenticationService: AuthenticationService,
    protected mobileSidebarService: MobileSidebarService,
    protected usersServiceBackend: UsersServiceBackend,
    protected cordovaService: CordovaService,
    protected modalService: MatDialog,
    protected neoModalService: NgxNeoModalMatService,
    protected translateService: TranslateService,
    private fileDownloaderService: FileDownloaderService,
    private fileService: FilesServiceBackend,
    private personalServiceBackend: PersonalServiceBackend,
    private perfilEmpresaService: PerfilEmpresaService,
    private personalLegajoServiceBackend: PersonalLegajoServiceBackend,
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private nativePushService: NativePushNotificationService,
    private http: HttpClient,
    private componentCache: ComponentRouteCacheService,
    private globalSettingsService: GlobalSettingsService,
  ) {
    this.userLogged = new UserDTO();
    this.idLastUser = 0;

    window.addEventListener(
      'popstate',
      () => {
        // The popstate event is fired each time when the current history entry changes.
        const beforeBackArgs: BeforeBackArgs = { path: '', cancelBack: false };
        if (!this.isModalOpen && this.beforeBack) {
          this.beforeBack(beforeBackArgs);
          this.removeScrollSaved();
        }
      },
      false,
    );

    this.location.subscribe(() => {
      if (this.mobileSidebarService.isOpen) {
        this.mobileSidebarService.showSidebar.next(false);
      }
    });

    router.events.subscribe((url: NavigationEnd) => {
      if (url instanceof NavigationEnd) {
        this.setComponentSelectedName(url.url);
      }
    });

    this.checkAppVersion();

    this.vistaEmpresaSubject.subscribe((adminView) => {
      if (adminView !== undefined) {
        localStorage.setItem('lastUrl', adminView ? '/admin' : '/user');
      }
    });

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!event.url?.length || event.url === '/user' || event.url === '/admin' || event.url === '/') {
        this.hideReturn$.next(true);
      } else {
        this.hideReturn$.next(false);
      }

      if (event.url.includes('/user')) {
        this.setModoEmpleado();
      } else if (event.url.includes('/admin') && this.isAdmin()) {
        this.setModoEmpresa();
        // entra en este if cuando se loguea ya que no contiene las urls anteriores
        // solo me importa el usuario logueado para poder guardar el último modo en el localstorage
      } else if (this.IsLogged()) {
        if (this.isAdmin()) {
          // Verfica si tiene guardado el modo y si es /user seteará a modoEmpleado, caso contrario, modoEmpresa ya que es Admin
          const lastUrl = localStorage.getItem('lastUrl');
          if (lastUrl && lastUrl.includes('/user')) {
            this.setModoEmpleado();
          } else {
            this.setModoEmpresa();
          }
        } else {
          this.setModoEmpleado();
        }
      }
    });

    this.breakpointObserver.observe([BreakpointSM]).subscribe((breakpoint) => {
      this.smallScreen.next(breakpoint.breakpoints[BreakpointSM]);
    });

    this.breakpointObserver.observe([BreakpointMD]).subscribe((breakpoint) => {
      this.mediumScreen.next(breakpoint.breakpoints[BreakpointMD]);
    });

    this.breakpointObserver.observe([BreakpointLG]).subscribe((breakpoint) => {
      this.largeScreen.next(breakpoint.breakpoints[BreakpointLG]);
    });

    this.$screenSize = toSignal(
      combineLatest([this.smallScreen$, this.mediumScreen$]).pipe(
        map(([small, medium]) => {
          if (small) {
            return 'small';
          }
          if (medium) {
            return 'medium';
          }
          return 'large';
        }),
      ),
    );
  }

  public isSmallScreen(): boolean {
    return this.smallScreen.value;
  }

  public addVideoAsk(): void {
    if (this.isUserTypesUser && this.isNewUser) {
      if (!this.videoAskAdded) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).VIDEOASK_EMBED_CONFIG = {
          kind: 'widget',
          url: 'https://www.videoask.com/f1aodc03j',
          options: {
            widgetType: 'VideoThumbnailSmall',
            text: '',
            backgroundColor: '#7D00FE',
            position: 'bottom-right',
            dismissable: false,
          },
        };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.videoask.com/embed/embed.js';
        document.body.appendChild(script);
        this.videoAskAdded = true;
      } else if (this.videoElement) {
        this.videoElement.style.display = null;
      }
    }
  }

  public setPageTitle(title: string): void {
    setTimeout(() => {
      const baseTitle = `Naaloo ${environment.appVersion}`;
      const additionalTitle = title ? this.translateService.instant(title) : '';
      this.titleService.setTitle(title ? `${baseTitle} | ${additionalTitle}` : baseTitle);
      this.pageTitleSubject.next(title);
    }, 100);
  }

  public removeVideoAsk(): void {
    if (this.videoElement) {
      this.videoElement.style.setProperty('display', 'none', 'important');
    }
  }

  public async loadUserEntity(): Promise<void> {
    if (this.IsLogged() && this.personalLegajoId > 0) {
      const personal = await this.personalServiceBackend.getPersonalLegajoBasicoId(this.personalLegajoId);
      this.employeeBasicFileSubject.next(personal);
    } else {
      this.employeeBasicFileSubject.next(null);
    }
  }

  public weekPassword(): boolean {
    return this.authenticationService.authResponseDTO.unsafePassword;
  }

  public termsAccepted(): boolean {
    return this.authenticationService.authResponseDTO?.termsAccepted;
  }

  public modoEmpresa(): boolean {
    return this.vistaEmpresaSubject.value;
  }

  public async setModoEmpresa(): Promise<void> {
    if (this.isAdmin()) {
      this.vistaEmpresaSubject.next(true);
    } else {
      await this.neoModalService.error('GENERAL.COMPANY_MODE_CONTROL');
    }
  }

  public setModoEmpleado(): void {
    this.vistaEmpresaSubject.next(false);
  }

  public loadMore(): void {
    this.loadMoreSubject.next(true);
  }

  public async cambiarModo(): Promise<void> {
    if (this.isAdmin()) {
      const modoEmpresa = this.vistaEmpresaSubject.value;
      this.vistaEmpresaSubject.next(!modoEmpresa);
      if (modoEmpresa) {
        await this.router.navigateByUrl('/user', { replaceUrl: true });
      } else {
        await this.router.navigateByUrl('/admin', { replaceUrl: true });
      }
    } else {
      await this.neoModalService.error('GENERAL.MODE_CHANGE_CONTROL');
    }
  }

  public async cambiarAFichaje(): Promise<void> {
    this.pushService.stop();
    if (this.cordovaService.isCordovaApp) {
      this.nativePushService.stop();
    }
    const nuevoAuth = await this.personalLegajoServiceBackend.insertPersonalLegajosVistaFichaje();

    await this.router.navigate(['/fichado']);
    this.authenticationService.login(nuevoAuth);
  }

  public async descargarArchivo(file: FileDBDTO): Promise<void> {
    const namedBlob: NamedBlobDTO = new NamedBlobDTO();
    await this.fileService.getFilesBlobId(file.id, namedBlob);
    this.fileDownloaderService.saveAs(namedBlob);
  }

  protected refreshCache(): void {
    this.perfilEmpresaService.loadCompany();
    this.globalSettingsService.getSettings();
    // Los flags se recargan porque el servicio esta subscripto al evento requestLoad
  }

  public transparentBackground(transparent: boolean): void {
    document.body.style.backgroundColor = transparent ? 'transparent' : null;
  }

  /**
   * Este codigo se encuentra comentado porque genera error de CORS en cordova y estamos
   * probando con un plugin, si funciona, podremos borrar todo lo relacionado (Commit 6b2d331)
   */
  private checkAppVersion(): void {
    /*
    if (!this.cordovaService.isAngularApp) {
      this.http.get<{ version: string }>(`${urlWeb}/version.json`).subscribe((data) => {
        const webVersion = data.version;
        const mobileVersion = environment.appVersion.split('-')[0];

        // Solamente consideramos desactualizado si cambia la primera o segunda parte de la version 1.1.XXXX < 1.2.XXXX
        if (webVersion.substring(0, webVersion.lastIndexOf('.')) > mobileVersion.substring(0, mobileVersion.lastIndexOf('.'))) {
          this.outdatedApp.next(true);
        }
      });
    } */
  }

  public nextReload(): void {
    this.requestLoad.next();
    this.refreshCache();
    this.loadComplete.next();
  }

  public newProfileImage(image: string): void {
    this.userLogged.image = image;

    if (image?.length) {
      const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER_WEB));
      currentUser.image = image;
      localStorage.setItem(CURRENT_USER_WEB, JSON.stringify(currentUser));
    }

    this.currentUserImageSubject.next(image);
  }

  private setComponentSelectedName(url: string): void {
    if (url) {
      const pos = url.lastIndexOf('/') + 1;
      if (pos > 0) {
        const urlFriendly: string = url;
        this.componentSelected = this.toPascalCase(urlFriendly); // this.toPascalCase(url.substring(pos));
      } else {
        this.componentSelected = '';
      }
    }
  }

  private getUserFromLocalStorage(): void {
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER_WEB));
    if (currentUser) {
      const upper = currentUser.userType[0].toUpperCase() + currentUser.userType.slice(1);
      currentUser.userType = UserTypes[upper];
      this.userLogged.PrepareDTO(currentUser);
      this.$userLogged.set(currentUser);
      this.currentUserImageSubject.next(this.userLogged.image);
    } else {
      this.userLogged = new UserDTO();
      this.$userLogged.set(null);
      this.translateService.use(this.translateService.getBrowserLang());
    }
  }

  public async initializeActualUser(): Promise<void> {
    this.getUserFromLocalStorage();
    const userLang = UserLanguage[this.userLogged?.language];
    if (userLang) {
      this.translateService.use(this.userLogged?.language === UserLanguage.Default ? 'es_ar' : userLang);
    }
  }

  public async refreshUser(): Promise<UserDTO> {
    const user = await this.usersServiceBackend.getUsersUsernameUSERNAME(this.userLogged.userName);
    const newUserLogged = new UserDTO();
    newUserLogged.PrepareDTO(this.userLogged);
    const updatedUserInformation = {
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      image: user.image,
      role: user.role,
    };

    // use only fields returned by user/username
    newUserLogged.PrepareDTO(updatedUserInformation);
    this.userLogged = newUserLogged;
    this.$userLogged.set(newUserLogged);

    this.updateLocalStorageUserInformation(updatedUserInformation);
    this.currentUserImageSubject.next(this.userLogged.image);
    return this.userLogged;
  }

  public dispose(): void {
    this.userLogged = new UserDTO();
    this.$userLogged.set(null);
    this.authenticationService.removeInfoLogin();
    this.employeeBasicFileSubject.next(null);
    this.perfilEmpresaService.cleanCache();
  }

  public navigateToComponent(component: string): void {
    this.router.navigate([component]);
  }

  public closeComponent(): void {
    this.back();
  }

  public IsLogged(): boolean {
    return this.userLogged && this.userLogged.id > 0;
  }

  public async Logout(): Promise<void> {
    // Lo inicializo cuando me voy, antes de hacer logout
    this.idLastUser = this.userLogged.id;
    try {
      await this.authenticationService.logout();
    } catch (err) {
      console.error(err);
    } finally {
      this.translateService.use(this.translateService.getBrowserLang());

      this.dispose();
      this.loggedOut$.next(true);
      if (this.cordovaService.isIOSApp) {
        this.router.navigate(['/ios-landing']);
      } else {
        this.router.navigate(['/login']);
      }
    }

    this.pushService.clearLastDisconnect();
    this.componentCache.clearCache();
  }

  public getUserName(): string {
    return this.userLogged.fullName;
  }

  public isAdmin(): boolean {
    return this.userLogged.userType === UserTypes.Administrator;
  }

  public async back(): Promise<void> {
    setTimeout(async () => {
      this.modalService.closeAll();

      const ruta = this.router.url;

      const beforeBackArgs: BeforeBackArgs = { path: ruta, cancelBack: false };
      if (this.beforeBack) {
        this.beforeBack(beforeBackArgs);
      }

      this.beforeBack = undefined;
      this.removeScrollSaved();

      if (!beforeBackArgs.cancelBack) {
        this.location.back();
      }
    }, 120);
  }

  public removeScrollSaved(): void {
    const neoComponentAsyncName = localStorage.getItem(NEO_COMPONENT_ASYNC);
    if (neoComponentAsyncName) {
      const scrolls = JSON.parse(localStorage.getItem(SCROLL));
      if (scrolls) {
        delete scrolls[neoComponentAsyncName];
        localStorage.setItem(SCROLL, JSON.stringify(scrolls));
      }
    }
    this.scrollToZero();
  }

  private toPascalCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

  public scrollToPosition(x: number, y: number): void {
    if (this.scrolleableComponent) {
      this.scrolleableComponent.nativeElement.scrollTop = y;
    }
  }

  public scrollToZero(): void {
    this.scrollToPosition(0, 0);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateLocalStorageUserInformation(updatedUserInformation: {
    userName: string;
    firstName: string;
    lastName: string;
    fullName: string;
    role: RoleDTO;
  }): void {
    const authDto = new AuthResponseDTO();
    authDto.PrepareDTO(JSON.parse(localStorage.getItem(CURRENT_USER_WEB)));
    authDto.PrepareDTO(updatedUserInformation);
    localStorage.setItem(CURRENT_USER_WEB, JSON.stringify(authDto));
  }
}
