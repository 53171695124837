import { Injectable } from '@angular/core';

// eslint-disable-next-line no-var
declare var zE: any; // ZendDesk widget

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  public call(element: string, action: string): void {
    try {
      if (typeof zE !== 'undefined') {
        zE(element, action);
      }
    } catch (error) {
      console.error('Error accessing Zendesk widget:', error);
    }
  }
}
