import { ConfigurationGoalLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationGoalLevelRange.DTO';
import { ConfigurationLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationLevelRange.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { LevelRangeDTO } from './levelRange.DTO';


export class OverallEvaluationGeneralResultsDTO implements IEntityDTO {

  id = 0;
  teammateId: number;
  teammateName:string;
  teammateLastname:string;
  teammateFullname:string;
  teammateImage:string;
  competenceResult: number;
  competenceResultRange:ConfigurationLevelRangeDTO;
  goalResult:number;
  goalResultRange:ConfigurationGoalLevelRangeDTO;
  suggestedResults: LevelRangeDTO[] | undefined;
  assignedResult: LevelRangeDTO | undefined;

   constructor() {}

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.teammateId != null) { this.teammateId = jsonObj.teammateId; }
      if (jsonObj.teammateName != null) { this.teammateName = jsonObj.teammateName; }
      if (jsonObj.teammateLastname != null) { this.teammateLastname = jsonObj.teammateLastname; }
      if (jsonObj.teammateFullname != null) { this.teammateFullname = jsonObj.teammateFullname; }
      if (jsonObj.teammateImage != null) { this.teammateImage = jsonObj.teammateImage; }
      if (jsonObj.goalResult != null) { this.goalResult = jsonObj.goalResult; }
      if (jsonObj.goalResultRange != null) { this.goalResultRange = new ConfigurationGoalLevelRangeDTO(); this.goalResultRange.PrepareDTO(jsonObj.goalResultRange); }
      if (jsonObj.competenceResult != null) { this.competenceResult = jsonObj.competenceResult; }
      if (jsonObj.competenceResultRange != null) { this.competenceResultRange = new ConfigurationLevelRangeDTO(); this.competenceResultRange.PrepareDTO(jsonObj.competenceResultRange); }
      if (jsonObj.suggestedResults != null) {
         this.suggestedResults = [];
         for (const item of jsonObj.suggestedResults) { const itemDTO = new LevelRangeDTO(); itemDTO.PrepareDTO(item); this.suggestedResults.push(itemDTO); }
      }
      if (jsonObj.assignedResult != null) { this.assignedResult = new LevelRangeDTO(); this.assignedResult.PrepareDTO(jsonObj.assignedResult); }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
