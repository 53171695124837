import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { DateTime, Settings } from 'luxon';

@Pipe({
  name: 'ngxdate',
  standalone: true,
})
export class NgxDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private headerService: HeaderAppService,
    private datePipe: DatePipe,
  ) {}

  public transform(value: any, pattern: string = this.translateService.instant('DATE.DEFAULT_FORMAT')): any {
    const currentLanguageIsEnglish = this.headerService.currentLanguage === 'en';
    Settings.defaultLocale = currentLanguageIsEnglish ? 'en' : 'es';
    const dateLanguage = currentLanguageIsEnglish ? this.headerService.currentLanguage : 'es-ar';
    const dateOrder = currentLanguageIsEnglish ? 'MM/dd' : 'dd/MM';
    switch (pattern) {
      case 'relative': {
        return DateTime.fromJSDate(value).toRelative();
      }
      case 'relativeCalendar': {
        return DateTime.fromJSDate(new Date(value)).toRelativeCalendar();
      }
      case 'shortDateFull': {
        pattern = `${dateOrder}/yyyy`;
        break;
      }
      case 'shortDateShort': {
        pattern = dateOrder;
        break;
      }
      case 'fullDateShortTime': {
        pattern = `${dateOrder}/yyyy hh:mm a`;
        break;
      }
      case 'fullDateDescriptiveShortTime': {
        pattern = 'EEEE dd MMMM hh:mm a';
        break;
      }
      case 'fullDateShortTime24': {
        pattern = `${dateOrder}/yyyy HH:mm`;
        break;
      }
      case 'monthYear': {
        pattern = `MM/yyyy`;
        break;
      }
      case 'fullMonthYear': {
        pattern = `MMMM yyyy`;
        break;
      }
      case 'fullDate': {
        pattern = currentLanguageIsEnglish ? 'MMMM dd, yyyy' : "dd 'de' MMMM, yyyy";
        break;
      }
      case 'fullDateTime': {
        pattern = `${dateOrder}/yyyy HH:mm:ss`;
        break;
      }
      case 'timeSimple': {
        pattern = 'hh:mm';
        break;
      }
    }
    return this.datePipe.transform(value, pattern, undefined, dateLanguage);
  }
}
