import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from '../../models';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AuthEditUserRequestDTO } from '../../models';
import { AuthNewUserRequestDTO } from '../../models';
import { AuthRequestDTO } from '../../models';
import { AuthResponseDTO } from '../../models';
import { AuditLogEntryDTO } from '../../models';
import { UserBasicDTO } from '../../models';
import { UserDTO } from '../../models';
import { UserPasswordInseguroDTO } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class UsersServiceBackend {
  constructor(@Inject(FrontEndConfigService) protected Constants: FrontEndConfig, protected http: HttpClient) {}

  public async getUsersIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/users/' + id + '/Auditory').toPromise();
    const resJson = res.data;
    const resDTO = new Array<AuditLogEntryDTO>();
    for (const item of resJson) {
      const itemDTO = new AuditLogEntryDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUsers(withoutRole: boolean, withRoleUsers: boolean = false): Promise<Array<UserDTO>> {
    const res = await this.http
      .get<DataDTO>(this.Constants.apiURL + '/users/' + '?withoutRole=' + withoutRole + '&withRoleUsers=' + withRoleUsers)
      .toPromise();
    const resJson = res.data;
    const resDTO = new Array<UserDTO>();
    for (const item of resJson) {
      const itemDTO = new UserDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUsersAutoComplete(contiene: string, pageSize: number): Promise<Array<UserBasicDTO>> {
    const res = await this.http
      .get<DataDTO>(this.Constants.apiURL + '/users/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize)
      .toPromise();
    const resJson = res.data;
    const resDTO = new Array<UserBasicDTO>();
    for (const item of resJson) {
      const itemDTO = new UserBasicDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUsersId(id: number): Promise<UserDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/users/' + id).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new UserDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUsersUsernameUSERNAME(username: string): Promise<UserDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/users/username/' + username).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new UserDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUsersSystemUSERNAME(username: string): Promise<UserDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/users/system/' + username).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new UserDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUsersBasic(onlyAdministratives: boolean = false): Promise<Array<UserBasicDTO>> {
    const res = await this.http
      .get<DataDTO>(this.Constants.apiURL + '/users/basic' + '?onlyAdministratives=' + onlyAdministratives)
      .toPromise();
    const resJson = res.data;
    const resDTO = new Array<UserBasicDTO>();
    for (const item of resJson) {
      const itemDTO = new UserBasicDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async getUsersIdPasswordInseguro(id: number): Promise<UserPasswordInseguroDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/users/' + id + '/passwordInseguro').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new UserPasswordInseguroDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUsersPasswordInseguro(): Promise<Array<UserPasswordInseguroDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/users/passwordInseguro').toPromise();
    const resJson = res.data;
    const resDTO = new Array<UserPasswordInseguroDTO>();
    for (const item of resJson) {
      const itemDTO = new UserPasswordInseguroDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async insertUsers(authNewUserRequestDTO: AuthNewUserRequestDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/users/', authNewUserRequestDTO).toPromise();
  }

  public async updateUsersId(id: number, authEditUserRequestDTO: AuthEditUserRequestDTO): Promise<UserDTO> {
    const res = await this.http.put(this.Constants.apiURL + '/users/' + id, authEditUserRequestDTO).toPromise();
    const resDTO = new UserDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async deleteUsersId(id: number): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/users/' + id).toPromise();
  }

  public async deleteUsersUSERNAMEPassword(username: string): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/users/' + username + '/password').toPromise();
  }

  public async insertUsersValidate(authRequestDTO: AuthRequestDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/users/validate', authRequestDTO).toPromise();
  }

  public async insertUsersIdInvalidateTokenAuth(id: number): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/users/' + id + '/invalidateTokenAuth', null).toPromise();
  }

  public async insertUsersGenerateOwnCodes(): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/users/generateOwnCodes', null).toPromise();
  }
}
