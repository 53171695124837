/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { CommentReactionDTO } from 'src/app/api/interfaces/comment-reaction.interface';
import { NewCommentReactionDTO } from 'src/app/api/interfaces/new-comment-reaction.interface';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';

@Injectable({
   providedIn: 'root'
})
export class CommentBackendService {

   constructor(protected http: HttpClient) { }


   public getCommentsIdReactions(id: number): Observable<CommentReactionDTO[]> {
      return this.http.get<DataDTO<CommentReactionDTO>>(Constants.apiURL + '/comments/' + id + '/reactions').pipe(map((res) => convertJsonDates(res.data) as CommentReactionDTO[]));
   }

   public updateCommentsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
      return this.http.put<CurrentReactionDTO>(Constants.apiURL + '/comments/' + id + '/reactions', newCommentReactionDTO);
   }

   public deleteCommentsIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
      return this.http.delete<CurrentReactionDTO>(Constants.apiURL + '/comments/' + id + '/reactions/' + emojiId);
   }}
