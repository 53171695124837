import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { IEntityDTO, NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class FichajeManualMasivoDTO implements IEntityDTO {

   fichajes: Array<FichajeBasicoDTO>;
   errores: Array<NameDetailDTO>;
   id = 0;

   constructor() {
      this.fichajes = new Array<FichajeBasicoDTO>();
      this.errores = new Array<NameDetailDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fichajes != null) { for (const item of jsonObj.fichajes) { const itemDTO = new FichajeBasicoDTO(); itemDTO.PrepareDTO(item); this.fichajes.push(itemDTO); } }
      if (jsonObj.errores != null) { for (const item of jsonObj.errores) { const itemDTO = new NameDetailDTO(); itemDTO.PrepareDTO(item); this.errores.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
