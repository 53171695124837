import { DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnumTranslatePipe } from 'src/app/shared/lib/ngx-neo-pipes/enumTranslate.pipe';
import { UnitTranslatePipe } from 'src/app/shared/lib/ngx-neo-pipes/unit-translate.pipe';
import { AccentPipe } from './accent.pipe';
import { ArrayTranslatePipe } from './arrayTranslate.pipe';
import {
  BeautifyPipe,
  FuncFilterPipe,
  NumberPipe,
  NumberPipeTwoLevel,
  RangePipe,
  RangePipeTwoLevel,
  TextPipe,
  TextPipeTwoLevel,
  ToStringPipe,
} from './filters.pipe';
import { NgxShortNumberPipe } from './ngx-short-number.pipe';
import { PadLeftPipe } from './number.pipe';
import { SafeStringPipe } from './safe-string.pipe';
import { SplitBeautyPipe } from './splitBeauty.pipe';
import { TimePadPipe } from './timePad.pipe';
import { UnisexPipe } from './unisex.pipe';

@NgModule({
  declarations: [
    NumberPipe,
    TextPipe,
    TextPipeTwoLevel,
    NumberPipeTwoLevel,
    ToStringPipe,
    FuncFilterPipe,
    BeautifyPipe,
    RangePipe,
    RangePipeTwoLevel,
    PadLeftPipe,
    SafeStringPipe,
    SplitBeautyPipe,
    UnisexPipe,
    AccentPipe,
    ArrayTranslatePipe,
    UnitTranslatePipe,
    NgxShortNumberPipe,
    TimePadPipe,
  ],
  imports: [EnumTranslatePipe],
  exports: [
    NumberPipe,
    TextPipe,
    TextPipeTwoLevel,
    NumberPipeTwoLevel,
    ToStringPipe,
    FuncFilterPipe,
    BeautifyPipe,
    RangePipe,
    RangePipeTwoLevel,
    PadLeftPipe,
    SafeStringPipe,
    SplitBeautyPipe,
    UnisexPipe,
    AccentPipe,
    ArrayTranslatePipe,
    EnumTranslatePipe,
    UnitTranslatePipe,
    NgxShortNumberPipe,
    TimePadPipe,
  ],
  providers: [DatePipe],
})
export class NgxNeoPipesModule {
  static forRoot(): ModuleWithProviders<NgxNeoPipesModule> {
    return {
      ngModule: NgxNeoPipesModule,
    };
  }
}
