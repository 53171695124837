import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoEstadoFichaje } from 'src/app/ModelDTO/DTO/tipoEstadoFichaje.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { ClockInOutMode } from '@api/enums/clock-in-out-mode.enum';


export class FichajeBasicoDTO implements IEntityDTO {

   clockInMode: ClockInOutMode;
   clockOutMode: ClockInOutMode;
   personalLegajo: PersonalLegajoBasicoDTO;
   observacionIngreso = '';
   observacionSalida = '';
   geoLocalizacionIngreso = '';
   fechaIngresoFront: Date;
   fechaIngreso: Date;
   fechaSalidaFront: Date;
   fechaSalida: Date;
   tieneErroresIngreso: boolean;
   tieneErroresSalida: boolean;
   estadoFichaje: TipoEstadoFichaje;
   edito: boolean;
   fechaIngresoAjustada: Date;
   fechaSalidaAjustada: Date;
   horasTrabajadas = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personalLegajo = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.clockInMode != null) { this.clockInMode = jsonObj.clockInMode as ClockInOutMode; }
      if (jsonObj.clockOutMode != null) { this.clockOutMode = jsonObj.clockOutMode as ClockInOutMode; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.observacionIngreso != null) { this.observacionIngreso = jsonObj.observacionIngreso; }
      if (jsonObj.observacionSalida != null) { this.observacionSalida = jsonObj.observacionSalida; }
      if (jsonObj.geoLocalizacionIngreso != null) { this.geoLocalizacionIngreso = jsonObj.geoLocalizacionIngreso; }
      if (jsonObj.fechaIngresoFront != null) { this.fechaIngresoFront = new Date(jsonObj.fechaIngresoFront); }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.fechaSalidaFront != null) { this.fechaSalidaFront = new Date(jsonObj.fechaSalidaFront); }
      if (jsonObj.fechaSalida != null) { this.fechaSalida = new Date(jsonObj.fechaSalida); }
      if (jsonObj.tieneErroresIngreso != null) { this.tieneErroresIngreso = jsonObj.tieneErroresIngreso; }
      if (jsonObj.tieneErroresSalida != null) { this.tieneErroresSalida = jsonObj.tieneErroresSalida; }
      if (jsonObj.estadoFichaje != null) { this.estadoFichaje = jsonObj.estadoFichaje as TipoEstadoFichaje; }
      if (jsonObj.edito != null) { this.edito = jsonObj.edito; }
      if (jsonObj.fechaIngresoAjustada != null) { this.fechaIngresoAjustada = new Date(jsonObj.fechaIngresoAjustada); }
      if (jsonObj.fechaSalidaAjustada != null) { this.fechaSalidaAjustada = new Date(jsonObj.fechaSalidaAjustada); }
      if (jsonObj.horasTrabajadas != null) { this.horasTrabajadas = jsonObj.horasTrabajadas; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
