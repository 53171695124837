import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DetallePersonaContactoModalComponent } from 'src/app/app-common/organigramas/detalle-persona-contacto-modal/detalle-persona-contacto-modal.component';
import { PersonalDTO, PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO';
import { ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO/resultadoImportacion.DTO';
import { ImportacionPersonalServiceBackend, PersonalServiceBackend } from 'src/app/ServiceBackend';
import { ExportacionPersonalServiceBackend } from 'src/app/ServiceBackend/exportacionPersonal.ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';
import { PersonalExtendModel } from './PersonalExtend.model';

@Injectable({
  providedIn: 'root',
})
export class OrganigramaService {
  // Se utiliza para actualizar los organigramas cada vez que se edita el personal.
  // Se puede aprovechar en un futuro para actualizar más cosas, ya que se activa cuando se edita el personal.
  public edicionPersonal = new BehaviorSubject<boolean>(false);
  public observableEdicionPersonal = this.edicionPersonal.asObservable();

  private colaboradoresActualizados: Subject<Array<PersonalExtendModel>> = new Subject<Array<PersonalExtendModel>>();
  public colaboradoresActualizados$: Observable<Array<PersonalExtendModel>> = this.colaboradoresActualizados.asObservable();

  constructor(
    private personalServiceBackend: PersonalServiceBackend,
    private importacionPersonalServiceBackend: ImportacionPersonalServiceBackend,
    private expoPersonal: ExportacionPersonalServiceBackend,
    private fileDownloaderService: FileDownloaderService,
    private matDialog: MatDialog,
  ) {}

  public async obtenerPersonal(): Promise<void> {
    const res = await this.personalServiceBackend.getPersonal();
    const array = new Array<PersonalExtendModel>();
    res.forEach((x) => array.push(new PersonalExtendModel(x)));
    this.colaboradoresActualizados.next(array);
  }

  public async importStaff(formData: FormData, progress: Subject<number>): Promise<ResultadoImportacionDTO> {
    const res = await this.importacionPersonalServiceBackend.insertImportacionesPersonal(formData, progress);
    return res;
  }

  public async exportStaff(withoutData: boolean = false): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.expoPersonal.getExportacionesPersonal(withoutData, blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public async openPersonalDetailsById(idTeammate: number, closeOtherModals = true): Promise<void> {
    if (idTeammate) {
      const teammate = await this.personalServiceBackend.getPersonalLegajoId(idTeammate);
      this.openPersonalDetails(teammate, closeOtherModals);
    }
  }

  public openPersonalDetails(personal: PersonalDTO | PersonalLegajoBasicoDTO, closeOtherModals = true): void {
    if (closeOtherModals) {
      this.matDialog.closeAll();
    }
    this.matDialog.open(DetallePersonaContactoModalComponent, {
      panelClass: 'detalle-persona-contacto-modal',
      data: personal,
      disableClose: false,
    });
  }
}
