export enum SortSurveySummary {
   Undefined = 0,
   ByTitle = 1,
   ByCreator = 2,
   ByPrivacy = 3,
   ByFrequency = 4,
   ByStartDate = 5,
   ByEndDate = 6,
   ByState = 7,
   ByEvaluationType = 8
 }
