import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CapacitacionAgrupadaDTO } from 'src/app/ModelDTO/DTO/capacitacionAgrupada.DTO';
import { TipoAgrupamiento } from 'src/app/ModelDTO/DTO/tipoAgrupamiento.ENUM';


export class CapacitacionAgrupadaModelDTO extends EntityModelDTO<CapacitacionAgrupadaDTO> {


   public constructor(protected entityDTO: CapacitacionAgrupadaDTO) {
      super(entityDTO);
   }

   public static getTipoAgrupamiento(): string[] {
      return EntityModelDTO.getEnumArray(TipoAgrupamiento);
   }

   public setEntityDTO(entityDTO: CapacitacionAgrupadaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Categoria(): string { return this.entityDTO.categoria; }
   set Categoria(value: string) { this.notifyChangeDTO('categoria', value); }

   get Subcategoria(): string { return this.entityDTO.subcategoria; }
   set Subcategoria(value: string) { this.notifyChangeDTO('subcategoria', value); }

   get Cantidad(): number { return this.entityDTO.cantidad; }
   set Cantidad(value: number) { this.notifyChangeDTO('cantidad', value); }

   get Fecha(): Date { return this.entityDTO.fecha; }
   set Fecha(value: Date) { this.notifyChangeDTO('fecha', value); }

   get TipoAgrupamiento(): string { return TipoAgrupamiento[this.entityDTO.tipoAgrupamiento]; }
   set TipoAgrupamiento(value: string) { this.notifyChangeDTO('tipoAgrupamiento', TipoAgrupamiento[value]); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
