import { Subscription } from 'rxjs';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { EstadoEntrega } from 'src/app/ModelDTO/DTO/estadoEntrega.ENUM';
import { PersonalEntregaDTO } from 'src/app/ModelDTO/DTO/personalEntrega.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO/tipoEntrega.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { TipoEntregaModelDTO } from 'src/app/ModelDTO/tipoEntrega.ModelDTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalEntregaModelDTO extends EntityModelDTO<PersonalEntregaDTO> {

   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;
   private personalLegajoModel: PersonalLegajoBasicoModelDTO;
   private personalLegajoSubscribe: Subscription;
   private entregaModel: TipoEntregaModelDTO;
   private entregaSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalEntregaDTO) {
      super(entityDTO);
   }

   public static getEstadoEntrega(): string[] {
      return EntityModelDTO.getEnumArray(EstadoEntrega);
   }

    protected getStringFromEstadoEntrega(enums: EstadoEntrega): Array<string> {
        if (enums) {
            const arrays = new Array<string>();
            for (let i = 0; i <= 31; i = i++) {
                const pow = Math.pow(2, i);
                if ((enums & pow) !== 0) {
                    arrays.push(EstadoEntrega[pow]);
                }
            }
            return arrays;
        } else {
            return undefined;
        }
    }

    protected getFlagFromEstadoEntregaString(strings: Array<string>): EstadoEntrega {
        let flags: EstadoEntrega;
        strings.forEach(element => {
            const enumVal: EstadoEntrega = EstadoEntrega[element];
            flags |= enumVal;
        });
        return flags;
    }

   public setEntityDTO(entityDTO: PersonalEntregaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.personalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personalLegajo);
      this.personalLegajoSubscribe = this.personalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.entregaModel = new TipoEntregaModelDTO(this.entityDTO.entrega);
      this.entregaSubscribe = this.entregaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
      this.personalLegajoModel.dispose();
      this.personalLegajoSubscribe.unsubscribe();
      this.entregaModel.dispose();
      this.entregaSubscribe.unsubscribe();
   }

   get Estado(): string { return EstadoEntrega[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', EstadoEntrega[value]); }

   get EstadoArray(): Array<string> { return this.getStringFromEstadoEntrega(this.entityDTO.estado); }
   set EstadoArray(value: Array<string>) { this.notifyChangeDTO('estado', this.getFlagFromEstadoEntregaString(value)); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get PersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.personalLegajoModel; }
   get PersonalLegajo(): PersonalLegajoBasicoDTO { return this.personalLegajoModel.getEntityDTO(); }
   set PersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personalLegajo = value; this.personalLegajoModel.setEntityDTO(value); }); }

   get EntregaModel(): TipoEntregaModelDTO { return this.entregaModel; }
   get Entrega(): TipoEntregaDTO { return this.entregaModel.getEntityDTO(); }
   set Entrega(value: TipoEntregaDTO) { this.notifyChange(() => { this.entityDTO.entrega = value; this.entregaModel.setEntityDTO(value); }); }

   get FechaPactada(): Date { return this.entityDTO.fechaPactada; }
   set FechaPactada(value: Date) { this.notifyChangeDTO('fechaPactada', value); }

   get EntregaEfectiva(): Date { return this.entityDTO.entregaEfectiva; }
   set EntregaEfectiva(value: Date) { this.notifyChangeDTO('entregaEfectiva', value); }

   get VencimientoAproximado(): Date { return this.entityDTO.vencimientoAproximado; }
   set VencimientoAproximado(value: Date) { this.notifyChangeDTO('vencimientoAproximado', value); }

   get UltimoCambio(): Date { return this.entityDTO.ultimoCambio; }
   set UltimoCambio(value: Date) { this.notifyChangeDTO('ultimoCambio', value); }

   get CodigoDeReferencia(): string { return this.entityDTO.codigoDeReferencia; }
   set CodigoDeReferencia(value: string) { this.notifyChangeDTO('codigoDeReferencia', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Motivo(): string { return this.entityDTO.motivo; }
   set Motivo(value: string) { this.notifyChangeDTO('motivo', value); }

   get ConSolicitud(): boolean { return this.entityDTO.conSolicitud; }
   set ConSolicitud(value: boolean) { this.notifyChangeDTO('conSolicitud', value); }

   get Automatica(): boolean { return this.entityDTO.automatica; }
   set Automatica(value: boolean) { this.notifyChangeDTO('automatica', value); }

   get SiguienteEntregaGenerada(): boolean { return this.entityDTO.siguienteEntregaGenerada; }
   set SiguienteEntregaGenerada(value: boolean) { this.notifyChangeDTO('siguienteEntregaGenerada', value); }

   get Cantidad(): number { return this.entityDTO.cantidad; }
   set Cantidad(value: number) { this.notifyChangeDTO('cantidad', value); }

   get Adjuntos(): Array<AttachmentUaalooDTO> { return this.entityDTO.adjuntos; }
   set Adjuntos(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('adjuntos', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
