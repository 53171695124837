import { TranslateService } from '@ngx-translate/core';
import { CompletitudGrupoModel } from 'src/app/app-common/perfil-empleado-shared/completitud.grupo-model';

export class CompletitudPerfilModel {
  public personales: CompletitudGrupoModel;
  public contacto: CompletitudGrupoModel;
  public laborales: CompletitudGrupoModel;

  public get total(): CompletitudGrupoModel {
    const total = new CompletitudGrupoModel(this.translateService);
    total.campos = [...this.personales.campos, ...this.contacto.campos, ...this.laborales.campos];
    return total;
  }
  constructor(private translateService: TranslateService) {
    this.personales = new CompletitudGrupoModel(translateService);
    this.contacto = new CompletitudGrupoModel(translateService);
    this.laborales = new CompletitudGrupoModel(translateService);
  }
}
