import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-mat-menu-executions',
  templateUrl: './form-mat-menu-executions.component.html',
  styleUrls: ['./form-mat-menu-executions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule, TranslateModule],
})
export class FormMatMenuExecutionsComponent {
  @Output() public openPlanningModal = new EventEmitter<void>();
  @Output() public openTemplateModal = new EventEmitter<void>();
  @Output() public exportForm = new EventEmitter<void>();

  public openPlanningModalAction(): void {
    this.openPlanningModal.emit();
  }

  public openTemplateModalAction(): void {
    this.openTemplateModal.emit();
  }

  public exportFormAction(): void {
    this.exportForm.emit();
  }
}
