import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { InformacionTipoDTO } from 'src/app/ModelDTO/DTO/informacionTipo.DTO';

@Injectable({
   providedIn: 'root'
})
export class InformacionTipoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getInformacionTipoIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/informacionTipo/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getInformacionTipoIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/informacionTipo/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getInformacionTipo(): Promise<Array<InformacionTipoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/informacionTipo/'));
         const resJson = res.data;
         const resDTO = new Array<InformacionTipoDTO>();
         for (const item of resJson) {
            const itemDTO = new InformacionTipoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertInformacionTipo(informacionTipoDTO: InformacionTipoDTO): Promise<InformacionTipoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/informacionTipo/', informacionTipoDTO));
         const resDTO = new InformacionTipoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateInformacionTipoId(id: number, informacionTipoDTO: InformacionTipoDTO): Promise<InformacionTipoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/informacionTipo/' + id, informacionTipoDTO));
         const resDTO = new InformacionTipoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteInformacionTipoId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/informacionTipo/' + id));

   }

   public async getInformacionTipoId(id: number): Promise<InformacionTipoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/informacionTipo/' + id));
         if (!res) { return null; }
         const resDTO = new InformacionTipoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
