import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { ComponentRouteCacheService } from 'src/app/core/component-route-reuse-strategy/component-route-cache.service';

export class ComponentRouteReuseStrategy implements RouteReuseStrategy {
  private readonly cacheService = inject(ComponentRouteCacheService);

  //  Cuando se sale de un ruta se llama a este metodo, si devuelve TRUE se ejecutara el metodo store()
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (route.data?.componentCacheKey) {
      /**
       * Cuando el shouldAttach se ejecuta, luego se llama al store con null
       * por eso aunque la cache sea valida, debo retornar true si el componente es null
       * para que la cache se actualice con la nueva version del componente cuando abandono la ruta
       */
      const nullComponent = this.cacheService.routeStore.get(route.data.componentCacheKey)?.component === null;
      return nullComponent || !this.cacheService.validCache(route.data?.componentCacheKey);
    }

    return false;
  }

  // Almaceno el componente en la cache. handle puede ser null porque luego del attach se limpia para el proximo detach
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    this.cacheService.save(route.data.componentCacheKey, handle);
  }

  // Cuando se ingresa a una ruta se llama a este metodo, si devuelve TRUE se ejecutara el metodo retrieve()
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.data?.componentCacheKey) {
      return this.cacheService.validCache(route.data?.componentCacheKey);
    }

    return false;
  }

  // Obtengo el componente de la cache
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.cacheService.routeStore.get(route.data.componentCacheKey).component;
  }

  /**
   * Se ejecuta cada vez que cambia una ruta, determina si se reutiliza la ruta.
   * Si retorna TRUE los demas metodos no seran ejecutados.
   * Es el default de angular para navegar a la misma ruta
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
