import { TaskGoalBasicDTO } from 'src/app/ModelDTO/DTO/taskGoalBasic.DTO';
import { TaskGoalInformationDTO } from 'src/app/ModelDTO/DTO/taskGoalInformation.DTO';
import { TaskStatus } from 'src/app/ModelDTO/DTO/taskStatus.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class TaskGoalDTO implements IEntityDTO {
   id = 0;
   body:string;
   forceOrder:boolean;
   position:number;
   status: TaskStatus;
   due: Date;
   taskGoalInformation: TaskGoalInformationDTO | undefined;
   tasks: Array<TaskGoalBasicDTO> | undefined;

   constructor() {
    this.tasks = [];
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.body != null) { this.body = jsonObj.body; }
      if (jsonObj.due != null) { this.due = new Date(jsonObj.due); }
      if (jsonObj.forceOrder != null) { this.forceOrder = jsonObj.forceOrder; }
      if (jsonObj.position != null) { this.position = jsonObj.position; }
      if (jsonObj.status != null) { this.status = jsonObj.status; }
      if (jsonObj.taskGoalInformation != null) {
        this.taskGoalInformation = new TaskGoalInformationDTO();
        this.taskGoalInformation.PrepareDTO(jsonObj.taskGoalInformation);
      }
      if (jsonObj.tasks != null) { for (const item of jsonObj.tasks) { const itemDTO = new TaskGoalBasicDTO(); itemDTO.PrepareDTO(item); this.tasks.push(itemDTO); } }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
