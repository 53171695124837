import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';

@Injectable({
   providedIn: 'root'
})
export class LocalidadServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getLocalidadesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/localidades/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getLocalidadesIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/localidades/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getLocalidades(): Promise<Array<LocalidadDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/localidades/'));
         const resJson = res.data;
         const resDTO = new Array<LocalidadDTO>();
         for (const item of resJson) {
            const itemDTO = new LocalidadDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertLocalidades(localidadDTO: LocalidadDTO): Promise<LocalidadDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/localidades/', localidadDTO));
         const resDTO = new LocalidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateLocalidadesId(id: number, localidadDTO: LocalidadDTO): Promise<LocalidadDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/localidades/' + id, localidadDTO));
         const resDTO = new LocalidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteLocalidadesId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/localidades/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getLocalidadesId(id: number): Promise<LocalidadDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/localidades/' + id));
         if (!res) { return null; }
         const resDTO = new LocalidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getLocalidadesAutoComplete(contiene: string, pageSize: number, paisId: number = 0): Promise<Array<LocalidadDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/localidades/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize + '&paisId=' + paisId));
         const resJson = res.data;
         const resDTO = new Array<LocalidadDTO>();
         for (const item of resJson) {
            const itemDTO = new LocalidadDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
