import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class PreguntaOpcionDTO implements IEntityDTO {

   texto = '';
   pesoSeleccionado = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.texto != null) { this.texto = jsonObj.texto; }
      if (jsonObj.pesoSeleccionado != null) { this.pesoSeleccionado = jsonObj.pesoSeleccionado; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
