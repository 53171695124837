import { CompetenceEvaluatorDTO } from 'src/app/ModelDTO/DTO/competenceEvaluator.DTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';
import { CompetenceEvaluationTypeDTO } from './DTO/competenceEvaluationType.DTO';


export class CompetenceEvaluationTypeModelDTO extends EntityModelDTO<CompetenceEvaluationTypeDTO> {


   public constructor(protected entityDTO: CompetenceEvaluationTypeDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: CompetenceEvaluationTypeDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Type(): string { return this.entityDTO.type; }
   set Type(value: string) { this.notifyChangeDTO('type', value); }

   get ShortDescription(): string { return this.entityDTO.shortDescription; }
   set ShortDescription(value: string) { this.notifyChangeDTO('shortDescription', value); }

   get Description(): string { return this.entityDTO.description; }
   set Description(value: string) { this.notifyChangeDTO('description', value); }

   get Evaluators(): Array<CompetenceEvaluatorDTO> { return this.entityDTO.evaluators; }
   set Evaluators(value: Array<CompetenceEvaluatorDTO>) { this.notifyChangeDTO('evaluators', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }
}
