import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CompleterComponent } from './components/completer-cmp';
import { CompleterListItemComponent } from './components/completer-list-item-cmp';
import { CtrCompleterDirective } from './directives/ctr-completer';
import { CtrDropdownDirective } from './directives/ctr-dropdown';
import { CtrInputDirective } from './directives/ctr-input';
import { CtrListDirective } from './directives/ctr-list';
import { CtrRowDirective } from './directives/ctr-row';
import { CompleterService } from './services/completer-service';
import { LocalDataFactory } from './services/local-data-factory';
import { RemoteDataFactory } from './services/remote-data-factory';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, MatInputModule, MatChipsModule, MatIconModule],
  declarations: [
    CompleterListItemComponent,
    CtrCompleterDirective,
    CtrDropdownDirective,
    CtrInputDirective,
    CtrListDirective,
    CtrRowDirective,
    CompleterComponent,
  ],
  exports: [
    CompleterComponent,
    CompleterListItemComponent,
    CtrCompleterDirective,
    CtrDropdownDirective,
    CtrInputDirective,
    CtrListDirective,
    CtrRowDirective,
  ],
})
export class NgxNeoCompleterMatModule {
  static forRoot(): ModuleWithProviders<NgxNeoCompleterMatModule> {
    return {
      ngModule: NgxNeoCompleterMatModule,
      providers: [CompleterService, LocalDataFactory, RemoteDataFactory],
    };
  }
}
