import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ThrottledDirective } from 'src/app/shared/directives/throttled-execution.directive';
import { ActionsSnackBarData } from 'src/app/shared/snack-bar/interfaces/actions-snack-bar-parameters.interface';

@Component({
  selector: 'app-multi-action-snack',
  standalone: true,
  imports: [NgFor, NgIf, NgClass, MatButtonModule, MatIconModule, TranslateModule, ThrottledDirective],
  templateUrl: './multi-action-snack.component.html',
  styleUrls: ['./multi-action-snack.component.scss'],
})
export class MultiActionSnackComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ActionsSnackBarData,
    private snackBar: MatSnackBar,
  ) {}

  public closeBar(force = true): void {
    if (force || this.data.dismissOnAction) {
      this.snackBar.dismiss();
    }
  }
}
