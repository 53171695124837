<div class="d-flex flex-column justify-content-center">
  <app-background-photo
    [props]="{
      centralImgSrc: evento.image ? evento.personal.image : 'assets/img/mat-icons/campaign.svg',
      backgroundImgSrc: evento.image,
      userName: evento.personal.nombreCompleto,
      backgroundColor: '#f3f3f3'
    }"
  ></app-background-photo>
  <div class="text-card">
    <div class="d-flex flex-column mt-2 mb-2">
      <div class="d-flex flex-row align-items-center flex-wrap">
        <div class="date-height">
          <small class="primary-color pl-2 mes-mayus">{{ evento.fechaInicio | ngxdate: 'MMM' }}</small>
          <div class="date-circle">
            {{ evento.fechaInicio | date: 'dd' }}
          </div>
        </div>
        <div class="flex-fill">
          <h5 class="no-margin ml-3">
            {{ evento.titulo }}
            <div class="d-flex flex-row justify-content-end"></div>
          </h5>
        </div>
        <div class="justify-content-end ml-auto mr-auto mt-sm-2">
          <button [matTooltip]="'EVENTS.ADD_TO_GOOGLE_CALENDAR' | translate" class="mr-2" mat-mini-fab (click)="openGoogleCalendar()">
            <mat-icon fontSet="material-symbols-outlined" svgIcon="google-calendar"></mat-icon>
          </button>
          <button [matTooltip]="'EVENTS.DOWNLOAD_ICS_FILE' | translate" class="mr-2" mat-mini-fab color="primary" (click)="downloadIcs()">
            <mat-icon fontSet="material-symbols-outlined" svgIcon="ics"></mat-icon>
          </button>
          <button
            [matTooltip]="'EVENTS.EDIT_EVENT' | translate"
            mat-mini-fab
            color="primary"
            (click)="editEvent()"
            *ngIf="vistaEmpresa$ | async"
          >
            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row mt-2 mb-2 align-items-center">
      <small><mat-icon fontSet="material-symbols-outlined" color="primary">schedule</mat-icon> {{ eventDate }} </small>
      <small class="ml-2" *ngIf="evento?.ubicacion?.length > 0"
        ><mat-icon fontSet="material-symbols-outlined" color="primary">location_on</mat-icon> {{ evento.ubicacion }}
      </small>
    </div>
    <div *ngIf="evento?.descripcion?.length > 0">
      <mat-divider></mat-divider>
      <div class="my-2 grey-text-color">
        <quill-view-html [content]="evento.descripcion" class="info-font"> </quill-view-html>
      </div>
    </div>
    <div class="mt-2">
      <app-file-chips [attachments]="evento.adjuntos" [noFilesMessage]="''"></app-file-chips>
    </div>
    <app-reaction-list class="my-2" (emojiClicked)="clickedEmoji($event)">
      <div [matTooltip]="reactionTooltipKey | translate" class="material-xsmall">
        <button
          mat-stroked-button
          class="add-reaction-button mt-1"
          [disabled]="reactionService.$reactionLimitReached()"
          [matMenuTriggerFor]="emojiMenu"
          #emojiMenuTrigger="matMenuTrigger"
        >
          <mat-icon fontSet="material-symbols-outlined">add_reaction</mat-icon>
        </button>
      </div>
      <mat-menu class="width-custom" #emojiMenu="matMenu" [hasBackdrop]="true">
        <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
          <emoji-mart
            (emojiSelect)="clickedEmoji($event.emoji.native)"
            [showPreview]="false"
            [isNative]="true"
            [totalFrequentLines]="1"
            [darkMode]="false"
            [i18n]="currentLanguage | ngxEmojiTranslate"
          ></emoji-mart>
        </div>
      </mat-menu>
    </app-reaction-list>
  </div>
</div>
