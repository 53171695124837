import { CompetenceEvaluationDTO } from 'src/app/ModelDTO/DTO/competenceEvaluation.DTO';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { CuestionarioRespuestaDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuesta.DTO';
import { EncuestaDTO } from 'src/app/ModelDTO/DTO/encuesta.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { OverallEvaluationDTO } from 'src/app/ModelDTO/DTO/overallEvaluation.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoEstadoPersonalLegajoCuestinario } from 'src/app/ModelDTO/DTO/tipoEstadoPersonalLegajoCuestinario.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalLegajoCuestionarioDTO implements IEntityDTO {

   cuestionario: CuestionarioDTO;
   encuesta: EncuestaDTO;
   evaluated: PersonalLegajoBasicoDTO;
   respuestas: Array<CuestionarioRespuestaDTO>;
   diasVencimiento: number;
   total: number;
   resultado: LevelRangeDTO;
   lastSelfEvaluation: CompetenceEvaluationDTO | undefined;
   overallEvaluation?: OverallEvaluationDTO;
   personalLegajo: PersonalLegajoBasicoDTO;
   estado: TipoEstadoPersonalLegajoCuestinario;
   fechaCreacion: Date;
   fechaRespuesta: Date;
   creador: PersonalLegajoBasicoDTO;
   firma = '';
   generalComment: string;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.cuestionario = new CuestionarioDTO();
      this.encuesta = new EncuestaDTO();
      this.evaluated = new PersonalLegajoBasicoDTO();
      this.respuestas = new Array<CuestionarioRespuestaDTO>();
      this.resultado = new LevelRangeDTO();
      this.personalLegajo = new PersonalLegajoBasicoDTO();
      this.creador = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cuestionario != null) { this.cuestionario.PrepareDTO(jsonObj.cuestionario); }
      if (jsonObj.encuesta != null) { this.encuesta.PrepareDTO(jsonObj.encuesta); }
      if (jsonObj.evaluated != null) { this.evaluated.PrepareDTO(jsonObj.evaluated); }
      if (jsonObj.respuestas != null) { for (const item of jsonObj.respuestas) { const itemDTO = new CuestionarioRespuestaDTO(); itemDTO.PrepareDTO(item); this.respuestas.push(itemDTO); } }
      if (jsonObj.diasVencimiento != null) { this.diasVencimiento = jsonObj.diasVencimiento; }
      if (jsonObj.total != null) { this.total = jsonObj.total; }
      if (jsonObj.resultado != null) { this.resultado.PrepareDTO(jsonObj.resultado); }
      if (jsonObj.lastSelfEvaluation != null) { this.lastSelfEvaluation = new CompetenceEvaluationDTO(); this.lastSelfEvaluation.PrepareDTO(jsonObj.lastSelfEvaluation); }
      if (jsonObj.overallEvaluation != null) { this.overallEvaluation = new OverallEvaluationDTO(); this.overallEvaluation.PrepareDTO(jsonObj.overallEvaluation); }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as TipoEstadoPersonalLegajoCuestinario; }
      if (jsonObj.fechaCreacion != null) { this.fechaCreacion = new Date(jsonObj.fechaCreacion); }
      if (jsonObj.fechaRespuesta != null) { this.fechaRespuesta = new Date(jsonObj.fechaRespuesta); }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.firma != null) { this.firma = jsonObj.firma; }
      if (jsonObj.generalComment != null) { this.generalComment = jsonObj.generalComment; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
