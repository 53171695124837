import { PersonalBasicoContratacionDTO } from 'src/app/ModelDTO/DTO';

export class PersonalExtendModel extends PersonalBasicoContratacionDTO {
  constructor(entityDTO: PersonalBasicoContratacionDTO) {
    super();
    this.PrepareDTO(entityDTO);
  }

  public toString(): string {
    return `${this.nombreCompleto}`;
  }
}
