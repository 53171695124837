import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './ngx-neo-loader.component';
import { NgxNeoLoaderService } from './ngx-neo-loader.service';
import { LoadingBarComponent } from 'src/app/shared/loading-bar/loading-bar.component';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, LoadingBarComponent],
  exports: [LoaderComponent],
})
export class NgxNeoLoaderModule {
  static forRoot(): ModuleWithProviders<NgxNeoLoaderModule> {
    return {
      ngModule: NgxNeoLoaderModule,
      providers: [NgxNeoLoaderService],
    };
  }
}
