import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class ResultadoImportacionDTO implements IEntityDTO {

   filasProcesadas = 0;
   filasConError = 0;
   elementosInsertados = 0;
   elementosActualizados = 0;
   errores: Array<NameDetailDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.errores = new Array<NameDetailDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.filasProcesadas != null) { this.filasProcesadas = jsonObj.filasProcesadas; }
      if (jsonObj.filasConError != null) { this.filasConError = jsonObj.filasConError; }
      if (jsonObj.elementosInsertados != null) { this.elementosInsertados = jsonObj.elementosInsertados; }
      if (jsonObj.elementosActualizados != null) { this.elementosActualizados = jsonObj.elementosActualizados; }
      if (jsonObj.errores != null) { for (const item of jsonObj.errores) { const itemDTO = new NameDetailDTO(); itemDTO.PrepareDTO(item); this.errores.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
