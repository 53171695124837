/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { NamedBlobDTO } from 'src/app/api/interfaces/named-blob.dto';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { EntregaAgrupadaDTO } from 'src/app/api/interfaces/entrega-agrupada.interface';
import { PersonalEntregaDTO } from 'src/app/api/interfaces/personal-entrega.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonalEntregaBackendService {

   constructor(protected http: HttpClient) { }


   public getEntregaIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/entrega/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getEntregaAgrupadas(tipoAgrupamiento: number, estado: number = 2): Observable<EntregaAgrupadaDTO[]> {
      return this.http.get<DataDTO<EntregaAgrupadaDTO>>(Constants.apiURL + '/entrega/agrupadas' + '?tipoAgrupamiento=' + tipoAgrupamiento +
            '&estado=' + estado).pipe(map((res) => convertJsonDates(res.data) as EntregaAgrupadaDTO[]));
   }

   public updateEntregaIdCambioEstado(id: number, personalEntregaDTO: PersonalEntregaDTO): Observable<void> {
      return this.http.put<void>(Constants.apiURL + '/entrega/' + id + '/cambioEstado', personalEntregaDTO);
   }
   public insertEntregaMasivoIdCategoriaIdSubategoria(idCategoria: number, idSubategoria: number, personalEntregaDTO: PersonalEntregaDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/entrega/masivo/' + idCategoria + '/' + idSubategoria, personalEntregaDTO);
   }
   public insertEntregaAreaMasivoIdAreaIdSector(idArea: number, idSector: number, personalEntregaDTO: PersonalEntregaDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/entrega/area/masivo/' + idArea + '/' + idSector, personalEntregaDTO);
   }
   public insertEntregaCargoMasivoIdCargo(idCargo: number, personalEntregaDTO: PersonalEntregaDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/entrega/cargo/masivo/' + idCargo, personalEntregaDTO);
   }
   public insertEntregaPersonalIDPersonal(iDPersonal: number, personalEntregaDTO: FormData): Observable<PersonalEntregaDTO> {
      return this.http.post<PersonalEntregaDTO>(Constants.apiURL + '/entrega/personal/' + iDPersonal, personalEntregaDTO).pipe(map((res) => convertJsonDates(res) as PersonalEntregaDTO ));
   }

   public getEntregaPersonal(params: { fechaDesde: Date, fechaHasta: Date, estado?: number, idPersonal?: number, idTipoEntrega?: number, sinRechazos?: boolean, duedReturn?: boolean }, ): Observable<PersonalEntregaDTO[]> {
      const { fechaDesde, fechaHasta, estado = 0, idPersonal = 0, idTipoEntrega = 0, sinRechazos = false, duedReturn = false } = params;
      return this.http.get<DataDTO<PersonalEntregaDTO>>(Constants.apiURL + '/entrega/personal' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&estado=' + estado +
            '&idPersonal=' + idPersonal +
            '&idTipoEntrega=' + idTipoEntrega +
            '&sinRechazos=' + sinRechazos +
            '&duedReturn=' + duedReturn).pipe(map((res) => convertJsonDates(res.data) as PersonalEntregaDTO[]));
   }

   public async getEntregaPersonalXLSX(params: { fechaDesde: Date, fechaHasta: Date, estado?: number, idPersonal?: number, idTipoEntrega?: number, sinRechazos?: boolean, duedReturn?: boolean, namedBlob: NamedBlobDTO }, ): Promise<Blob> {
      const { fechaDesde, fechaHasta, estado = 0, idPersonal = 0, idTipoEntrega = 0, sinRechazos = false, duedReturn = false, namedBlob } = params;
      const res = await lastValueFrom(this.http.get(Constants.apiURL +
'/entrega/personal' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&estado=' + estado +
            '&idPersonal=' + idPersonal +
            '&idTipoEntrega=' + idTipoEntrega +
            '&sinRechazos=' + sinRechazos +
            '&duedReturn=' + duedReturn + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/text' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }


   public deleteEntregaIdPersonalIDPersonal(id: number, iDPersonal: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/entrega/' + id + '/personal/' + iDPersonal);
   }}
