import { TranslateService } from '@ngx-translate/core';
import { CompletitudItemModel } from 'src/app/app-common/perfil-empleado-shared/completitud-item.model';

export class CompletitudGrupoModel {
  public campos: CompletitudItemModel[] = [];

  public get completados(): number {
    return this.campos.filter((campo) => campo.completado).length;
  }

  public get total(): number {
    return this.campos.length;
  }

  public get porcentaje(): number {
    return (this.completados / this.total) * 100;
  }

  constructor(private translateService: TranslateService) {}

  public toString(): string {
    const faltantes = this.campos.filter((campo) => !campo.completado);

    return faltantes.length
      ? `${this.translateService.instant('PROFILE.MISSING_FIELDS')}  ${faltantes.map((campo) => campo.campo).join(', ')}`
      : this.translateService.instant('PROFILE.COMPLETED');
  }
}
