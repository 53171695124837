import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { PersonalSectorModelDTO } from 'src/app/ModelDTO/personalSector.ModelDTO';
import { PersonalAreaBasicaDTO } from 'src/app/ModelDTO/DTO/personalAreaBasica.DTO';
import { PersonalAreaBasicaModelDTO } from 'src/app/ModelDTO/personalAreaBasica.ModelDTO';


export class PersonalAreaModelDTO extends EntityModelDTO<PersonalAreaDTO> {

   private superiorModel: PersonalAreaBasicaModelDTO;
   private superiorSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalAreaDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: PersonalAreaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.superiorModel = new PersonalAreaBasicaModelDTO(this.entityDTO.superior);
      this.superiorSubscribe = this.superiorModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.superiorModel.dispose();
      this.superiorSubscribe.unsubscribe();
   }

   get Sectores(): Array<PersonalSectorDTO> { return this.entityDTO.sectores; }
   set Sectores(value: Array<PersonalSectorDTO>) { this.notifyChangeDTO('sectores', value); }

   get SuperiorModel(): PersonalAreaBasicaModelDTO { return this.superiorModel; }
   get Superior(): PersonalAreaBasicaDTO { return this.superiorModel.getEntityDTO(); }
   set Superior(value: PersonalAreaBasicaDTO) { this.notifyChange(() => { this.entityDTO.superior = value; this.superiorModel.setEntityDTO(value); }); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Alcance(): string { return this.entityDTO.alcance; }
   set Alcance(value: string) { this.notifyChangeDTO('alcance', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
