import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConsultaDTO implements IEntityDTO {

   asunto = '';
   mensaje = '';
   sendTokenToSupport: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.asunto != null) { this.asunto = jsonObj.asunto; }
      if (jsonObj.mensaje != null) { this.mensaje = jsonObj.mensaje; }
      if (jsonObj.sendTokenToSupport != null) { this.sendTokenToSupport = jsonObj.sendTokenToSupport; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
