import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EstadisticaTotalesDTO } from 'src/app/ModelDTO/DTO/estadisticaTotales.DTO';


export class EstadisticasAusenciasDTO implements IEntityDTO {

   promedioPorTipoAusencia: Array<EstadisticaTotalesDTO>;
   promedioAusencias = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.promedioPorTipoAusencia = new Array<EstadisticaTotalesDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.promedioPorTipoAusencia != null) { for (const item of jsonObj.promedioPorTipoAusencia) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.promedioPorTipoAusencia.push(itemDTO); } }
      if (jsonObj.promedioAusencias != null) { this.promedioAusencias = jsonObj.promedioAusencias; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
