import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { Observable } from 'rxjs';
import { ComentarioVisibilidad } from '@api/enums/comentario-visibilidad.enum';
import { ComentarioBase } from './comentario-base';
import { ComentariosService } from './comentarios.service';

@Injectable({
  providedIn: 'root',
})
export class ComentariosComunicadosUserHandler extends ComentarioBase {
  constructor(
    private comentarioService: ComentariosService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public editMessage(id: number, mensaje: ComentarioDTO): Observable<ComentarioDTO> {
    return this.comentarioService.editarMensajeEnComunicadosUser(id, mensaje);
  }
  public deleteMessage(id: number, mensaje: ComentarioDTO): Observable<void> {
    return this.comentarioService.borrarMensajeEnComunicadosUser(id, mensaje);
  }

  public sendMessage(id: number, mensaje: FormData): Observable<void> {
    return this.comentarioService.enviarMensajeEnComunicadosUser(id, mensaje);
  }
  public onInit(id: number): Observable<Array<ComentarioDTO>> {
    return this.comentarioService.obtenerMensajesEnComunicadosUser(id);
  }

  public isPrivateDefault(): boolean {
    return false;
  }

  public getTitle(): string {
    return this.translateService.instant('GENERAL.COMMENTS');
  }

  public needPadding(): boolean {
    return false;
  }

  public getPlaceHolder(tipoVisibilidad: ComentarioVisibilidad): string {
    if (tipoVisibilidad === ComentarioVisibilidad.Publico) {
      return this.translateService.instant('COMMENTS.WRITE_COMMENT_MESSAGE');
    }
    return this.translateService.instant('COMMENTS.WRITE_PRIVATE_NOTE');
  }

  public getNoDataString(): string {
    return this.translateService.instant('COMMENTS.NO_COMMENT_MESSAGE');
  }
}
