import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { BeneficioPersonalDTO } from 'src/app/ModelDTO/DTO/beneficioPersonal.DTO';


export class BeneficioPersonalModelDTO extends EntityModelDTO<BeneficioPersonalDTO> {


   public constructor(protected entityDTO: BeneficioPersonalDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: BeneficioPersonalDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Observacion(): string { return this.entityDTO.observacion; }
   set Observacion(value: string) { this.notifyChangeDTO('observacion', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
