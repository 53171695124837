<div class="container">
  <div class="slider-container hidden-lg" [ngSwitch]="currentStep">
    <button color="primary" class="p-0" mat-icon-button [disabled]="currentStep === 1" (click)="prevStep()">
      <mat-icon fontSet="material-symbols-outlined">chevron_left</mat-icon>
    </button>

    <div class="steps-container">
      <div class="step" *ngSwitchCase="1">
        <mat-icon fontSet="material-symbols-outlined" color="primary">mark_email_read</mat-icon>
        Confirmación email
      </div>

      <div
        class="step"
        *ngSwitchCase="2"
        [ngClass]="{
          'icon-incompleted': !postulatedCandidateForm.get('cv').value
        }"
      >
        <mat-icon fontSet="material-symbols-outlined" color="primary">cloud_upload</mat-icon>
        CV cargado
      </div>

      <div
        class="step"
        *ngSwitchCase="3"
        [ngClass]="{
          'icon-incompleted': !successfulApplication
        }"
      >
        <mat-icon fontSet="material-symbols-outlined" color="primary">cloud_done</mat-icon>
        CV recibido
      </div>
    </div>

    <button color="primary" class="p-0" mat-icon-button [disabled]="currentStep === 3" (click)="nextStep()">
      <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
    </button>
  </div>

  <div class="steps hidden-sm">
    <div class="step">
      <mat-icon fontSet="material-symbols-outlined" color="primary">mark_email_read</mat-icon>
      Confirmación email
    </div>
    <span
      class="dotted-line"
      [ngClass]="{
        'dotted-line-incompleted': !postulatedCandidateForm.get('cv').value
      }"
    >
      <div
        class="step"
        [ngClass]="{
          'icon-incompleted': !postulatedCandidateForm.get('cv').value
        }"
      >
        <mat-icon fontSet="material-symbols-outlined" color="primary">cloud_upload</mat-icon>
        CV cargado
      </div>
    </span>
    <span
      class="dotted-line"
      [ngClass]="{
        'dotted-line-incompleted': !successfulApplication
      }"
    >
      <div
        class="step"
        [ngClass]="{
          'icon-incompleted': !successfulApplication
        }"
      >
        <mat-icon fontSet="material-symbols-outlined" color="primary">cloud_done</mat-icon>
        CV recibido
      </div>
    </span>
  </div>
  <div *ngIf="!successfulApplication" class="container-postulation d-flex flex-column">
    <h1>{{ publicOffer.titulo }}</h1>
    <form [formGroup]="postulatedCandidateForm" (ngSubmit)="onSubmit()">
      <div formGroupName="candidate" class="container-form">
        <div class="container-field gap-1">
          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
          </mat-form-field>
          <div class="d-flex flex-wrap gap-1">
            <mat-form-field class="flex-fill" appearance="outline">
              <mat-label>Nombre</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="postulatedCandidateForm.get('candidate.firstName').hasError('required')"
                >{{ 'GENERAL.REQUIRED_FIELD' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="flex-fill" appearance="outline">
              <mat-label>Apellido</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="postulatedCandidateForm.get('candidate.lastName').hasError('required')"
                >{{ 'GENERAL.REQUIRED_FIELD' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex flex-wrap gap-1">
            <mat-form-field *ngIf="phoneRequired" class="flex-fill" appearance="outline">
              <mat-label>Tel&eacute;fono</mat-label>
              <input matInput formControlName="phone" />
              <mat-error *ngIf="postulatedCandidateForm.get('candidate.phone').hasError('required')"
                >{{ 'GENERAL.REQUIRED_FIELD' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="linkedInRequired" class="flex-fill" appearance="outline">
              <mat-label>LinkedIn</mat-label>
              <input matInput formControlName="linkedIn" />
              <mat-error *ngIf="postulatedCandidateForm.get('candidate.linkedIn').hasError('required')"
                >{{ 'GENERAL.REQUIRED_FIELD' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="profilePictureRequired">
          <app-seleccionar-profile-img
            [candidateImage]="imageData"
            [imageControl]="imageControl"
            [width]="200"
            [invalid]="isSubmitted && postulatedCandidateForm.get('candidate.image').hasError('required')"
          ></app-seleccionar-profile-img>
        </div>
      </div>
      <div class="mb-20"></div>
      <ng-container *ngIf="cvRequired">
        <h4>Adjuntar CV</h4>
        <app-file-selector
          [onlyOneFile]="true"
          [(files)]="files"
          accept=".pdf"
          (filesChange)="filesChange.emit($event)"
        ></app-file-selector>
        <mat-error class="required-field" *ngIf="isSubmitted && postulatedCandidateForm.get('cv').hasError('required')">
          {{ 'GENERAL.REQUIRED_FIELD' | translate }}
        </mat-error>
      </ng-container>

      <div class="mb-20"></div>
      <div *ngIf="coverLetterRequired" class="d-flex flex-column flex-grow-1 mt-2 p-relative">
        <label class="label-quill">Carta de presentaci&oacute;n</label>
        <quill-editor
          class="form-control"
          [modules]="quill_module"
          formControlName="coverLetter"
          placeholder=""
          bounds="quill-editor"
        ></quill-editor>
        <mat-error class="required-field" *ngIf="isSubmitted && postulatedCandidateForm.get('coverLetter').hasError('required')">{{
          'GENERAL.REQUIRED_FIELD' | translate
        }}</mat-error>
      </div>
      <div class="d-flex flex-column flex-grow-1 pb-6" *ngIf="skillsFormArray?.length">
        <h4>Nivel de conocimientos y/o competencias</h4>
        <div class="offer-details">
          <div class="skill-level" formArrayName="skills">
            <div class="skill" *ngFor="let skill of skillsFormArray.controls; let i = index" [formGroupName]="i">
              {{ skill.value.skillName }}
              <mat-slider color="primary" min="0" max="100" step="10" aria-label="unit(s)" discrete showTickMarks>
                <input matSliderThumb [formControl]="skillScoreControl(skill)" />
              </mat-slider>
              <mat-error class="required-field" *ngIf="isSubmitted && skill.get('score').errors">{{
                'GENERAL.REQUIRED_FIELD' | translate
              }}</mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-20"></div>
      <div class="d-flex flex-grow-1 mb-20">
        <mat-slide-toggle class="toggle-space" color="primary" formControlName="public"
          >Acepto que mi postulación pueda visualizarse en la comunidad de Naaloo y que otras personas en Naaloo puedan referirme para
          futuros empleos.</mat-slide-toggle
        >
      </div>
      <div class="mb-20"></div>
      <div class="d-flex justify-content-center pt-6 mb-20">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          class="button-margin"
          [ngClass]="{
            'button-green': successfulApplication
          }"
        >
          {{ successfulApplication ? 'Ya te has postulado!' : 'Postularme a este trabajo' }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="successfulApplication" class="container-image">
    <img src="assets/img/recruitment-postulation-1.png" class="hide-md" />
    <img src="assets/img/recruitment-postulation-2.png" class="hidden-lg" />
    <div class="message-container">
      <h1 class="title mt-0">¡Estás un paso más cerca! La empresa ha recibido tu CV.</h1>
      <span>En caso de avanzar con tu postulación se pondrán en contacto contigo.</span>
    </div>
  </div>
</div>
