import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CompleterBaseData } from './completer-base-data';
import { CompleterItem } from '../components/completer-item';

export class RemoteData extends CompleterBaseData {
  public dataSourceChange: EventEmitter<void> = new EventEmitter<void>();

  private pRemoteUrl: string | null;
  private remoteSearch: Subscription;
  private pUrlFormater: ((term: string) => string) | null = null;
  private pDataField: string | null = null;
  private pRequestOptions: any;

  constructor(private http: HttpClient) {
    super();
  }

  public remoteUrl(remoteUrl: string | null) {
    this.pRemoteUrl = remoteUrl;
    this.dataSourceChange.emit();

    return this;
  }

  public urlFormater(urlFormater: (term: string) => string) {
    this.pUrlFormater = urlFormater;
  }

  public dataField(dataField: string) {
    this.pDataField = dataField;
  }

  public requestOptions(requestOptions: any) {
    this.pRequestOptions = requestOptions;
  }

  public search(term: string): void {
    this.cancel();
    let url = '';
    if (this.pUrlFormater) {
      url = this.pUrlFormater(term);
    } else {
      url = this.pRemoteUrl + encodeURIComponent(term);
    }

    this.remoteSearch = this.http
      .get(url, Object.assign({}, this.pRequestOptions))
      .pipe(
        map((data: any) => {
          const matches = this.extractValue(data, this.pDataField);
          return this.extractMatches(matches, term);
        }),
        catchError(() => [])
      )
      .subscribe((matches: any[]) => {
        const results = this.processResults(matches);
        this.next(results);
      });
  }

  public cancel() {
    if (this.remoteSearch) {
      this.remoteSearch.unsubscribe();
    }
  }

  public convertToItem(data: any): CompleterItem | null {
    return super.convertToItem(data);
  }
}
