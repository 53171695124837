import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';
import { DocumentacionConAnalisisDTO } from 'src/app/ModelDTO/DTO/documentacionConAnalisis.DTO';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';


export class InformacionConAnalisisDTO implements IEntityDTO {

   informacion: InformacionDTO;
   documentacionConAnalisisDTO: DocumentacionConAnalisisDTO;
   authRequest: AuthRequestDTO;
   solicitarFirma: boolean;
   firmarAhora: boolean;
   notificarFirma: boolean;
   posicionFirmaColaborador: ConfiguracionFirmaDigitalPosicionDTO;
   posicionFirmaEmpresa: ConfiguracionFirmaDigitalPosicionDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.informacion = new InformacionDTO();
      this.documentacionConAnalisisDTO = new DocumentacionConAnalisisDTO();
      this.authRequest = new AuthRequestDTO();
      this.posicionFirmaColaborador = new ConfiguracionFirmaDigitalPosicionDTO();
      this.posicionFirmaEmpresa = new ConfiguracionFirmaDigitalPosicionDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.informacion != null) { this.informacion.PrepareDTO(jsonObj.informacion); }
      if (jsonObj.documentacionConAnalisisDTO != null) { this.documentacionConAnalisisDTO.PrepareDTO(jsonObj.documentacionConAnalisisDTO); }
      if (jsonObj.authRequest != null) { this.authRequest.PrepareDTO(jsonObj.authRequest); }
      if (jsonObj.solicitarFirma != null) { this.solicitarFirma = jsonObj.solicitarFirma; }
      if (jsonObj.firmarAhora != null) { this.firmarAhora = jsonObj.firmarAhora; }
      if (jsonObj.notificarFirma != null) { this.notificarFirma = jsonObj.notificarFirma; }
      if (jsonObj.posicionFirmaColaborador != null) { this.posicionFirmaColaborador.PrepareDTO(jsonObj.posicionFirmaColaborador); }
      if (jsonObj.posicionFirmaEmpresa != null) { this.posicionFirmaEmpresa.PrepareDTO(jsonObj.posicionFirmaEmpresa); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
