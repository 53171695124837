import { Component, Input } from '@angular/core';
import { MobileSidebarService } from '../mobile-sidebar/mobile-sidebar.service';
import { OnDestroy } from '@angular/core';
import { fromEvent, timer, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'neo-pull-to-refresh',
  template: `
    <div *ngIf="showSpinner" style="position: absolute; top: 50px; left: calc(50% - 20px); z-index: 999">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  `,
})
export class PullToRefreshComponent implements OnDestroy {
  @Input() scrolledElement: any = document.body;

  public showSpinner = false;

  private sidebarNotOpen = true;
  private subscription: Subscription = new Subscription();
  private startY = 0; // Para almacenar la posición inicial de touchstart
  private dragging = false; // Para saber si se está arrastrando
  private touchstart$ = fromEvent<TouchEvent>(document, 'touchstart');
  private touchend$ = fromEvent<TouchEvent>(document, 'touchend');

  constructor(
    public headerService: HeaderAppService,
    public mobileSidebarService: MobileSidebarService,
  ) {
    this.subscription.add(
      this.mobileSidebarService.showSidebar.subscribe((value) => {
        this.sidebarNotOpen = !value;
      }),
    );

    this.subscription.add(
      this.headerService.loadComplete$
        .pipe(
          switchMap(() => timer(1000)), // Esperar al menos 1 segundos
        )
        .subscribe(() => {
          this.showSpinner = false;
          this.dragging = false;
        }),
    );

    this.subscription.add(
      this.touchstart$.subscribe((event) => {
        this.startY = event.touches[0].clientY;

        if (this.scrolledElement.scrollTop === 0) {
          this.dragging = true;
        }
      }),
    );

    this.subscription.add(
      this.touchend$.subscribe((event) => {
        const endY = event.changedTouches[0].clientY;
        if (endY - this.startY > 100 && this.sidebarNotOpen && this.dragging) {
          // this.showSpinner = true; por ahora usamos el loader del header
          this.headerService.nextReload();
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
