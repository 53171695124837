import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectAndCropComponent } from 'src/app/shared/select-and-crop/select-and-crop.component';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';

export interface CandidateImageData {
  imagen: string;
  changed: boolean;
}

@Component({
  selector: 'app-seleccionar-profile-img',
  templateUrl: './seleccionar-profile-img.component.html',
  styleUrls: ['./seleccionar-profile-img.component.scss'],
})
export class SeleccionarProfileImgComponent {
  @ViewChild('fileImage') fileImage: ElementRef;
  @Input() candidateImage: CandidateImageData;
  @Input() imageControl: AbstractControl;
  @Input() width: number;
  @Input() invalid = false;

  constructor(private dialog: MatDialog) {}

  public fileChangeEvent(event: unknown): void {
    const dialogRef = this.dialog.open(SelectAndCropComponent, {
      width: '600px',
      disableClose: false,
      data: { event },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        const image = new ImageEntityDTO();
        image.image = result;
        this.candidateImage.imagen = image.image;
        this.candidateImage.changed = true;
        this.imageControl.setValue(image.image);
      }
    });
  }

  public dropImagen(files: FileList): void {
    this.fileChangeEvent({
      target: {
        files,
      },
    });
  }

  public clickImageInput(): void {
    this.fileImage.nativeElement.click();
  }
}
