import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/app/shared/constants';

@Pipe({
  name: 'cdnImageUrl',
  standalone: true,
})
export class CdnImageUrlPipe implements PipeTransform {
  public transform(image: string): string {
    let newImage = image;
    if (newImage) {
      // Base64
      if (newImage.length > 200) {
        return image;
      }

      if (!newImage.startsWith('http') && !newImage.includes('.jpg')) {
        newImage += '.jpg';
      }
      newImage = newImage.replace(/&pixelsSize=\d+/g, '');
      return newImage.startsWith('http') ? `${newImage}&pixelsSize=96` : `${Constants.apiURL}/image${newImage}?pixelsSize=96`;
    }
    return null;
  }
}
