export enum TypeSocial {
  Ninguno = 0,
  Facebook = 1,
  Twitter = 2,
  Instagram = 3,
  GOOGLE = 4,
  WindowsLive = 5,
  FIREBASE = 6,
  APPLE = 7,
  APPLE_DEVICEID = 8,
  Tenant = 9,
  Recaptcha = 10,
  Webpush = 11,
}
