import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalCambioEstadoBasicoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoBasico.DTO';
import { TipoCambioEstadoPersonalDTO } from 'src/app/ModelDTO/DTO/tipoCambioEstadoPersonal.DTO';
import { TipoCambioEstadoPersonalModelDTO } from 'src/app/ModelDTO/tipoCambioEstadoPersonal.ModelDTO';


export class PersonalCambioEstadoBasicoModelDTO extends EntityModelDTO<PersonalCambioEstadoBasicoDTO> {

   private tipoModel: TipoCambioEstadoPersonalModelDTO;
   private tipoSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalCambioEstadoBasicoDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: PersonalCambioEstadoBasicoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.tipoModel = new TipoCambioEstadoPersonalModelDTO(this.entityDTO.tipo);
      this.tipoSubscribe = this.tipoModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.tipoModel.dispose();
      this.tipoSubscribe.unsubscribe();
   }

   get Desde(): Date { return this.entityDTO.desde; }
   set Desde(value: Date) { this.notifyChangeDTO('desde', value); }

   get Hasta(): Date { return this.entityDTO.hasta; }
   set Hasta(value: Date) { this.notifyChangeDTO('hasta', value); }

   get Observacion(): string { return this.entityDTO.observacion; }
   set Observacion(value: string) { this.notifyChangeDTO('observacion', value); }

   get Litigio(): boolean { return this.entityDTO.litigio; }
   set Litigio(value: boolean) { this.notifyChangeDTO('litigio', value); }

   get LitigioObservacion(): string { return this.entityDTO.litigioObservacion; }
   set LitigioObservacion(value: string) { this.notifyChangeDTO('litigioObservacion', value); }

   get LitigioFecha(): Date { return this.entityDTO.litigioFecha; }
   set LitigioFecha(value: Date) { this.notifyChangeDTO('litigioFecha', value); }

   get TipoModel(): TipoCambioEstadoPersonalModelDTO { return this.tipoModel; }
   get Tipo(): TipoCambioEstadoPersonalDTO { return this.tipoModel.getEntityDTO(); }
   set Tipo(value: TipoCambioEstadoPersonalDTO) { this.notifyChange(() => { this.entityDTO.tipo = value; this.tipoModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
