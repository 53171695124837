import { Injectable } from '@angular/core';
import { TipoEntregaDTO } from '@api/interfaces/tipo-entrega.interface';
import { TipoEntregaBackendService } from '@api/services/tipo-entrega-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Injectable()
export class DeliveryService {
  public from: DateTime = DateTime.now().minus({ months: 3 });
  public to: DateTime = DateTime.now().plus({ months: 3 });
  public deliveryTypes: TipoEntregaDTO[] = [];
  public selectedDeliveryType: TipoEntregaDTO;

  constructor(
    private tipoEntregaBackendService: TipoEntregaBackendService,
    private translate: TranslateService,
  ) {
    this.tipoEntregaBackendService.getTipoEntrega().subscribe((deliveryTypes) => {
      this.deliveryTypes = deliveryTypes;
      this.addAllOption();
    });
  }

  private addAllOption(): void {
    const allOption: TipoEntregaDTO = {
      id: 0,
      nombre: this.translate.instant('GENERAL.ALL'),
      tiempoDevolucion: 0,
      poseeCertificacion: false,
      unidadDevolucion: null,
      tipoDevolucion: null,
      diasPrevioAviso: 0,
      asignarColaboradoresExistentes: false,
      generarConstancia: false,
      solicitarFirma: false,
      convenio: {
        id: 0,
        nombre: '',
        horasSemanales: 0,
        dailyHours: 0,
        domingo: false,
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
      },
    };
    this.deliveryTypes.unshift(allOption);
  }
}
