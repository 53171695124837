<div class="server-error-dialog">
  <div class="d-flex flew-row justify-content-end">
    <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
  </div>
  <div class="server-error-dialog-message">
    <h1>Oh no...</h1>
    <p>{{ data.errorMessage }}</p>
    <button (click)="reload()" mat-stroked-button color="primary">Recargar</button>
  </div>
  <div class="version">{{ version }}</div>
</div>
