import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { PersonalFeedbackDTO } from '@api/interfaces/personal-feedback.interface';
import { PersonalLegajoBasicoDTO } from '@api/interfaces/personal-legajo-basico.interface';
import { PersonalLegajoBackendService } from '@api/services/personal-legajo-backend.service';
import { DateTime, Duration } from 'luxon';
import { featureFeedbackEnviado, featureFeedbackRecibido } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';

interface TeamamteFeedbackInformation {
  teammate: PersonalLegajoBasicoDTO | undefined;
  lastUpdate?: DateTime;
  sent?: PersonalFeedbackDTO[];
  recieved?: PersonalFeedbackDTO[];
}

@Injectable()
export class TeammateFeedbackService {
  private $teammateFeedback: WritableSignal<TeamamteFeedbackInformation>;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public $teammateFeedbackInformation: Signal<TeamamteFeedbackInformation>;

  private $feedbackFeatures = computed(() => ({
    feedbackSentFlag: FeatureFlagHelper.featureOn(featureFeedbackEnviado, this.flagsService.$flags().flags),
    feedbackRecievedFlag: FeatureFlagHelper.featureOn(featureFeedbackRecibido, this.flagsService.$flags().flags),
  }));

  constructor(
    private teammateService: PersonalLegajoBackendService,
    private flagsService: FeatureFlagService,
  ) {
    this.$teammateFeedback = signal({
      teammate: undefined,
      lastUpdate: undefined,
    });

    this.$teammateFeedbackInformation = this.$teammateFeedback.asReadonly();
  }

  public updateTeammateFeedback(teammate: PersonalLegajoBasicoDTO, force = false): void {
    const { lastUpdate } = this.$teammateFeedback();
    const { feedbackSentFlag, feedbackRecievedFlag } = this.$feedbackFeatures();
    let shouldUpdate = !lastUpdate;

    if (lastUpdate) {
      const difference = DateTime.now().diff(lastUpdate);
      shouldUpdate = difference < Duration.fromObject({ minutes: 5 });
    }

    if (feedbackSentFlag && (force || shouldUpdate)) {
      this.teammateService.getPersonalLegajosIdFeedbackEnviados(teammate.id, 0, 0).subscribe((x) => {
        this.$teammateFeedback.update((currentValue) => ({
          teammate,
          sent: x.sort((a: PersonalFeedbackDTO, b: PersonalFeedbackDTO) => b.fechaCarga.toMillis() - a.fechaCarga.toMillis()),
          recieved: currentValue.recieved,
        }));
      });
    }

    if (feedbackRecievedFlag && (force || shouldUpdate)) {
      this.teammateService.getPersonalLegajosIdFeedbackRecibidos(teammate.id, 0, 0).subscribe((x) => {
        this.$teammateFeedback.update((currentValue) => ({
          teammate,
          recieved: x.sort((a: PersonalFeedbackDTO, b: PersonalFeedbackDTO) => b.fechaCarga.toMillis() - a.fechaCarga.toMillis()),
          sent: currentValue.sent,
        }));
      });
    }
  }
}
