import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AltasEmpleadoEnum } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/detalle-empleado.component';

@Injectable({
  providedIn: 'root',
})
export class DetalleEmpleadoService {
  private altaEvento = new Subject<AltasEmpleadoEnum>();
  public altaEvento$ = this.altaEvento.asObservable();

  public nuevoAltaEvento(tipo: AltasEmpleadoEnum): void {
    this.altaEvento.next(tipo);
  }
}
