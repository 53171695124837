import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class FichandoDTO implements IEntityDTO {

   fechaFichaje: Date;
   geoLocalizacion = '';
   imageUrl = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaFichaje != null) { this.fechaFichaje = new Date(jsonObj.fechaFichaje); }
      if (jsonObj.geoLocalizacion != null) { this.geoLocalizacion = jsonObj.geoLocalizacion; }
      if (jsonObj.imageUrl != null) { this.imageUrl = jsonObj.imageUrl; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
