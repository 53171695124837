export const ADMIN_TIME_MANAGEMENT = '/admin/fichaje';
export const RECRUITMENT_DASHBOARD = '/reclutamiento';

// Value is validation time in seconds
export const ROUTE_CACHED_ENDPOINTS = {
  [ADMIN_TIME_MANAGEMENT]: 30,
  [RECRUITMENT_DASHBOARD]: 300,
};

export type ComponentCacheKeys = keyof typeof ROUTE_CACHED_ENDPOINTS;
