/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { AddresseesDTO } from 'src/app/api/interfaces/addressees.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { EncuestaDTO } from 'src/app/api/interfaces/encuesta.interface';
import { FullTaskGoalDTO } from 'src/app/api/interfaces/full-task-goal.interface';
import { PendingTaskUserDTO } from 'src/app/api/interfaces/pending-task-user.interface';
import { PersonalTaskGoalsDTO } from 'src/app/api/interfaces/personal-task-goals.interface';
import { TaskElementDTO } from 'src/app/api/interfaces/task-element.interface';
import { TaskGoalInformationDTO } from 'src/app/api/interfaces/task-goal-information.interface';

@Injectable({
   providedIn: 'root'
})
export class TaskBackendService {

   constructor(protected http: HttpClient) { }


   public getTasksIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/tasks/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getTasks(): Observable<TaskElementDTO[]> {
      return this.http.get<DataDTO<TaskElementDTO>>(Constants.apiURL + '/tasks/').pipe(map((res) => convertJsonDates(res.data) as TaskElementDTO[]));
   }

   public insertTasks(taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.post<TaskElementDTO>(Constants.apiURL + '/tasks/', taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public deleteTasksId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/tasks/' + id);
   }
   public getTasksId(id: number): Observable<TaskElementDTO> {
      return this.http.get<TaskElementDTO>(Constants.apiURL + '/tasks/' + id).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public updateTasksId(id: number, taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.put<TaskElementDTO>(Constants.apiURL + '/tasks/' + id, taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public getTasksCurrentUser(params: { pageNumber: number, pageSize: number, startDate: Date, endDate: Date, createdByMe?: boolean, includeCompleted?: boolean, sortActive?: number, isAsc?: boolean }, ): Observable<TaskElementDTO[]> {
      const { pageNumber, pageSize, startDate, endDate, createdByMe = false, includeCompleted = false, sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<TaskElementDTO>>(Constants.apiURL + '/tasks/currentUser' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&startDate=' + (startDate?.toISOString() ?? '') +
            '&endDate=' + (endDate?.toISOString() ?? '') +
            '&createdByMe=' + createdByMe +
            '&includeCompleted=' + includeCompleted +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as TaskElementDTO[]));
   }

   public getTasksPendingByTeammate(params: { pageNumber: number, pageSize: number, teammateId?: number, sortActive?: number, isAsc?: boolean }, ): Observable<PendingTaskUserDTO[]> {
      const { pageNumber, pageSize, teammateId = 0, sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<PendingTaskUserDTO>>(Constants.apiURL + '/tasks/pendingByTeammate' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&teammateId=' + teammateId +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as PendingTaskUserDTO[]));
   }

   public getTasksTeammatesteammateId(params: { teammateId: number, pageNumber: number, pageSize: number, startDate: Date, endDate: Date, includeCompleted?: boolean, sortActive?: number, isAsc?: boolean }, ): Observable<TaskElementDTO[]> {
      const { teammateId, pageNumber, pageSize, startDate, endDate, includeCompleted = false, sortActive = 0, isAsc = false } = params;
      return this.http.get<DataDTO<TaskElementDTO>>(Constants.apiURL + '/tasks/teammates/' + teammateId + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&startDate=' + (startDate?.toISOString() ?? '') +
            '&endDate=' + (endDate?.toISOString() ?? '') +
            '&includeCompleted=' + includeCompleted +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc).pipe(map((res) => convertJsonDates(res.data) as TaskElementDTO[]));
   }

   public getTasksTemplates(filterGoals: boolean = false): Observable<TaskElementDTO[]> {
      return this.http.get<DataDTO<TaskElementDTO>>(Constants.apiURL + '/tasks/templates' + '?filterGoals=' + filterGoals).pipe(map((res) => convertJsonDates(res.data) as TaskElementDTO[]));
   }

   public insertTasksTeammatesteammateId(teammateId: number, taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.post<TaskElementDTO>(Constants.apiURL + '/tasks/teammates/' + teammateId, taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public insertTasksTemplates(taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.post<TaskElementDTO>(Constants.apiURL + '/tasks/templates', taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public updateTasksTemplateId(id: number, taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.put<TaskElementDTO>(Constants.apiURL + '/tasks/template/' + id, taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public getTasksTemplateId(id: number): Observable<TaskElementDTO> {
      return this.http.get<TaskElementDTO>(Constants.apiURL + '/tasks/template/' + id).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public deleteTasksTemplateId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/tasks/template/' + id);
   }
   public insertTasksTemplatestemplateIdTeammateteammateId(templateId: number, teammateId: number, taskElementDTO: TaskElementDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/tasks/templates/' + templateId + '/teammate/' + teammateId, taskElementDTO);
   }
   public getTasksGoals(params: { personal?: number, year?: number, strategicDriver?: number, overallEvaluationId?: number, withoutOverallEvaluation?: boolean }, ): Observable<PersonalTaskGoalsDTO[]> {
      const { personal = undefined, year = undefined, strategicDriver = undefined, overallEvaluationId = 0, withoutOverallEvaluation = false } = params;
      return this.http.get<DataDTO<PersonalTaskGoalsDTO>>(Constants.apiURL + '/tasks/goals' + '?personal=' + personal +
            '&year=' + year +
            '&strategicDriver=' + strategicDriver +
            '&overallEvaluationId=' + overallEvaluationId +
            '&withoutOverallEvaluation=' + withoutOverallEvaluation).pipe(map((res) => convertJsonDates(res.data) as PersonalTaskGoalsDTO[]));
   }

   public getTasksGoalsId(id: number): Observable<FullTaskGoalDTO> {
      return this.http.get<FullTaskGoalDTO>(Constants.apiURL + '/tasks/goals/' + id).pipe(map((res) => convertJsonDates(res) as FullTaskGoalDTO ));
   }

   public getTasksCurrentUserGoals(params: { year?: number, strategicDriver?: number, overallEvaluationId?: number, withoutOverallEvaluation?: boolean }, ): Observable<PersonalTaskGoalsDTO[]> {
      const { year = undefined, strategicDriver = undefined, overallEvaluationId = undefined, withoutOverallEvaluation = false } = params;
      return this.http.get<DataDTO<PersonalTaskGoalsDTO>>(Constants.apiURL + '/tasks/currentUser/goals' + '?year=' + year +
            '&strategicDriver=' + strategicDriver +
            '&overallEvaluationId=' + overallEvaluationId +
            '&withoutOverallEvaluation=' + withoutOverallEvaluation).pipe(map((res) => convertJsonDates(res.data) as PersonalTaskGoalsDTO[]));
   }

   public insertTasksGoalsGOALIDBulk(goalId: number, addresseesDTO: AddresseesDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/tasks/goals/' + goalId + '/bulk', addresseesDTO);
   }
   public updateTasksIdEvaluate(id: number, taskGoalInformationDTO: TaskGoalInformationDTO): Observable<TaskElementDTO> {
      return this.http.put<TaskElementDTO>(Constants.apiURL + '/tasks/' + id + '/evaluate', taskGoalInformationDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public updateTasksIdStatusChange(id: number, taskElementDTO: TaskElementDTO): Observable<TaskElementDTO> {
      return this.http.put<TaskElementDTO>(Constants.apiURL + '/tasks/' + id + '/statusChange', taskElementDTO).pipe(map((res) => convertJsonDates(res) as TaskElementDTO ));
   }

   public getTasksIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/tasks/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertTasksIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/tasks/' + id + '/comentarios', stream);
   }
   public updateTasksIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/tasks/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteTasksIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/tasks/' + id + '/comentarios/' + iDComentario);
   }}
