<h3 mat-dialog-title class="d-flex flew-row justify-content-between">
  <span>{{ title }}</span>
  <span class="dialog-close-icon material-symbols-outlined" mat-dialog-close> close </span>
</h3>
<div mat-dialog-content>
  <p>
    <strong>{{ 'PERFORMANCE.GOAL' | translate }}:</strong>
    {{ goalBody$ | async }}
  </p>
  <p>
    <strong>{{ 'GENERAL.EDITOR' | translate }}:</strong>
    {{ editorName$ | async }}
  </p>
  <strong>{{ 'GENERAL.CHANGES' | translate }}:</strong>
  <p class="text-prewrap">{{ changes$ | async }}</p>
</div>
<div mat-dialog-actions class="d-flex flex-row justify-content-end">
  <button mat-raised-button color="primary" (click)="redirectToGoal()">{{ 'PERFORMANCE.GOALS.SEE_GOAL' | translate }}</button>
</div>
