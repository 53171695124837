import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PersonalLegajoBasicoDTO } from '@api/interfaces/personal-legajo-basico.interface';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { AbsenceCommentHandler } from 'src/app/app-common/comentarios/absence-comments-handler';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { UserAusenciasService } from 'src/app/layout/user/user-ausencias/user-ausencias.service';
import { PersonalAusenciaModelDTO } from 'src/app/ModelDTO';
import {
  EstadoAprobacionSolicitud,
  PersonalAusenciaDTO,
  PersonalLegajoBasicoDTO as PersonalLegajoBasicoDTOclass,
} from 'src/app/ModelDTO/DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { AlertButton, NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { classToJson } from 'src/app/shared/shared-functions';

export interface IAbsenceModalDataClosed {
  deleted: boolean;
  redirect: boolean;
}
@Component({
  selector: 'app-detalle-ausencia-modal',
  templateUrl: './detalle-ausencia-modal.component.html',
})
export class DetalleAusenciaModalComponent implements OnInit {
  public ausenciaModel: PersonalAusenciaModelDTO;
  public eliminarCmd: ICommand = new Command(() => this.eliminar(), new BehaviorSubject(true), true);

  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public estadosSolicitud = EstadoAprobacionSolicitud;
  public readonly convertToJson = classToJson<PersonalLegajoBasicoDTOclass, PersonalLegajoBasicoDTO>;

  constructor(
    private headerService: HeaderAppService,
    @Inject(MAT_DIALOG_DATA) public data: PersonalAusenciaDTO,
    public comentarioHandler: AbsenceCommentHandler,
    private dialogRef: MatDialogRef<DetalleAusenciaModalComponent>,
    private userAusenciaService: UserAusenciasService,
    private neoModalService: NgxNeoModalMatService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.ausenciaModel = new PersonalAusenciaModelDTO(this.data);
  }

  public ngOnInit(): void {
    this.dialogRef
      .keydownEvents()
      .pipe(
        filter((e: KeyboardEvent) => e.code === 'Escape'),
        take(1),
      )
      .subscribe(() => this.dialogRef.close({ deleted: false, redirect: false }));

    this.dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close({ deleted: false, redirect: false });
      });
  }

  public async eliminar(): Promise<void> {
    const res = await this.neoModalService.decision(
      this.translate.instant('TIMEOFF.DECISION_TO_ELIMINATE_ABSENCE', {
        teammate: this.ausenciaModel.PersonalLegajo.nombreCompleto,
      }),
    );
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.userAusenciaService.eliminarAusenciaPersonal(this.ausenciaModel.getEntityDTO());
      this.dialogRef.close({ deleted: true, redirect: false });
    }
  }

  public close(): void {
    this.dialogRef.close({ deleted: false, redirect: false });
  }

  public addDocumentation(): void {
    this.router.navigate(['admin/ausencias/edit-absence', this.ausenciaModel.Id]);
    this.dialogRef.close({ deleted: false, redirect: true });
  }
}
