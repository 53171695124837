import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';

@Injectable({
   providedIn: 'root'
})
export class PaisServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getPaisesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/paises/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPaisesIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/paises/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getPaises(): Promise<Array<PaisDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/paises/'));
         const resJson = res.data;
         const resDTO = new Array<PaisDTO>();
         for (const item of resJson) {
            const itemDTO = new PaisDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPaises(paisDTO: PaisDTO): Promise<PaisDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/paises/', paisDTO));
         const resDTO = new PaisDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePaisesId(id: number, paisDTO: PaisDTO): Promise<PaisDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/paises/' + id, paisDTO));
         const resDTO = new PaisDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deletePaisesId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/paises/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getPaisesId(id: number): Promise<PaisDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/paises/' + id));
         if (!res) { return null; }
         const resDTO = new PaisDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPaisesAutoComplete(contiene: string, pageSize: number): Promise<Array<PaisDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/paises/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PaisDTO>();
         for (const item of resJson) {
            const itemDTO = new PaisDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPaisesNacionalidadesAutoComplete(contiene: string, pageSize: number): Promise<Array<PaisDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/paises/nacionalidades/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PaisDTO>();
         for (const item of resJson) {
            const itemDTO = new PaisDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
