import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO,  HttpClientExtended, StreamingResultDto, IStreamingOptions } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO/resultadoImportacion.DTO';

@Injectable({
   providedIn: 'root'
})
export class ImportacionPersonalServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getImportacionesPersonalIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/importacionesPersonal/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertImportacionesPersonal(stream: FormData, progress: Subject<number>, options?: IStreamingOptions<ResultadoImportacionDTO>): Promise<ResultadoImportacionDTO> {

         const res = await (<HttpClientExtended>this.http).GetResponseByStreamingOnPost<StreamingResultDto<ResultadoImportacionDTO>, ResultadoImportacionDTO>(Constants.apiURL + '/importacionesPersonal/', stream, progress, options);
         const resDTO = new ResultadoImportacionDTO();
         resDTO.PrepareDTO(res.message);
         return resDTO;

   }

}
