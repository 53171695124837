import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfiguracionControlHorarioDTO implements IEntityDTO {

   foto: boolean;
   gps: boolean;
   obligatorio: boolean;
   tiempoRegeneracionQR = 0;
   requerirObservacion: boolean;
   observacionObligatoria: boolean;
   notifyClockinEmail: boolean;
   faceDetectionEnabled: boolean;
   maximoEntrada = '';
   maximoSalida = '';
   tiempoDescanso = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.foto != null) { this.foto = jsonObj.foto; }
      if (jsonObj.gps != null) { this.gps = jsonObj.gps; }
      if (jsonObj.obligatorio != null) { this.obligatorio = jsonObj.obligatorio; }
      if (jsonObj.tiempoRegeneracionQR != null) { this.tiempoRegeneracionQR = jsonObj.tiempoRegeneracionQR; }
      if (jsonObj.requerirObservacion != null) { this.requerirObservacion = jsonObj.requerirObservacion; }
      if (jsonObj.observacionObligatoria != null) { this.observacionObligatoria = jsonObj.observacionObligatoria; }
      if (jsonObj.notifyClockinEmail != null) { this.notifyClockinEmail = jsonObj.notifyClockinEmail; }
      if (jsonObj.faceDetectionEnabled != null) { this.faceDetectionEnabled = jsonObj.faceDetectionEnabled; }
      if (jsonObj.maximoEntrada != null) { this.maximoEntrada = jsonObj.maximoEntrada; }
      if (jsonObj.maximoSalida != null) { this.maximoSalida = jsonObj.maximoSalida; }
      if (jsonObj.tiempoDescanso != null) { this.tiempoDescanso = jsonObj.tiempoDescanso; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
