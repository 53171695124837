import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TipoFeedbackDTO } from 'src/app/ModelDTO/DTO/tipoFeedback.DTO';
import { AuditLogEntryDTO, DataDTO, NamedBlobDTO, NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class TipoFeedbackServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getTipoFeedbackIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoFeedback/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoFeedbackIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoFeedback/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/pdf' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getTipoFeedback(): Promise<Array<TipoFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoFeedback/'));
         const resJson = res.data;
         const resDTO = new Array<TipoFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertTipoFeedback(tipoFeedbackDTO: TipoFeedbackDTO): Promise<TipoFeedbackDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tipoFeedback/', tipoFeedbackDTO));
         const resDTO = new TipoFeedbackDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateTipoFeedbackId(id: number, tipoFeedbackDTO: TipoFeedbackDTO): Promise<TipoFeedbackDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tipoFeedback/' + id, tipoFeedbackDTO));
         const resDTO = new TipoFeedbackDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteTipoFeedbackId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/tipoFeedback/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getTipoFeedbackId(id: number): Promise<TipoFeedbackDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoFeedback/' + id));
         if (!res) { return null; }
         const resDTO = new TipoFeedbackDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
