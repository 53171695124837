export enum TipoEstadoFichaje {
   No_definido = 0,
   Sin_analizar = 1,
   Editando_supervisor = 2,
   Editado_por_supervisor = 3,
   Editando_jefe = 4,
   Editado_por_jefe = 5,
   Editando_RRHH = 6,
   Editado_por_RRHH = 7
 }
