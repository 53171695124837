import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class DatosTerminalFichajeDTO implements IEntityDTO {

   connectionID = '';
   nombreTerminal = '';
   qr = '';
   qrRegenerationTime = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.connectionID != null) { this.connectionID = jsonObj.connectionID; }
      if (jsonObj.nombreTerminal != null) { this.nombreTerminal = jsonObj.nombreTerminal; }
      if (jsonObj.qr != null) { this.qr = jsonObj.qr; }
      if (jsonObj.qrRegenerationTime != null) { this.qrRegenerationTime = jsonObj.qrRegenerationTime; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
