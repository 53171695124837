import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EstadoAprobacionSolicitud } from 'src/app/ModelDTO/DTO/estadoAprobacionSolicitud.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';


export class PersonalAusenciaDTO implements IEntityDTO {

   estado: EstadoAprobacionSolicitud;
   creador: PersonalLegajoBasicoDTO;
   personalLegajo: PersonalLegajoBasicoDTO;
   fechaDesde: Date;
   fechaHasta: Date;
   dateTimeFrom: Date;
   dateTimeTo: Date;
   descripcion = '';
   ausencia: AusenciaDTO;
   conSolicitud: boolean;
   adjuntos: Array<AttachmentUaalooDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoBasicoDTO();
      this.personalLegajo = new PersonalLegajoBasicoDTO();
      this.ausencia = new AusenciaDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as EstadoAprobacionSolicitud; }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.fechaDesde != null) { this.fechaDesde = new Date(jsonObj.fechaDesde); }
      if (jsonObj.fechaHasta != null) { this.fechaHasta = new Date(jsonObj.fechaHasta); }
      if (jsonObj.dateTimeFrom != null) { this.dateTimeFrom = new Date(jsonObj.dateTimeFrom); }
      if (jsonObj.dateTimeTo != null) { this.dateTimeTo = new Date(jsonObj.dateTimeTo); }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.ausencia != null) { this.ausencia.PrepareDTO(jsonObj.ausencia); }
      if (jsonObj.conSolicitud != null) { this.conSolicitud = jsonObj.conSolicitud; }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
