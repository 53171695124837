import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';


export class ConfiguracionFirmaDigitalDTO implements IEntityDTO {

   activada: boolean;
   pedirConformidad: boolean;
   firmaObligatoria: boolean;
   posicionesFirma: Array<ConfiguracionFirmaDigitalPosicionDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.posicionesFirma = new Array<ConfiguracionFirmaDigitalPosicionDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.activada != null) { this.activada = jsonObj.activada; }
      if (jsonObj.pedirConformidad != null) { this.pedirConformidad = jsonObj.pedirConformidad; }
      if (jsonObj.firmaObligatoria != null) { this.firmaObligatoria = jsonObj.firmaObligatoria; }
      if (jsonObj.posicionesFirma != null) { for (const item of jsonObj.posicionesFirma) { const itemDTO = new ConfiguracionFirmaDigitalPosicionDTO(); itemDTO.PrepareDTO(item); this.posicionesFirma.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
