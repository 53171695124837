import { Injectable } from '@angular/core';
import { NamedBlobDTO as NamedBlobApiDTO } from '@api/interfaces/named-blob.dto';
import FileSaver from 'file-saver';
import { CordovaService, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor(private cordovaService: CordovaService) {}

  public saveAs(namedBlob: NamedBlobDTO | NamedBlobApiDTO): void {
    if (this.cordovaService.isCordovaApp) {
      this.cordovaService.downloadAndOpen(namedBlob);
    } else {
      FileSaver.saveAs(namedBlob.blob, namedBlob.name);
    }
  }
}
