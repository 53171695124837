/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { TipoEntregaDTO } from 'src/app/api/interfaces/tipo-entrega.interface';
import { TipoEntregaFlatDTO } from 'src/app/api/interfaces/tipo-entrega-flat.interface';

@Injectable({
   providedIn: 'root'
})
export class TipoEntregaBackendService {

   constructor(protected http: HttpClient) { }


   public getTipoEntregaIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/tipoEntrega/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getTipoEntrega(): Observable<TipoEntregaDTO[]> {
      return this.http.get<DataDTO<TipoEntregaDTO>>(Constants.apiURL + '/tipoEntrega/').pipe(map((res) => convertJsonDates(res.data) as TipoEntregaDTO[]));
   }

   public getTipoEntregaId(id: number): Observable<TipoEntregaDTO> {
      return this.http.get<TipoEntregaDTO>(Constants.apiURL + '/tipoEntrega/' + id).pipe(map((res) => convertJsonDates(res) as TipoEntregaDTO ));
   }

   public updateTipoEntregaId(id: number, tipoEntregaDTO: TipoEntregaDTO): Observable<TipoEntregaDTO> {
      return this.http.put<TipoEntregaDTO>(Constants.apiURL + '/tipoEntrega/' + id, tipoEntregaDTO).pipe(map((res) => convertJsonDates(res) as TipoEntregaDTO ));
   }

   public insertTipoEntrega(tipoEntregaDTO: TipoEntregaDTO): Observable<TipoEntregaDTO> {
      return this.http.post<TipoEntregaDTO>(Constants.apiURL + '/tipoEntrega/', tipoEntregaDTO).pipe(map((res) => convertJsonDates(res) as TipoEntregaDTO ));
   }

   public getTipoEntregaAutoComplete(contiene: string, pageSize: number): Observable<TipoEntregaDTO[]> {
      return this.http.get<DataDTO<TipoEntregaDTO>>(Constants.apiURL + '/tipoEntrega/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as TipoEntregaDTO[]));
   }

   public getTipoEntregaPersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Observable<TipoEntregaFlatDTO[]> {
      return this.http.get<DataDTO<TipoEntregaFlatDTO>>(Constants.apiURL + '/tipoEntrega/personal/' + idPersonalLegajo).pipe(map((res) => convertJsonDates(res.data) as TipoEntregaFlatDTO[]));
   }

   public getTipoEntregaConvenioIDCONVENIO(idConvenio: number): Observable<TipoEntregaFlatDTO[]> {
      return this.http.get<DataDTO<TipoEntregaFlatDTO>>(Constants.apiURL + '/tipoEntrega/convenio/' + idConvenio).pipe(map((res) => convertJsonDates(res.data) as TipoEntregaFlatDTO[]));
   }

   public getTipoEntregaFullpersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Observable<TipoEntregaDTO[]> {
      return this.http.get<DataDTO<TipoEntregaDTO>>(Constants.apiURL + '/tipoEntrega/fullpersonal/' + idPersonalLegajo).pipe(map((res) => convertJsonDates(res.data) as TipoEntregaDTO[]));
   }

   public deleteTipoEntregaId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/tipoEntrega/' + id);
   }}
