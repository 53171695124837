import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';


export class InformacionesConCantidadDTO implements IEntityDTO {

   informaciones: Array<InformacionDTO>;
   cantidadTotal = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.informaciones = new Array<InformacionDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.informaciones != null) { for (const item of jsonObj.informaciones) { const itemDTO = new InformacionDTO(); itemDTO.PrepareDTO(item); this.informaciones.push(itemDTO); } }
      if (jsonObj.cantidadTotal != null) { this.cantidadTotal = jsonObj.cantidadTotal; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
