import { DocumentacionConAnalisisHojaDTO } from 'src/app/ModelDTO/DTO/documentacionConAnalisisHoja.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';


export class DocumentacionConAnalisisDocumentoDTO implements IEntityDTO {

   nombre = '';
   hojas: Array<DocumentacionConAnalisisHojaDTO>;
   totalColaboradores = 0;
   id = 0;

   constructor() {
      this.hojas = new Array<DocumentacionConAnalisisHojaDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.hojas != null) { for (const item of jsonObj.hojas) { const itemDTO = new DocumentacionConAnalisisHojaDTO(); itemDTO.PrepareDTO(item); this.hojas.push(itemDTO); } }
      if (jsonObj.totalColaboradores != null) { this.totalColaboradores = jsonObj.totalColaboradores; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
