import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { ComentarioBase } from './comentario-base';
import { ComentariosService } from './comentarios.service';

@Injectable({
  providedIn: 'root',
})
export class AbsenceCommentHandler extends ComentarioBase {
  constructor(private commentsService: ComentariosService) {
    super();
  }

  public sendMessage(id: number, mensaje: FormData): Observable<void> {
    return this.commentsService.saveAbsenceComment(id, mensaje);
  }

  public onInit(id: number): Observable<Array<ComentarioDTO>> {
    return this.commentsService.getAbssenceComments(id);
  }

  public isPrivateDefault(): boolean {
    return false;
  }

  public showPrivate(): boolean {
    return true;
  }

  public getNoDataString(): string {
    return '';
  }

  public canEditMessage(): boolean {
    return false;
  }

  public canDeleteMessage(): boolean {
    return false;
  }

  public allowStars(): boolean {
    return false;
  }
}
