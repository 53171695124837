import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';

export class NewsFormatDTO implements IEntityDTO {

   name = '';
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
