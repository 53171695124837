import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { DatosTerminalFichajeDTO } from 'src/app/ModelDTO/DTO/datosTerminalFichaje.DTO';
import { FichajeDTO } from 'src/app/ModelDTO/DTO/fichaje.DTO';
import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { FichajeErrorDTO } from 'src/app/ModelDTO/DTO/fichajeError.DTO';
import { FichajeEstadoFlatDTO } from 'src/app/ModelDTO/DTO/fichajeEstadoFlat.DTO';
import { FichajeManualDTO } from 'src/app/ModelDTO/DTO/fichajeManual.DTO';
import { FichajeNovedadDTO } from 'src/app/ModelDTO/DTO/fichajeNovedad.DTO';
import { FichajeOkDTO } from 'src/app/ModelDTO/DTO/fichajeOk.DTO';
import { FichajeQrDTO } from 'src/app/ModelDTO/DTO/fichajeQr.DTO';
import { FichandoCierreDTO } from 'src/app/ModelDTO/DTO/fichandoCierre.DTO';
import { TurnoConFichajeDTO } from 'src/app/ModelDTO/DTO/turnoConFichaje.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FichajeManualMasivoDTO } from '../ModelDTO/DTO/fichajeManualMasivo.DTO';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class FichajeServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFichajes(fechaDesde: Date, fechaHasta: Date, vistaColaborador: boolean = false, pageNumber: number = 0, pageSize: number = 0): Promise<Array<FichajeBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/fichajes/' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&vistaColaborador=' + vistaColaborador + '&pageNumber=' + pageNumber +
         '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<FichajeBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new FichajeBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFichajesTurnoSupervisorId(id: number): Promise<Array<TurnoConFichajeDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/fichajes/turnoSupervisor/' + id));
         const resJson = res.data;
         const resDTO = new Array<TurnoConFichajeDTO>();
         for (const item of resJson) {
            const itemDTO = new TurnoConFichajeDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFichajesUltimo(): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/fichajes/ultimo'));
         if (!res) { return null; }
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getFichajesUltimoOtro(idPersonal: number): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/fichajes/ultimoOtro' + '?idPersonal=' + idPersonal));
         if (!res) { return null; }
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajes(fichandoDTO: FormData): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/', fichandoDTO));
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesOtro(fichandoOtroDTO: FormData): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/otro', fichandoOtroDTO));
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesManual(fichajeManualDTO: FichajeManualDTO): Promise<FichajeBasicoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/manual', fichajeManualDTO));
         const resDTO = new FichajeBasicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesManualOwn(fichajeManualDTO: FichajeManualDTO): Promise<FichajeBasicoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/manual-own', fichajeManualDTO));
         const resDTO = new FichajeBasicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateFichajesIdCerrar(id: number, fichandoCierreDTO: FichandoCierreDTO): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/fichajes/' + id + '/cerrar', fichandoCierreDTO));
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesInasistenciaIDTURNO(iDTURNO: number, fichajeNovedadDTO: FichajeNovedadDTO): Promise<FichajeNovedadDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/inasistencia/' + iDTURNO, fichajeNovedadDTO));
         const resDTO = new FichajeNovedadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateFichajesId(id: number, fichajeOkDTO: FichajeOkDTO): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/fichajes/' + id, fichajeOkDTO));
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteFichajesId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/fichajes/' + id));

   }

   public async insertFichajesIdErrorEnIngreso(id: number, fichajeErrorDTO: FichajeErrorDTO): Promise<FichajeDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/' + id + '/errorEnIngreso', fichajeErrorDTO));
         const resDTO = new FichajeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesIdErrorEnSalida(id: number, fichajeErrorDTO: FichajeErrorDTO): Promise<FichajeDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/' + id + '/errorEnSalida', fichajeErrorDTO));
         const resDTO = new FichajeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesIngresoTerminal(datosTerminalFichajeDTO: DatosTerminalFichajeDTO): Promise<DatosTerminalFichajeDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/ingresoTerminal', datosTerminalFichajeDTO));
         const resDTO = new DatosTerminalFichajeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertFichajesQr(fichajeQrDTO: FichajeQrDTO): Promise<FichajeOkDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/qr', fichajeQrDTO));
         const resDTO = new FichajeOkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public  getFichajesStates(isPresent?: boolean, workDayIds?: string, areaId: number = 0, sectorId: number = 0, officeId = 0): Observable<Array<FichajeEstadoFlatDTO>> {
         return this.http.get<DataDTO>(Constants.apiURL + '/fichajes/states' + '?isPresent=' + isPresent +
            '&workDayIds=' + workDayIds +
            '&officeId=' + officeId +
            '&areaId=' + areaId +
            '&sectorId=' + sectorId).pipe(map(res =>{
         const resJson = res.data;
         const resDTO = new Array<FichajeEstadoFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new FichajeEstadoFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
      }))

   }

   public async getFichajesAbsencesDate(date: string, isPresent?: boolean, workDayIds: string = '', areaId: number = 0, sectorId: number = 0): Promise<Array<FichajeEstadoFlatDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/fichajes/absences/' + date + '?isPresent=' + isPresent +
            '&workDayIds=' + workDayIds +
            '&areaId=' + areaId +
            '&sectorId=' + sectorId));
         const resJson = res.data;
         const resDTO = new Array<FichajeEstadoFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new FichajeEstadoFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

 public async insertFichajesManualBatch(fichajeManualDTO: FichajeManualDTO[]): Promise<FichajeManualMasivoDTO> {

      const res = await lastValueFrom(this.http.post(Constants.apiURL + '/fichajes/manual-batch', fichajeManualDTO));
      const resDTO = new FichajeManualMasivoDTO();
      resDTO.PrepareDTO(res);
      return resDTO;

}

}
