import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CordovaService } from '../../services/cordova/cordova.service';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';

@Component({
  selector: 'app-server-error-dialog',
  templateUrl: './server-error-dialog.component.html',
  styleUrls: ['./server-error-dialog.component.scss'],
})
export class ServerErrorDialogComponent implements OnInit {
  public isCordovaApp = false;
  public version: string = environment.appVersion;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ServerErrorDialogData,
    private dialogRef: MatDialogRef<ServerErrorDialogComponent>,
    private cordovaService: CordovaService,
    private authenticationService: AuthenticationService,
  ) {}

  public ngOnInit(): void {
    this.isCordovaApp = this.cordovaService.isCordovaApp;
  }

  public close(result = false): void {
    this.dialogRef.close(result);
  }

  public reload(): void {
    this.authenticationService.reloadPage();
  }
}

export interface ServerErrorDialogData {
  errorMessage: string;
}
