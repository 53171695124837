import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfigurationOverallPerformanceDTO implements IEntityDTO {

   behaviorsContributes: boolean;
   goalsContributes: boolean;
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.behaviorsContributes != null) { this.behaviorsContributes = jsonObj.behaviorsContributes; }
      if (jsonObj.goalsContributes != null) { this.goalsContributes = jsonObj.goalsContributes; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
