<div class="d-flex flex-column flex-grow-1">
  <form [formGroup]="planningForm">
    <div class="mb-2">
      <mat-slide-toggle class="my-2 wrap-content" color="primary" formControlName="includeHolidays">
        {{ 'GENERAL.INCLUDE_HOLIDAYS' | translate }}
      </mat-slide-toggle>
    </div>

    <div class="d-flex flex-row flex-wrap mb-2">
      <mat-button-toggle-group name="days" class="my-2" multiple>
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.monday"
          (change)="setDayValue(planningForm.controls.monday, $event.source.checked)"
          >L</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.tuesday"
          (change)="setDayValue(planningForm.controls.tuesday, $event.source.checked)"
          >M</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.wednesday"
          (change)="setDayValue(planningForm.controls.wednesday, $event.source.checked)"
          >M</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.thursday"
          (change)="setDayValue(planningForm.controls.thursday, $event.source.checked)"
          >J</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.friday"
          (change)="setDayValue(planningForm.controls.friday, $event.source.checked)"
          >V</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.saturday"
          (change)="setDayValue(planningForm.controls.saturday, $event.source.checked)"
          >S</mat-button-toggle
        >
        <mat-button-toggle
          class="boton-round gray-light-background color-gray-light"
          [checked]="planningForm.value.sunday"
          (change)="setDayValue(planningForm.controls.sunday, $event.source.checked)"
          >D</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </form>
</div>
