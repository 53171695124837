import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { AuditLogEntryDTO, DataDTO, NamedBlobDTO, PublicLinkDTO, SignatureType } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class AttachmentFirmaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getAttachmentsFirmaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/attachmentsFirma/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAttachmentsFirmaIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getAttachmentsFirma(pageNumber: number, pageSize: number, type: SignatureType = undefined, requestedDateAscOrder: boolean = false, teammateId: number = 0): Promise<Array<AttachmentUaalooDTO>> {
      
         const res = await lastValueFrom( this.http.get<DataDTO>(Constants.apiURL + '/attachmentsFirma/' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&type=' + type +
            '&requestedDateAscOrder=' + requestedDateAscOrder +
            '&teammateId=' + teammateId));
         const resJson = res.data;
         const resDTO = new Array<AttachmentUaalooDTO>();
         for (const item of resJson) {
            const itemDTO = new AttachmentUaalooDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
     
   }

   public async insertAttachmentsFirma(attachmentUaalooDTO: AttachmentUaalooDTO): Promise<AttachmentUaalooDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/attachmentsFirma/', attachmentUaalooDTO));
         const resDTO = new AttachmentUaalooDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateAttachmentsFirmaId(id: number, attachmentUaalooDTO: AttachmentUaalooDTO): Promise<AttachmentUaalooDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/attachmentsFirma/' + id, attachmentUaalooDTO));
         const resDTO = new AttachmentUaalooDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteAttachmentsFirmaId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/attachmentsFirma/' + id));

   }

   public async updateAttachmentsFirmaIdFirmar(id: number, personalLegajoId: number, publicDoc: boolean, signatureType: number, sendMail: boolean, attachmentUaalooDTO: AttachmentUaalooDTO, owner: number = 0, acceptPrivacyPolicy: boolean = false): Promise<AttachmentUaalooDTO> {
     
         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/attachmentsFirma/' + id + '/firmar' + '?personalLegajoId=' + personalLegajoId +
            '&publicDoc=' + publicDoc +
            '&signatureType=' + signatureType +
            '&sendMail=' + sendMail +
            '&owner=' + owner +
            '&acceptPrivacyPolicy=' + acceptPrivacyPolicy, attachmentUaalooDTO));
         const resDTO = new AttachmentUaalooDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   
   }

   public async updateAttachmentsFirmaIdAgregarFirmantes(id: number, attachmentConNuevosFirmantesDTO: AttachmentConNuevosFirmantesDTO): Promise<AttachmentUaalooDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/attachmentsFirma/' + id + '/agregarFirmantes', attachmentConNuevosFirmantesDTO));
         const resDTO = new AttachmentUaalooDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateAttachmentsFirmaIdQuitarFirmanteIDPERSONALLEGAJO(id: number, iDPERSONALLEGAJO: number): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/attachmentsFirma/' + id + '/quitarFirmante/' + iDPERSONALLEGAJO, null));

   }

   public async getAttachmentsFirmaId(id: number): Promise<AttachmentUaalooDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/' + id));
         if (!res) { return null; }
         const resDTO = new AttachmentUaalooDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getAttachmentsFirmaIdPosicionFirmaIDPERSONALLEGAJO(id: number, iDPERSONALLEGAJO: number): Promise<ConfiguracionFirmaDigitalPosicionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/' + id + '/posicionFirma/' + iDPERSONALLEGAJO));
         if (!res) { return null; }
         const resDTO = new ConfiguracionFirmaDigitalPosicionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getAttachmentsFirmaCertificado(namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/certificado' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getAttachmentsFirmaIdAuditoria(id: number, owner: number = 0, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/' + id + '/auditoria' + '?owner=' + owner + '&BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async insertAttachmentsFirmaConsultaFirmas(fechaDesde: Date, fechaHasta: Date, attachmentUaalooDTO: AttachmentUaalooDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/attachmentsFirma/consultaFirmas' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString(), attachmentUaalooDTO));

   }

   public async getAttachmentsFirmaPublicLinkId(id: number, personalId: number, expiredMinutes: number = 15): Promise<PublicLinkDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/attachmentsFirma/publicLink/' + id + '?personalId=' + personalId + '&expiredMinutes=' + expiredMinutes));
         if (!res) { return null; }
         const resDTO = new PublicLinkDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
