<mat-form-field [floatLabel]="floatLabel" [ngClass]="formFieldClass" [appearance]="appearance" [subscriptSizing]="subscriptSizing">
  <mat-label>{{ label }}</mat-label>
  <div class="completer-holder" ctrCompleter>
    <input
      #ctrInput
      [class.none-display]="completerItem"
      [required]="required"
      matInput
      [attr.id]="inputId.length > 0 ? inputId : null"
      type="search"
      class="completer-input"
      ctrInput
      [ngClass]="inputClass"
      [ngModel]="searchStr"
      (ngModelChange)="onChange($event)"
      [attr.name]="inputName"
      [attr.maxlength]="maxChars"
      [tabindex]="fieldTabindex"
      [disabled]="ctrInputHidden.disabled"
      [clearSelected]="clearSelected"
      [clearUnselected]="clearUnselected"
      [overrideSuggested]="overrideSuggested"
      [openOnFocus]="openOnFocus"
      [fillHighlighted]="fillHighlighted"
      [openOnClick]="openOnClick"
      [selectOnClick]="selectOnClick"
      [autoSelectOnEnter]="autoSelectOnEnter"
      (blur)="onBlur()"
      (focus)="onFocus()"
      (keyup)="onKeyup($event)"
      (keydown)="onKeydown($event)"
      (click)="onClick($event)"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      [placeholder]="placeholder"
    />
    <input #ctrInputHidden [class.none-display]="true" matInput [formControl]="control" />
    <div
      class="completer-dropdown-holder"
      *ctrList="
          dataService;
          minSearchLength: minSearchLength;
          pause: pause;
          autoMatch: autoMatch;
          initialValue: initialValue;
          autoHighlight: autoHighlight;
          displaySearching: displaySearching;
          let items = results;
          let searchActive = searching;
          let isInitialized = searchInitialized;
          let isOpen = isOpen
        "
    >
      <div
        class="completer-dropdown"
        ctrDropdown
        *ngIf="
            isInitialized &&
            isOpen &&
            (items?.length > 0 || (displayNoResults && !searchActive) || (searchActive && displaySearching))
          "
      >
        <div *ngIf="searchActive && displaySearching" class="completer-searching">{{ searchingText }}</div>
        <div *ngIf="!searchActive && (!items || items?.length === 0)" class="completer-no-results">{{ noResultsText }}</div>
        <div class="completer-row-wrapper" *ngFor="let item of items; let rowIndex = index">
          <div class="completer-row" [ctrRow]="rowIndex" [dataItem]="item">
            <div *ngIf="item.image || item.image === ''" class="completer-image-holder">
              <img *ngIf="item.image?.length" src="{{ item.image }}" class="completer-image" />
              <div *ngIf="!item.image?.length" class="completer-image-default"></div>
            </div>
            <div class="completer-item-text" [ngClass]="{ 'completer-item-text-image': item.image || item.image === '' }">
              <completer-list-item
                class="completer-title"
                [text]="item.title"
                [matchClass]="matchClass"
                [searchStr]="searchStr"
                [type]="'title'"
              ></completer-list-item>
              <completer-list-item
                *ngIf="item.description?.length"
                class="completer-description"
                [text]="item.description"
                [matchClass]="matchClass"
                [searchStr]="searchStr"
                [type]="'description'"
              >
              </completer-list-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="completerItem" class="completer-holder">
    <mat-chip-listbox [disabled]="ctrInputHidden.disabled">
      <mat-chip id="chip-badge" [removable]="true" (removed)="removeItem()">
        <span class="">{{ completerItem?.title }}</span>
        <mat-icon fontSet="material-symbols-outlined" matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-listbox>
  </div>
</mat-form-field>
