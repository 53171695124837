import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class ConfiguracionSolicitudesCambioDTO implements IEntityDTO {

   cantidadMaxima = 0;
   activada: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cantidadMaxima != null) { this.cantidadMaxima = jsonObj.cantidadMaxima; }
      if (jsonObj.activada != null) { this.activada = jsonObj.activada; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
