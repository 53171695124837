import { TaskGoalDTO } from 'src/app/ModelDTO/DTO';
import { ConfigurationGoalLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationGoalLevelRange.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalTaskGoalsDTO implements IEntityDTO {
   id = 0;
   personalId:number;
   personalNombre:string;
   personalApellido:string;
   personalImage:string;
   generalResult:number;
   generalResultRange:ConfigurationGoalLevelRangeDTO;
   goals: Array<TaskGoalDTO>;

   constructor() {
    this.goals = [];

   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.personalId != null) { this.personalId = jsonObj.personalId; }
      if (jsonObj.personalNombre != null) { this.personalNombre = jsonObj.personalNombre; }
      if (jsonObj.personalApellido != null) { this.personalApellido = jsonObj.personalApellido; }
      if (jsonObj.personalImage != null) { this.personalImage = jsonObj.personalImage; }
      if (jsonObj.goals != null) { for (const item of jsonObj.goals) { const itemDTO = new TaskGoalDTO(); itemDTO.PrepareDTO(item); this.goals.push(itemDTO); } }
      if (jsonObj.generalResult != null) { this.generalResult = jsonObj.generalResult; }
      if (jsonObj.generalResultRange != null) {
        this.generalResultRange = new ConfigurationGoalLevelRangeDTO();
        this.generalResultRange.PrepareDTO(jsonObj.generalResultRange);
     }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
