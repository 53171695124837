import { Component, Input } from '@angular/core';
import { CuestionarioPreguntaDTO, CuestionarioRespuestaDTO, TipoEstadoPersonalLegajoCuestinario } from 'src/app/ModelDTO/DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';

@Component({
  selector: 'app-only-text-question',
  templateUrl: './only-text-question.component.html',
  styleUrls: ['./only-text-question.component.scss'],
})
export class OnlyTextQuestionComponent implements QuestionComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;

  public respuestaChange(respuesta: CuestionarioRespuestaDTO, value: string): void {
    respuesta.modificada = true;
    respuesta.fecha = new Date();
    respuesta.valorTexto = value;
    respuesta.id = this.data.id;
  }
}
