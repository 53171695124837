import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';


export class CambiosHistoricosDTO implements IEntityDTO {

   cargos: Array<CambioDTO>;
   sueldos: Array<CambioDTO>;
   categoria: Array<CambioDTO>;
   subcategoria: Array<CambioDTO>;
   area: Array<CambioDTO>;
   sector: Array<CambioDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.cargos = new Array<CambioDTO>();
      this.sueldos = new Array<CambioDTO>();
      this.categoria = new Array<CambioDTO>();
      this.subcategoria = new Array<CambioDTO>();
      this.area = new Array<CambioDTO>();
      this.sector = new Array<CambioDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cargos != null) { for (const item of jsonObj.cargos) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.cargos.push(itemDTO); } }
      if (jsonObj.sueldos != null) { for (const item of jsonObj.sueldos) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.sueldos.push(itemDTO); } }
      if (jsonObj.categoria != null) { for (const item of jsonObj.categoria) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.categoria.push(itemDTO); } }
      if (jsonObj.subcategoria != null) { for (const item of jsonObj.subcategoria) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.subcategoria.push(itemDTO); } }
      if (jsonObj.area != null) { for (const item of jsonObj.area) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.area.push(itemDTO); } }
      if (jsonObj.sector != null) { for (const item of jsonObj.sector) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.sector.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
