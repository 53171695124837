import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoEmpresaDTO } from 'src/app/ModelDTO/DTO/personalLegajoEmpresa.DTO';
import { HorasTrabajadasPorLegajoDiaDTO } from 'src/app/ModelDTO/DTO/horasTrabajadasPorLegajoDia.DTO';


export class HorasTrabajadasPorLegajoDTO implements IEntityDTO {

   personalLegajo: PersonalLegajoEmpresaDTO;
   dias: Array<HorasTrabajadasPorLegajoDiaDTO>;
   totalFeriadosTrabajados = 0;
   totalHorasExtras50 = 0;
   totalHorasExtras100 = 0;
   totalHorasNocturnas = 0;
   totalHorasDeFrancoTrabajados = 0;
   totalDiasTrabajados = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personalLegajo = new PersonalLegajoEmpresaDTO();
      this.dias = new Array<HorasTrabajadasPorLegajoDiaDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.dias != null) { for (const item of jsonObj.dias) { const itemDTO = new HorasTrabajadasPorLegajoDiaDTO(); itemDTO.PrepareDTO(item); this.dias.push(itemDTO); } }
      if (jsonObj.totalFeriadosTrabajados != null) { this.totalFeriadosTrabajados = jsonObj.totalFeriadosTrabajados; }
      if (jsonObj.totalHorasExtras50 != null) { this.totalHorasExtras50 = jsonObj.totalHorasExtras50; }
      if (jsonObj.totalHorasExtras100 != null) { this.totalHorasExtras100 = jsonObj.totalHorasExtras100; }
      if (jsonObj.totalHorasNocturnas != null) { this.totalHorasNocturnas = jsonObj.totalHorasNocturnas; }
      if (jsonObj.totalHorasDeFrancoTrabajados != null) { this.totalHorasDeFrancoTrabajados = jsonObj.totalHorasDeFrancoTrabajados; }
      if (jsonObj.totalDiasTrabajados != null) { this.totalDiasTrabajados = jsonObj.totalDiasTrabajados; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
