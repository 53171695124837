<div class="d-flex flex-wrap gap-1" [formGroup]="quizQuestionForm">
  <mat-form-field class="flex-fill" appearance="outline">
    <mat-label>{{ 'FORMS.MINIMUM_VALUE' | translate }}</mat-label>
    <input matInput type="number" id="min" name="min" formControlName="min" />
  </mat-form-field>
  <mat-form-field class="flex-fill" appearance="outline">
    <mat-label>{{ 'FORMS.MAXIMUM_VALUE' | translate }}</mat-label>
    <input matInput type="number" id="max" name="max" formControlName="max" />
  </mat-form-field>
</div>
