import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotificationPriority } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';

@Component({
  selector: 'app-priority-indicator',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  template: `
    <div class="d-flex" [ngSwitch]="priority">
      <mat-icon *ngSwitchCase="priorities.High" fontSet="material-symbols-outlined" class="red-icon">keyboard_arrow_up</mat-icon>
      <mat-icon *ngSwitchCase="priorities.Medium" fontSet="material-symbols-outlined" class="yellow-icon">drag_handle</mat-icon>
      <mat-icon *ngSwitchCase="priorities.Low" fontSet="material-symbols-outlined" class="blue-icon">keyboard_arrow_down</mat-icon>
    </div>
  `,
  styles: [
    `
      :host {
        align-self: center;
      }
    `,
  ],
})
export class PriorirtyIndicatorComponent {
  @Input() priority: NotificationPriority;
  public readonly priorities = NotificationPriority;
}
