import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ReclutamientoCandidatoRecomendadoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoRecomendado.DTO';
import { CandidatoPostuladoOfertaDTO } from 'src/app/ModelDTO/DTO/candidatoPostuladoOferta.DTO';
import { ReclutamientoCandidatoPuntajeDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoPuntaje.DTO';
import { ReclutamientoFaseCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoFaseCandidato.DTO';
import { ReclutamientoCandidatoConocimientoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoConocimiento.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { RecruitmentTagDTO } from '@api/interfaces/recruitment-tag.interface';


export class ReclutamientoCandidatoDTO implements IEntityDTO {

   fechaCarga: Date;
   actualizado: Date;
   creador: PersonalLegajoDTO;
   nombre = '';
   apellido = '';
   email = '';
   telefono = '';
   image = '';
   cv: FileDBDTO;
   cartaDePresentacion = '';
   linkedin = '';
   linkPublico = '';
   talento: boolean;
   recomendado: boolean;
   community: ReclutamientoCandidatoRecomendadoDTO;
   postulate: CandidatoPostuladoOfertaDTO;
   reclutamientoOfertaPuntajeDTO: ReclutamientoCandidatoPuntajeDTO;
   postulaciones: Array<ReclutamientoFaseCandidatoDTO>;
   conocimientos: Array<ReclutamientoCandidatoConocimientoDTO>;
   comentarios: Array<ComentarioDTO>;
   tags: RecruitmentTagDTO[];
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoDTO();
      this.cv = new FileDBDTO();
      this.community = new ReclutamientoCandidatoRecomendadoDTO();
      this.postulate = new CandidatoPostuladoOfertaDTO();
      this.reclutamientoOfertaPuntajeDTO = new ReclutamientoCandidatoPuntajeDTO();
      this.postulaciones = new Array<ReclutamientoFaseCandidatoDTO>();
      this.conocimientos = new Array<ReclutamientoCandidatoConocimientoDTO>();
      this.comentarios = new Array<ComentarioDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaCarga != null) { this.fechaCarga = new Date(jsonObj.fechaCarga); }
      if (jsonObj.actualizado != null) { this.actualizado = new Date(jsonObj.actualizado); }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.telefono != null) { this.telefono = jsonObj.telefono; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.cv != null) { this.cv.PrepareDTO(jsonObj.cv); }
      if (jsonObj.cartaDePresentacion != null) { this.cartaDePresentacion = jsonObj.cartaDePresentacion; }
      if (jsonObj.linkedin != null) { this.linkedin = jsonObj.linkedin; }
      if (jsonObj.linkPublico != null) { this.linkPublico = jsonObj.linkPublico; }
      if (jsonObj.talento != null) { this.talento = jsonObj.talento; }
      if (jsonObj.recomendado != null) { this.recomendado = jsonObj.recomendado; }
      if (jsonObj.community != null) { this.community.PrepareDTO(jsonObj.community); }
      if (jsonObj.postulate != null) { this.postulate.PrepareDTO(jsonObj.postulate); }
      if (jsonObj.reclutamientoOfertaPuntajeDTO != null) { this.reclutamientoOfertaPuntajeDTO.PrepareDTO(jsonObj.reclutamientoOfertaPuntajeDTO); }
      if (jsonObj.postulaciones != null) { for (const item of jsonObj.postulaciones) { const itemDTO = new ReclutamientoFaseCandidatoDTO(); itemDTO.PrepareDTO(item); this.postulaciones.push(itemDTO); } }
      if (jsonObj.conocimientos != null) { for (const item of jsonObj.conocimientos) { const itemDTO = new ReclutamientoCandidatoConocimientoDTO(); itemDTO.PrepareDTO(item); this.conocimientos.push(itemDTO); } }
      if (jsonObj.comentarios != null) { for (const item of jsonObj.comentarios) { const itemDTO = new ComentarioDTO(); itemDTO.PrepareDTO(item); this.comentarios.push(itemDTO); } }
      if (jsonObj.tags != null) { this.tags = jsonObj.tags }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
