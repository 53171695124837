import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/categoriaCapacitacion.DTO';
import { SubcategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/subcategoriaCapacitacion.DTO';
import { SubcategoriaCapacitacionModelDTO } from 'src/app/ModelDTO/subcategoriaCapacitacion.ModelDTO';


export class CategoriaCapacitacionModelDTO extends EntityModelDTO<CategoriaCapacitacionDTO> {


   public constructor(protected entityDTO: CategoriaCapacitacionDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: CategoriaCapacitacionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Subcategorias(): Array<SubcategoriaCapacitacionDTO> { return this.entityDTO.subcategorias; }
   set Subcategorias(value: Array<SubcategoriaCapacitacionDTO>) { this.notifyChangeDTO('subcategorias', value); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
