import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class TipoEntregaFlatDTO implements IEntityDTO {

   nombre = '';
   convenioId: number;
   convenioNombreCompleto = '';
   categoriaId: number;
   categoriaNombre = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.convenioId != null) { this.convenioId = jsonObj.convenioId; }
      if (jsonObj.convenioNombreCompleto != null) { this.convenioNombreCompleto = jsonObj.convenioNombreCompleto; }
      if (jsonObj.categoriaId != null) { this.categoriaId = jsonObj.categoriaId; }
      if (jsonObj.categoriaNombre != null) { this.categoriaNombre = jsonObj.categoriaNombre; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
