import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CuentaCorrienteDTO } from 'src/app/ModelDTO/DTO/cuentaCorriente.DTO';
import { TipoDeMovimiento } from 'src/app/ModelDTO/DTO/tipoDeMovimiento.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { AusenciaModelDTO } from 'src/app/ModelDTO/ausencia.ModelDTO';


export class CuentaCorrienteModelDTO extends EntityModelDTO<CuentaCorrienteDTO> {

   private colaboradorModel: PersonalLegajoBasicoModelDTO;
   private colaboradorSubscribe: Subscription;
   private ausenciaModel: AusenciaModelDTO;
   private ausenciaSubscribe: Subscription;

   public constructor(protected entityDTO: CuentaCorrienteDTO) {
      super(entityDTO);
   }

   public static getTipoDeMovimiento(): string[] {
      return EntityModelDTO.getEnumArray(TipoDeMovimiento);
   }

   public setEntityDTO(entityDTO: CuentaCorrienteDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.colaboradorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.colaborador);
      this.colaboradorSubscribe = this.colaboradorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.ausenciaModel = new AusenciaModelDTO(this.entityDTO.ausencia);
      this.ausenciaSubscribe = this.ausenciaModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.colaboradorModel.dispose();
      this.colaboradorSubscribe.unsubscribe();
      this.ausenciaModel.dispose();
      this.ausenciaSubscribe.unsubscribe();
   }

   get Fecha(): Date { return this.entityDTO.fecha; }
   set Fecha(value: Date) { this.notifyChangeDTO('fecha', value); }

   get TipoDeMovimiento(): string { return TipoDeMovimiento[this.entityDTO.tipoDeMovimiento]; }
   set TipoDeMovimiento(value: string) { this.notifyChangeDTO('tipoDeMovimiento', TipoDeMovimiento[value]); }

   get ColaboradorModel(): PersonalLegajoBasicoModelDTO { return this.colaboradorModel; }
   get Colaborador(): PersonalLegajoBasicoDTO { return this.colaboradorModel.getEntityDTO(); }
   set Colaborador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.colaborador = value; this.colaboradorModel.setEntityDTO(value); }); }

   get AusenciaModel(): AusenciaModelDTO { return this.ausenciaModel; }
   get Ausencia(): AusenciaDTO { return this.ausenciaModel.getEntityDTO(); }
   set Ausencia(value: AusenciaDTO) { this.notifyChange(() => { this.entityDTO.ausencia = value; this.ausenciaModel.setEntityDTO(value); }); }

   get Debe(): number { return this.entityDTO.debe; }
   set Debe(value: number) { this.notifyChangeDTO('debe', value); }

   get Haber(): number { return this.entityDTO.haber; }
   set Haber(value: number) { this.notifyChangeDTO('haber', value); }

   get IdRenovacionAcumulado(): number { return this.entityDTO.idRenovacionAcumulado; }
   set IdRenovacionAcumulado(value: number) { this.notifyChangeDTO('idRenovacionAcumulado', value); }

   get Saldo(): number { return this.entityDTO.saldo; }
   set Saldo(value: number) { this.notifyChangeDTO('saldo', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get Observations(): string { return this.entityDTO.observations; }
   set Observations(value: string) { this.notifyChangeDTO('observations', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
