import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';


export class ContingenciaDTO implements IEntityDTO {

   valor = 0;
   sueldoMayor = 0;
   sueldos: Array<CambioDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.sueldos = new Array<CambioDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.valor != null) { this.valor = jsonObj.valor; }
      if (jsonObj.sueldoMayor != null) { this.sueldoMayor = jsonObj.sueldoMayor; }
      if (jsonObj.sueldos != null) { for (const item of jsonObj.sueldos) { const itemDTO = new CambioDTO(); itemDTO.PrepareDTO(item); this.sueldos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
