import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { MessageDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AyudaVideoDTO } from 'src/app/ModelDTO/DTO/ayudaVideo.DTO';

@Injectable({
   providedIn: 'root'
})
export class AyudaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getAyudasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ayudas/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAyudasVideos(): Promise<Array<AyudaVideoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ayudas/videos'));
         const resJson = res.data;
         const resDTO = new Array<AyudaVideoDTO>();
         for (const item of resJson) {
            const itemDTO = new AyudaVideoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertAyudasEmailPrueba(messageDTO: MessageDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/ayudas/emailPrueba', messageDTO));

   }

}
