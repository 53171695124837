import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EstadoCapacitacion } from 'src/app/ModelDTO/DTO/estadoCapacitacion.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoResultado } from 'src/app/ModelDTO/DTO/tipoResultado.ENUM';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';


export class PersonalCapacitacionDTO implements IEntityDTO {

   estado: EstadoCapacitacion;
   creador: PersonalLegajoBasicoDTO;
   personalLegajo: PersonalLegajoBasicoDTO;
   capacitacion: TipoCapacitacionDTO;
   resultado: TipoResultado;
   fechaPactada: Date;
   capacitacionEfectiva: Date;
   vencimientoAproximado: Date;
   ultimoCambio: Date;
   codigoDeReferencia = '';
   descripcion = '';
   motivo = '';
   conSolicitud: boolean;
   automatica: boolean;
   siguienteCapacitacionGenerada: boolean;
   adjuntos: Array<AttachmentUaalooDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoBasicoDTO();
      this.personalLegajo = new PersonalLegajoBasicoDTO();
      this.capacitacion = new TipoCapacitacionDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as EstadoCapacitacion; }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.capacitacion != null) { this.capacitacion.PrepareDTO(jsonObj.capacitacion); }
      if (jsonObj.resultado != null) { this.resultado = jsonObj.resultado as TipoResultado; }
      if (jsonObj.fechaPactada != null) { this.fechaPactada = new Date(jsonObj.fechaPactada); }
      if (jsonObj.capacitacionEfectiva != null) { this.capacitacionEfectiva = new Date(jsonObj.capacitacionEfectiva); }
      if (jsonObj.vencimientoAproximado != null) { this.vencimientoAproximado = new Date(jsonObj.vencimientoAproximado); }
      if (jsonObj.ultimoCambio != null) { this.ultimoCambio = new Date(jsonObj.ultimoCambio); }
      if (jsonObj.codigoDeReferencia != null) { this.codigoDeReferencia = jsonObj.codigoDeReferencia; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.motivo != null) { this.motivo = jsonObj.motivo; }
      if (jsonObj.conSolicitud != null) { this.conSolicitud = jsonObj.conSolicitud; }
      if (jsonObj.automatica != null) { this.automatica = jsonObj.automatica; }
      if (jsonObj.siguienteCapacitacionGenerada != null) { this.siguienteCapacitacionGenerada = jsonObj.siguienteCapacitacionGenerada; }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
