<div
  class="d-flex"
  [style.flexDirection]="row ? 'row' : 'column'"
  [style.height.px]="height"
  [style.padding.px]="padding"
  [class.no-border]="hideBorder"
>
  <ngx-skeleton-loader [theme]="pictureTheme" count="1" appearance="circle"></ngx-skeleton-loader>
  <ngx-skeleton-loader class="flex-fill" [theme]="lineTheme" [count]="lines" appearance="line"></ngx-skeleton-loader>
</div>
