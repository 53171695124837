/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { ImageEntityDTO } from 'src/app/api/interfaces/image-entity.interface';
import { CandidatoPostuladoOfertaDTO } from 'src/app/api/interfaces/candidato-postulado-oferta.interface';
import { CandidatoPostuladoUserValidationDTO } from 'src/app/api/interfaces/candidato-postulado-user-validation.interface';
import { ReclutamientoCandidatoDTO } from 'src/app/api/interfaces/reclutamiento-candidato.interface';
import { ReclutamientoCandidatoParaPostulacionDTO } from 'src/app/api/interfaces/reclutamiento-candidato-para-postulacion.interface';
import { ReclutamientoOfertaPublicaDTO } from 'src/app/api/interfaces/reclutamiento-oferta-publica.interface';

@Injectable({
   providedIn: 'root'
})
export class ReclutamientoPublicoBackendService {

   constructor(protected http: HttpClient) { }


   public insertReclutamientoPublico(userToken: string, candidatoPostuladoOfertaDTO: FormData): Observable<CandidatoPostuladoOfertaDTO> {
      return this.http.post<CandidatoPostuladoOfertaDTO>(Constants.apiURL + '/reclutamientoPublico/' + '?userToken=' + userToken, candidatoPostuladoOfertaDTO).pipe(map((res) => convertJsonDates(res) as CandidatoPostuladoOfertaDTO ));
   }

   public updateReclutamientoPublicoId(id: number, userToken: string, candidatoPostuladoOfertaDTO: FormData): Observable<CandidatoPostuladoOfertaDTO> {
      return this.http.put<CandidatoPostuladoOfertaDTO>(Constants.apiURL + '/reclutamientoPublico/' + id + '?userToken=' + userToken, candidatoPostuladoOfertaDTO).pipe(map((res) => convertJsonDates(res) as CandidatoPostuladoOfertaDTO ));
   }

   public insertReclutamientoPublicoIdProfilePicture(params: { id: number, userToken: string, recaptchaToken: string }, imageEntityDTO: ImageEntityDTO): Observable<void> {
      const { id, userToken, recaptchaToken } = params;
      return this.http.post<void>(Constants.apiURL + '/reclutamientoPublico/' + id + '/profilePicture' + '?userToken=' + userToken +
            '&recaptchaToken=' + recaptchaToken, imageEntityDTO);
   }
   public getReclutamientoPublicoAuthenticatedusersUSERTOKEN(userToken: string): Observable<CandidatoPostuladoOfertaDTO> {
      return this.http.get<CandidatoPostuladoOfertaDTO>(Constants.apiURL + '/reclutamientoPublico/authenticated-users/' + userToken).pipe(map((res) => convertJsonDates(res) as CandidatoPostuladoOfertaDTO ));
   }

   public insertReclutamientoPublicoValidateEmail(candidatoPostuladoUserValidationDTO: CandidatoPostuladoUserValidationDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/reclutamientoPublico/validateEmail', candidatoPostuladoUserValidationDTO);
   }
   public getReclutamientoPublicoReclutamientoOfertaLINK(link: string): Observable<ReclutamientoOfertaPublicaDTO> {
      return this.http.get<ReclutamientoOfertaPublicaDTO>(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link).pipe(map((res) => convertJsonDates(res) as ReclutamientoOfertaPublicaDTO ));
   }

   public getReclutamientoPublicoReclutamientoOfertaLINKCandidato(link: string, email: string): Observable<ReclutamientoCandidatoParaPostulacionDTO> {
      return this.http.get<ReclutamientoCandidatoParaPostulacionDTO>(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link + '/candidato' + '?email=' + email).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoParaPostulacionDTO ));
   }

   public insertReclutamientoPublicoReclutamientoOfertaLINK(link: string, reclutamientoOfertaPostulacionInputDTO: FormData): Observable<ReclutamientoCandidatoDTO> {
      return this.http.post<ReclutamientoCandidatoDTO>(Constants.apiURL + '/reclutamientoPublico/reclutamientoOferta/' + link, reclutamientoOfertaPostulacionInputDTO).pipe(map((res) => convertJsonDates(res) as ReclutamientoCandidatoDTO ));
   }
}
