/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { NamedBlobDTO } from 'src/app/api/interfaces/named-blob.dto';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { NameDetailDTO } from 'src/app/api/interfaces/name-detail.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { PersonalSancionDTO } from 'src/app/api/interfaces/personal-sancion.interface';
import { SancionDTO } from 'src/app/api/interfaces/sancion.interface';
import { TipoSancionDTO } from 'src/app/api/interfaces/tipo-sancion.interface';

@Injectable({
   providedIn: 'root'
})
export class SancionBackendService {

   constructor(protected http: HttpClient) { }


   public getComplianceSancionIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/compliance/sancion/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getComplianceSancionSancionados(pageNumber: number, pageSize: number): Observable<PersonalSancionDTO[]> {
      return this.http.get<DataDTO<PersonalSancionDTO>>(Constants.apiURL + '/compliance/sancion/sancionados' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalSancionDTO[]));
   }

   public getComplianceSancionId(params: { id: number, fechaDesde: Date, fechaHasta: Date, todas?: boolean }, ): Observable<SancionDTO[]> {
      const { id, fechaDesde, fechaHasta, todas = true } = params;
      return this.http.get<DataDTO<SancionDTO>>(Constants.apiURL + '/compliance/sancion/' + id + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&todas=' + todas).pipe(map((res) => convertJsonDates(res.data) as SancionDTO[]));
   }

   public getComplianceSancionIdIDSANCION(id: number, idSancion: number): Observable<PersonalSancionDTO> {
      return this.http.get<PersonalSancionDTO>(Constants.apiURL + '/compliance/sancion/' + id + '/' + idSancion).pipe(map((res) => convertJsonDates(res) as PersonalSancionDTO ));
   }

   public insertComplianceSancionId(id: number, sancionDTO: FormData): Observable<SancionDTO> {
      return this.http.post<SancionDTO>(Constants.apiURL + '/compliance/sancion/' + id, sancionDTO).pipe(map((res) => convertJsonDates(res) as SancionDTO ));
   }

   public deleteComplianceSancionIdIDSANCION(id: number, iDSANCION: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/compliance/sancion/' + id + '/' + iDSANCION);
   }
   public getComplianceSancionTipoSancion(): Observable<TipoSancionDTO[]> {
      return this.http.get<DataDTO<TipoSancionDTO>>(Constants.apiURL + '/compliance/sancion/tipoSancion').pipe(map((res) => convertJsonDates(res.data) as TipoSancionDTO[]));
   }

   public getComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number): Observable<TipoSancionDTO> {
      return this.http.get<TipoSancionDTO>(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION).pipe(map((res) => convertJsonDates(res) as TipoSancionDTO ));
   }

   public insertComplianceSancionTipoSancion(tipoSancionDTO: TipoSancionDTO): Observable<TipoSancionDTO> {
      return this.http.post<TipoSancionDTO>(Constants.apiURL + '/compliance/sancion/tipoSancion', tipoSancionDTO).pipe(map((res) => convertJsonDates(res) as TipoSancionDTO ));
   }

   public updateComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number, tipoSancionDTO: TipoSancionDTO): Observable<TipoSancionDTO> {
      return this.http.put<TipoSancionDTO>(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION, tipoSancionDTO).pipe(map((res) => convertJsonDates(res) as TipoSancionDTO ));
   }

   public deleteComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION);
   }
   public getComplianceSancionIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertComplianceSancionIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios', stream);
   }
   public updateComplianceSancionIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteComplianceSancionIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios/' + iDComentario);
   }
   public async getComplianceSancionExport(params: { from: Date, to: Date, includeDismissed?: boolean, namedBlob: NamedBlobDTO }, ): Promise<Blob> {
      const { from, to, includeDismissed = false, namedBlob } = params;
      const res = await lastValueFrom(this.http.get(Constants.apiURL +
'/compliance/sancion/export' + '?from=' + (from?.toISOString() ?? '') +
            '&to=' + (to?.toISOString() ?? '') +
            '&includeDismissed=' + includeDismissed + '&BLOB=true', { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/pdf' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }


   public async getComplianceSancionExportXLSX(params: { from: Date, to: Date, includeDismissed?: boolean, namedBlob: NamedBlobDTO }, ): Promise<Blob> {
      const { from, to, includeDismissed = false, namedBlob } = params;
      const res = await lastValueFrom(this.http.get(Constants.apiURL +
'/compliance/sancion/export' + '?from=' + (from?.toISOString() ?? '') +
            '&to=' + (to?.toISOString() ?? '') +
            '&includeDismissed=' + includeDismissed + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/text' });
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         // eslint-disable-next-line no-param-reassign
         namedBlob.blob = res.body;
      }
      return resDTO;
      }

}
