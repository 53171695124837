export enum TipoDeMovimiento {
   No_Definido = 0,
   Ausencia = 1,
   Ajuste_m1as = 2,
   Ajuste_menos = 3,
   Por_arrastre = 4,
   Por_renovaci1on = 5,
   Proportional = 6,
   Expiration = 7,
 }
