import { NgIf } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationState } from '@api/enums/notification-state.enum';
import { AttachmentDTO } from '@api/interfaces/attachment.interface';
import { NotificationAttachmentsDTO } from '@api/interfaces/notification-attachments.interface';
import { NotificationBackendService } from '@api/services/notification-backend.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { EMPTY, Observable, from, map, switchMap } from 'rxjs';
import { ComentariosComunicadosUserHandler } from 'src/app/app-common/comentarios/comentarios-comunicados-user.handler';
import { ComentariosModule } from 'src/app/app-common/comentarios/comentarios.module';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { PriorirtyIndicatorComponent } from 'src/app/layout/notificaciones/priority-indicator/priority-indicator.component';
import { ThrottledDirective } from 'src/app/shared/directives/throttled-execution.directive';
import { NotificationPriority, NotificationServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AlertButton, NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { EnumTranslatePipe } from 'src/app/shared/lib/ngx-neo-pipes/enumTranslate.pipe';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { EmojiListPipe } from 'src/app/shared/pipes/emoji-list.pipe';
import { NgxEmojiTranslatePipe } from 'src/app/shared/reactions/ngx-emoji-translate.pipe';
import { ReactionListComponent } from 'src/app/shared/reactions/reaction-list/reaction-list.component';
import { ReactionService } from 'src/app/shared/reactions/reaction.service';
import { parseStringReactions } from 'src/app/shared/shared-functions';
import { SharedModule } from 'src/app/shared/shared.module';
import { ThrottledObservableGeneratorData, throttledObservableGenerator } from 'src/app/shared/utils/throttled-obsevable-generator';

@Component({
  selector: 'app-detalle-notificacion',
  templateUrl: './detalle-notificacion.component.html',
  standalone: true,
  providers: [ReactionService],
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatTooltipModule,
    ReactionListComponent,
    ComentariosModule,
    EmojiModule,
    TranslateModule,
    NgxDatePipe,
    EnumTranslatePipe,
    PickerModule,
    NgxEmojiTranslatePipe,
    QuillModule,
    SharedModule,
    EmojiListPipe,
    PriorirtyIndicatorComponent,
    ThrottledDirective,
  ],
})
export class DetalleNotificacionComponent implements OnInit {
  @ViewChild('emojiMenuTrigger') public emojiMenuTrigger: MatMenuTrigger;
  public notificacion: NotificationAttachmentsDTO;
  public archivos: AttachmentDTO[];
  public readonly NotificationPriority = NotificationPriority;
  public readonly NotificationState = NotificationState;

  public currentLanguage = this.headerService.currentLanguage;

  public get reactionTooltipKey(): string {
    return this.reactionService.$reactionLimitReached() ? 'COMMENTS.REACTION_LIMIT_REACHED' : 'COMMENTS.ADD_REACTION';
  }

  private executeClickedEmoji: ThrottledObservableGeneratorData<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: NotificationAttachmentsDTO,
    private dialogRef: MatDialogRef<DetalleNotificacionComponent>,
    private headerService: HeaderAppService,
    private neoModalService: NgxNeoModalMatService,
    private notificationServiceBackend: NotificationServiceBackend,
    private apiNotificationService: NotificationBackendService,
    public comentarioHandler: ComentariosComunicadosUserHandler,
    public reactionService: ReactionService,
  ) {
    this.notificacion = this.data;
    const destroyRef = inject(DestroyRef);

    this.reactionService.initialize(
      () => this.apiNotificationService.getUserNotificationsNotificationIdReactions(this.notificacion.id),
      (dto) => this.notificationServiceBackend.updateUserNotificationsNotificationIdReactions(this.notificacion.id, dto),
      (emoji) => this.notificationServiceBackend.deleteUserNotificationsNotificationIdReactionsEMOJIID(this.notificacion.id, emoji),
    );

    this.executeClickedEmoji = throttledObservableGenerator<string>((emoji) => this.reactionService.handleReaction(emoji), destroyRef);
  }

  public ngOnInit(): void {
    if (this.notificacion.attachments) {
      this.archivos = this.notificacion.attachments;
      this.reactionService.$reactions.set(parseStringReactions(this.notificacion.reactions));
    } else {
      this.apiNotificationService.getUserNotificationsId(this.notificacion.id).subscribe((response) => {
        this.notificacion = response;
        this.archivos = this.notificacion.attachments;
        this.reactionService.$reactions.set(parseStringReactions(this.notificacion.reactions));
      });
    }
  }

  public leiElComunicado = (): Observable<void> =>
    this.apiNotificationService.updateUserNotificationsId(this.notificacion.id, this.notificacion).pipe(
      map((response) => {
        this.notificacion = { ...this.notificacion, readDateTime: response.readDateTime, state: NotificationState.Read };
      }),
    );

  public comprendiElComunicado = (): Observable<void> =>
    this.apiNotificationService.updateUserNotificationsIdUnderstood(this.notificacion.id, this.notificacion).pipe(
      map((response) => {
        this.notificacion = {
          ...this.notificacion,
          understoodDateTime: response.understoodDateTime,
          state: NotificationState.Read,
          notUnderstood: false,
        };
      }),
    );

  public noComprendiElComunicado = (): Observable<void> =>
    from(this.neoModalService.decision('COMMUNICATIONS.HELP_REQUEST')).pipe(
      switchMap((res) => {
        if (res.ButtonResponse === AlertButton.Accept) {
          return this.apiNotificationService.updateUserNotificationsIdNotUnderstood(this.notificacion.id, this.notificacion).pipe(
            map(() => {
              this.notificacion = {
                ...this.notificacion,
                understoodDateTime: null,
                state: NotificationState.Read,
                notUnderstood: true,
              };
            }),
          );
        }

        return EMPTY;
      }),
    );

  public async close(): Promise<void> {
    if (this.notificacion.state !== NotificationState.Read) {
      await this.neoModalService.alert('COMMUNICATIONS.READ_CONFIRMATION');
    } else {
      this.dialogRef.close();
    }
  }

  public getPriorityColor(priority: NotificationPriority): string {
    switch (priority) {
      case NotificationPriority.High:
        return 'danger';
      case NotificationPriority.Medium:
        return 'warn';
      default:
        return 'primary';
    }
  }

  public clickedEmoji(emoji: string): void {
    this.executeClickedEmoji.executionSubject$.next(emoji);
    this.emojiMenuTrigger?.closeMenu();
  }
}
