import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ConfigurationGoalLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationGoalLevelRange.DTO';
import { StrategicDriverBasicDTO } from 'src/app/ModelDTO/DTO/strategicDriverBasic.DTO';

export class TaskGoalInformationDTO implements IEntityDTO {

   id = 0;
   achievementPercentage: number;
   weightedResult: number;
   weight = 0;
   strategicDriver: StrategicDriverBasicDTO;
   result: ConfigurationGoalLevelRangeDTO;
   evaluationDate: Date;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.achievementPercentage != null) { this.achievementPercentage = jsonObj.achievementPercentage; }
      if (jsonObj.weightedResult != null) { this.weightedResult = jsonObj.weightedResult; }
      if (jsonObj.weight != null) { this.weight = jsonObj.weight; }
      if (jsonObj.strategicDriver != null) { this.strategicDriver = jsonObj.strategicDriver; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.result != null) { this.result = new ConfigurationGoalLevelRangeDTO(); this.result.PrepareDTO(jsonObj.result); }
      if (jsonObj.evaluationDate != null) { this.evaluationDate = new Date(jsonObj.evaluationDate); }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
