import { Injectable } from '@angular/core';
import { ComentarioDTO } from '@api/interfaces/comentario.interface';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';
import { CommentBackendService } from '@api/services/comment-backend.service';
import { ComunicadoBackendService } from '@api/services/comunicado-backend.service';
import { EventoBackendService } from '@api/services/evento-backend.service';
import { FeedbackBackendService } from '@api/services/feedback-backend.service';
import { PersonalAusenciaBackendService } from '@api/services/personal-ausencia-backend.service';
import { PersonalLegajoBackendService } from '@api/services/personal-legajo-backend.service';
import { ReclutamientoCandidatosBackendService } from '@api/services/reclutamiento-candidatos-backend.service';
import { SancionBackendService } from '@api/services/sancion-backend.service';
import { TaskBackendService } from '@api/services/task-backend.service';
import { Observable, Subject, tap } from 'rxjs';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';

@Injectable({
  providedIn: 'root',
})
export class ComentariosService {
  public comentarios = new Array<ComentarioDTO>();
  public comentariosCambio = new Subject<void>();
  constructor(
    private comunicadosServiceBackend: ComunicadoBackendService,
    private eventoServiceBackend: EventoBackendService,
    private personalLegajoServiceBackend: PersonalLegajoBackendService,
    private sancionesServiceBackend: SancionBackendService,
    private feedbackServiceBackend: FeedbackBackendService,
    private reclutamientoCandidatosServiceBackend: ReclutamientoCandidatosBackendService,
    private ausenciaServiceBackend: PersonalAusenciaBackendService,
    private commentServiceBackend: CommentBackendService,
    private taskServiceBackend: TaskBackendService,
  ) {}

  public enviarMensajeEnComunicadosAdmin(id: number, comentario: FormData): Observable<void> {
    return this.comunicadosServiceBackend.insertNotificationsIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerMensajesEnComunicadosAdmin(id: number): Observable<Array<ComentarioDTO>> {
    return this.comunicadosServiceBackend.getNotificationsIdComentarios(id);
  }

  public editarMensajeEnComunicadosAdmin(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.comunicadosServiceBackend
      .updateNotificationsIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarMensajeEnComunicadosAdmin(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.comunicadosServiceBackend
      .deleteNotificationsIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public enviarMensajeEnComunicadosUser(id: number, comentario: FormData): Observable<void> {
    return this.comunicadosServiceBackend
      .insertUserNotificationsIdComentarios(id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerMensajesEnComunicadosUser(id: number): Observable<Array<ComentarioDTO>> {
    return this.comunicadosServiceBackend.getUserNotificationsIdComentarios(id);
  }

  public editarMensajeEnComunicadosUser(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.comunicadosServiceBackend
      .updateUserNotificationsIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarMensajeEnComunicadosUser(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.comunicadosServiceBackend
      .deleteUserNotificationsIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public enviarMensajesEnEventos(id: number, comentario: FormData): Observable<void> {
    return this.eventoServiceBackend.insertEventoIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerMensajesEnEventos(id: number): Observable<Array<ComentarioDTO>> {
    return this.eventoServiceBackend.getEventoIdComentarios(id);
  }
  public editarMensajesEnEventos(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.eventoServiceBackend
      .updateEventoIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarMensajesEnEventos(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.eventoServiceBackend
      .deleteEventoIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public enviarMensajesPerfilUsuario(id: number, comentario: FormData): Observable<void> {
    return this.personalLegajoServiceBackend
      .insertPersonalLegajosIdComentarios(id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerMensajesPerfilUsuario(id: number): Observable<Array<ComentarioDTO>> {
    return this.personalLegajoServiceBackend.getPersonalLegajosIdComentarios(id);
  }

  public editarMensajesPerfilUsuario(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.personalLegajoServiceBackend
      .updatePersonalLegajosIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarMensajesPerfilUsuario(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.personalLegajoServiceBackend
      .deletePersonalLegajosIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public enviarComentariosEnFeedback(id: number, comentario: FormData): Observable<void> {
    return this.feedbackServiceBackend.insertFeedbackIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerComentariosEnFeedback(id: number): Observable<Array<ComentarioDTO>> {
    return this.feedbackServiceBackend.getFeedbackIdComentarios(id);
  }

  public editarComentariosEnFeedback(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.feedbackServiceBackend
      .updateFeedbackIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarComentariosEnFeedback(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.feedbackServiceBackend
      .deleteFeedbackIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public enviarComentariosEnSancion(id: number, comentario: FormData): Observable<void> {
    return this.sancionesServiceBackend.insertComplianceSancionIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerComentariosEnSancion(id: number): Observable<Array<ComentarioDTO>> {
    return this.sancionesServiceBackend.getComplianceSancionIdComentarios(id);
  }

  public editarComentariosEnSancion(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.sancionesServiceBackend
      .updateComplianceSancionIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public borrarComentariosEnSancion(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.sancionesServiceBackend
      .deleteComplianceSancionIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public obtenerComentariosCandidato(id: number): Observable<Array<ComentarioDTO>> {
    return this.reclutamientoCandidatosServiceBackend.getReclutamientoCandidatosIdComentarios(id);
  }

  public enviarComentarioACandidato(id: number, comentario: FormData): Observable<void> {
    return this.reclutamientoCandidatosServiceBackend
      .insertReclutamientoCandidatosIdComentario(id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public deleteCommentToCandidate(id: number, comment: ComentarioDTO): Observable<void> {
    return this.reclutamientoCandidatosServiceBackend
      .deleteReclutamientoCandidatosIdComentariosIDCOMENTARIO(id, comment.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public editCommentToCandidate(id: number, comment: ComentarioDTO): Observable<ComentarioDTO> {
    return this.reclutamientoCandidatosServiceBackend
      .updateReclutamientoCandidatosIdComentariosIDCOMENTARIO(id, comment.id, comment)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public saveAbsenceComment(id: number, comentario: FormData): Observable<void> {
    return this.ausenciaServiceBackend.insertAusenciasIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public getAbssenceComments(id: number): Observable<Array<ComentarioDTO>> {
    return this.ausenciaServiceBackend.getAusenciasIdComentarios(id);
  }
  public editAbsenceComment(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.ausenciaServiceBackend
      .updateAusenciasIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public deleteAbsenceComment(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.ausenciaServiceBackend
      .deleteAusenciasIdComentariosIDCOMENTARIO(id, comentario.id)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public saveTaskComment(id: number, comentario: FormData): Observable<void> {
    return this.taskServiceBackend.insertTasksIdComentarios(id, comentario).pipe(tap(() => this.comentariosCambio.next()));
  }

  public getTaskComments(id: number): Observable<Array<ComentarioDTO>> {
    return this.taskServiceBackend.getTasksIdComentarios(id);
  }
  public editTaskComment(id: number, comentario: ComentarioDTO): Observable<ComentarioDTO> {
    return this.taskServiceBackend
      .updateTasksIdComentariosIDCOMENTARIO(id, comentario.id, comentario)
      .pipe(tap(() => this.comentariosCambio.next()));
  }

  public deleteTaskComment(id: number, comentario: ComentarioDTO): Observable<void> {
    return this.taskServiceBackend.deleteTasksIdComentariosIDCOMENTARIO(id, comentario.id).pipe(tap(() => this.comentariosCambio.next()));
  }

  public updateCommentsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
    return this.commentServiceBackend.updateCommentsIdReactions(id, newCommentReactionDTO);
  }

  public deleteCommentsIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
    return this.commentServiceBackend.deleteCommentsIdReactionsEMOJIID(id, emojiId);
  }
}
