import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { ReclutamientoEstadoOferta } from 'src/app/ModelDTO/DTO/reclutamientoEstadoOferta.ENUM';
import { ReclutamientoOfertaCampoDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaCampo.DTO';
import { ReclutamientoOfertaConocimientoDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaConocimiento.DTO';
import { RemunerationPeriod } from 'src/app/ModelDTO/DTO/remunerationPeriod.ENUM';
import { RemunerationType } from 'src/app/ModelDTO/DTO/remunerationType.ENUM';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class ReclutamientoOfertaPublicaDTO implements IEntityDTO {

   titulo = '';
   nombreOficina = '';
   area = '';
   location: WorkLocationType;
   localidad: LocalidadDTO;
   tipoContrato: ModalidadContractualDTO;
   tipoJornada: TipoContratacion;
   salarioAnual: number;
   desde: number;
   hasta: number;
   currency: CurrencyDTO;
   remunerationType: RemunerationType;
   remunerationPeriod: RemunerationPeriod;
   remunerationVisible: boolean;
   descripcion = '';
   companyName = '';
   companyImage = '';
   headerImage = '';
   estado: ReclutamientoEstadoOferta;
   campos: Array<ReclutamientoOfertaCampoDTO>;
   conocimientos: Array<ReclutamientoOfertaConocimientoDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.localidad = new LocalidadDTO();
      this.tipoContrato = new ModalidadContractualDTO();
      this.currency = new CurrencyDTO();
      this.campos = new Array<ReclutamientoOfertaCampoDTO>();
      this.conocimientos = new Array<ReclutamientoOfertaConocimientoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.titulo != null) { this.titulo = jsonObj.titulo; }
      if (jsonObj.nombreOficina != null) { this.nombreOficina = jsonObj.nombreOficina; }
      if (jsonObj.area != null) { this.area = jsonObj.area; }
      if (jsonObj.location != null) { this.location = jsonObj.location as WorkLocationType; }
      if (jsonObj.localidad != null) { this.localidad.PrepareDTO(jsonObj.localidad); }
      if (jsonObj.tipoContrato != null) { this.tipoContrato.PrepareDTO(jsonObj.tipoContrato); }
      if (jsonObj.tipoJornada != null) { this.tipoJornada = jsonObj.tipoJornada as TipoContratacion; }
      if (jsonObj.salarioAnual != null) { this.salarioAnual = jsonObj.salarioAnual; }
      if (jsonObj.desde != null) { this.desde = jsonObj.desde; }
      if (jsonObj.hasta != null) { this.hasta = jsonObj.hasta; }
      if (jsonObj.currency != null) { this.currency.PrepareDTO(jsonObj.currency); }
      if (jsonObj.remunerationType != null) { this.remunerationType = jsonObj.remunerationType as RemunerationType; }
      if (jsonObj.remunerationPeriod != null) { this.remunerationPeriod = jsonObj.remunerationPeriod as RemunerationPeriod; }
      if (jsonObj.remunerationVisible != null) { this.remunerationVisible = jsonObj.remunerationVisible; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.companyName != null) { this.companyName = jsonObj.companyName; }
      if (jsonObj.companyImage != null) { this.companyImage = jsonObj.companyImage; }
      if (jsonObj.headerImage != null) { this.headerImage = jsonObj.headerImage; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as ReclutamientoEstadoOferta; }
      if (jsonObj.campos != null) { for (const item of jsonObj.campos) { const itemDTO = new ReclutamientoOfertaCampoDTO(); itemDTO.PrepareDTO(item); this.campos.push(itemDTO); } }
      if (jsonObj.conocimientos != null) { for (const item of jsonObj.conocimientos) { const itemDTO = new ReclutamientoOfertaConocimientoDTO(); itemDTO.PrepareDTO(item); this.conocimientos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
