import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private $collapsedStateSidebar = signal(false);
  public $collapsedSate = this.$collapsedStateSidebar.asReadonly();

  public toggleSidebar(): void {
    this.$collapsedStateSidebar.update((current) => !current);
  }

  public collapseSidebar(): void {
    this.$collapsedStateSidebar.set(true);
  }

  public expandSidebar(): void {
    this.$collapsedStateSidebar.set(false);
  }
}
