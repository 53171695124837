export enum ConfigurationSection {
   Undefined = 0,
   DigitalSignature = 1,
   Notifications = 2,
   TimeManagement = 3,
   Feedback = 4,
   OverallPerformance = 5,
   Forms = 6,
   Analytics = 7
 }
