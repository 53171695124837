import { TimeRangeDTO } from 'src/app/ModelDTO/DTO/timeRange.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { WorkLocationType } from './workLocationType.ENUM';


export class WorkDayDTO implements IEntityDTO {

   name = '';
   description = '';
   workingMode: WorkLocationType;
   monday: boolean;
   tuesday: boolean;
   wednesday: boolean;
   thursday: boolean;
   friday: boolean;
   saturday: boolean;
   sunday: boolean;
   totalWorkDay = '';
   totalComputedWorkDay = '';
   ranges: Array<TimeRangeDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.ranges = new Array<TimeRangeDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.description != null) { this.description = jsonObj.description; }
      if (jsonObj.workingMode != null) { this.workingMode = jsonObj.workingMode as WorkLocationType; }
      if (jsonObj.monday != null) { this.monday = jsonObj.monday; }
      if (jsonObj.tuesday != null) { this.tuesday = jsonObj.tuesday; }
      if (jsonObj.wednesday != null) { this.wednesday = jsonObj.wednesday; }
      if (jsonObj.thursday != null) { this.thursday = jsonObj.thursday; }
      if (jsonObj.friday != null) { this.friday = jsonObj.friday; }
      if (jsonObj.saturday != null) { this.saturday = jsonObj.saturday; }
      if (jsonObj.sunday != null) { this.sunday = jsonObj.sunday; }
      if (jsonObj.totalWorkDay != null) { this.totalWorkDay = jsonObj.totalWorkDay; }
      if (jsonObj.totalComputedWorkDay != null) { this.totalComputedWorkDay = jsonObj.totalComputedWorkDay; }
      if (jsonObj.ranges != null) { for (const item of jsonObj.ranges) { const itemDTO = new TimeRangeDTO(); itemDTO.PrepareDTO(item); this.ranges.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
