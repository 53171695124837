import { PrivacyPolicyDTO } from 'src/app/ModelDTO/DTO/privacyPolicy.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalLegajoPrivacyPolicyDTO implements IEntityDTO {

   privacyPolicy: PrivacyPolicyDTO;
   acceptedDate: Date;
   id = 0;

   constructor() {
      this.privacyPolicy = new PrivacyPolicyDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.privacyPolicy != null) { this.privacyPolicy.PrepareDTO(jsonObj.privacyPolicy); }
      if (jsonObj.acceptedDate != null) { this.acceptedDate = new Date(jsonObj.acceptedDate); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
