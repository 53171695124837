import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class TurnoBasicoDTO implements IEntityDTO {

   alias = '';
   horaDesde: Date;
   horaHasta: Date;
   fechaDia: Date;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.horaDesde != null) { this.horaDesde = new Date(jsonObj.horaDesde); }
      if (jsonObj.horaHasta != null) { this.horaHasta = new Date(jsonObj.horaHasta); }
      if (jsonObj.fechaDia != null) { this.fechaDia = new Date(jsonObj.fechaDia); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
