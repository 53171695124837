import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class CandidatoPostuladoDTO implements IEntityDTO {

   firstName = '';
   lastName = '';
   profilePicture = '';
   email = '';
   phone = '';
   linkedIn = '';
   creationDate: Date;
   lastUpdate: Date;
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.firstName != null) { this.firstName = jsonObj.firstName; }
      if (jsonObj.lastName != null) { this.lastName = jsonObj.lastName; }
      if (jsonObj.profilePicture != null) { this.profilePicture = jsonObj.profilePicture; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.phone != null) { this.phone = jsonObj.phone; }
      if (jsonObj.linkedIn != null) { this.linkedIn = jsonObj.linkedIn; }
      if (jsonObj.creationDate != null) { this.creationDate = new Date(jsonObj.creationDate); }
      if (jsonObj.lastUpdate != null) { this.lastUpdate = new Date(jsonObj.lastUpdate); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
