import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderConfigTheme, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss'],
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
})
export class SkeletonCardComponent {
  @Input() public height: number;
  @Input() public lines = 4;
  @Input() public hideBorder = false;
  @Input() public row = false;
  @Input() public padding = 20;
  @Input() public pictureTheme: NgxSkeletonLoaderConfigTheme;
  @Input() public lineTheme: NgxSkeletonLoaderConfigTheme;
}
