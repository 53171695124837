<div appShowRecaptcha class="d-flex flex-column flex-grow-1">
  <div class="header w-100">
    <div class="d-flex justify-content-center">
      <h3 class="offer-title">
        <ng-container *ngIf="loadedOffer"> {{ ofertaPublica.titulo }} </ng-container>
        <ngx-skeleton-loader *ngIf="!loadedOffer" count="1" appearance="line"></ngx-skeleton-loader>
      </h3>
    </div>
  </div>
  <div class="public-container d-flex flex-column flex-grow-1 justify-content-center align-items-center">
    <div class="form-container" *ngIf="loadedOffer && !loadingCandidate; else skeleton">
      <app-postulation-form
        *ngIf="postulatedCandidateDTO"
        [postulatedCandidateForm]="postulatedCandidateForm"
        [publicOffer]="ofertaPublica"
        [imageData]="imageData"
        [successfulApplication]="successfulApplication"
        [files]="files"
        (submitApplication)="submitFormCmd.execute()"
        (filesChange)="filesChange($event)"
      ></app-postulation-form>

      <app-email-validation-form
        *ngIf="!postulatedCandidateDTO"
        [validateEmailForm]="validateEmailForm"
        [offer]="ofertaPublica"
        [hideForm]="hideForm"
        (validateEmail)="validateEmailCmd.execute()"
      />
    </div>
    <ng-template #skeleton>
      <div class="mt-6 skeleton form-container">
        <app-skeleton-card [height]="200"></app-skeleton-card>
        <app-skeleton-card [height]="200"></app-skeleton-card>
      </div>
    </ng-template>
  </div>
  <app-footer-public></app-footer-public>
</div>
