import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  imports: [NgxSkeletonLoaderModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent {}
