import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RecaptchaService } from './recaptcha.service';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaWebService extends RecaptchaService {
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {
    super();
  }

  public getToken(accion: string) {
    return this.recaptchaV3Service.execute(accion).toPromise();
  }
}
