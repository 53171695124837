<div class="imagen-rectangular" *ngIf="properties">
  <img *ngIf="backgroundImg" [src]="backgroundImg" alt="Background Photo" />
  <div *ngIf="properties.showEditButton" class="justify-content-between">
    <button mat-icon-button class="btn-edit" (click)="editEvent()">
      <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
    </button>
  </div>
</div>
<div class="bottom-title" *ngIf="properties.title">
  {{ properties.title }}
</div>
