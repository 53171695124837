import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalCategoriaBasicaDTO } from 'src/app/ModelDTO/DTO/personalCategoriaBasica.DTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { ConvenioColectivoModelDTO } from 'src/app/ModelDTO/convenioColectivo.ModelDTO';


export class PersonalCategoriaBasicaModelDTO extends EntityModelDTO<PersonalCategoriaBasicaDTO> {

   private convenioModel: ConvenioColectivoModelDTO;
   private convenioSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalCategoriaBasicaDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: PersonalCategoriaBasicaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.convenioModel = new ConvenioColectivoModelDTO(this.entityDTO.convenio);
      this.convenioSubscribe = this.convenioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.convenioModel.dispose();
      this.convenioSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get ConvenioModel(): ConvenioColectivoModelDTO { return this.convenioModel; }
   get Convenio(): ConvenioColectivoDTO { return this.convenioModel.getEntityDTO(); }
   set Convenio(value: ConvenioColectivoDTO) { this.notifyChange(() => { this.entityDTO.convenio = value; this.convenioModel.setEntityDTO(value); }); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
