import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoCambioEstadoPersonalDTO } from 'src/app/ModelDTO/DTO/tipoCambioEstadoPersonal.DTO';
import { EstadisticasAgrupadaMesBajaDTO } from 'src/app/ModelDTO/DTO/estadisticasAgrupadaMesBaja.DTO';


export class EstadisticasAgrupadaTipoBajaDTO implements IEntityDTO {

   tipoCambioEstadoPersonal: TipoCambioEstadoPersonalDTO;
   meses: Array<EstadisticasAgrupadaMesBajaDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.tipoCambioEstadoPersonal = new TipoCambioEstadoPersonalDTO();
      this.meses = new Array<EstadisticasAgrupadaMesBajaDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipoCambioEstadoPersonal != null) { this.tipoCambioEstadoPersonal.PrepareDTO(jsonObj.tipoCambioEstadoPersonal); }
      if (jsonObj.meses != null) { for (const item of jsonObj.meses) { const itemDTO = new EstadisticasAgrupadaMesBajaDTO(); itemDTO.PrepareDTO(item); this.meses.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
