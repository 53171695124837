import { Injectable, NgZone, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusService {
  public $isOnline = signal(true);

  constructor(private ngZone: NgZone) {
    window.addEventListener('online', () => this.updateConnectionStatus(true));
    window.addEventListener('offline', () => this.updateConnectionStatus(false));
  }

  public updateConnectionStatus(isOnline: boolean): void {
    this.ngZone.run(() => {
      this.$isOnline.set(isOnline);
    });
  }
}
