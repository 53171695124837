import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class TimeRangeDTO implements IEntityDTO {

   name = '';
   start = '';
   end = '';
   restDuration = '';
   restIncludedInRange: boolean;
   restStart : string;
   restEnd : string;
   totalRange = '';
   totalComputedRange = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.start != null) { this.start = jsonObj.start; }
      if (jsonObj.end != null) { this.end = jsonObj.end; }
      if (jsonObj.restDuration != null) { this.restDuration = jsonObj.restDuration; }
      if (jsonObj.restIncludedInRange != null) { this.restIncludedInRange = jsonObj.restIncludedInRange; }
      if (jsonObj.restStart != null) { this.restStart = jsonObj.restStart; }
      if (jsonObj.restEnd != null) { this.restEnd = jsonObj.restEnd; }
      if (jsonObj.totalRange != null) { this.totalRange = jsonObj.totalRange; }
      if (jsonObj.totalComputedRange != null) { this.totalComputedRange = jsonObj.totalComputedRange; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
