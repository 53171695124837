import { Directive, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appShowRecaptcha]',
  standalone: true,
})
export class ShowRecaptchaDirective implements OnInit, OnDestroy {
  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: object) {}

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.addClass(this.document.body, 'recaptcha');
    }
  }

  public ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, 'recaptcha');
    }
  }
}
