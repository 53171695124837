import { Subscription } from 'rxjs';
import { CompetenceEvaluationTypeModelDTO } from 'src/app/ModelDTO/competenceEvaluationType.ModelDTO';
import { CuestionarioModelDTO } from 'src/app/ModelDTO/cuestionario.ModelDTO';
import { CompetenceEvaluationTypeDTO } from 'src/app/ModelDTO/DTO/competenceEvaluationType.DTO';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { EncuestaDTO } from 'src/app/ModelDTO/DTO/encuesta.DTO';
import { EncuestaEvaluatorDTO } from 'src/app/ModelDTO/DTO/encuestaEvaluator.DTO';
import { EstadoEncuesta } from 'src/app/ModelDTO/DTO/estadoEncuesta.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PlanificacionDTO } from 'src/app/ModelDTO/DTO/planificacion.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { PlanificacionModelDTO } from 'src/app/ModelDTO/planificacion.ModelDTO';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class EncuestaModelDTO extends EntityModelDTO<EncuestaDTO> {

   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;
   private planificacionModel: PlanificacionModelDTO;
   private planificacionSubscribe: Subscription;
   private cuestionarioModel: CuestionarioModelDTO;
   private cuestionarioSubscribe: Subscription;
   private evaluationModel: CompetenceEvaluationTypeModelDTO;
   private evaluationSubscribe: Subscription;

   public constructor(protected entityDTO: EncuestaDTO) {
      super(entityDTO);
   }

   public static getEstadoEncuesta(): string[] {
      return EntityModelDTO.getEnumArray(EstadoEncuesta);
   }

   public setEntityDTO(entityDTO: EncuestaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.planificacionModel = new PlanificacionModelDTO(this.entityDTO.planificacion);
      this.planificacionSubscribe = this.planificacionModel.changed.subscribe((changed) => this.changed.next(changed));
      this.cuestionarioModel = new CuestionarioModelDTO(this.entityDTO.cuestionario);
      this.cuestionarioSubscribe = this.cuestionarioModel.changed.subscribe((changed) => this.changed.next(changed));
      this.evaluationModel = new CompetenceEvaluationTypeModelDTO(this.entityDTO.evaluation);
      this.evaluationSubscribe = this.evaluationModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
      this.planificacionModel.dispose();
      this.planificacionSubscribe.unsubscribe();
      this.cuestionarioModel.dispose();
      this.cuestionarioSubscribe.unsubscribe();
      this.evaluationModel.dispose();
      this.evaluationSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Estado(): string { return EstadoEncuesta[this.entityDTO.estado]; }
   set Estado(value: string) { this.notifyChangeDTO('estado', EstadoEncuesta[value]); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get PlanificacionModel(): PlanificacionModelDTO { return this.planificacionModel; }
   get Planificacion(): PlanificacionDTO { return this.planificacionModel.getEntityDTO(); }
   set Planificacion(value: PlanificacionDTO) { this.notifyChange(() => { this.entityDTO.planificacion = value; this.planificacionModel.setEntityDTO(value); }); }

   get CuestionarioModel(): CuestionarioModelDTO { return this.cuestionarioModel; }
   get Cuestionario(): CuestionarioDTO { return this.cuestionarioModel.getEntityDTO(); }
   set Cuestionario(value: CuestionarioDTO) { this.notifyChange(() => { this.entityDTO.cuestionario = value; this.cuestionarioModel.setEntityDTO(value); }); }

   get IsAnonymous(): boolean { return this.entityDTO.isAnonymous; }
   set IsAnonymous(value: boolean) { this.notifyChangeDTO('isAnonymous', value); }

   get ResultsAvailableForParticipants(): boolean { return this.entityDTO.resultsAvailableForParticipants; }
   set ResultsAvailableForParticipants(value: boolean) { this.notifyChangeDTO('resultsAvailableForParticipants', value); }

   get OnlyCreatorCanEdit(): boolean { return this.entityDTO.onlyCreatorCanEdit; }
   set OnlyCreatorCanEdit(value: boolean) { this.notifyChangeDTO('onlyCreatorCanEdit', value); }

   get EvaluationModel(): CompetenceEvaluationTypeModelDTO { return this.evaluationModel; }
   get Evaluation(): CompetenceEvaluationTypeDTO { return this.evaluationModel.getEntityDTO(); }
   set Evaluation(value: CompetenceEvaluationTypeDTO) { this.notifyChange(() => { this.entityDTO.evaluation = value; this.evaluationModel.setEntityDTO(value); }); }

   get Evaluators(): Array<EncuestaEvaluatorDTO> { return this.entityDTO.evaluators; }
   set Evaluators(value: Array<EncuestaEvaluatorDTO>) { this.notifyChangeDTO('evaluators', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
