import { IFeatureFlag, IFlagsData } from 'src/app/shared/feature-flags/flag.interface';

export class FeatureFlagHelper {
  public static featureOn(featureName: string, flags: Map<string, IFeatureFlag> | undefined): boolean {
    if (!featureName) {
      return true;
    }

    return flags?.get(featureName)?.active ?? false;
  }

  public static featureOff(featureName: string, flags: Map<string, IFeatureFlag>): boolean {
    return !FeatureFlagHelper.featureOn(featureName, flags);
  }

  public static featureDue(featureName: string, flags: Map<string, IFeatureFlag>): Date | null | undefined {
    return flags?.get(featureName)?.due;
  }

  public static featureOverLimit(featureName: string, limit: number, flags: Map<string, IFeatureFlag>): boolean {
    return flags?.has(featureName) ? (flags.get(featureName).limit ?? Number.POSITIVE_INFINITY) < limit : false;
  }

  public static canMatchModule(path: string, data: IFlagsData): boolean {
    if (data.modules?.has(path)) {
      const flag = data.modules.get(path);
      return FeatureFlagHelper.featureOn(flag, data?.flags);
    }
    return false;
  }
}
