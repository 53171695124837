import { WorkDayDTO } from 'src/app/ModelDTO/DTO/workDay.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class JornadasPorDiaDTO implements IEntityDTO {

   date: Date;
   workDays: Array<WorkDayDTO>;
   id = 0;

   constructor() {
      this.workDays = new Array<WorkDayDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.date != null) { this.date = new Date(jsonObj.date); }
      if (jsonObj.workDays != null) { for (const item of jsonObj.workDays) { const itemDTO = new WorkDayDTO(); itemDTO.PrepareDTO(item); this.workDays.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
