import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class EstadisticaTotalesDTO implements IEntityDTO {

   totalAux = 0;
   tipo = 0;
   tipoString = '';
   total = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.totalAux != null) { this.totalAux = jsonObj.totalAux; }
      if (jsonObj.tipo != null) { this.tipo = jsonObj.tipo; }
      if (jsonObj.tipoString != null) { this.tipoString = jsonObj.tipoString; }
      if (jsonObj.total != null) { this.total = jsonObj.total; }
   }
}
