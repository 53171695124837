import Quill from 'quill';

// Import and extend the default video formatter
const Video = Quill.import('formats/video');

class CustomVideo extends Video {
  public static create(value: unknown): unknown {
    // Validate the video URL before inserting
    if (typeof value === 'string' && CustomVideo.isValidVideoUrl(value)) {
      return super.create(value);
    }

    const node = document.createElement('span');
    node.innerText = 'Link error. https://www.(youtube.com|youtu.be|vimeo.com|player.vimeo.com)/*';
    node.style.color = 'red';
    return node;
  }

  public static isValidVideoUrl(url: string): boolean {
    // Check for valid video URL (YouTube, Vimeo, etc.)
    const videoUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|vimeo\.com|player\.vimeo\.com)\/.+$/;
    return videoUrlRegex.test(url);
  }
}

// Register the custom video blot globally
Quill.register(CustomVideo, true);
