export enum RoleIdEnum {
  Propietario = 1,
  Administrativo,
  Colaborador,
  Supervisor,
  Auditor = 10,
  Terminal = 50,
  Demo = 90,
  Api = 100,
}
