import { CompetenceCategoryDTO, PersonalTaskGoalsDTO } from 'src/app/ModelDTO/DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class OverallPerformanceDTO implements IEntityDTO {

  behaviors: Array<CompetenceCategoryDTO>;
  goals: Array<PersonalTaskGoalsDTO>;
  suggestedResult: Array<LevelRangeDTO>;
  generalResultBehaviors: LevelRangeDTO;
  generalResultGoals: LevelRangeDTO;
  assignedResult: LevelRangeDTO | undefined;
  calibratedResult: LevelRangeDTO | undefined;

   id = 0;
   cacheStamp = 0;

   constructor() {
      this.behaviors = new Array<CompetenceCategoryDTO>();
      this.goals = new Array<PersonalTaskGoalsDTO>();
      this.suggestedResult = new Array<LevelRangeDTO>();
      this.generalResultBehaviors = new LevelRangeDTO();
      this.generalResultGoals = new LevelRangeDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.behaviors != null) { for (const item of jsonObj.behaviors) { const itemDTO = new CompetenceCategoryDTO(); itemDTO.PrepareDTO(item); this.behaviors.push(itemDTO); } }
      if (jsonObj.goals != null) { for (const item of jsonObj.goals) { const itemDTO = new PersonalTaskGoalsDTO(); itemDTO.PrepareDTO(item); this.goals.push(itemDTO); } }
      if (jsonObj.suggestedResult != null) { for (const item of jsonObj.suggestedResult) { const itemDTO = new LevelRangeDTO(); itemDTO.PrepareDTO(item); this.suggestedResult.push(itemDTO); } }
      if (jsonObj.generalResultBehaviors != null) { this.generalResultBehaviors.PrepareDTO(jsonObj.generalResultBehaviors); }
      if (jsonObj.generalResultGoals != null) { this.generalResultGoals.PrepareDTO(jsonObj.generalResultGoals); }
      if (jsonObj.assignedResult != null) {
        this.assignedResult = new LevelRangeDTO();
        this.assignedResult.PrepareDTO(jsonObj.assignedResult);
      }
      if (jsonObj.calibratedResult != null) {
        this.calibratedResult = new LevelRangeDTO();
        this.calibratedResult.PrepareDTO(jsonObj.calibratedResult);
      }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
