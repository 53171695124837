import { Component, Input } from '@angular/core';
import { AttachmentType, AttachmentDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { AttachmentDTO as IntefaceAttachmet } from '@api/interfaces/attachment.interface';
import { ICommand, Command } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { BehaviorSubject } from 'rxjs';
import { PrevisualizacionDocumentoService } from 'src/app/app-common/previsualizacion-documentos/previsualizacion-documento.service';
import { TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { extensionArchivo, fileIconClass, hasImageExtension, parseLink } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-file-chips',
  templateUrl: './file-chips.component.html',
  styleUrls: ['./file-chips.component.scss'],
})
export class FileChipsComponent {
  @Input() public noFilesMessage = 'No files';
  @Input() public back = false;
  @Input() public setOwner = false;
  @Input() public owner = null;
  @Input() public set attachments(value: Array<AttachmentDTO | IntefaceAttachmet>) {
    this.pattachments = value.filter((x) => x.type === AttachmentType.File && x.file);
  }

  public chipClickedCmd: ICommand = new Command((value) => this.verArchivo(value), new BehaviorSubject(true), false);
  public readonly fileIconClass = fileIconClass;
  public readonly hasImageExtension = hasImageExtension;

  public get attachments(): Array<AttachmentDTO | IntefaceAttachmet> {
    return this.pattachments;
  }

  private pattachments = new Array<AttachmentDTO | IntefaceAttachmet>();
  constructor(
    private previsualizarDocumentoService: PrevisualizacionDocumentoService,
    private headerService: HeaderAppService,
  ) {}

  public backgroundImage(imageSource: string): string {
    return `url(${imageSource || parseLink(imageSource)})`;
  }

  public extension(archivo: string): string {
    return extensionArchivo(archivo);
  }

  private async verArchivo(adjunto: AttachmentDTO): Promise<void> {
    this.previsualizarDocumentoService.visualizarArchivoById(
      adjunto.id,
      this.setOwner ? this.owner?.id ?? this.headerService.personalLegajoId : 0,
      TipoCategoriaDocumento.No_definido,
      true,
    );
  }
}
