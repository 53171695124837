<mat-form-field [appearance]="appearance" #formField>
  <mat-label>{{ label }}</mat-label>
  <input
    [formControl]="dateFormControl"
    type="text"
    #formInp
    matInput
    [placeholder]="placeholder"
    [id]="id"
    [name]="name"
    [neoAutofocus]="neoAutoFocus"
    (click)="clickInput()"
    mask="00/00/0000"
  />

  <input
    [required]="required"
    type="hidden"
    [matDatepicker]="picker"
    [formControl]="_date"
    [min]="min"
    [max]="max"
    (dateChange)="newDate($event)"
  />

  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon fontSet="material-symbols-outlined" matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker [touchUi]="small" #picker></mat-datepicker>
  <mat-hint *ngIf="infoDate"> {{ infoDate }} </mat-hint>
</mat-form-field>
