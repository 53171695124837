<div class="d-flex flex-column flex-grow-1 mb-2">
  <form [formGroup]="form">
    <div>
      <mat-slide-toggle class="my-2 wrap-content" color="primary" formControlName="includeHolidays">
        {{ 'GENERAL.INCLUDE_HOLIDAYS' | translate }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle class="my-2 wrap-content" color="primary" formControlName="includeSaturday">
        {{ 'FORMS.INCLUDE_SATURDAY' | translate }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle class="my-2 wrap-content" color="primary" formControlName="includeSunday">
        {{ 'FORMS.INCLUDE_SUNDAY' | translate }}
      </mat-slide-toggle>
    </div>
  </form>
</div>
