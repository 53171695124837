import { Pipe, PipeTransform, Injectable } from '@angular/core';

/**
 * Filter object array from property
 */
@Pipe({
  name: 'accent',
})
@Injectable()
export class AccentPipe implements PipeTransform {
  transform(value: string, replace: boolean) {
    if (value) {
      if (replace) {
        let val = value;
        val = val.replace(/1a/g, 'á');
        val = val.replace(/1e/g, 'é');
        val = val.replace(/1i/g, 'í');
        val = val.replace(/1o/g, 'ó');
        val = val.replace(/1u/g, 'ú');
        return val;
      } else {
        return value.replace(/1/g, '');
      }
    }
  }
}
