import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class CandidatoPostuladoConocimientoDTO implements IEntityDTO {

   skill = 0;
   score = 0;
   creationDate: Date;
   lastUpdate: Date;
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.skill != null) { this.skill = jsonObj.skill; }
      if (jsonObj.score != null) { this.score = jsonObj.score; }
      if (jsonObj.creationDate != null) { this.creationDate = new Date(jsonObj.creationDate); }
      if (jsonObj.lastUpdate != null) { this.lastUpdate = new Date(jsonObj.lastUpdate); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
