<div class="container-footer">
  <div class="d-flex justify-content-center">
    <div class="container">
      <div class="container-links">
        <div class="container-logo-link">
          <div class="container-logo">
            <a href="{{ urlWebPage }}"
              ><img src="assets/img/logo-lg.svg" alt="Software de Recursos Humanos" loading="lazy" class="footer-logo"
            /></a>
          </div>
          <div class="d-flex justify-content-between hidden-lg">
            <a href="https://www.linkedin.com/company/naaloohr" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" color="primary" svgIcon="linkedin"></mat-icon
            ></a>
            <a href="https://www.facebook.com/naaloohr/" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="facebook"></mat-icon
            ></a>
            <a href="https://www.instagram.com/naaloohr/" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="instagram"></mat-icon
            ></a>
            <a href="https://twitter.com/naaloohr" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="twitter"></mat-icon
            ></a>
          </div>
        </div>

        <div class="d-flex flex-column justify-content-between">
          <div class="d-flex justify-content-end hide-md">
            <a href="https://www.linkedin.com/company/naaloohr" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" color="primary" svgIcon="linkedin"></mat-icon
            ></a>
            <a href="https://www.facebook.com/naaloohr/" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="facebook"></mat-icon
            ></a>
            <a href="https://www.instagram.com/naaloohr/" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="instagram"></mat-icon
            ></a>
            <a href="https://twitter.com/naaloohr" target="_blank" class="footer-social-link w-inline-block"
              ><mat-icon fontSet="material-symbols-outlined" svgIcon="twitter"></mat-icon
            ></a>
          </div>
          <div class="footer-credits d-flex justify-content-center py-6">
            <a class="footer-link hide-md">Notificación de cookies ·&nbsp;</a>
            <a href="{{ urlWebPage }}/terminos-y-condiciones" class="footer-link">Términos y condiciones ·&nbsp;</a>
            <a href="{{ urlWebPage }}/politica-privacidad" class="footer-link">Política de privacidad</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
