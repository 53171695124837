import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxNeoModalMatComponent } from './ngx-neo-modal-mat.component';

@NgModule({
  declarations: [NgxNeoModalMatComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatInputModule, FormsModule, TranslateModule],
  exports: [NgxNeoModalMatComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true } }],
})
export class NgxNeoModalMatModule {}
