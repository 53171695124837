import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class AddresseesDTO implements IEntityDTO {

   todaLaNomina: boolean;
   personalLegajos: Array<number>;
   areas: Array<number>;
   sectores: Array<number>;
   modalidadadesContractuales: Array<number>;
   offices: Array<number>;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.todaLaNomina != null) { this.todaLaNomina = jsonObj.todaLaNomina; }
      if (jsonObj.personalLegajos != null) { this.personalLegajos = jsonObj.personalLegajos; }
      if (jsonObj.areas != null) { this.areas = jsonObj.areas; }
      if (jsonObj.sectores != null) { this.sectores = jsonObj.sectores; }
      if (jsonObj.modalidadadesContractuales != null) { this.modalidadadesContractuales = jsonObj.modalidadadesContractuales; }
      if (jsonObj.offices != null) { this.offices = jsonObj.offices; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
