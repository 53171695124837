import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';

@Injectable({
   providedIn: 'root'
})
export class PersonalCategoriaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getPersonalCategoriasIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalCategorias/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalCategoriasIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalCategorias/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getPersonalCategorias(): Promise<Array<PersonalCategoriaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalCategorias/'));
         const resJson = res.data;
         const resDTO = new Array<PersonalCategoriaDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalCategoriaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deletePersonalCategoriasId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personalCategorias/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getPersonalCategoriasId(id: number): Promise<PersonalCategoriaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalCategorias/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalCategoriaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePersonalCategoriasId(id: number, personalCategoriaDTO: PersonalCategoriaDTO): Promise<PersonalCategoriaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personalCategorias/' + id, personalCategoriaDTO));
         const resDTO = new PersonalCategoriaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalCategorias(personalCategoriaDTO: PersonalCategoriaDTO): Promise<PersonalCategoriaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalCategorias/', personalCategoriaDTO));
         const resDTO = new PersonalCategoriaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalCategoriasIdSubcategoria(id: number, personalSubcategoriaDTO: PersonalSubcategoriaDTO): Promise<PersonalCategoriaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalCategorias/' + id + '/subcategoria', personalSubcategoriaDTO));
         const resDTO = new PersonalCategoriaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
