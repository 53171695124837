import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CategoriaCantidadDTO } from 'src/app/ModelDTO/DTO/categoriaCantidad.DTO';
import { CategoriaEntregaDTO } from 'src/app/ModelDTO/DTO/categoriaEntrega.DTO';
import { SubcategoriaEntregaDTO } from 'src/app/ModelDTO/DTO/subcategoriaEntrega.DTO';

@Injectable({
   providedIn: 'root'
})
export class CategoriaEntregaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCategoriaEntregaIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/categoriaEntrega/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCategoriaEntregaIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/categoriaEntrega/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getCategoriaEntrega(): Promise<Array<CategoriaEntregaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/categoriaEntrega/'));
         const resJson = res.data;
         const resDTO = new Array<CategoriaEntregaDTO>();
         for (const item of resJson) {
            const itemDTO = new CategoriaEntregaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteCategoriaEntregaId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/categoriaEntrega/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getCategoriaEntregaId(id: number): Promise<CategoriaEntregaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/categoriaEntrega/' + id));
         if (!res) { return null; }
         const resDTO = new CategoriaEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCategoriaEntregaId(id: number, categoriaEntregaDTO: CategoriaEntregaDTO): Promise<CategoriaEntregaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/categoriaEntrega/' + id, categoriaEntregaDTO));
         const resDTO = new CategoriaEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertCategoriaEntrega(categoriaEntregaDTO: CategoriaEntregaDTO): Promise<CategoriaEntregaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/categoriaEntrega/', categoriaEntregaDTO));
         const resDTO = new CategoriaEntregaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertCategoriaEntregaIdSubcategoria(id: number, subcategoriaEntregaDTO: SubcategoriaEntregaDTO): Promise<CategoriaCantidadDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/categoriaEntrega/' + id + '/subcategoria', subcategoriaEntregaDTO));
         const resDTO = new CategoriaCantidadDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
