import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  CategoriaCantidadDTO,
  CategoriaInformacionDTO,
  DocumentacionConAnalisisDTO,
  InformacionDTO,
  InformacionesConCantidadDTO,
  InformacionMasivaDTO,
  InformacionTipoDTO,
  TipoCategoriaDocumento,
} from 'src/app/ModelDTO/DTO';
import { DistribucionMasivaDTO } from 'src/app/ModelDTO/DTO/distribucionMasiva.DTO';
import { InformacionModelDTO } from 'src/app/ModelDTO/informacion.ModelDTO';
import { CategoriaInformacionServiceBackend, InformacionTipoServiceBackend } from 'src/app/ServiceBackend';
import { InformacionServiceBackend } from 'src/app/ServiceBackend/informacion.ServiceBackend';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';
import { compare } from 'src/app/shared/shared-functions';

@Injectable({
  providedIn: 'root',
})
export class DocumentacionSharedService {
  public informacion = new Subject<Array<InformacionModelDTO>>();

  // TODO: remove
  public get InformacionModel(): InformacionModelDTO {
    return this.informacionModel;
  }

  public get InformacionAEditarModel(): InformacionModelDTO {
    return this.informacionAEditarModel;
  }

  public set InformacionModel(info: InformacionModelDTO) {
    this.informacionModel = info;
  }

  public set InformacionAEditarModel(info: InformacionModelDTO) {
    this.informacionAEditarModel = info;
  }

  private informacionModel: InformacionModelDTO;
  private informacionAEditarModel: InformacionModelDTO;

  constructor(
    public informacionBackend: InformacionServiceBackend,
    private categoriasService: CategoriaInformacionServiceBackend,
    private tipoService: InformacionTipoServiceBackend,
    private router: Router,
    private fileDownloaderService: FileDownloaderService,
  ) {}

  public agregarInformacion(informacion: Array<InformacionModelDTO>): void {
    this.informacion.next(informacion);
  }

  public async obtenerDocumentacionUsuario(
    pageNumber: number,
    pageSize: number,
    fechaDesde: Date,
    fechaHasta: Date,
    period: Date = undefined,
    idCategoria: number = 0,
    personalLegajoId: number = 0,
    sortActive: number = 0,
    isAsc: boolean = false,
    modalityContractual: number = 0,
    name?: string,
    office: number = 0,
    businessName: number = 0,
  ): Promise<InformacionModelDTO[]> {
    const res = await this.informacionBackend.getBiblioteca(
      pageNumber,
      pageSize,
      fechaDesde,
      fechaHasta,
      period,
      idCategoria,
      personalLegajoId,
      sortActive,
      isAsc,
      modalityContractual,
      name,
      office,
      businessName,
    );
    const response = new Array<InformacionModelDTO>();
    res.forEach((x) => {
      response.push(new InformacionModelDTO(x));
    });
    return response;
  }

  public async obtenerDocumentacionEmpresa(
    pageNumber: number,
    pageSize: number,
    fechaDesde: Date,
    fechaHasta: Date,
    period: Date = undefined,
    idCategoria: number = 0,
    sortActive: number = 0,
    isAsc: boolean = false,
    name?: string,
  ): Promise<InformacionModelDTO[]> {
    const res = await this.informacionBackend.getBibliotecaEmpresa(
      pageNumber,
      pageSize,
      fechaDesde,
      fechaHasta,
      period,
      idCategoria,
      sortActive,
      isAsc,
      name,
    );
    const response = new Array<InformacionModelDTO>();
    res.forEach((x) => {
      response.push(new InformacionModelDTO(x));
    });
    return response;
  }

  public async obtenerProcedimientos(
    pageNumber: number,
    pageSize: number,
    fechaDesde: Date,
    fechaHasta: Date,
    period: Date = undefined,
    idCategoria: number = 0,
    sortActive: number = 0,
    isAsc: boolean = false,
    name?: string,
  ): Promise<InformacionModelDTO[]> {
    const res = await this.informacionBackend.getBibliotecaProcedimientos(
      pageNumber,
      pageSize,
      fechaDesde,
      fechaHasta,
      period,
      idCategoria,
      sortActive,
      isAsc,
      name,
    );
    const response = new Array<InformacionModelDTO>();
    res.forEach((x) => {
      response.push(new InformacionModelDTO(x));
    });
    return response;
  }

  public async obtenerPublicosUltimosCantidad(cantidad: number): Promise<InformacionesConCantidadDTO> {
    return this.informacionBackend.getBibliotecaPublicosUltimosCANTIDAD(cantidad);
  }

  public async crearDocumentacion(formData: FormData): Promise<InformacionDTO> {
    return this.informacionBackend.insertBibliotecaDocumentacion(formData);
  }

  public async getCategoriasConCantidad(
    visualizacion: number,
    fechaDesde: Date,
    fechaHasta: Date,
    idPersona: number = 0,
  ): Promise<Array<CategoriaCantidadDTO>> {
    return this.informacionBackend.getBibliotecaCategoriasCantidad(visualizacion, fechaDesde, fechaHasta, idPersona);
  }

  public async getCategorias(): Promise<Array<CategoriaInformacionDTO>> {
    return this.categoriasService.getCategoriaInformacion();
  }

  public async getTipos(): Promise<Array<InformacionTipoDTO>> {
    return this.tipoService.getInformacionTipo();
  }

  public async getCategoriaInformacionFiltrada(
    tipo: TipoCategoriaDocumento,
    mostrarMasivos: boolean = false,
  ): Promise<Array<CategoriaInformacionDTO>> {
    const categoriasDocumento = await this.categoriasService.getCategoriaInformacionFiltrada(tipo, mostrarMasivos);
    return categoriasDocumento.sort((a, b) => compare(a.nombre, b.nombre, true));
  }

  public async eliminarDocumentacionProcedimiento(infoDTO: InformacionDTO): Promise<void> {
    await this.informacionBackend.deleteBibliotecaId(infoDTO.id);
  }
  public async eliminarArchivoAdjunto(infoDTO: InformacionDTO, attachmentId: number): Promise<void> {
    await this.informacionBackend.deleteBibliotecaIdFilesATTACHMENTID(infoDTO.id, attachmentId);
  }

  public async downloadDocumentationZip(
    idCategoria: number = 0,
    idPersonal: number = 0,
    period?: Date,
    modalityContractual: number = 0,
    searchValue?: string,
    office: number = 0,
    businessName: number = 0,
  ): Promise<void> {
    this.informacionBackend.getBibliotecaDescargar(period, idCategoria, idPersonal, modalityContractual, searchValue, office, businessName);
  }

  public async analizarDocumentacionUnoAMuchos(
    stream: FormData,
    selector: number,
    progress: Subject<number>,
  ): Promise<DocumentacionConAnalisisDTO> {
    return this.informacionBackend.insertBibliotecaAnalisisDocumentacion(stream, selector, progress);
  }

  public async subirInformacionConAnalisis(stream: FormData, progress: Subject<number>): Promise<DistribucionMasivaDTO> {
    return this.informacionBackend.insertBibliotecaDocumentacionConAnalisis(stream, progress);
  }

  public async obtenerDocumentacionMasiva(fechaDesde: Date, fechaHasta: Date): Promise<InformacionMasivaDTO[]> {
    return this.informacionBackend.getBibliotecaInformacionMasiva(fechaDesde, fechaHasta);
  }

  public async obtenerDocumentacionMasivaPorId(id: number): Promise<InformacionMasivaDTO> {
    return this.informacionBackend.getBibliotecaInformacionMasivaId(id);
  }

  public async obtenerDocumentoId(id: number): Promise<InformacionDTO> {
    return this.informacionBackend.getBibliotecaId(id);
  }
  public verInformacionMasiva(idInformacionMasiva: number): void {
    this.router.navigate(['/admin/documentacion/masiva/', idInformacionMasiva]);
  }
}
