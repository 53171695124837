import { IEntityDTO } from './entity.DTO';
import { SignatureStatusType } from './signatureStatusType.ENUM';
import { SignatureType } from './signatureType.ENUM';

export class AttachmentSignatureFlatDTO implements IEntityDTO {
  informacionId = 0;
  informacionTitulo = '';
  attachmentId = 0;
  fileId = 0;
  fileName = '';
  ownerLegajoId = 0;
  ownerUserId = 0;
  ownerFullName = '';
  ownerImage = '';
  requesterLegajoId = 0;
  requesterUserId: number;
  requesterFullName = '';
  requesterImage = '';
  requestDate: Date;
  signDate: Date;
  status: SignatureStatusType;
  type: SignatureType;
  lastReminderDate: Date;
  id = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.informacionId != null) {
      this.informacionId = jsonObj.informacionId;
    }
    if (jsonObj.informacionTitulo != null) {
      this.informacionTitulo = jsonObj.informacionTitulo;
    }
    if (jsonObj.attachmentId != null) {
      this.attachmentId = jsonObj.attachmentId;
    }
    if (jsonObj.fileId != null) {
      this.fileId = jsonObj.fileId;
    }
    if (jsonObj.fileName != null) {
      this.fileName = jsonObj.fileName;
    }
    if (jsonObj.ownerLegajoId != null) {
      this.ownerLegajoId = jsonObj.ownerLegajoId;
    }
    if (jsonObj.ownerUserId != null) {
      this.ownerUserId = jsonObj.ownerUserId;
    }
    if (jsonObj.ownerFullName != null) {
      this.ownerFullName = jsonObj.ownerFullName;
    }
    if (jsonObj.ownerImage != null) {
      this.ownerImage = jsonObj.ownerImage;
    }
    if (jsonObj.requesterLegajoId != null) {
      this.requesterLegajoId = jsonObj.requesterLegajoId;
    }
    if (jsonObj.requesterUserId != null) {
      this.requesterUserId = jsonObj.requesterUserId;
    }
    if (jsonObj.requesterFullName != null) {
      this.requesterFullName = jsonObj.requesterFullName;
    }
    if (jsonObj.requesterImage != null) {
      this.requesterImage = jsonObj.requesterImage;
    }
    if (jsonObj.requestDate != null) {
      this.requestDate = new Date(jsonObj.requestDate);
    }
    if (jsonObj.signDate != null) {
      this.signDate = new Date(jsonObj.signDate);
    }
    if (jsonObj.status != null) {
      this.status = jsonObj.status as SignatureStatusType;
    }
    if (jsonObj.type != null) {
      this.type = jsonObj.type as SignatureType;
    }
    if (jsonObj.lastReminderDate != null) {
      this.lastReminderDate = new Date(jsonObj.lastReminderDate);
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
