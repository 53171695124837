import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO/tipoEntrega.DTO';
import { TipoUnidadDevolucion } from 'src/app/ModelDTO/DTO/tipoUnidadDevolucion.ENUM';
import { CategoriaEntregaBasicaDTO } from 'src/app/ModelDTO/DTO/categoriaEntregaBasica.DTO';
import { CategoriaEntregaBasicaModelDTO } from 'src/app/ModelDTO/categoriaEntregaBasica.ModelDTO';
import { SubcategoriaEntregaDTO } from 'src/app/ModelDTO/DTO/subcategoriaEntrega.DTO';
import { SubcategoriaEntregaModelDTO } from 'src/app/ModelDTO/subcategoriaEntrega.ModelDTO';
import { TipoDevolucionEntrega } from 'src/app/ModelDTO/DTO/tipoDevolucionEntrega.ENUM';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { CargoModelDTO } from 'src/app/ModelDTO/cargo.ModelDTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { ConvenioColectivoModelDTO } from 'src/app/ModelDTO/convenioColectivo.ModelDTO';


export class TipoEntregaModelDTO extends EntityModelDTO<TipoEntregaDTO> {

   private categoriaModel: CategoriaEntregaBasicaModelDTO;
   private categoriaSubscribe: Subscription;
   private subcategoriaModel: SubcategoriaEntregaModelDTO;
   private subcategoriaSubscribe: Subscription;
   private convenioModel: ConvenioColectivoModelDTO;
   private convenioSubscribe: Subscription;

   public constructor(protected entityDTO: TipoEntregaDTO) {
      super(entityDTO);
   }

   public static getTipoUnidadDevolucion(): string[] {
      return EntityModelDTO.getEnumArray(TipoUnidadDevolucion);
   }

   public static getTipoDevolucionEntrega(): string[] {
      return EntityModelDTO.getEnumArray(TipoDevolucionEntrega);
   }

   public setEntityDTO(entityDTO: TipoEntregaDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.categoriaModel = new CategoriaEntregaBasicaModelDTO(this.entityDTO.categoria);
      this.categoriaSubscribe = this.categoriaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.subcategoriaModel = new SubcategoriaEntregaModelDTO(this.entityDTO.subcategoria);
      this.subcategoriaSubscribe = this.subcategoriaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.convenioModel = new ConvenioColectivoModelDTO(this.entityDTO.convenio);
      this.convenioSubscribe = this.convenioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.categoriaModel.dispose();
      this.categoriaSubscribe.unsubscribe();
      this.subcategoriaModel.dispose();
      this.subcategoriaSubscribe.unsubscribe();
      this.convenioModel.dispose();
      this.convenioSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get TiempoDevolucion(): number { return this.entityDTO.tiempoDevolucion; }
   set TiempoDevolucion(value: number) { this.notifyChangeDTO('tiempoDevolucion', value); }

   get Marca(): string { return this.entityDTO.marca; }
   set Marca(value: string) { this.notifyChangeDTO('marca', value); }

   get Modelo(): string { return this.entityDTO.modelo; }
   set Modelo(value: string) { this.notifyChangeDTO('modelo', value); }

   get PoseeCertificacion(): boolean { return this.entityDTO.poseeCertificacion; }
   set PoseeCertificacion(value: boolean) { this.notifyChangeDTO('poseeCertificacion', value); }

   get UnidadDevolucion(): string { return TipoUnidadDevolucion[this.entityDTO.unidadDevolucion]; }
   set UnidadDevolucion(value: string) { this.notifyChangeDTO('unidadDevolucion', TipoUnidadDevolucion[value]); }

   get CategoriaModel(): CategoriaEntregaBasicaModelDTO { return this.categoriaModel; }
   get Categoria(): CategoriaEntregaBasicaDTO { return this.categoriaModel.getEntityDTO(); }
   set Categoria(value: CategoriaEntregaBasicaDTO) { this.notifyChange(() => { this.entityDTO.categoria = value; this.categoriaModel.setEntityDTO(value); }); }

   get SubcategoriaModel(): SubcategoriaEntregaModelDTO { return this.subcategoriaModel; }
   get Subcategoria(): SubcategoriaEntregaDTO { return this.subcategoriaModel.getEntityDTO(); }
   set Subcategoria(value: SubcategoriaEntregaDTO) { this.notifyChange(() => { this.entityDTO.subcategoria = value; this.subcategoriaModel.setEntityDTO(value); }); }

   get TipoDevolucion(): string { return TipoDevolucionEntrega[this.entityDTO.tipoDevolucion]; }
   set TipoDevolucion(value: string) { this.notifyChangeDTO('tipoDevolucion', TipoDevolucionEntrega[value]); }

   get DiasPrevioAviso(): number { return this.entityDTO.diasPrevioAviso; }
   set DiasPrevioAviso(value: number) { this.notifyChangeDTO('diasPrevioAviso', value); }

   get Cargos(): Array<CargoDTO> { return this.entityDTO.cargos; }
   set Cargos(value: Array<CargoDTO>) { this.notifyChangeDTO('cargos', value); }

   get ConvenioModel(): ConvenioColectivoModelDTO { return this.convenioModel; }
   get Convenio(): ConvenioColectivoDTO { return this.convenioModel.getEntityDTO(); }
   set Convenio(value: ConvenioColectivoDTO) { this.notifyChange(() => { this.entityDTO.convenio = value; this.convenioModel.setEntityDTO(value); }); }

   get AsignarColaboradoresExistentes(): boolean { return this.entityDTO.asignarColaboradoresExistentes; }
   set AsignarColaboradoresExistentes(value: boolean) { this.notifyChangeDTO('asignarColaboradoresExistentes', value); }

   get GenerarConstancia(): boolean { return this.entityDTO.generarConstancia; }
   set GenerarConstancia(value: boolean) { this.notifyChangeDTO('generarConstancia', value); }

   get SolicitarFirma(): boolean { return this.entityDTO.solicitarFirma; }
   set SolicitarFirma(value: boolean) { this.notifyChangeDTO('solicitarFirma', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
