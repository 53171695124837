import { HttpClient, HttpContext, HttpContextToken, HttpEvent, HttpEventType, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxNeoLoaderService } from 'src/app/shared/lib/ngx-neo-loader/public_api';

export const SHOW_LOADING = new HttpContextToken<boolean>(() => true);
const isHttpDownloadProgressEventGuard = (event: HttpEvent<any>): event is HttpEvent<any> & { partialText?: string } => {
  return event.type === HttpEventType.DownloadProgress && 'partialText' in event;
};

@Injectable()
export class HttpClientExtended extends HttpClient {
  constructor(
    handler: HttpHandler,
    private loaderService: NgxNeoLoaderService,
  ) {
    super(handler);
  }

  public async GetResponseByStreamingOnPost<T, Y = undefined>(
    url: string,
    data: any,
    progress: Subject<number>,
    options?: IStreamingOptions<Y>,
  ): Promise<T> {
    let spinnerSub: number;
    if (!options) {
      options = { showSpinner: true, blobResponse: false };
    }

    return new Promise((resolve, reject) => {
      const context = new HttpContext().set(SHOW_LOADING, options.showSpinner);
      const request = new HttpRequest('POST', url, data, {
        reportProgress: true,
        responseType: options.blobResponse ? 'blob' : options.jsonData ? 'text' : 'json',
        context,
      });

      var requestObservable = this.request<T>(request);
      let totalElements = 0;
      let actualElements = 0;
      let percentage = 0;
      let lastLoaded = 0;
      requestObservable.subscribe({
        next: (event) => {
          // progress
          if (event.type === HttpEventType.DownloadProgress) {
            if (options.jsonData && isHttpDownloadProgressEventGuard(event)) {
              let responseText = event.partialText.substring(lastLoaded);
              if (responseText === undefined) {
                return;
              }

              if (!totalElements) {
                const countMatch = responseText.match(/(?<="count":\s*)\d+/);
                totalElements = countMatch ? parseInt(countMatch[0], 10) : 0;
                const messageText = '"message": [';
                const posMessages = responseText.indexOf(messageText) + messageText.length;
                lastLoaded += posMessages;
                responseText = responseText.substring(posMessages);
              }

              let items: Y[] = null;
              // we need to check if json is valid because network could give us partial responses
              try {
                if (responseText.endsWith(' ] }')) {
                  responseText = responseText.substring(0, responseText.length - 4);
                }
                if (!responseText.startsWith('[')) {
                  responseText = '[ ' + (responseText.startsWith(', ') ? responseText.substring(2) : responseText);
                }
                if (!responseText.endsWith(']')) {
                  responseText += ' ]';
                }
                const parsedResponse = JSON.parse(responseText);
                items = parsedResponse && Array.isArray(parsedResponse) ? parsedResponse : null;
                lastLoaded = event.loaded;
              } catch (err) {
                /* ignore invalid json data */
              }

              if (items && items.length > 0 && totalElements) {
                actualElements += items.length;
                percentage = (actualElements / totalElements) * 100;
                options.jsonData(items);
              }
            } else {
              percentage = event.loaded - 13;
            }
            if (percentage <= 100) {
              progress.next(percentage);
            }
          }
          // finished
          if (event.type === HttpEventType.Response) {
            progress.next(100);

            return resolve(event.body);
          }
        },
        error: (error) => {
          try {
            this.loaderService.onCatch(error, null, spinnerSub);
          } catch {}
          reject(error);
        },
      });
    });
  }
}

export class StreamingResultDto<T> {
  public header = '';
  public message: T;
}

export interface IStreamingOptions<T> {
  showSpinner: boolean;
  blobResponse: boolean;
  jsonData?: (items: T[]) => void;
}
