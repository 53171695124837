import { BehaviorSubject, Observable, Subject, catchError, exhaustMap, finalize, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef } from '@angular/core';

export interface ThrottledObservableGeneratorData<T> {
  executionSubject$: Subject<T>;
  isExecuting$: Observable<boolean>;
}

export const throttledObservableGenerator = <T>(
  action: (args: T) => Observable<unknown>,
  destroyRef: DestroyRef,
): ThrottledObservableGeneratorData<T> => {
  const executionSubject$ = new Subject<T>();
  const isExecuting = new BehaviorSubject<boolean>(false);

  executionSubject$
    .pipe(
      exhaustMap((args) => {
        isExecuting.next(true);
        return action(args).pipe(
          takeUntilDestroyed(destroyRef),
          finalize(() => isExecuting.next(false)),
          catchError((error: unknown) => of({ error })),
        );
      }),
    )
    .subscribe((res: unknown) => {
      if (typeof res === 'object' && res !== null && 'error' in res) {
        throw res.error;
      }
    });

  return { executionSubject$, isExecuting$: isExecuting.asObservable() } as const;
};
