import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { TipoCuestionario } from 'src/app/ModelDTO/DTO/tipoCuestionario.ENUM';
import { TipoEvaluacionCuestionario } from 'src/app/ModelDTO/DTO/tipoEvaluacionCuestionario.ENUM';


export class CuestionarioBasicoDTO implements IEntityDTO {

   creador: PersonalLegajoBasicoDTO;
   fechaCreacion: Date;
   tipo: TipoCuestionario;
   nombre = '';
   descripcion = '';
   image = '';
   descripcionPie = '';
   datosFirmaColaborador: boolean;
   evaluationType: TipoEvaluacionCuestionario;
   evaluacionRegular = 0;
   evaluacionDesaprobada = 0;
   deSistema: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.creador = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.creador != null) { this.creador.PrepareDTO(jsonObj.creador); }
      if (jsonObj.fechaCreacion != null) { this.fechaCreacion = new Date(jsonObj.fechaCreacion); }
      if (jsonObj.tipo != null) { this.tipo = jsonObj.tipo as TipoCuestionario; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.descripcionPie != null) { this.descripcionPie = jsonObj.descripcionPie; }
      if (jsonObj.datosFirmaColaborador != null) { this.datosFirmaColaborador = jsonObj.datosFirmaColaborador; }
      if (jsonObj.evaluationType != null) { this.evaluationType = jsonObj.evaluationType as TipoEvaluacionCuestionario; }
      if (jsonObj.evaluacionRegular != null) { this.evaluacionRegular = jsonObj.evaluacionRegular; }
      if (jsonObj.evaluacionDesaprobada != null) { this.evaluacionDesaprobada = jsonObj.evaluacionDesaprobada; }
      if (jsonObj.deSistema != null) { this.deSistema = jsonObj.deSistema; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
