/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { NameDetailDTO } from 'src/app/api/interfaces/name-detail.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { CommentReactionDTO } from 'src/app/api/interfaces/comment-reaction.interface';
import { CurrentReactionDTO } from 'src/app/api/interfaces/current-reaction.interface';
import { FeedbackDTO } from 'src/app/api/interfaces/feedback.interface';
import { NewCommentReactionDTO } from 'src/app/api/interfaces/new-comment-reaction.interface';
import { PersonalFeedbackDTO } from 'src/app/api/interfaces/personal-feedback.interface';
import { SortFeedback } from 'src/app/api/enums/sort-feedback.enum';

@Injectable({
   providedIn: 'root'
})
export class FeedbackBackendService {

   constructor(protected http: HttpClient) { }


   public getFeedbackIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/feedback/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getFeedback(params: { pageNumber: number, pageSize: number, teammateId?: number, feedbackTypeId?: number, sortActive?: SortFeedback, isAsc?: boolean, all?: boolean }, ): Observable<PersonalFeedbackDTO[]> {
      const { pageNumber, pageSize, teammateId = 0, feedbackTypeId = 0, sortActive = 0, isAsc = false, all = false } = params;
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/feedback/' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&teammateId=' + teammateId +
            '&feedbackTypeId=' + feedbackTypeId +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc +
            '&all=' + all).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public getFeedbackConFeedback(pageNumber: number, pageSize: number): Observable<PersonalFeedbackDTO[]> {
      return this.http.get<DataDTO<PersonalFeedbackDTO>>(Constants.apiURL + '/feedback/conFeedback' + '?pageNumber=' + pageNumber +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalFeedbackDTO[]));
   }

   public getFeedbackPersonalLegajoId(params: { id: number, fechaDesde: Date, fechaHasta: Date, todas?: boolean }, ): Observable<FeedbackDTO[]> {
      const { id, fechaDesde, fechaHasta, todas = true } = params;
      return this.http.get<DataDTO<FeedbackDTO>>(Constants.apiURL + '/feedback/personalLegajo/' + id + '?fechaDesde=' + (fechaDesde?.toISOString() ?? 'undefined') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? 'undefined') +
            '&todas=' + todas).pipe(map((res) => convertJsonDates(res.data) as FeedbackDTO[]));
   }

   public getFeedbackId(id: number): Observable<PersonalFeedbackDTO> {
      return this.http.get<PersonalFeedbackDTO>(Constants.apiURL + '/feedback/' + id).pipe(map((res) => convertJsonDates(res) as PersonalFeedbackDTO ));
   }

   public insertFeedbackPersonalLegajoId(id: number, feedbackDTO: FormData): Observable<FeedbackDTO> {
      return this.http.post<FeedbackDTO>(Constants.apiURL + '/feedback/personalLegajo/' + id, feedbackDTO).pipe(map((res) => convertJsonDates(res) as FeedbackDTO ));
   }

   public deleteFeedbackId(id: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/feedback/' + id);
   }
   public getFeedbackIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/feedback/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertFeedbackIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/feedback/' + id + '/comentarios', stream);
   }
   public updateFeedbackIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/feedback/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteFeedbackIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/feedback/' + id + '/comentarios/' + iDComentario);
   }
   public getFeedbackIdReactions(id: number): Observable<CommentReactionDTO[]> {
      return this.http.get<DataDTO<CommentReactionDTO>>(Constants.apiURL + '/feedback/' + id + '/reactions').pipe(map((res) => convertJsonDates(res.data) as CommentReactionDTO[]));
   }

   public updateFeedbackIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
      return this.http.put<CurrentReactionDTO>(Constants.apiURL + '/feedback/' + id + '/reactions', newCommentReactionDTO).pipe(map((res) => convertJsonDates(res) as CurrentReactionDTO ));
   }

   public deleteFeedbackIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
      return this.http.delete<CurrentReactionDTO>(Constants.apiURL + '/feedback/' + id + '/reactions/' + emojiId).pipe(map((res) => convertJsonDates(res) as CurrentReactionDTO ));
   }
}
