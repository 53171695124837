import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ngxEmojiTranslate',
  standalone: true,
})
export class NgxEmojiTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public transform(value: string): unknown {
    return {
      search: this.translateService.instant('REACTIONS.SEARCH'),
      notfound: this.translateService.instant('REACTIONS.NOTFOUND'),
      categories: {
        search: this.translateService.instant('REACTIONS.CATEGORIES.SEARCH'),
        recent: this.translateService.instant('REACTIONS.CATEGORIES.RECENT'),
        people: this.translateService.instant('REACTIONS.CATEGORIES.PEOPLE'),
        nature: this.translateService.instant('REACTIONS.CATEGORIES.NATURE'),
        foods: this.translateService.instant('REACTIONS.CATEGORIES.FOODS'),
        activity: this.translateService.instant('REACTIONS.CATEGORIES.ACTIVITY'),
        places: this.translateService.instant('REACTIONS.CATEGORIES.PLACES'),
        objects: this.translateService.instant('REACTIONS.CATEGORIES.OBJECTS'),
        symbols: this.translateService.instant('REACTIONS.CATEGORIES.SYMBOLS'),
        flags: this.translateService.instant('REACTIONS.CATEGORIES.FLAGS'),
      },
    };
  }
}
