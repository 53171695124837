import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { OficinaDTO, PersonalAreaDTO, PersonalDTO, PersonalSectorDTO } from 'src/app/ModelDTO/DTO';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PersonalExtendModel } from './PersonalExtend.model';

@Injectable({
  providedIn: 'root',
})
export class TeammatesListService {
  public userIndex: number;
  public userFilter: string;
  public oficinaFilter: OficinaDTO;
  public businessNameFilter: BusinessNameDTO;
  public areaFilter: PersonalAreaDTO;
  public sectorFilter: PersonalSectorDTO;
  public arrayPersonalFiltrado: PersonalExtendModel[] = [];
  // Colaboradores
  public arrayPersonal: PersonalExtendModel[] = [];
  public arrayModificado: PersonalExtendModel[] = [];
  public mostrarBaja: boolean;

  public sinResultados: boolean;

  constructor(protected headerService: HeaderAppService) {
    this.initViewModel();

    this.headerService.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => this.clearViewModel());
  }

  public initViewModel(): void {
    this.clearViewModel();
    this.listaConNoActivos(true);
  }

  public actualizarColaborador(personal: PersonalDTO): void {
    const colaborador: PersonalExtendModel = this.arrayPersonal.find((x) => x.id === personal.id);
    if (colaborador !== undefined) {
      colaborador.fechaIngreso = personal.legajo.fechaIngreso;
      colaborador.nombreCompleto = personal.nombreCompleto;
      if (personal.legajo.puestos.length > 0) {
        colaborador.area = personal.legajo.puestos[0].area.nombre;
        colaborador.cargo = personal.legajo.puestos[0].cargo.nombre;
      }
      colaborador.image = personal.image;
      colaborador.activo = personal.legajo.estadoActual.tipo.esAlta;
      colaborador.profileCompleteness = personal.profileCompleteness;
      this.arrayPersonal = [...this.arrayPersonal];
    }
  }

  public listaConNoActivos(baja: boolean): void {
    this.mostrarBaja = baja;
    if (baja) {
      this.arrayModificado = this.arrayPersonal.filter((arr) => arr.activo);
    } else {
      this.arrayModificado = this.arrayPersonal;
    }
  }

  public onFilterChanged(): void {
    this.arrayPersonalFiltrado = this.arrayModificado;

    if (this.userFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => {
        const searchValue = `${colaborador.nombreCompleto} ${colaborador.dni} ${colaborador.cuil} ${colaborador.legajo}`.toLowerCase();

        return searchValue.includes(this.userFilter?.toLowerCase());
      });
    }

    if (this.oficinaFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter(
        (colaborador) => colaborador.oficinaNombre === this.oficinaFilter.nombre,
      );
    }

    if (this.businessNameFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter(
        (colaborador) => colaborador.businessNameId === this.businessNameFilter.id,
      );
    }

    if (this.businessNameFilter) {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter(
        (colaborador) => colaborador.businessNameId === this.businessNameFilter.id,
      );
    }

    if (this.areaFilter && this.areaFilter.nombre !== 'Todas') {
      this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => colaborador.area === this.areaFilter.nombre);
      if (this.sectorFilter && this.sectorFilter.nombre !== 'Todos') {
        this.arrayPersonalFiltrado = this.arrayPersonalFiltrado.filter((colaborador) => colaborador.sector === this.sectorFilter.nombre);
      }
    }

    this.sinResultados = this.arrayPersonalFiltrado.length <= 0;
  }

  private clearViewModel(): void {
    this.userIndex = 0;
    this.userFilter = '';
    this.oficinaFilter = undefined;
    this.businessNameFilter = undefined;
    this.sinResultados = false;
    this.areaFilter = undefined;
    this.sectorFilter = undefined;
    this.arrayModificado = [];
  }
}
