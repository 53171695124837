import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO/resultadoImportacion.DTO';

@Injectable({
   providedIn: 'root'
})
export class ImportacionCuentaCorrienteServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getImportacionesCuentaCorrienteIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/importacionesCuentaCorriente/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertImportacionesCuentaCorriente(stream: FormData): Promise<ResultadoImportacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/importacionesCuentaCorriente/', stream));
         const resDTO = new ResultadoImportacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
