import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DayOfWeek } from 'src/app/ModelDTO/DTO';
import { WeekNumber } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/administrador-planificacion-cuestionario.component';
import { TypeMonthlyPlanningComponent } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/monthly/tipo-planificacion-mensual/typeMonthlyPlanning.component';

@Component({
  selector: 'app-day-week',
  templateUrl: './day-week.component.html',
  styleUrls: ['./day-week.component.scss'],
})
export class DayWeekComponent implements TypeMonthlyPlanningComponent {
  @Input() public planningForm: UntypedFormGroup;
  @Input() public weekNumber: WeekNumber[];

  public dayOfWeek = DayOfWeek;
}
