import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class EstadisticasArtDTO implements IEntityDTO {

   accidentesMenosCobertura = 0;
   accidentadosPromedio = 0;
   siniestralidadPromedio = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.accidentesMenosCobertura != null) { this.accidentesMenosCobertura = jsonObj.accidentesMenosCobertura; }
      if (jsonObj.accidentadosPromedio != null) { this.accidentadosPromedio = jsonObj.accidentadosPromedio; }
      if (jsonObj.siniestralidadPromedio != null) { this.siniestralidadPromedio = jsonObj.siniestralidadPromedio; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
