import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { UserBasicDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { SignatureStatusType } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { SignatureType } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class AttachmentSignatureUaalooDTO implements IEntityDTO {

   ownerPersonalLegajo: PersonalLegajoBasicoDTO;
   requesterPersonalLegajo: PersonalLegajoBasicoDTO;
   ownerUser: UserBasicDTO;
   requesterUser: UserBasicDTO;
   status: SignatureStatusType;
   type: SignatureType;
   isCreator: boolean;
   requestDate: Date;
   signDate: Date;
   asBussiness: boolean;
   notify: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.ownerPersonalLegajo = new PersonalLegajoBasicoDTO();
      this.requesterPersonalLegajo = new PersonalLegajoBasicoDTO();
      this.ownerUser = new UserBasicDTO();
      this.requesterUser = new UserBasicDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.ownerPersonalLegajo != null) { this.ownerPersonalLegajo.PrepareDTO(jsonObj.ownerPersonalLegajo); }
      if (jsonObj.requesterPersonalLegajo != null) { this.requesterPersonalLegajo.PrepareDTO(jsonObj.requesterPersonalLegajo); }
      if (jsonObj.ownerUser != null) { this.ownerUser.PrepareDTO(jsonObj.ownerUser); }
      if (jsonObj.requesterUser != null) { this.requesterUser.PrepareDTO(jsonObj.requesterUser); }
      if (jsonObj.status != null) { this.status = jsonObj.status as SignatureStatusType; }
      if (jsonObj.type != null) { this.type = jsonObj.type as SignatureType; }
      if (jsonObj.isCreator != null) { this.isCreator = jsonObj.isCreator; }
      if (jsonObj.requestDate != null) { this.requestDate = new Date(jsonObj.requestDate); }
      if (jsonObj.signDate != null) { this.signDate = new Date(jsonObj.signDate); }
      if (jsonObj.asBussiness != null) { this.asBussiness = jsonObj.asBussiness; }
      if (jsonObj.notify != null) { this.notify = jsonObj.notify; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
