import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TipoPlanificacionMensual } from 'src/app/ModelDTO/DTO/tipoPlanificacionMensual.ENUM';
import { WeekNumber } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/administrador-planificacion-cuestionario.component';
import { TypeRecurrenceComponent } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/typeRecurrence.component';

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss'],
})
export class MonthlyComponent implements TypeRecurrenceComponent {
  @Input() public planningForm: UntypedFormGroup;
  @Input() public minDate: Date;
  @Input() public weekNumber: WeekNumber[];

  public readonly tipoPlanificacionMensual = TipoPlanificacionMensual;
}
