import { Injectable } from '@angular/core';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class PersonalEntityService {
  constructor(private personal: PersonalLegajoServiceBackend) {}

  public async getEntitiesByNombre(search: string, todos: boolean): Promise<Array<PersonalLegajoBasicoDTO>> {
    const res = await this.personal.getPersonalLegajosAutoComplete(search, 30, todos);
    return res;
  }

  public async getEntitiesByDniCuil(search: string): Promise<Array<PersonalLegajoBasicoDTO>> {
    const res = await this.personal.getPersonalLegajosAutoCompleteDniCuil(search, 30);
    return res;
  }

  public async getEntities(search: string, all: boolean): Promise<Array<PersonalLegajoBasicoDTO>> {
    const res = await this.personal.getPersonalLegajosSearch(search, all);
    return res;
  }
}
