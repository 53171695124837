import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionStatusService } from 'src/app/shared/services/connectionStatus.service';

@Component({
  selector: 'neo-connection-checker',
  templateUrl: './connection-checker.component.html',
  styleUrls: ['./connection-checker.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class ConnectionCheckerComponent {
  constructor(public connectionStatusService: ConnectionStatusService) {}
}
