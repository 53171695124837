import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { SolicitudCapacitacionDTO } from 'src/app/ModelDTO/DTO/solicitudCapacitacion.DTO';
import { SolicitudCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/solicitudCapacitacionFlat.DTO';

@Injectable({
   providedIn: 'root'
})
export class SolicitudCapacitacionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getSolicitudCapacitacionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudCapacitacion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertSolicitudCapacitacion(solicitudCapacitacionDTO: FormData): Promise<SolicitudCapacitacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/solicitudCapacitacion/', solicitudCapacitacionDTO));
         const resDTO = new SolicitudCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudCapacitacionId(id: number, solicitudCapacitacionDTO: FormData): Promise<SolicitudCapacitacionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudCapacitacion/' + id, solicitudCapacitacionDTO));
         const resDTO = new SolicitudCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudCapacitacionPorSolicitudId(id: number): Promise<SolicitudCapacitacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudCapacitacion/porSolicitud/' + id));
         if (!res) { return null; }
         const resDTO = new SolicitudCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateSolicitudCapacitacionIdEstado(id: number, solicitudCapacitacionDTO: SolicitudCapacitacionDTO): Promise<SolicitudCapacitacionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/solicitudCapacitacion/' + id + '/estado', solicitudCapacitacionDTO));
         const resDTO = new SolicitudCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getSolicitudCapacitacionFiltradas(fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, estado: number = 0, personal: number = 0): Promise<Array<SolicitudCapacitacionFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/solicitudCapacitacion/filtradas' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&estado=' + estado + '&personal=' + personal));
         const resJson = res.data;
         const resDTO = new Array<SolicitudCapacitacionFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new SolicitudCapacitacionFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getSolicitudCapacitacionIdPersonalCapacitacion(idPersonalCapacitacion: number): Promise<SolicitudCapacitacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/solicitudCapacitacion/teammate-training/' + idPersonalCapacitacion));
         if (!res) { return null; }
         const resDTO = new SolicitudCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteSolicitudCapacitacionIdSolicitudCancelar(idSolicitud: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/solicitudCapacitacion/' + idSolicitud + '/cancelar'));

   }

}
