import { HealthInsuranceCompanyDTO } from '@api/interfaces/health-insurance-company.interface';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { EmpresaBasicoDTO } from 'src/app/ModelDTO/DTO/empresaBasico.DTO';
import { EstadoCivil } from 'src/app/ModelDTO/DTO/estadoCivil.ENUM';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalCambioEstadoBasicoDTO } from 'src/app/ModelDTO/DTO/personalCambioEstadoBasico.DTO';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { RelationshipPositionEnum } from 'src/app/ModelDTO/DTO/relationshipPositionEnum.ENUM';
import { TipoContratacion } from 'src/app/ModelDTO/DTO/tipoContratacion.ENUM';
import { TipoDocumento } from 'src/app/ModelDTO/DTO/tipoDocumento.ENUM';
import { TipoGenero } from 'src/app/ModelDTO/DTO/tipoGenero.ENUM';
import { IEntityDTO, RoleBasicDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class PersonalLegajoDTO implements IEntityDTO {

   perfil: RoleBasicDTO;
   fechaIngreso: Date;
   fechaSalida: Date;
   categoriaContratacion: PersonalCategoriaDTO;
   subcategoriaContratacion: PersonalSubcategoriaDTO;
   puestos: Array<PuestoDTO>;
   representanteGremial: boolean;
   inicioRepresentacionGremial: Date;
   finRepresentacionGremial: Date;
   emailEmpresa = '';
   antiguedad = 0;
   sueldoBruto = 0;
   currency: CurrencyDTO;
   estadoActual: PersonalCambioEstadoBasicoDTO;
   empresa: EmpresaBasicoDTO;
   oficina: OficinaDTO;
   legajo = '';
   contratacion: TipoContratacion;
   modalidad: ModalidadContractualDTO;
   esSupervisor: boolean;
   esAdmin = 0;
   internNumber: number;
   tieneCertificado: boolean;
   image = '';
   nombre = '';
   apellido = '';
   nombreCompleto = '';
   dni = '';
   tipoDocumento: TipoDocumento;
   fechaNacimiento: Date;
   genero: TipoGenero;
   cuil = '';
   telefonos = '';
   email = '';
   confirmarEmail: boolean;
   edad: number;
   peso: number;
   altura: number;
   circunferenciaCabeza: number;
   tallePantalon = '';
   talleCalzado = '';
   talleCamisa = '';
   movilidadPropia: boolean;
   disponibilidadTraslado: boolean;
   oficinaNombre = '';
   estadoCivil: EstadoCivil;
   familiaresACargo: number;
   ultimoSueldo: number;
   grupoSanguineo = '';
   nroCuenta = '';
   alias = '';
   relationshipPosition: RelationshipPositionEnum;
   businessName: BusinessNameDTO | undefined;
   hrArea: PersonalAreaDTO | undefined;
   healthInsuranceCompany?: HealthInsuranceCompanyDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.perfil = new RoleBasicDTO();
      this.categoriaContratacion = new PersonalCategoriaDTO();
      this.subcategoriaContratacion = new PersonalSubcategoriaDTO();
      this.puestos = new Array<PuestoDTO>();
      this.currency = new CurrencyDTO();
      this.estadoActual = new PersonalCambioEstadoBasicoDTO();
      this.empresa = new EmpresaBasicoDTO();
      this.oficina = new OficinaDTO();
      this.modalidad = new ModalidadContractualDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.perfil != null) { this.perfil.PrepareDTO(jsonObj.perfil); }
      if (jsonObj.fechaIngreso != null) { this.fechaIngreso = new Date(jsonObj.fechaIngreso); }
      if (jsonObj.fechaSalida != null) { this.fechaSalida = new Date(jsonObj.fechaSalida); }
      if (jsonObj.categoriaContratacion != null) { this.categoriaContratacion.PrepareDTO(jsonObj.categoriaContratacion); }
      if (jsonObj.subcategoriaContratacion != null) { this.subcategoriaContratacion.PrepareDTO(jsonObj.subcategoriaContratacion); }
      if (jsonObj.puestos != null) { for (const item of jsonObj.puestos) { const itemDTO = new PuestoDTO(); itemDTO.PrepareDTO(item); this.puestos.push(itemDTO); } }
      if (jsonObj.representanteGremial != null) { this.representanteGremial = jsonObj.representanteGremial; }
      if (jsonObj.inicioRepresentacionGremial != null) { this.inicioRepresentacionGremial = new Date(jsonObj.inicioRepresentacionGremial); }
      if (jsonObj.finRepresentacionGremial != null) { this.finRepresentacionGremial = new Date(jsonObj.finRepresentacionGremial); }
      if (jsonObj.emailEmpresa != null) { this.emailEmpresa = jsonObj.emailEmpresa; }
      if (jsonObj.antiguedad != null) { this.antiguedad = jsonObj.antiguedad; }
      if (jsonObj.sueldoBruto != null) { this.sueldoBruto = jsonObj.sueldoBruto; }
      if (jsonObj.currency != null) { this.currency.PrepareDTO(jsonObj.currency); }
      if (jsonObj.estadoActual != null) { this.estadoActual.PrepareDTO(jsonObj.estadoActual); }
      if (jsonObj.empresa != null) { this.empresa.PrepareDTO(jsonObj.empresa); }
      if (jsonObj.oficina != null) { this.oficina.PrepareDTO(jsonObj.oficina); }
      if (jsonObj.legajo != null) { this.legajo = jsonObj.legajo; }
      if (jsonObj.contratacion != null) { this.contratacion = jsonObj.contratacion as TipoContratacion; }
      if (jsonObj.modalidad != null) { this.modalidad.PrepareDTO(jsonObj.modalidad); }
      if (jsonObj.esSupervisor != null) { this.esSupervisor = jsonObj.esSupervisor; }
      if (jsonObj.esAdmin != null) { this.esAdmin = jsonObj.esAdmin; }
      if (jsonObj.internNumber != null) { this.internNumber = jsonObj.internNumber; }
      if (jsonObj.tieneCertificado != null) { this.tieneCertificado = jsonObj.tieneCertificado; }
      if (jsonObj.image != null) { this.image = jsonObj.image; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.apellido != null) { this.apellido = jsonObj.apellido; }
      if (jsonObj.nombreCompleto != null) { this.nombreCompleto = jsonObj.nombreCompleto; }
      if (jsonObj.dni != null) { this.dni = jsonObj.dni; }
      if (jsonObj.tipoDocumento != null) { this.tipoDocumento = jsonObj.tipoDocumento as TipoDocumento; }
      if (jsonObj.fechaNacimiento != null) { this.fechaNacimiento = new Date(jsonObj.fechaNacimiento); }
      if (jsonObj.genero != null) { this.genero = jsonObj.genero as TipoGenero; }
      if (jsonObj.cuil != null) { this.cuil = jsonObj.cuil; }
      if (jsonObj.telefonos != null) { this.telefonos = jsonObj.telefonos; }
      if (jsonObj.email != null) { this.email = jsonObj.email; }
      if (jsonObj.confirmarEmail != null) { this.confirmarEmail = jsonObj.confirmarEmail; }
      if (jsonObj.edad != null) { this.edad = jsonObj.edad; }
      if (jsonObj.peso != null) { this.peso = jsonObj.peso; }
      if (jsonObj.altura != null) { this.altura = jsonObj.altura; }
      if (jsonObj.circunferenciaCabeza != null) { this.circunferenciaCabeza = jsonObj.circunferenciaCabeza; }
      if (jsonObj.tallePantalon != null) { this.tallePantalon = jsonObj.tallePantalon; }
      if (jsonObj.talleCalzado != null) { this.talleCalzado = jsonObj.talleCalzado; }
      if (jsonObj.talleCamisa != null) { this.talleCamisa = jsonObj.talleCamisa; }
      if (jsonObj.movilidadPropia != null) { this.movilidadPropia = jsonObj.movilidadPropia; }
      if (jsonObj.disponibilidadTraslado != null) { this.disponibilidadTraslado = jsonObj.disponibilidadTraslado; }
      if (jsonObj.oficinaNombre != null) { this.oficinaNombre = jsonObj.oficinaNombre; }
      if (jsonObj.estadoCivil != null) { this.estadoCivil = jsonObj.estadoCivil as EstadoCivil; }
      if (jsonObj.familiaresACargo != null) { this.familiaresACargo = jsonObj.familiaresACargo; }
      if (jsonObj.ultimoSueldo != null) { this.ultimoSueldo = jsonObj.ultimoSueldo; }
      if (jsonObj.grupoSanguineo != null) { this.grupoSanguineo = jsonObj.grupoSanguineo; }
      if (jsonObj.nroCuenta != null) { this.nroCuenta = jsonObj.nroCuenta; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.relationshipPosition != null) { this.relationshipPosition = jsonObj.relationshipPosition as RelationshipPositionEnum; }
      if (jsonObj.businessName != null) { this.businessName = new BusinessNameDTO(); this.businessName.PrepareDTO(jsonObj.businessName); }
      if (jsonObj.hrArea != null) { this.hrArea = new PersonalAreaDTO(); this.hrArea.PrepareDTO(jsonObj.hrArea); }
      if (jsonObj.healthInsuranceCompany != null) { this.healthInsuranceCompany = jsonObj.healthInsuranceCompany }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
