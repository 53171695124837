import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { CuestionarioPreguntaDTO } from 'src/app/ModelDTO/DTO/cuestionarioPregunta.DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { TipoCuestionario } from 'src/app/ModelDTO/DTO/tipoCuestionario.ENUM';
import { TipoEvaluacionCuestionario } from 'src/app/ModelDTO/DTO/tipoEvaluacionCuestionario.ENUM';


export class CuestionarioModelDTO extends EntityModelDTO<CuestionarioDTO> {

   private creadorModel: PersonalLegajoBasicoModelDTO;
   private creadorSubscribe: Subscription;

   public constructor(protected entityDTO: CuestionarioDTO) {
      super(entityDTO);
   }

   public static getTipoCuestionario(): string[] {
      return EntityModelDTO.getEnumArray(TipoCuestionario);
   }

   public static getTipoEvaluacionCuestionario(): string[] {
      return EntityModelDTO.getEnumArray(TipoEvaluacionCuestionario);
   }

   public setEntityDTO(entityDTO: CuestionarioDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.creadorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.creador);
      this.creadorSubscribe = this.creadorModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.creadorModel.dispose();
      this.creadorSubscribe.unsubscribe();
   }

   get Preguntas(): Array<CuestionarioPreguntaDTO> { return this.entityDTO.preguntas; }
   set Preguntas(value: Array<CuestionarioPreguntaDTO>) { this.notifyChangeDTO('preguntas', value); }

   get LevelRanges(): Array<LevelRangeDTO> { return this.entityDTO.levelRanges; }
   set LevelRanges(value: Array<LevelRangeDTO>) { this.notifyChangeDTO('levelRanges', value); }

   get CreadorModel(): PersonalLegajoBasicoModelDTO { return this.creadorModel; }
   get Creador(): PersonalLegajoBasicoDTO { return this.creadorModel.getEntityDTO(); }
   set Creador(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.creador = value; this.creadorModel.setEntityDTO(value); }); }

   get FechaCreacion(): Date { return this.entityDTO.fechaCreacion; }
   set FechaCreacion(value: Date) { this.notifyChangeDTO('fechaCreacion', value); }

   get Tipo(): string { return TipoCuestionario[this.entityDTO.tipo]; }
   set Tipo(value: string) { this.notifyChangeDTO('tipo', TipoCuestionario[value]); }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get Image(): string { return this.entityDTO.image; }
   set Image(value: string) { this.notifyChangeDTO('image', value); }

   get DescripcionPie(): string { return this.entityDTO.descripcionPie; }
   set DescripcionPie(value: string) { this.notifyChangeDTO('descripcionPie', value); }

   get DatosFirmaColaborador(): boolean { return this.entityDTO.datosFirmaColaborador; }
   set DatosFirmaColaborador(value: boolean) { this.notifyChangeDTO('datosFirmaColaborador', value); }

   get EvaluationType(): string { return TipoEvaluacionCuestionario[this.entityDTO.evaluationType]; }
   set EvaluationType(value: string) { this.notifyChangeDTO('evaluationType', TipoEvaluacionCuestionario[value]); }

   get DeSistema(): boolean { return this.entityDTO.deSistema; }
   set DeSistema(value: boolean) { this.notifyChangeDTO('deSistema', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
