import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ConfiguracionDTO } from 'src/app/ModelDTO/DTO';
import { ConfiguracionServiceBackend } from 'src/app/ServiceBackend';
import { GLOBAL_SETTINGS, GLOBAL_SETTINGS_TIMESTAMP } from 'src/app/shared/localStorageConstants';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  constructor(private configuracionServiceBackend: ConfiguracionServiceBackend) {}

  public initSettings(): void {
    const storedSettings = localStorage.getItem(GLOBAL_SETTINGS);

    if (!storedSettings || this.isOutdated()) {
      this.getSettings();
    }
  }

  public async getSettings(useCache: boolean = false): Promise<ConfiguracionDTO> {
    // Check for cached settings in localStorage
    if (useCache) {
      const storedSettings = localStorage.getItem(GLOBAL_SETTINGS);
      if (storedSettings) {
        return JSON.parse(storedSettings);
      }
    }

    try {
      // Try fetching from backend
      const res: ConfiguracionDTO[] = await this.configuracionServiceBackend.getConfiguracion();
      const settings = res && res.length ? res[0] : null;

      // Store the settings in localStorage for future use
      this.saveLocalSettings(settings);
      return settings;
    } catch (error) {
      const storedSettings = localStorage.getItem(GLOBAL_SETTINGS);

      if (!useCache && storedSettings) {
        return JSON.parse(storedSettings);
      }

      // If backend fails and no cached settings were used, reject the promise
      throw error;
    }
  }

  public async updateSettings(settingsId: number, settingsDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {
    const res = await this.configuracionServiceBackend.patchConfiguracionId(settingsId, settingsDTO);
    this.saveLocalSettings(res);
    return res;
  }

  public async updateSpecialPermissions(settingsDTO: ConfiguracionDTO): Promise<ConfiguracionDTO> {
    const res = await this.configuracionServiceBackend.updateConfiguracionIdPermisos(settingsDTO.id, settingsDTO);
    this.saveLocalSettings(res);
    return res;
  }

  private saveLocalSettings(settings: ConfiguracionDTO): void {
    localStorage.setItem(GLOBAL_SETTINGS, JSON.stringify(settings));
    localStorage.setItem(GLOBAL_SETTINGS_TIMESTAMP, DateTime.now().toISO());
  }

  private isOutdated(): boolean {
    const lastUpdated = localStorage.getItem(GLOBAL_SETTINGS_TIMESTAMP);
    if (!lastUpdated) return true;

    const oneWeekAgo = DateTime.now().minus({ weeks: 1 });
    return DateTime.fromISO(lastUpdated) < oneWeekAgo;
  }
}
