import { EstadisticaTotalesDTO } from 'src/app/ModelDTO/DTO/estadisticaTotales.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class EstadisticasPoblacionDTO implements IEntityDTO {

   totalPoblacion = 0;
   antiguedadPromedio = 0;
   edadPromedio = 0;
   cargaFamiliarPromedio = 0;
   femaleExpectedPercentage = 0;
   distribucionConvenio: Array<EstadisticaTotalesDTO>;
   distribucionSexo: Array<EstadisticaTotalesDTO>;
   distribucionEstadoCivil: Array<EstadisticaTotalesDTO>;
   distribucionContrato: Array<EstadisticaTotalesDTO>;
   distribucionEstudios: Array<EstadisticaTotalesDTO>;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.distribucionConvenio = new Array<EstadisticaTotalesDTO>();
      this.distribucionSexo = new Array<EstadisticaTotalesDTO>();
      this.distribucionEstadoCivil = new Array<EstadisticaTotalesDTO>();
      this.distribucionContrato = new Array<EstadisticaTotalesDTO>();
      this.distribucionEstudios = new Array<EstadisticaTotalesDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.totalPoblacion != null) { this.totalPoblacion = jsonObj.totalPoblacion; }
      if (jsonObj.antiguedadPromedio != null) { this.antiguedadPromedio = jsonObj.antiguedadPromedio; }
      if (jsonObj.edadPromedio != null) { this.edadPromedio = jsonObj.edadPromedio; }
      if (jsonObj.cargaFamiliarPromedio != null) { this.cargaFamiliarPromedio = jsonObj.cargaFamiliarPromedio; }
      if (jsonObj.femaleExpectedPercentage != null) { this.femaleExpectedPercentage = jsonObj.femaleExpectedPercentage; }
      if (jsonObj.distribucionConvenio != null) { for (const item of jsonObj.distribucionConvenio) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.distribucionConvenio.push(itemDTO); } }
      if (jsonObj.distribucionSexo != null) { for (const item of jsonObj.distribucionSexo) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.distribucionSexo.push(itemDTO); } }
      if (jsonObj.distribucionEstadoCivil != null) { for (const item of jsonObj.distribucionEstadoCivil) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.distribucionEstadoCivil.push(itemDTO); } }
      if (jsonObj.distribucionContrato != null) { for (const item of jsonObj.distribucionContrato) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.distribucionContrato.push(itemDTO); } }
      if (jsonObj.distribucionEstudios != null) { for (const item of jsonObj.distribucionEstudios) { const itemDTO = new EstadisticaTotalesDTO(); itemDTO.PrepareDTO(item); this.distribucionEstudios.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
