import { Component } from '@angular/core';
import { NgxNeoLoaderService } from './ngx-neo-loader.service';

@Component({
  selector: 'neo-app-loader',
  templateUrl: 'ngx-neo-loader.component.html',
  styleUrls: ['ngx-neo-loader.component.scss'],
})
export class LoaderComponent {
  public readonly $showLoading = this.loaderService.$isLoading;
  public readonly $blockUI = this.loaderService.$blockUI;

  constructor(private loaderService: NgxNeoLoaderService) {}
}
