<div class="d-flex flex-row flex-wrap selector-area-sector gap-1">
  <mat-form-field class="flex-grow-1" appearance="outline" matTooltipClass="break-line">
    <mat-label>{{ 'GENERAL.AREA' | translate }}</mat-label>
    <mat-select [compareWith]="compareId" (selectionChange)="onAreaChange()" [(ngModel)]="area" id="area" name="area" [disabled]="disabled">
      <mat-option *ngFor="let area of areas" [value]="area">{{ area.id === 0 ? ('GENERAL.ALL' | translate) : area.nombre }} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="flex-grow-1" appearance="outline" matTooltipClass="break-line">
    <mat-label>{{ 'GENERAL.SECTOR' | translate }}</mat-label>
    <mat-select
      [compareWith]="compareId"
      (selectionChange)="onSectorChange()"
      [disabled]="!area?.id || disabled"
      [(ngModel)]="sector"
      id="sec"
      name="sec"
    >
      <mat-option *ngFor="let sec of area?.sectores" [value]="sec">{{
        sec.id === 0 ? ('GENERAL.ALL' | translate) : sec.nombre
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
