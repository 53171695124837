'use strict';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { CtrCompleterDirective } from '../directives/ctr-completer';
import { MAX_CHARS, MIN_SEARCH_LENGTH, PAUSE, TEXT_NO_RESULTS, TEXT_SEARCHING } from '../globals';
import { CompleterData } from '../services/completer-data';
import { CompleterService } from '../services/completer-service';
import { CompleterItem } from './completer-item';

const noop = () => {
  return;
};

const COMPLETER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CompleterComponent),
  multi: true,
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'neo-completer-mat',
  templateUrl: 'completer-cmp.html',
  styleUrls: ['completer-cmp.scss'],
  providers: [COMPLETER_CONTROL_VALUE_ACCESSOR],
})
export class CompleterComponent implements OnInit, ControlValueAccessor, AfterViewChecked, AfterViewInit {
  @Input() public dataService: CompleterData;
  @Input() public required: boolean;
  @Input() public name: string;
  @Input() public inputName = '';
  @Input() public inputId = '';
  @Input() public pause = PAUSE;
  @Input() public minSearchLength = MIN_SEARCH_LENGTH;
  @Input() public maxChars = MAX_CHARS;
  @Input() public overrideSuggested = false;
  @Input() public clearSelected = false;
  @Input() public clearUnselected = false;
  @Input() public fillHighlighted = true;
  @Input() public label = '';
  @Input() public matchClass = 'match-class-default';
  @Input() public fieldTabindex: number;
  @Input() public autoMatch = false;
  @Input() public disableInput = false;
  @Input() public inputClass: string;
  @Input() public formFieldClass: string;
  @Input() public autofocus = false;
  @Input() public openOnFocus = false;
  @Input() public openOnClick = false;
  @Input() public selectOnClick = false;
  @Input() public initialValue: any;
  @Input() public autoHighlight = false;
  @Input() public autoSelectOnEnter = true;
  @Input() public appearance: MatFormFieldAppearance;
  @Input() public form: NgForm;
  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  @Input() public subscriptSizing: SubscriptSizing = 'fixed';

  @Output() selected = new EventEmitter<CompleterItem>();
  @Output() highlighted = new EventEmitter<CompleterItem>();
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('blur') blurEvent = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('focus') focusEvent = new EventEmitter();
  @Output() opened = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() keyup = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() keydown = new EventEmitter();

  @ViewChild(CtrCompleterDirective, { static: true }) public completer: CtrCompleterDirective;
  @ViewChild('ctrInput', { static: true }) public ctrInput: ElementRef;

  public searchStr = '';
  public control = new UntypedFormControl('');
  public displaySearching = true;
  public displayNoResults = true;
  public noResultsText = TEXT_NO_RESULTS;
  public searchingText = TEXT_SEARCHING;
  public completerItem: CompleterItem;
  public isOpen = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  private hasFocus = false;

  private onlyOnInit = true;

  constructor(
    private completerService: CompleterService,
    private cdr: ChangeDetectorRef,
  ) {}

  get value(): string | CompleterItem {
    return this.completerItem ? this.completerItem.originalObject : this.searchStr;
  }

  set value(v: CompleterItem | string) {
    if (typeof v === 'string') {
      this.searchStr = v;
    } else {
      this.completerItem = v;
    }
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.hasFocus = true;
    }
  }

  public ngAfterViewChecked(): void {
    if (this.hasFocus) {
      setTimeout(() => {
        if (this.ctrInput) {
          this.ctrInput.nativeElement.focus();
          this.hasFocus = false;
        }
      }, 0);
    }
  }

  public onTouched(): void {
    this.onTouchedCallback();
  }

  public writeValue(value: any): void {
    if (value && (value.id === 0 || value.id === 0)) value = null;
    if (value && this.dataService.convertToItem) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.completerItem = this.dataService.convertToItem!(value) as CompleterItem;
      this.searchStr = this.completerItem.title;
    } else {
      this.completerItem = null;
      this.searchStr = null;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disableInput = isDisabled;
    if (!this.form) {
      if (isDisabled) {
        this.control.disable({ onlySelf: false, emitEvent: true });
      } else {
        this.control.enable({ onlySelf: false, emitEvent: true });
      }
    }
    this.cdr.detectChanges();
  }

  @Input()
  public set datasource(source: CompleterData | string | Array<any>) {
    if (source) {
      if (source instanceof Array) {
        this.dataService = this.completerService.local(source);
      } else if (typeof source === 'string') {
        this.dataService = this.completerService.remote(source);
      } else {
        this.dataService = source;
      }
    }
  }

  @Input()
  public set textNoResults(text: string) {
    if (this.noResultsText !== text) {
      this.noResultsText = text;
      this.displayNoResults = !!this.noResultsText && this.noResultsText !== 'false';
    }
  }

  @Input()
  public set textSearching(text: string) {
    if (this.searchingText !== text) {
      this.searchingText = text;
      this.displaySearching = !!this.searchingText && this.searchingText !== 'false';
    }
  }

  public ngOnInit(): void {
    if ((this.required as any) === '') this.required = true;
    if (this.required) {
      this.control.setValidators(Validators.required);
    }
    if (this.form) {
      this.form.control.addControl(this.name, this.control);
      this.form.control.get(this.name).updateValueAndValidity();
    }

    this.completer.selected.subscribe((item: CompleterItem) => {
      this.completerItem = item;
      if (!this.completerItem) this.searchStr = undefined;
      this.onChangeCallback(this.completerItem ? this.completerItem.originalObject : null);
      this.selected.emit(item);
    });
    this.completer.highlighted.subscribe((item: CompleterItem) => {
      this.highlighted.emit(item);
    });
    this.completer.opened.subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;
      this.opened.emit(isOpen);
    });
  }

  public removeItem(): void {
    this.completerItem = undefined;
    this.searchStr = undefined;
    this.onChangeCallback(this.completerItem ? this.completerItem.originalObject : null);
    this.selected.emit(null);
    setTimeout(() => {
      if (this.ctrInput) {
        this.ctrInput.nativeElement.focus();
        this.hasFocus = false;
      }
    }, 10);
  }

  public onBlur(): void {
    this.blurEvent.emit();
    this.onTouched();
    this.cdr.detectChanges();
  }

  public onFocus(): void {
    this.focusEvent.emit();
  }

  public onClick(event: any): void {
    this.click.emit(event);
    this.onTouched();
  }

  public onKeyup(event: any): void {
    this.keyup.emit(event);
  }

  public onKeydown(event: any): void {
    this.onlyOnInit = false;
    this.keydown.emit(event);
  }

  public onChange(value: CompleterItem | string): void {
    this.value = value;
  }

  public open(): void {
    this.completer.open();
  }

  public close(): void {
    this.completer.clear();
  }

  public focus(): void {
    if (this.ctrInput) {
      this.ctrInput.nativeElement.focus();
    } else {
      this.hasFocus = true;
    }
  }

  public blur(): void {
    if (this.ctrInput) {
      this.ctrInput.nativeElement.blur();
    } else {
      this.hasFocus = false;
    }
  }
}
