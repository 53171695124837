import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormField, MatFormFieldAppearance } from '@angular/material/form-field';
import { DateTime } from 'luxon';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

const MY_FORMATS = {
  parse: {
    dateInput: ['dd/MM/yyyy', 'dd-MM-yyyy'],
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd MMM yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'neo-date-selector',
  templateUrl: './date-selector.component.html',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-Ar' },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateSelectorComponent implements OnInit, AfterViewInit {
  public _date: UntypedFormControl;
  public dateFormControl: UntypedFormControl;

  @Input() set date(date: Date) {
    if (date) {
      let dateTime = DateTime.fromJSDate(date);
      if (this.useCurrentLocaleOffset) {
        dateTime = this.adjustLocaleOffset(dateTime);
      }

      this._date.setValue(dateTime);
      const _dateCustom = dateTime.toFormat('ddMMyyyy');
      if (_dateCustom) {
        this.dateFormControl.setValue(_dateCustom);
      }
    } else if (this.enableClear) {
      this.dateFormControl.setValue(null);
      this._date.setValue(null);
    }
  }
  @Output() dateChange = new EventEmitter<Date>();

  @Input() min: Date;
  @Input() max: Date;
  @Input() id: string;
  @Input() name: string;
  @Input() formClass: string;
  @Input() inpClass: string;
  @Input() appearance: MatFormFieldAppearance;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() neoAutoFocus: boolean = false;
  @Input() required: boolean = false;
  @Input() set useCurrentLocaleOffset(value: boolean) {
    this.pUseCurrentLocaleOffset = value;
    let dateValue = this._date.value;
    if (value && dateValue) {
      dateValue = this.adjustLocaleOffset(dateValue);

      this._date.setValue(dateValue);
      const _dateCustom = dateValue.toFormat('ddMMyyyy');
      if (_dateCustom) {
        this.dateFormControl.setValue(_dateCustom);
      }

      this.emit(this.adjustDateTimeToJs(dateValue));
    }
  }
  @Input() form: NgForm;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.dateFormControl.disable();
      this._date.disable();
    } else {
      this._date.enable();
      this.dateFormControl.enable();
    }
  }
  @Input() onlyValidDate: boolean = false;
  @Input() infoDate: string;
  @Input() enableClear = false;

  @ViewChild('formField', { static: false }) formField: MatFormField;
  @ViewChild('formInp', { static: false }) formInp: ElementRef;
  @ViewChild('picker', { static: false }) picker: MatDatepicker<any>;

  public small: boolean;
  public get useCurrentLocaleOffset(): boolean {
    return this.pUseCurrentLocaleOffset;
  }

  private pUseCurrentLocaleOffset = false;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private platform: Platform,
    private breakpointObserver: BreakpointObserver,
    private headerService: HeaderAppService,
    private adapter: DateAdapter<any>,
  ) {
    this.small = this.breakpointObserver.isMatched('(max-width: 599px)');
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe((result) => {
      this.small = result.matches;
    });

    this.dateFormControl = new UntypedFormControl();
    this._date = new UntypedFormControl();
  }

  ngOnInit() {
    if (<any>this.required === '') this.required = true;
    if (this.name === undefined && this.id && this.id.length > 0) this.name = this.id;
    this.el.nativeElement.id = undefined;

    if (this.required) {
      this.dateFormControl.setValidators(Validators.required);
    }
    if (this.form) {
      this.form.control.addControl(this.name, this.dateFormControl);
      this.form.control.get(this.name).updateValueAndValidity();
    }
    const len = this.headerService.currentLanguage;
    setTimeout(() => {
      // it wasn't working on afterviewinit
      this.adapter.setLocale(len);
    });
  }

  ngAfterViewInit() {
    if (this.formClass) {
      const classes = this.formClass.split(' ');
      classes.forEach((htmlClass) => this.renderer.addClass(this.formField._elementRef.nativeElement, htmlClass));
    }
    if (this.inpClass) {
      const classes = this.inpClass.split(' ');
      classes.forEach((htmlClass) => this.renderer.addClass(this.formInp.nativeElement, htmlClass));
    }

    fromEvent(this.formInp.nativeElement, 'keyup')
      .pipe(
        filter((event: any) => {
          return event.keyCode === undefined || event.keyCode < 37 || event.keyCode > 40;
        }),
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe((text: string) => {
        this.customChange(text);
      });
  }

  public newDate($event: MatDatepickerInputEvent<any>) {
    let dateTime: DateTime = $event.value;
    if (this.useCurrentLocaleOffset) {
      dateTime = this.adjustLocaleOffset(dateTime);
    }

    const _dateCustom = dateTime.toFormat('ddMMyyyy');
    this.dateFormControl.setValue(_dateCustom);

    this.emit(this.adjustDateTimeToJs(dateTime));
  }

  private adjustLocaleOffset(dateTime: DateTime) {
    const offset = Math.round(DateTime.local().offset / 60);
    const fixedOffset = `UTC${offset >= 0 ? '+' : '-'}${Math.abs(offset)}`;
    dateTime = DateTime.fromObject(
      {
        year: dateTime.year,
        month: dateTime.month,
        day: dateTime.day,
      },
      { zone: fixedOffset },
    ).startOf('day');
    return dateTime;
  }

  private customChange($event: string) {
    const _dateCustom = this.dateFormControl.value;
    if (_dateCustom && _dateCustom.length === 8) {
      const date = DateTime.fromFormat(_dateCustom, 'ddMMyyyy');
      const nativeDate = date.toJSDate();

      if (date.isValid) {
        let valid = true;

        if (this.min && this.min > nativeDate) {
          this.dateFormControl.setErrors({ min: true });
          valid = false;
        }

        if (this.max && this.max < nativeDate) {
          this.dateFormControl.setErrors({ max: true });
          valid = false;
        }

        if (valid) {
          this._date.setValue(date);
          this.emit(nativeDate);
        } else {
          this.emit(null);
        }
      } else {
        this.dateFormControl.setErrors({ incorrect: true });
        this.emit(null);
      }
    } else {
      this.dateFormControl.setErrors({ incorrect: true });
      this.emit(null);
    }
  }

  private emit(date: Date | null | undefined): void {
    if (date || !this.onlyValidDate) {
      this.dateChange.emit(date);
    }
  }

  public clickInput() {
    if (this.platform.ANDROID || this.platform.IOS || this.small) {
      this.openPicker();
    }
  }

  private openPicker() {
    this.picker.open();
  }

  private adjustDateTimeToJs(dateTime: DateTime): Date {
    if (!this.useCurrentLocaleOffset) {
      return dateTime.toJSDate();
    }
    // Invert value to compensate offset
    const offset = dateTime.offset * -1;

    return new Date(dateTime.toMillis() + offset * 60 * 1000);
  }
}
