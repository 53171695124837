import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { TurnoBasicoDTO } from 'src/app/ModelDTO/DTO/turnoBasico.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';


export class TurnoConFichajeDTO implements IEntityDTO {

   fichaje: FichajeBasicoDTO;
   complementarioPrevio: TurnoBasicoDTO;
   descripcion = '';
   personalLegajo: PersonalLegajoBasicoDTO;
   cambio: boolean;
   alias = '';
   horaDesde: Date;
   horaHasta: Date;
   fechaDia: Date;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.fichaje = new FichajeBasicoDTO();
      this.complementarioPrevio = new TurnoBasicoDTO();
      this.personalLegajo = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fichaje != null) { this.fichaje.PrepareDTO(jsonObj.fichaje); }
      if (jsonObj.complementarioPrevio != null) { this.complementarioPrevio.PrepareDTO(jsonObj.complementarioPrevio); }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.personalLegajo != null) { this.personalLegajo.PrepareDTO(jsonObj.personalLegajo); }
      if (jsonObj.cambio != null) { this.cambio = jsonObj.cambio; }
      if (jsonObj.alias != null) { this.alias = jsonObj.alias; }
      if (jsonObj.horaDesde != null) { this.horaDesde = new Date(jsonObj.horaDesde); }
      if (jsonObj.horaHasta != null) { this.horaHasta = new Date(jsonObj.horaHasta); }
      if (jsonObj.fechaDia != null) { this.fechaDia = new Date(jsonObj.fechaDia); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
