import { NgxNeoLoaderService } from 'src/app/shared/lib/ngx-neo-loader/public_api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';

@Injectable({
  providedIn: 'root',
})
export class CommandFactoryService {
  private static _loaderService: NgxNeoLoaderService = null;

  public static newCommandAsync(executeParam: (any?) => any, canExecute$?: Observable<boolean>, delay?: number): ICommand {
    const command = new Command(executeParam, canExecute$, true, delay);
    command.asyncAction = (result: any) => {
      if (result === undefined) {
        /* const res = CommandFactoryService._loaderService.show();
        return res; */
        return null;
      } else {
        //  CommandFactoryService._loaderService.hide(result);
        return null;
      }
    };
    return command;
  }

  constructor(private loaderService: NgxNeoLoaderService) {
    CommandFactoryService._loaderService = loaderService;
  }
}
