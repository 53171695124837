<div
  class="d-inline-block cursor-pointer mb-2"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
  [matTooltip]="ratingId + 1"
  matTooltipPosition="above"
>
  <mat-icon fontSet="material-symbols-outlined" color="primary">
    {{ selectedRating >= i + 1 ? 'heart_check' : 'favorite' }}
  </mat-icon>
</div>
