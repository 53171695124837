export class DayEventModel {
  nameEvent: string;
  startDate: Date;
  endingDate: Date;
  color: string;
  idEvent: number;
  pos: number;
  holidayEvent: boolean;
  allDay: boolean;

  constructor(name: string, start: Date, end: Date, idEvent?: number, allDay?: boolean, color?: string) {
    this.nameEvent = name;
    this.startDate = start;
    this.endingDate = end;
    this.idEvent = idEvent;
    this.pos = -1;
    this.holidayEvent = false;
    this.allDay = allDay;
    if (color) {
      this.color = color;
    } else {
      this.color = '#5ca9e3';
    }
  }
}
