<div class="d-flex justify-content-between" [ngClass]="{ 'flex-column': data.isColumnLayout, 'align-items-center': !data.isColumnLayout }">
  <span class="mr-4"> {{ data.text }} </span>
  <div
    class="material-xsmall d-flex"
    [ngClass]="{ 'justify-content-between': data.isColumnLayout, 'align-items-center': !data.isColumnLayout }"
  >
    <ng-container *ngFor="let action of data.actions">
      <button mat-button [throttledExecution]="action.action" (click)="closeBar(false)">
        {{ action.labelKey | translate }}
      </button>
    </ng-container>
    <mat-icon *ngIf="data.dismissButton" class="ml-3" (click)="closeBar()">close</mat-icon>
  </div>
</div>
