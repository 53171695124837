<div class="d-flex flex-column flex-grow-1">
  <form [formGroup]="planningForm">
    <mat-form-field class="w-50" appearance="outline">
      <mat-label>Día de la semana</mat-label>
      <mat-select class="form-control" id="tipoPlanif" name="tipoPlanif" formControlName="numberDayWeek">
        <mat-option *ngFor="let week of weekNumber" [value]="week"> {{ week.position }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="d-flex flex-row flex-wrap mb-2">
      <mat-button-toggle-group name="days" class="my-2" formControlName="dayWeek">
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Monday">L</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Tuesday">M</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Wednesday">M</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Thursday">J</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Friday">V</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Saturday">S</mat-button-toggle>
        <mat-button-toggle class="boton-round gray-light-background color-gray-light" [value]="dayOfWeek.Sunday">D</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</div>
