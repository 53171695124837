import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';

export class MessageAttachmentDTO implements IEntityDTO {
  name = '';
  id = 0;
  cacheStamp = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.name != null) {
      this.name = jsonObj.name;
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.cacheStamp != null) {
      this.cacheStamp = jsonObj.cacheStamp;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
