import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { TipoEstadoFichaje } from 'src/app/ModelDTO/DTO/tipoEstadoFichaje.ENUM';
import { ClockInOutMode } from '@api/enums/clock-in-out-mode.enum';


export class FichajeBasicoModelDTO extends EntityModelDTO<FichajeBasicoDTO> {

   private personalLegajoModel: PersonalLegajoBasicoModelDTO;
   private personalLegajoSubscribe: Subscription;

   public constructor(protected entityDTO: FichajeBasicoDTO) {
      super(entityDTO);
   }

   public static getTipoEstadoFichaje(): string[] {
      return EntityModelDTO.getEnumArray(TipoEstadoFichaje);
   }

   public setEntityDTO(entityDTO: FichajeBasicoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.personalLegajoModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personalLegajo);
      this.personalLegajoSubscribe = this.personalLegajoModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.personalLegajoModel.dispose();
      this.personalLegajoSubscribe.unsubscribe();
   }

   get ClockInMode(): string { return ClockInOutMode[this.entityDTO.clockInMode]; }
   set ClockInMode(value: string) { this.notifyChangeDTO('clockInMode', ClockInOutMode[value]); }

   get ClockOutMode(): string { return ClockInOutMode[this.entityDTO.clockOutMode]; }
   set ClockOutMode(value: string) { this.notifyChangeDTO('clockOutMode', ClockInOutMode[value]); }

   get PersonalLegajoModel(): PersonalLegajoBasicoModelDTO { return this.personalLegajoModel; }
   get PersonalLegajo(): PersonalLegajoBasicoDTO { return this.personalLegajoModel.getEntityDTO(); }
   set PersonalLegajo(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personalLegajo = value; this.personalLegajoModel.setEntityDTO(value); }); }

   get ObservacionIngreso(): string { return this.entityDTO.observacionIngreso; }
   set ObservacionIngreso(value: string) { this.notifyChangeDTO('observacionIngreso', value); }

   get ObservacionSalida(): string { return this.entityDTO.observacionSalida; }
   set ObservacionSalida(value: string) { this.notifyChangeDTO('observacionSalida', value); }

   get FechaIngresoFront(): Date { return this.entityDTO.fechaIngresoFront; }
   set FechaIngresoFront(value: Date) { this.notifyChangeDTO('fechaIngresoFront', value); }

   get FechaIngreso(): Date { return this.entityDTO.fechaIngreso; }
   set FechaIngreso(value: Date) { this.notifyChangeDTO('fechaIngreso', value); }

   get FechaSalidaFront(): Date { return this.entityDTO.fechaSalidaFront; }
   set FechaSalidaFront(value: Date) { this.notifyChangeDTO('fechaSalidaFront', value); }

   get FechaSalida(): Date { return this.entityDTO.fechaSalida; }
   set FechaSalida(value: Date) { this.notifyChangeDTO('fechaSalida', value); }

   get TieneErroresIngreso(): boolean { return this.entityDTO.tieneErroresIngreso; }
   set TieneErroresIngreso(value: boolean) { this.notifyChangeDTO('tieneErroresIngreso', value); }

   get TieneErroresSalida(): boolean { return this.entityDTO.tieneErroresSalida; }
   set TieneErroresSalida(value: boolean) { this.notifyChangeDTO('tieneErroresSalida', value); }

   get EstadoFichaje(): string { return TipoEstadoFichaje[this.entityDTO.estadoFichaje]; }
   set EstadoFichaje(value: string) { this.notifyChangeDTO('estadoFichaje', TipoEstadoFichaje[value]); }

   get Edito(): boolean { return this.entityDTO.edito; }
   set Edito(value: boolean) { this.notifyChangeDTO('edito', value); }

   get FechaIngresoAjustada(): Date { return this.entityDTO.fechaIngresoAjustada; }
   set FechaIngresoAjustada(value: Date) { this.notifyChangeDTO('fechaIngresoAjustada', value); }

   get FechaSalidaAjustada(): Date { return this.entityDTO.fechaSalidaAjustada; }
   set FechaSalidaAjustada(value: Date) { this.notifyChangeDTO('fechaSalidaAjustada', value); }

   get HorasTrabajadas(): string { return this.entityDTO.horasTrabajadas; }
   set HorasTrabajadas(value: string) { this.notifyChangeDTO('horasTrabajadas', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
