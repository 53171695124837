import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CuestionarioDTO } from 'src/app/ModelDTO/DTO/cuestionario.DTO';
import { CuestionarioBasicoDTO } from 'src/app/ModelDTO/DTO/cuestionarioBasico.DTO';
import { EncuestaDTO } from 'src/app/ModelDTO/DTO/encuesta.DTO';
import { EncuestaEjecucionDTO } from 'src/app/ModelDTO/DTO/encuestaEjecucion.DTO';
import { EncuestaResumenDTO } from 'src/app/ModelDTO/DTO/encuestaResumen.DTO';
import { PersonalLegajoCuestionarioDTO } from 'src/app/ModelDTO/DTO/personalLegajoCuestionario.DTO';
import { ResultsDTO } from 'src/app/ModelDTO/DTO/results.DTO';
import { EstadoEncuesta, SkillsSurveyExecutionDTO, TipoCuestionario } from '../ModelDTO/DTO';
import { EncuestaStatsDTO } from '../ModelDTO/DTO/encuestaStats.DTO';
import { SortSurveySummary } from '../ModelDTO/DTO/sortSurveySummary.ENUM';
import { TipoEvaluacionCuestionario } from '../ModelDTO/DTO/tipoEvaluacionCuestionario.ENUM';
import { Constants } from '../shared/constants';
import { AuditLogEntryDTO, DataDTO, ImageEntityDTO, NamedBlobDTO } from '../shared/lib/ngx-neo-frontend-mat/public_api';

@Injectable({
   providedIn: 'root'
})
export class CuestionarioServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCuestionariosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCuestionarios(fechaDesde: Date, fechaHasta: Date, plantillaId: number = 0): Promise<PersonalLegajoCuestionarioDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&plantillaId=' + plantillaId));
         if (!res) { return null; }
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosXLSX(fechaDesde: Date, fechaHasta: Date, plantillaId: number = 0, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&plantillaId=' + plantillaId + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getCuestionariosId(id: number): Promise<PersonalLegajoCuestionarioDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosUsuarioActual(quizType: TipoCuestionario, fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, estado: number = -1, evaluatedId: number = 0, overallEvaluationId: number = undefined , withoutOverallEvaluation: boolean = false): Promise<Array<PersonalLegajoCuestionarioDTO>> {
     
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/usuarioActual' + '?quizType=' + quizType +
            '&fechaDesde=' + fechaDesde?.toISOString() +
            '&fechaHasta=' + fechaHasta?.toISOString() +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&estado=' + estado +
            '&evaluatedId=' + evaluatedId +
            '&overallEvaluationId=' + overallEvaluationId +
            '&withoutOverallEvaluation=' + withoutOverallEvaluation));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoCuestionarioDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoCuestionarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCuestionariosUsuarioActualId(id: number): Promise<PersonalLegajoCuestionarioDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/usuarioActual/' + id));
         if (!res) { return null; }
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosCurrentUserSurveyIdSurvey(idSurvey: number): Promise<PersonalLegajoCuestionarioDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/currentUser/survey/' + idSurvey));
         if (!res) { return null; }
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCuestionariosId(id: number, personalLegajoCuestionarioDTO: PersonalLegajoCuestionarioDTO): Promise<PersonalLegajoCuestionarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cuestionarios/' + id, personalLegajoCuestionarioDTO));
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosCuestionario(quizType: number = 0, latest = false): Promise<Array<CuestionarioBasicoDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/cuestionario' + '?quizType=' + quizType+'&latest='+latest));
         const resJson = res.data;
         const resDTO = new Array<CuestionarioBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new CuestionarioBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCuestionariosCuestionarioId(id: number): Promise<CuestionarioDTO> {
         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/cuestionario/' + id));
         if (!res) { return null; }
         const itemDTO = new CuestionarioDTO();
         itemDTO.PrepareDTO(res);
         return itemDTO;
   }

   public async insertCuestionariosCuestionario(cuestionarioDTO: CuestionarioDTO): Promise<CuestionarioDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/cuestionarios/cuestionario', cuestionarioDTO));
         const resDTO = new CuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCuestionariosCuestionarioIDCuestionario(iDCuestionario: number, cuestionarioDTO: CuestionarioDTO): Promise<CuestionarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cuestionarios/cuestionario/' + iDCuestionario, cuestionarioDTO));
         const resDTO = new CuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteCuestionariosCuestionarioIDCuestionario(iDCuestionario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/cuestionarios/cuestionario/' + iDCuestionario));

   }

   public async getCuestionariosEncuestas(quizType: TipoCuestionario, startDate: Date, endDate: Date, pageNumber: number, pageSize: number, templateId: number = 0, sortActive: SortSurveySummary = undefined, isAsc: boolean = false, state: EstadoEncuesta = undefined, evaluationType: TipoEvaluacionCuestionario = undefined , overallEvaluationId: number = 0): Promise<Array<EncuestaResumenDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/encuestas' + '?quizType=' + quizType +
            '&startDate=' + startDate?.toISOString() +
            '&endDate=' + endDate?.toISOString() +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&templateId=' + templateId +
            '&sortActive=' + sortActive +
            '&isAsc=' + isAsc +
            '&state=' + state +
            '&evaluationType=' + evaluationType +
            '&overallEvaluationId=' + overallEvaluationId));
         const resJson = res.data;
         const resDTO = new Array<EncuestaResumenDTO>();
         for (const item of resJson) {
            const itemDTO = new EncuestaResumenDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

   public async getCuestionariosEncuestasIdEncuesta(idEncuesta: number): Promise<EncuestaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/encuestas/' + idEncuesta));
         if (!res) { return null; }
         const resDTO = new EncuestaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosEncuestasIdEncuestaEjecuciones(idEncuesta: number): Promise<Array<EncuestaEjecucionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/encuestas/' + idEncuesta + '/ejecuciones'));
         const resJson = res.data;
         const resDTO = new Array<EncuestaEjecucionDTO>();
         for (const item of resJson) {
            const itemDTO = new EncuestaEjecucionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCuestionariosEncuestasIdEncuestaEjecucionesXLSX(idEncuesta: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/encuestas/' + idEncuesta + '/ejecuciones' + '?BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getCuestionariosEncuestasIdEncuestaEjecucionesFecha(idEncuesta: number, fecha: Date): Promise<Array<PersonalLegajoCuestionarioDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/encuestas/' + idEncuesta + '/ejecuciones/fecha' + '?fecha=' + fecha?.toISOString()));
      const resJson = res.data;
      const resDTO = new Array<PersonalLegajoCuestionarioDTO>();
      for (const item of resJson) {
         const itemDTO = new PersonalLegajoCuestionarioDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async getCuestionariosSurveysurveyIdExecutionsDateDATEResults(surveyId: number, dATE: string): Promise<ResultsDTO> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/survey/' + surveyId + '/executions/date/' + dATE + '/results'));
      if (!res) { return null; }
      const resDTO = new ResultsDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
   }

   public async insertCuestionariosPlanificacion(encuestaDTO: EncuestaDTO): Promise<EncuestaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/cuestionarios/planificacion', encuestaDTO));
         const resDTO = new EncuestaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCuestionariosPlanificacionIdEncuesta(idEncuesta: number, encuestaDTO: EncuestaDTO, mostrar: boolean = false, reanudar: boolean = false): Promise<EncuestaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cuestionarios/planificacion/' + idEncuesta + '?mostrar=' + mostrar + '&reanudar=' + reanudar, encuestaDTO));
         const resDTO = new EncuestaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCuestionariosPlanificacionIdEncuestaCambioEstado(idEncuesta: number, accion: number): Promise<EncuestaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cuestionarios/planificacion/' + idEncuesta + '/cambioEstado' + '?accion=' + accion, null));
         const resDTO = new EncuestaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteCuestionariosPlanificacionIdEncuesta(idEncuesta: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/cuestionarios/planificacion/' + idEncuesta));

   }

   public async insertCuestionariosCuestionarioIDCuestionarioImage(iDCuestionario: number, imageEntityDTO: ImageEntityDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/cuestionarios/cuestionario/' + iDCuestionario + '/Image', imageEntityDTO));

   }

   public async getCuestionariosEncuestasIdStats(id: number): Promise<EncuestaStatsDTO> {

       const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/personal/' + id + '/stats'));
       if (!res) { return null; }
       const resDTO = new EncuestaStatsDTO();
       resDTO.PrepareDTO(res);
       return resDTO;

 }

public async getCuestionariosSkillsSurveySurveyIdExecutions(surveyId: number, teammateId: number = 0): Promise<Array<SkillsSurveyExecutionDTO>> {
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/skills-survey/' + surveyId + '/executions' + '?TeammateId=' + teammateId));
         const resJson = res.data;
         const resDTO = new Array<SkillsSurveyExecutionDTO>();
         for (const item of resJson) {
            const itemDTO = new SkillsSurveyExecutionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

   public async getCuestionariosSkillsSurveySurveyIdExecutionsXLSX(surveyId: number, teammateId: number = 0, namedBlob: NamedBlobDTO = null): Promise<Blob> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/skills-survey/' + surveyId + '/executions' + '?TeammateId=' + teammateId + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
      const resDTO = new Blob([res.body], { type: 'application/text' });
      if (namedBlob) {
        namedBlob.setBlobNameFromHttpResponse(res);
        namedBlob.blob = res.body;
      }
      return resDTO;
  }

   public async getCuestionariosSkillsSurveysurveyIdExecutionsDateDATEResults(surveyId: number, dATE: Date, evaluatedId: number): Promise<ResultsDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/skills-survey/' + surveyId + '/executions/date/' + dATE?.toISOString() + '/results' + '?evaluatedId=' + evaluatedId));
         if (!res) { return null; }
         const resDTO = new ResultsDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCuestionariosSkillssurveysurveyIdExecutionsDatedate(surveyId: number, date: Date, evaluatedId: number): Promise<Array<PersonalLegajoCuestionarioDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cuestionarios/skills-survey/' + surveyId + '/executions/date/' + date?.toISOString() + '?evaluatedId=' + evaluatedId));
      const resJson = res.data;
      const resDTO = new Array<PersonalLegajoCuestionarioDTO>();
      for (const item of resJson) {
            const itemDTO = new PersonalLegajoCuestionarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async getCuestionariosSkillssurveysurveyIdExecutionsDateDATEResults(surveyId: number, dATE: Date, evaluatedId: number): Promise<ResultsDTO> {
            const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cuestionarios/skills-survey/' + surveyId + '/executions/date/' + dATE?.toISOString() + '/results' + '?evaluatedId=' + evaluatedId));
            if (!res) { return null; }
            const resDTO = new ResultsDTO();
            resDTO.PrepareDTO(res);
            return resDTO;
   }

   public async updateCuestionariosIdEditAnswers(id: number, personalLegajoCuestionarioDTO: PersonalLegajoCuestionarioDTO): Promise<PersonalLegajoCuestionarioDTO> {
         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cuestionarios/' + id + '/answers', personalLegajoCuestionarioDTO));
         const resDTO = new PersonalLegajoCuestionarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

}
