import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { WeekNumber } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/administrador-planificacion-cuestionario.component';
import { TypeRecurrenceComponent } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/typeRecurrence.component';

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.scss'],
})
export class WeeklyComponent implements TypeRecurrenceComponent {
  @Input() public planningForm: UntypedFormGroup;
  @Input() public minDate: Date;
  @Input() public weekNumber: WeekNumber[];

  public setDayValue(control: AbstractControl, value: boolean): void {
    control.setValue(value);
    const peso = value ? 1 : -1;
    this.planningForm.patchValue({ cantDaysSelected: this.planningForm.value.cantDaysSelected + peso });
  }
}
