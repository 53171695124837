import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WeekNumber } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/administrador-planificacion-cuestionario.component';
import { TypeRecurrenceComponent } from 'src/app/layout/administrador/administrador-cuestionarios/administrador-planificacion-cuestionario/planificacion-tipo-recurrencia/tipo-recurrencia/typeRecurrence.component';

@Component({
  selector: 'app-for-only-time',
  templateUrl: './for-only-time.component.html',
  styleUrls: ['./for-only-time.component.scss'],
})
export class ForOnlyTimeComponent implements TypeRecurrenceComponent {
  @Input() public planningForm: UntypedFormGroup;
  @Input() public minDate: Date;
  @Input() public weekNumber: WeekNumber[];
}
