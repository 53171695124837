import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';


export class LevelRangeModelDTO extends EntityModelDTO<LevelRangeDTO> {


   public constructor(protected entityDTO: LevelRangeDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: LevelRangeDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Name(): string { return this.entityDTO.name; }
   set Name(value: string) { this.notifyChangeDTO('name', value); }

   get Start(): number { return this.entityDTO.start; }
   set Start(value: number) { this.notifyChangeDTO('start', value); }

   get End(): number { return this.entityDTO.end; }
   set End(value: number) { this.notifyChangeDTO('end', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
