<div class="d-flex flex-column flex-grow-1 gap-1">
  <app-question-type-header [position]="position" [title]="data.texto" [mandatory]="data.obligatoria"></app-question-type-header>

  <mat-form-field class="flex-fill w-100" appearance="outline" *ngIf="!modoAdministrador">
    <input
      #rtaTexto
      matInput
      type="text"
      id="rtaTexto"
      name="rtaTexto"
      class="form-control"
      [(ngModel)]="data.respuesta.valorTexto"
      (ngModelChange)="respuestaChange(data.respuesta, $event)"
      [disabled]="!editionMode && estadoCuestionario === cuestionarioTerminado"
    />
  </mat-form-field>
  <label *ngIf="modoAdministrador" class="flex-fill mb-2">{{
    data.respuesta.valorTexto?.length ? data.respuesta.valorTexto : 'Sin responder'
  }}</label>
</div>
