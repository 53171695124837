import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class CambioDTO implements IEntityDTO {

   estado = '';
   desde: Date;
   hasta: Date;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado; }
      if (jsonObj.desde != null) { this.desde = new Date(jsonObj.desde); }
      if (jsonObj.hasta != null) { this.hasta = new Date(jsonObj.hasta); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
