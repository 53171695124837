import { Component, Input } from '@angular/core';
import { CuestionarioPreguntaDTO, TipoEstadoPersonalLegajoCuestinario } from 'src/app/ModelDTO/DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';

@Component({
  selector: 'app-question-subgroup',
  templateUrl: './question-subgroup.component.html',
  styleUrls: ['./question-subgroup.component.scss'],
})
export class QuestionSubgroupComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;
}
