import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/capacitacionFlat.DTO';
import { EstadoAprobacionSolicitud } from 'src/app/ModelDTO/DTO/estadoAprobacionSolicitud.ENUM';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';


export class SolicitudCapacitacionFlatDTO implements IEntityDTO {

   capacitacion: CapacitacionFlatDTO;
   estado: EstadoAprobacionSolicitud;
   inicio: Date;
   cambioEstado: Date;
   solicitante: PersonalLegajoBasicoDTO;
   motivo = '';
   adjuntos: Array<AttachmentUaalooDTO>;
   responsable: PersonalLegajoBasicoDTO;
   observacionSupervisor = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.capacitacion = new CapacitacionFlatDTO();
      this.solicitante = new PersonalLegajoBasicoDTO();
      this.adjuntos = new Array<AttachmentUaalooDTO>();
      this.responsable = new PersonalLegajoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.capacitacion != null) { this.capacitacion.PrepareDTO(jsonObj.capacitacion); }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as EstadoAprobacionSolicitud; }
      if (jsonObj.inicio != null) { this.inicio = new Date(jsonObj.inicio); }
      if (jsonObj.cambioEstado != null) { this.cambioEstado = new Date(jsonObj.cambioEstado); }
      if (jsonObj.solicitante != null) { this.solicitante.PrepareDTO(jsonObj.solicitante); }
      if (jsonObj.motivo != null) { this.motivo = jsonObj.motivo; }
      if (jsonObj.adjuntos != null) { for (const item of jsonObj.adjuntos) { const itemDTO = new AttachmentUaalooDTO(); itemDTO.PrepareDTO(item); this.adjuntos.push(itemDTO); } }
      if (jsonObj.responsable != null) { this.responsable.PrepareDTO(jsonObj.responsable); }
      if (jsonObj.observacionSupervisor != null) { this.observacionSupervisor = jsonObj.observacionSupervisor; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
