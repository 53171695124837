import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EmpresaBasicoDTO } from 'src/app/ModelDTO/DTO/empresaBasico.DTO';


export class EmpresaBasicoModelDTO extends EntityModelDTO<EmpresaBasicoDTO> {


   public constructor(protected entityDTO: EmpresaBasicoDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: EmpresaBasicoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Name(): string { return this.entityDTO.name; }
   set Name(value: string) { this.notifyChangeDTO('name', value); }

   get Telefono(): string { return this.entityDTO.telefono; }
   set Telefono(value: string) { this.notifyChangeDTO('telefono', value); }

   get ArtPoliza(): string { return this.entityDTO.artPoliza; }
   set ArtPoliza(value: string) { this.notifyChangeDTO('artPoliza', value); }

   get ArtNombre(): string { return this.entityDTO.artNombre; }
   set ArtNombre(value: string) { this.notifyChangeDTO('artNombre', value); }

   get ArtPrivado(): string { return this.entityDTO.artPrivado; }
   set ArtPrivado(value: string) { this.notifyChangeDTO('artPrivado', value); }

   get ArtPublico(): string { return this.entityDTO.artPublico; }
   set ArtPublico(value: string) { this.notifyChangeDTO('artPublico', value); }

   get RazonSocial(): string { return this.entityDTO.razonSocial; }
   set RazonSocial(value: string) { this.notifyChangeDTO('razonSocial', value); }

   get IdentificadorFiscal(): string { return this.entityDTO.identificadorFiscal; }
   set IdentificadorFiscal(value: string) { this.notifyChangeDTO('identificadorFiscal', value); }

   get Sucursal(): number { return this.entityDTO.sucursal; }
   set Sucursal(value: number) { this.notifyChangeDTO('sucursal', value); }

   get UaalooImage(): string { return this.entityDTO.uaalooImage; }
   set UaalooImage(value: string) { this.notifyChangeDTO('uaalooImage', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
