import { Injectable } from '@angular/core';
import { Subscription, concat, interval, take, tap, timer } from 'rxjs';
import confetti, { Options } from 'canvas-confetti';
import { colors } from 'src/app/shared/colors';
import {
  BASIC_CANNON,
  FIREWORKS,
  RANDOM_DIRECTION,
  REALISTIC_LOOK,
  SCHOOL_PRIDE,
  SNOW,
  STARS,
} from 'src/app/shared/animations-events.constants';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  public fireworks(duration = 4_000): Subscription {
    const animationEnd = Date.now() + duration;
    const defaults: Options = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 1001 };

    const subscription = interval(250).subscribe(() => {
      const timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) {
        subscription.unsubscribe();
      }

      const particleCount = 50 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
      confetti({ ...defaults, particleCount, origin: { x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    });

    return subscription;
  }

  public cannon(): void {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      zIndex: 1001,
    });
  }

  public random(): void {
    confetti({
      angle: this.randomInRange(55, 125),
      spread: this.randomInRange(50, 70),
      particleCount: this.randomInRange(50, 100),
      origin: { y: 0.6 },
      zIndex: 1001,
    });
  }

  public realistic(): void {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
      zIndex: 1001,
    };

    const fire = (particleRatio: number, opts: any): void => {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  public snow(duration = 6_000): Subscription {
    const animationEnd = Date.now() + duration;
    let skew = 1;

    const subscription = interval(250).subscribe(() => {
      const timeLeft = animationEnd - Date.now();
      skew = Math.max(0.8, skew - 0.001);
      const ticks = Math.max(200, 500 * (timeLeft / duration));
      if (timeLeft <= 0) {
        subscription.unsubscribe();
      }

      confetti({
        particleCount: 1,
        startVelocity: 0,
        ticks,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: Math.random() * skew - 0.2,
        },
        colors: [colors.primary],
        shapes: ['circle'],
        gravity: this.randomInRange(0.4, 0.6),
        scalar: this.randomInRange(0.4, 1),
        drift: this.randomInRange(-0.4, 0.4),
        zIndex: 1001,
      });
    });

    return subscription;
  }

  public stars(): Subscription {
    const defaults: Options = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
      zIndex: 1001,
    };

    const timers = [0, 150, 200].map((time) =>
      timer(time).pipe(
        tap(() => {
          confetti({
            ...defaults,
            particleCount: 40,
            scalar: 1.2,
            shapes: ['star'],
          });

          confetti({
            ...defaults,
            particleCount: 10,
            scalar: 0.75,
            shapes: ['circle'],
          });
        }),
        take(1),
      ),
    );

    return concat(...timers).subscribe();
  }

  public cannons(duration = 4_000): Subscription {
    const animationEnd = Date.now() + duration;
    const colorsCannon = ['#bb0000', '#ffffff'];

    const subscription = interval().subscribe(() => {
      const timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) {
        subscription.unsubscribe();
      }

      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colorsCannon,
        zIndex: 1001,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colorsCannon,
        zIndex: 1001,
      });
    });

    return subscription;
  }

  public visualize(code: string): void {
    switch (code) {
      case STARS:
        this.stars();
        break;
      case SNOW:
        this.snow();
        break;
      case BASIC_CANNON:
        this.cannon();
        break;
      case REALISTIC_LOOK:
        this.realistic();
        break;
      case RANDOM_DIRECTION:
        this.random();
        break;
      case FIREWORKS:
        this.fireworks();
        break;
      case SCHOOL_PRIDE:
        this.cannons();
        break;
      default:
        break;
    }
  }

  private readonly randomInRange = (min: number, max: number): number => Math.random() * (max - min) + min;
}
