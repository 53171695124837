import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserTypes } from '../models';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export const UserDecisionGuard: CanActivateFn = (): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);
  const lastUrl = localStorage.getItem('lastUrl');

  if (lastUrl) {
    return router.parseUrl(lastUrl);
  } else if (headerService.userLogged.userType === UserTypes.Administrator) {
    return router.parseUrl('/admin');
  } else {
    return router.parseUrl('/user');
  }
};
