import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoNotificacion } from 'src/app/ModelDTO/DTO/tipoNotificacion.ENUM';


export class TipoEventoDTO implements IEntityDTO {

   tipos: TipoNotificacion;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.tipos != null) { this.tipos = jsonObj.tipos as TipoNotificacion; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
