import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateFn, UrlTree } from '@angular/router';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export const LoginGuard: CanActivateFn = (next: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);

  if (headerService.IsLogged()) {
    const redirect = next.data ? next.data.redirect : false;
    if (redirect) {
      return router.parseUrl('/home');
    } else {
      return false;
    }
  } else {
    return true;
  }
};
