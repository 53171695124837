import { Injectable, Inject } from '@angular/core';
import { DataDTO, NameDetailDTO, NotificationDTO, PublicLinkDTO, TypeSocial } from '../../models';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { HttpClient } from '@angular/common/http';
import { AuthRequestDTO } from '../../models';
import { AuthResponseDTO } from '../../models';
import { AuthTenantsResponseDTO } from '../../models';
import { AuditLogEntryDTO } from '../../models';
import { UserDTO } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class UserServiceBackend {
  constructor(
    @Inject(FrontEndConfigService) protected Constants: FrontEndConfig,
    protected http: HttpClient,
  ) {}

  public async getUserIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/user/' + id + '/Auditory').toPromise();
    const resJson = res.data;
    const resDTO = new Array<AuditLogEntryDTO>();
    for (const item of resJson) {
      const itemDTO = new AuditLogEntryDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async updateUser(userDTO: UserDTO): Promise<UserDTO> {
    const res = await this.http.put(this.Constants.apiURL + '/user/', userDTO).toPromise();
    const resDTO = new UserDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async insertUserDeviceID(authRequestDTO: AuthRequestDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/deviceID', authRequestDTO).toPromise();
  }

  public async getUserAllTenants(): Promise<AuthTenantsResponseDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/allTenants').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new AuthTenantsResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserTenantsTOKEN(token: string, token2fa?: string): Promise<AuthResponseDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/tenants/' + token + '?token2fa=' + token2fa).toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getUserSocialLogin(): Promise<Array<AuthResponseDTO>> {
    const res = await this.http.get<DataDTO>(this.Constants.apiURL + '/user/socialLogin').toPromise();
    const resJson = res.data;
    const resDTO = new Array<AuthResponseDTO>();
    for (const item of resJson) {
      const itemDTO = new AuthResponseDTO();
      itemDTO.PrepareDTO(item);
      resDTO.push(itemDTO);
    }
    return resDTO;
  }

  public async insertUserSocialLogin(authRequestDTO: AuthRequestDTO): Promise<AuthResponseDTO> {
    const res = await this.http.post(this.Constants.apiURL + '/user/socialLogin', authRequestDTO).toPromise();
    const resDTO = new AuthResponseDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async deleteUserSocialLogin(socialType: TypeSocial): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/user/socialLogin' + '?socialType=' + socialType).toPromise();
  }

  public async insertUserTenants(notificationDTO: NotificationDTO): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/tenants', notificationDTO).toPromise();
  }

  public async deleteUserTenantsNotificationsId(id: number): Promise<void> {
    await this.http.delete(this.Constants.apiURL + '/user/tenants/notifications/' + id).toPromise();
  }

  public async insertUser2fa(nameDetailDTO: NameDetailDTO, active: boolean = true): Promise<void> {
    await this.http.post(this.Constants.apiURL + '/user/2fa' + '?active=' + active, nameDetailDTO).toPromise();
  }

  public async getUser2fa(): Promise<PublicLinkDTO> {
    const res = await this.http.get(this.Constants.apiURL + '/user/2fa').toPromise();
    if (!res) {
      return null;
    }
    const resDTO = new PublicLinkDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }
}
