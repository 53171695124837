import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, share } from 'rxjs';
import { STATUS_PAGE_API_ID, STATUS_PAGE_ID } from 'src/app/shared/constants';
import { IComponent, IStatusResponse } from 'src/app/shared/status-page/status-page.interface';

// eslint-disable-next-line no-var
declare var StatusPage: any; // For typecript compilation

@Injectable({
  providedIn: 'root',
})
export class StatusPageService {
  private statusPage: any;
  private maintenanceMessageSubject = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public maintenanceMessage$ = this.maintenanceMessageSubject.asObservable();

  private statusObs: Observable<IStatusResponse>;

  constructor() {
    // eslint-disable-next-line new-cap
    this.statusPage = new StatusPage.page({ page: STATUS_PAGE_ID });
    this.initializeObservable();
  }

  public setComponentMaintenanceMessage(component: IComponent, status: IStatusResponse): void {
    let componentIncident = status.incidents?.find((incident) =>
      incident.components?.some((incidentComponent) => incidentComponent.id === component.id)
    );

    if (!componentIncident) {
      componentIncident = status.scheduled_maintenances?.find((incident) =>
        incident.components?.some((incidentComponent) => incidentComponent.id === component.id)
      );
    }

    this.maintenanceMessageSubject.next(componentIncident?.name ?? component.name);
  }

  public checkStatus(): Observable<IStatusResponse> {
    return this.statusObs;
  }

  public systemDown(status: IStatusResponse): boolean {
    const apiStatus = status.components?.find((component) => component.id === STATUS_PAGE_API_ID);
    if (apiStatus?.status && ['partial_outage', 'under_maintenance', 'major_outage'].includes(apiStatus.status)) {
      this.setComponentMaintenanceMessage(apiStatus, status);
      return true;
    }
    return false;
  }

  private initializeObservable(): void {
    this.statusObs = new Observable<IStatusResponse>((observer) => {
      this.statusPage.summary({
        success: (data: IStatusResponse) => {
          observer.next(data);
          observer.complete();
        },
        error: (error: any) => {
          console.error(error);
          observer.next({});
          observer.complete();
        },
      });
    }).pipe(share<IStatusResponse>()); // Use share to prevent multiple calls at same time while statusPage.summary is executing
  }
}
