import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ReclutamientoCandidatoParaFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoParaFase.DTO';
import { ReclutamientoOfertaFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoOfertaFase.DTO';
import { ReclutamientoCandidatoEstado } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoEstado.ENUM';
import { RecruitmentRejectionReasonDTO } from '@api/interfaces/recruitment-rejection-reason.interface';


export class ReclutamientoFaseCandidatoDTO implements IEntityDTO {

   candidato: ReclutamientoCandidatoParaFaseDTO;
   fase: ReclutamientoOfertaFaseDTO;
   estado: ReclutamientoCandidatoEstado;
   rejectionReason?: RecruitmentRejectionReasonDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.candidato = new ReclutamientoCandidatoParaFaseDTO();
      this.fase = new ReclutamientoOfertaFaseDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.candidato != null) { this.candidato.PrepareDTO(jsonObj.candidato); }
      if (jsonObj.fase != null) { this.fase.PrepareDTO(jsonObj.fase); }
      if (jsonObj.estado != null) { this.estado = jsonObj.estado as ReclutamientoCandidatoEstado; }
      if (jsonObj.rejectionReason != null) { this.rejectionReason = jsonObj.rejectionReason; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
