import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EstadoAprobacion } from 'src/app/ModelDTO/DTO/estadoAprobacion.ENUM';
import { TipoEstadoFichaje } from 'src/app/ModelDTO/DTO/tipoEstadoFichaje.ENUM';


export class FichajeNovedadDTO implements IEntityDTO {

   fechaAprobacionIngreso: Date;
   fechaAprobacionSalida: Date;
   aproboIngreso: EstadoAprobacion;
   aproboSalida: EstadoAprobacion;
   observacionIngreso = '';
   observacionSalida = '';
   observacionIngresoAnterior = '';
   observacionSalidaAnterior = '';
   estadoFichaje: TipoEstadoFichaje;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fechaAprobacionIngreso != null) { this.fechaAprobacionIngreso = new Date(jsonObj.fechaAprobacionIngreso); }
      if (jsonObj.fechaAprobacionSalida != null) { this.fechaAprobacionSalida = new Date(jsonObj.fechaAprobacionSalida); }
      if (jsonObj.aproboIngreso != null) { this.aproboIngreso = jsonObj.aproboIngreso as EstadoAprobacion; }
      if (jsonObj.aproboSalida != null) { this.aproboSalida = jsonObj.aproboSalida as EstadoAprobacion; }
      if (jsonObj.observacionIngreso != null) { this.observacionIngreso = jsonObj.observacionIngreso; }
      if (jsonObj.observacionSalida != null) { this.observacionSalida = jsonObj.observacionSalida; }
      if (jsonObj.observacionIngresoAnterior != null) { this.observacionIngresoAnterior = jsonObj.observacionIngresoAnterior; }
      if (jsonObj.observacionSalidaAnterior != null) { this.observacionSalidaAnterior = jsonObj.observacionSalidaAnterior; }
      if (jsonObj.estadoFichaje != null) { this.estadoFichaje = jsonObj.estadoFichaje as TipoEstadoFichaje; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
