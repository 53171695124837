export enum TipoCuestionarioPregunta {
   Subgrupo = -1,
   Sin_definir = 0,
   Tilde = 1,
   Opciones_SingleSelect = 2,
   Opciones_MultiSelect = 3,
   Texto = 4,
   Numeric = 5,
   Subtitle = 6,
   Grupo = 7,
 }
