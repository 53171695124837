<div class="d-flex flex-column flex-grow-1">
  <form [formGroup]="planningForm">
    <mat-form-field class="w-50" appearance="outline">
      <mat-label>{{ 'FORMS.PLANNING_TYPE_MONTHLY' | translate }}</mat-label>
      <mat-select class="form-control" id="tipoPlanif" name="tipoPlanif" formControlName="monthlyPlanningType">
        <mat-option [value]="tipoPlanificacionMensual.PrimerDiaHabil">
          {{ 'ENUM.FORMS.PLANNING_TYPE_MONTHLY.PrimerDiaHabil' | translate }}
        </mat-option>
        <mat-option [value]="tipoPlanificacionMensual.DiaSemana">
          {{ 'ENUM.FORMS.PLANNING_TYPE_MONTHLY.DiaSemana' | translate }}
        </mat-option>
        <mat-option [value]="tipoPlanificacionMensual.DiaParticular"
          >{{ 'ENUM.FORMS.PLANNING_TYPE_MONTHLY.DiaParticular' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-tipo-planificacion-mensual
      [planningForm]="planningForm"
      [typeMonthlyPlanning]="planningForm.value.monthlyPlanningType"
      [weekNumber]="weekNumber"
    ></app-tipo-planificacion-mensual>
  </form>
</div>
