import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ServerErrorDialogComponent } from './components/server-error-dialog/server-error-dialog.component';
import { FrontEndConfig, FrontEndConfigService } from './FrontendConfig';

@NgModule({
  declarations: [ServerErrorDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, RouterModule],
})
export class NgxNeoFrontendMatModule {
  static forRoot(config: FrontEndConfig): ModuleWithProviders<NgxNeoFrontendMatModule> {
    return {
      ngModule: NgxNeoFrontendMatModule,
      providers: [
        {
          provide: FrontEndConfigService,
          useValue: config,
        },
      ],
    };
  }
}
