import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CandidatoPostuladoDTO } from 'src/app/ModelDTO/DTO/candidatoPostulado.DTO';
import { LinkPublicoDTO } from 'src/app/ModelDTO/DTO/linkPublico.DTO';
import { FileDBDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CandidatoPostuladoConocimientoDTO } from 'src/app/ModelDTO/DTO/candidatoPostuladoConocimiento.DTO';


export class CandidatoPostuladoOfertaDTO implements IEntityDTO {

   candidate: CandidatoPostuladoDTO;
   offer: LinkPublicoDTO;
   coverLetter = '';
   public: boolean;
   cv: FileDBDTO;
   skills: Array<CandidatoPostuladoConocimientoDTO>;
   reCaptchaToken = '';
   id = 0;

   constructor() {
      this.candidate = new CandidatoPostuladoDTO();
      this.offer = new LinkPublicoDTO();
      this.cv = new FileDBDTO();
      this.skills = new Array<CandidatoPostuladoConocimientoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.candidate != null) { this.candidate.PrepareDTO(jsonObj.candidate); }
      if (jsonObj.offer != null) { this.offer.PrepareDTO(jsonObj.offer); }
      if (jsonObj.coverLetter != null) { this.coverLetter = jsonObj.coverLetter; }
      if (jsonObj.public != null) { this.public = jsonObj.public; }
      if (jsonObj.cv != null) { this.cv.PrepareDTO(jsonObj.cv); }
      if (jsonObj.skills != null) { for (const item of jsonObj.skills) { const itemDTO = new CandidatoPostuladoConocimientoDTO(); itemDTO.PrepareDTO(item); this.skills.push(itemDTO); } }
      if (jsonObj.reCaptchaToken != null) { this.reCaptchaToken = jsonObj.reCaptchaToken; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
