import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO} from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CompetenceCategoryBasicDTO } from 'src/app/ModelDTO/DTO/competenceCategoryBasic.DTO';
import { CompetenceCategoryDTO } from 'src/app/ModelDTO/DTO/competenceCategory.DTO';
import { CompetenceEvaluationTypeDTO } from 'src/app/ModelDTO/DTO/competenceEvaluationType.DTO';
import { AsignacionCargosCompetenciasDTO } from '../ModelDTO/DTO/asignacionCargosCompetencias.DTO';
import { OverallPerformanceDTO } from 'src/app/ModelDTO/DTO/overallPerformance.DTO';

@Injectable({
   providedIn: 'root'
})
export class CompetenceServiceBackend {

   constructor(protected http: HttpClient) { }

   public async getCompetencesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCompetencesIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/competences/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getCompetences(): Promise<Array<CompetenceCategoryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/'));
         const resJson = res.data;
         const resDTO = new Array<CompetenceCategoryDTO>();
         for (const item of resJson) {
            const itemDTO = new CompetenceCategoryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertCompetences(competenceCategoryDTO: CompetenceCategoryDTO): Promise<CompetenceCategoryDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/competences/', competenceCategoryDTO));
         const resDTO = new CompetenceCategoryDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateCompetencesId(id: number, competenceCategoryDTO: CompetenceCategoryDTO): Promise<CompetenceCategoryDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/competences/' + id, competenceCategoryDTO));
         const resDTO = new CompetenceCategoryDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteCompetencesId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/competences/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getCompetencesId(id: number): Promise<CompetenceCategoryDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/competences/' + id));
         if (!res) { return null; }
         const resDTO = new CompetenceCategoryDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getCompetencesBasic(): Promise<Array<CompetenceCategoryBasicDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/basic'));
         const resJson = res.data;
         const resDTO = new Array<CompetenceCategoryBasicDTO>();
         for (const item of resJson) {
            const itemDTO = new CompetenceCategoryBasicDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCompetencesEvaluationTypes(): Promise<Array<CompetenceEvaluationTypeDTO>> {
       const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/evaluation-types'));
       const resJson = res.data;
       const resDTO = new Array<CompetenceEvaluationTypeDTO>();
       for (const item of resJson) {
          const itemDTO = new CompetenceEvaluationTypeDTO();
          itemDTO.PrepareDTO(item);
          resDTO.push(itemDTO);
       }
       return resDTO;
 }

    public async insertCompetencesBulkConflicts(asignacionCargosCompetenciasDTO: AsignacionCargosCompetenciasDTO): Promise<AsignacionCargosCompetenciasDTO> {
         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/competences/bulk/conflicts', asignacionCargosCompetenciasDTO));
         const resDTO = new AsignacionCargosCompetenciasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
     }

    public async updateCompetencesBulk(overwrite: boolean, asignacionCargosCompetenciasDTO: AsignacionCargosCompetenciasDTO): Promise<void> {
         await lastValueFrom(this.http.put(Constants.apiURL + '/competences/bulk' + '?overwrite=' + overwrite, asignacionCargosCompetenciasDTO));
    }

    public async getCompetencesTeammatesTeammateId(id: number): Promise<Array<CompetenceCategoryDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/teammates/' + id));
      const resJson = res.data;
      const resDTO = new Array<CompetenceCategoryDTO>();
      for (const item of resJson) {
         const itemDTO = new CompetenceCategoryDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
    }


    public async getOverallPerformance(overallEvaluationId: number = undefined, teammateId:number = undefined ): Promise<OverallPerformanceDTO> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/competences/overallPerformance?overallEvaluationId=' + overallEvaluationId + '&teammate=' + teammateId));
      if (!res) { return null; }
      const resDTO = new OverallPerformanceDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
    }

    public async getBehaviors(overallEvaluationId: number = undefined): Promise<CompetenceCategoryDTO[]> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/competences/behaviors'+ '?overallEvaluationId=' + overallEvaluationId));
      const resJson = res.data;
      const resDTO = new Array<CompetenceCategoryDTO>();
      for (const item of resJson) {
         const itemDTO = new CompetenceCategoryDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
    }

}
