import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CuestionarioPreguntaDTO, CuestionarioRespuestaDTO, TipoEstadoPersonalLegajoCuestinario } from 'src/app/ModelDTO/DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';

@Component({
  selector: 'app-numeric-question',
  templateUrl: './numeric-question.component.html',
  styleUrls: ['./numeric-question.component.scss'],
})
export class NumericQuestionComponent implements QuestionComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;
  @Input() public answerCommentEnabled: boolean;

  @Output() public commentClicked = new EventEmitter<void>();

  public get answer(): string {
    return Number.isNaN(Number(this.data?.respuesta?.valorNumerico)) ? 'Sin responder' : this.data.respuesta.valorNumerico.toString();
  }

  public respuestaChange(respuesta: CuestionarioRespuestaDTO, value: number): void {
    respuesta.modificada = true;
    respuesta.fecha = new Date();
    respuesta.valorNumerico = value;
    respuesta.id = this.data.id;
  }

  public onCommentClicked(): void {
    this.commentClicked.emit();
  }
}
