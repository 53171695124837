export enum ExpansionResumen {
  No_definido,
  Sanciones,
}

export enum SectionsEmployee {
  DatosPersonales,
  DatosLaborales,
  Documentos,
  Ausencias,
  Entregas,
  Disciplina,
  Feedback,
  Capacitaciones,
  Politicas,
  Trayectoria,
  CambioDeEstado,
  Notas,
  Company,
}
