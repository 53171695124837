import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';
import { NotificationPriority } from './notificationPriority.ENUM';
import { NotificationState } from './notificationState.ENUM';

export class NotificationTotalDTO implements IEntityDTO {
  userName = '';
  priority: NotificationPriority;
  notificationType = 0;
  state: NotificationState;
  creationDateTime: Date;
  openDateTime: Date;
  readDateTime: Date;
  disableUnderstanding: boolean;
  understoodDateTime: Date;
  notUnderstood: boolean;
  title = '';
  notShowInMenu: boolean;
  details = '';
  serviceNotify = '';
  service = '';
  serviceId = 0;
  serviceId2 = 0;
  creatorId = 0;
  teammateFileCreatorId = 0;
  tenant = '';
  image = '';
  reactions: string | undefined;
  currentReaction: string | undefined;
  total = 0;
  unreaded = 0;
  readed = 0;
  attachmentsCount = 0;
  commentsCount = 0;
  archived: boolean;
  withAttachments: boolean;
  id = 0;
  cacheStamp = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.userName != null) {
      this.userName = jsonObj.userName;
    }
    if (jsonObj.priority != null) {
      this.priority = jsonObj.priority as NotificationPriority;
    }
    if (jsonObj.notificationType != null) {
      this.notificationType = jsonObj.notificationType;
    }
    if (jsonObj.state != null) {
      this.state = jsonObj.state as NotificationState;
    }
    if (jsonObj.creationDateTime != null) {
      this.creationDateTime = new Date(jsonObj.creationDateTime);
    }
    if (jsonObj.openDateTime != null) {
      this.openDateTime = new Date(jsonObj.openDateTime);
    }
    if (jsonObj.readDateTime != null) {
      this.readDateTime = new Date(jsonObj.readDateTime);
    }
    if (jsonObj.disableUnderstanding != null) {
      this.disableUnderstanding = jsonObj.disableUnderstanding;
    }
    if (jsonObj.understoodDateTime != null) {
      this.understoodDateTime = new Date(jsonObj.understoodDateTime);
    }
    if (jsonObj.notUnderstood != null) {
      this.notUnderstood = jsonObj.notUnderstood;
    }
    if (jsonObj.title != null) {
      this.title = jsonObj.title;
    }
    if (jsonObj.notShowInMenu != null) {
      this.notShowInMenu = jsonObj.notShowInMenu;
    }
    if (jsonObj.details != null) {
      this.details = jsonObj.details;
    }
    if (jsonObj.serviceNotify != null) {
      this.serviceNotify = jsonObj.serviceNotify;
    }
    if (jsonObj.service != null) {
      this.service = jsonObj.service;
    }
    if (jsonObj.serviceId != null) {
      this.serviceId = jsonObj.serviceId;
    }
    if (jsonObj.serviceId2 != null) {
      this.serviceId2 = jsonObj.serviceId2;
    }
    if (jsonObj.creatorId != null) {
      this.creatorId = jsonObj.creatorId;
    }
    if (jsonObj.teammateFileCreatorId != null) {
      this.teammateFileCreatorId = jsonObj.teammateFileCreatorId;
    }
    if (jsonObj.tenant != null) {
      this.tenant = jsonObj.tenant;
    }
    if (jsonObj.image != null) {
      this.image = jsonObj.image;
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.cacheStamp != null) {
      this.cacheStamp = jsonObj.cacheStamp;
    }
    if (jsonObj.reactions != null) {
      this.reactions = jsonObj.reactions;
    }
    if (jsonObj.currentReaction != null) {
      this.currentReaction = jsonObj.currentReaction;
    }
    if (jsonObj.total != null) {
      this.total = jsonObj.total;
    }
    if (jsonObj.unreaded != null) {
      this.unreaded = jsonObj.unreaded;
    }
    if (jsonObj.readed != null) {
      this.readed = jsonObj.readed;
    }
    if (jsonObj.attachmentsCount != null) {
      this.attachmentsCount = jsonObj.attachmentsCount;
    }
    if (jsonObj.commentsCount != null) {
      this.commentsCount = jsonObj.commentsCount;
    }
    if (jsonObj.archived != null) {
      this.archived = jsonObj.archived;
    }
    if (jsonObj.withAttachments != null) {
      this.withAttachments = jsonObj.withAttachments;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
