import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class HorasTrabajadasPorLegajoDiaDTO implements IEntityDTO {

   deFranco: boolean;
   esFeriado: boolean;
   diaTrabajado: boolean;
   francoTrabajado: boolean;
   enVacaciones: boolean;
   enLicencia: boolean;
   horaDesde: Date;
   horaHasta: Date;
   geoLocalizacionIngreso = '';
   geoLocalizacionSalida = '';
   cantHorasTrabajadas = '';
   cantHorasExtras50 = '';
   cantHorasExtras100 = '';
   cantHorasNocturnas = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.deFranco != null) { this.deFranco = jsonObj.deFranco; }
      if (jsonObj.esFeriado != null) { this.esFeriado = jsonObj.esFeriado; }
      if (jsonObj.diaTrabajado != null) { this.diaTrabajado = jsonObj.diaTrabajado; }
      if (jsonObj.francoTrabajado != null) { this.francoTrabajado = jsonObj.francoTrabajado; }
      if (jsonObj.enVacaciones != null) { this.enVacaciones = jsonObj.enVacaciones; }
      if (jsonObj.enLicencia != null) { this.enLicencia = jsonObj.enLicencia; }
      if (jsonObj.horaDesde != null) { this.horaDesde = new Date(jsonObj.horaDesde); }
      if (jsonObj.horaHasta != null) { this.horaHasta = new Date(jsonObj.horaHasta); }
      if (jsonObj.geoLocalizacionIngreso != null) { this.geoLocalizacionIngreso = jsonObj.geoLocalizacionIngreso; }
      if (jsonObj.geoLocalizacionSalida != null) { this.geoLocalizacionSalida = jsonObj.geoLocalizacionSalida; }
      if (jsonObj.cantHorasTrabajadas != null) { this.cantHorasTrabajadas = jsonObj.cantHorasTrabajadas; }
      if (jsonObj.cantHorasExtras50 != null) { this.cantHorasExtras50 = jsonObj.cantHorasExtras50; }
      if (jsonObj.cantHorasExtras100 != null) { this.cantHorasExtras100 = jsonObj.cantHorasExtras100; }
      if (jsonObj.cantHorasNocturnas != null) { this.cantHorasNocturnas = jsonObj.cantHorasNocturnas; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
