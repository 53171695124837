<div class="d-flex flex-column flex-grow-1 pt-2">
  <ng-container *ngIf="options?.length > 0">
    <table mat-table [dataSource]="options">
      <ng-container matColumnDef="Opciones">
        <th mat-header-cell *matHeaderCellDef>{{ 'FORMS.OPTION' | translate }}</th>
        <td mat-cell *matCellDef="let option">
          {{ option.texto }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Peso">
        <th mat-header-cell *matHeaderCellDef>{{ 'FORMS.WEIGHT' | translate }}</th>
        <td mat-cell *matCellDef="let option">
          {{ option.pesoSeleccionado }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Accion">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="text-right" mat-cell *matCellDef="let option">
          <button color="danger" mat-icon-button class="mr-2" [command]="removeCmd" [commandValue]="option">
            <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
          </button>
          <button color="primary" mat-icon-button [command]="editCmd" [commandValue]="option">
            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let option; columns: displayedColumns"></tr>
    </table>
  </ng-container>
</div>
