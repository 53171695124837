import { IEntityDTO, NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CargoConCategoriasDTO } from 'src/app/ModelDTO/DTO/cargoConCategorias.DTO';


export class AsignacionCargosCompetenciasDTO implements IEntityDTO {

   cargos: Array<CargoConCategoriasDTO>;
   conflictos: Array<NameDetailDTO>;
   id = 0;

   constructor() {
      this.cargos = new Array<CargoConCategoriasDTO>();
      this.conflictos = new Array<NameDetailDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.cargos != null) { for (const item of jsonObj.cargos) { const itemDTO = new CargoConCategoriasDTO(); itemDTO.PrepareDTO(item); this.cargos.push(itemDTO); } }
      if (jsonObj.conflictos != null) { for (const item of jsonObj.conflictos) { const itemDTO = new NameDetailDTO(); itemDTO.PrepareDTO(item); this.conflictos.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
