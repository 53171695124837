import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoUnidadDevolucion } from 'src/app/ModelDTO/DTO/tipoUnidadDevolucion.ENUM';
import { CategoriaEntregaBasicaDTO } from 'src/app/ModelDTO/DTO/categoriaEntregaBasica.DTO';
import { SubcategoriaEntregaDTO } from 'src/app/ModelDTO/DTO/subcategoriaEntrega.DTO';
import { TipoDevolucionEntrega } from 'src/app/ModelDTO/DTO/tipoDevolucionEntrega.ENUM';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';


export class TipoEntregaDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   tiempoDevolucion = 0;
   marca = '';
   modelo = '';
   poseeCertificacion: boolean;
   unidadDevolucion: TipoUnidadDevolucion;
   categoria: CategoriaEntregaBasicaDTO;
   subcategoria: SubcategoriaEntregaDTO;
   tipoDevolucion: TipoDevolucionEntrega;
   diasPrevioAviso = 0;
   cargos: Array<CargoDTO>;
   convenio: ConvenioColectivoDTO;
   asignarColaboradoresExistentes: boolean;
   generarConstancia: boolean;
   solicitarFirma: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.categoria = new CategoriaEntregaBasicaDTO();
      this.subcategoria = new SubcategoriaEntregaDTO();
      this.cargos = new Array<CargoDTO>();
      this.convenio = new ConvenioColectivoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.tiempoDevolucion != null) { this.tiempoDevolucion = jsonObj.tiempoDevolucion; }
      if (jsonObj.marca != null) { this.marca = jsonObj.marca; }
      if (jsonObj.modelo != null) { this.modelo = jsonObj.modelo; }
      if (jsonObj.poseeCertificacion != null) { this.poseeCertificacion = jsonObj.poseeCertificacion; }
      if (jsonObj.unidadDevolucion != null) { this.unidadDevolucion = jsonObj.unidadDevolucion as TipoUnidadDevolucion; }
      if (jsonObj.categoria != null) { this.categoria.PrepareDTO(jsonObj.categoria); }
      if (jsonObj.subcategoria != null) { this.subcategoria.PrepareDTO(jsonObj.subcategoria); }
      if (jsonObj.tipoDevolucion != null) { this.tipoDevolucion = jsonObj.tipoDevolucion as TipoDevolucionEntrega; }
      if (jsonObj.diasPrevioAviso != null) { this.diasPrevioAviso = jsonObj.diasPrevioAviso; }
      if (jsonObj.cargos != null) { for (const item of jsonObj.cargos) { const itemDTO = new CargoDTO(); itemDTO.PrepareDTO(item); this.cargos.push(itemDTO); } }
      if (jsonObj.convenio != null) { this.convenio.PrepareDTO(jsonObj.convenio); }
      if (jsonObj.asignarColaboradoresExistentes != null) { this.asignarColaboradoresExistentes = jsonObj.asignarColaboradoresExistentes; }
      if (jsonObj.generarConstancia != null) { this.generarConstancia = jsonObj.generarConstancia; }
      if (jsonObj.solicitarFirma != null) { this.solicitarFirma = jsonObj.solicitarFirma; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
