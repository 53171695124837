import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoUnidadDevolucion } from 'src/app/ModelDTO/DTO/tipoUnidadDevolucion.ENUM';
import { CategoriaCapacitacionBasicaDTO } from 'src/app/ModelDTO/DTO/categoriaCapacitacionBasica.DTO';
import { CategoriaCapacitacionBasicaModelDTO } from 'src/app/ModelDTO/categoriaCapacitacionBasica.ModelDTO';
import { SubcategoriaCapacitacionDTO } from 'src/app/ModelDTO/DTO/subcategoriaCapacitacion.DTO';
import { SubcategoriaCapacitacionModelDTO } from 'src/app/ModelDTO/subcategoriaCapacitacion.ModelDTO';
import { TipoDevolucionCapacitacion } from 'src/app/ModelDTO/DTO/tipoDevolucionCapacitacion.ENUM';
import { TipoModalidad } from 'src/app/ModelDTO/DTO/tipoModalidad.ENUM';
import { TipoCaracter } from 'src/app/ModelDTO/DTO/tipoCaracter.ENUM';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { CargoModelDTO } from 'src/app/ModelDTO/cargo.ModelDTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { ConvenioColectivoModelDTO } from 'src/app/ModelDTO/convenioColectivo.ModelDTO';


export class TipoCapacitacionModelDTO extends EntityModelDTO<TipoCapacitacionDTO> {

   private categoriaModel: CategoriaCapacitacionBasicaModelDTO;
   private categoriaSubscribe: Subscription;
   private subcategoriaModel: SubcategoriaCapacitacionModelDTO;
   private subcategoriaSubscribe: Subscription;
   private convenioModel: ConvenioColectivoModelDTO;
   private convenioSubscribe: Subscription;

   public constructor(protected entityDTO: TipoCapacitacionDTO) {
      super(entityDTO);
   }

   public static getTipoUnidadDevolucion(): string[] {
      return EntityModelDTO.getEnumArray(TipoUnidadDevolucion);
   }

   public static getTipoDevolucionCapacitacion(): string[] {
      return EntityModelDTO.getEnumArray(TipoDevolucionCapacitacion);
   }

   public static getTipoModalidad(): string[] {
      return EntityModelDTO.getEnumArray(TipoModalidad);
   }

   public static getTipoCaracter(): string[] {
      return EntityModelDTO.getEnumArray(TipoCaracter);
   }

   public setEntityDTO(entityDTO: TipoCapacitacionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.categoriaModel = new CategoriaCapacitacionBasicaModelDTO(this.entityDTO.categoria);
      this.categoriaSubscribe = this.categoriaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.subcategoriaModel = new SubcategoriaCapacitacionModelDTO(this.entityDTO.subcategoria);
      this.subcategoriaSubscribe = this.subcategoriaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.convenioModel = new ConvenioColectivoModelDTO(this.entityDTO.convenio);
      this.convenioSubscribe = this.convenioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.categoriaModel.dispose();
      this.categoriaSubscribe.unsubscribe();
      this.subcategoriaModel.dispose();
      this.subcategoriaSubscribe.unsubscribe();
      this.convenioModel.dispose();
      this.convenioSubscribe.unsubscribe();
   }

   get Nombre(): string { return this.entityDTO.nombre; }
   set Nombre(value: string) { this.notifyChangeDTO('nombre', value); }

   get Descripcion(): string { return this.entityDTO.descripcion; }
   set Descripcion(value: string) { this.notifyChangeDTO('descripcion', value); }

   get TiempoDevolucion(): number { return this.entityDTO.tiempoDevolucion; }
   set TiempoDevolucion(value: number) { this.notifyChangeDTO('tiempoDevolucion', value); }

   get UnidadDevolucion(): string { return TipoUnidadDevolucion[this.entityDTO.unidadDevolucion]; }
   set UnidadDevolucion(value: string) { this.notifyChangeDTO('unidadDevolucion', TipoUnidadDevolucion[value]); }

   get CategoriaModel(): CategoriaCapacitacionBasicaModelDTO { return this.categoriaModel; }
   get Categoria(): CategoriaCapacitacionBasicaDTO { return this.categoriaModel.getEntityDTO(); }
   set Categoria(value: CategoriaCapacitacionBasicaDTO) { this.notifyChange(() => { this.entityDTO.categoria = value; this.categoriaModel.setEntityDTO(value); }); }

   get SubcategoriaModel(): SubcategoriaCapacitacionModelDTO { return this.subcategoriaModel; }
   get Subcategoria(): SubcategoriaCapacitacionDTO { return this.subcategoriaModel.getEntityDTO(); }
   set Subcategoria(value: SubcategoriaCapacitacionDTO) { this.notifyChange(() => { this.entityDTO.subcategoria = value; this.subcategoriaModel.setEntityDTO(value); }); }

   get TipoDevolucion(): string { return TipoDevolucionCapacitacion[this.entityDTO.tipoDevolucion]; }
   set TipoDevolucion(value: string) { this.notifyChangeDTO('tipoDevolucion', TipoDevolucionCapacitacion[value]); }

   get Modalidad(): string { return TipoModalidad[this.entityDTO.modalidad]; }
   set Modalidad(value: string) { this.notifyChangeDTO('modalidad', TipoModalidad[value]); }

   get Caracter(): string { return TipoCaracter[this.entityDTO.caracter]; }
   set Caracter(value: string) { this.notifyChangeDTO('caracter', TipoCaracter[value]); }

   get DiasPrevioAviso(): number { return this.entityDTO.diasPrevioAviso; }
   set DiasPrevioAviso(value: number) { this.notifyChangeDTO('diasPrevioAviso', value); }

   get Cargos(): Array<CargoDTO> { return this.entityDTO.cargos; }
   set Cargos(value: Array<CargoDTO>) { this.notifyChangeDTO('cargos', value); }

   get ConvenioModel(): ConvenioColectivoModelDTO { return this.convenioModel; }
   get Convenio(): ConvenioColectivoDTO { return this.convenioModel.getEntityDTO(); }
   set Convenio(value: ConvenioColectivoDTO) { this.notifyChange(() => { this.entityDTO.convenio = value; this.convenioModel.setEntityDTO(value); }); }

   get AsignarColaboradoresExistentes(): boolean { return this.entityDTO.asignarColaboradoresExistentes; }
   set AsignarColaboradoresExistentes(value: boolean) { this.notifyChangeDTO('asignarColaboradoresExistentes', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
