<div class="d-flex flex-column p-relative justify-content-center align-items-center">
  <input
    hidden
    type="file"
    accept="image/jpeg,image/png,image/jpg;capture=camera"
    (change)="fileChangeEvent($event)"
    #fileImage
    id="file"
  />
  <app-circle-image
    (click)="clickImageInput()"
    appDragAndDrop
    (fileDropped)="dropImagen($event)"
    [imageSource]="candidateImage?.imagen"
    [width]="width"
    [invalid]="invalid"
  >
  </app-circle-image>
  <mat-error class="required-field text-center" *ngIf="invalid">{{ 'GENERAL.REQUIRED_FIELD' | translate }} </mat-error>
  <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary" type="button">
    <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
  </button>
</div>
