import { EncuestaEvaluatorDTO } from 'src/app/ModelDTO/DTO/encuestaEvaluator.DTO';
import { EvaluatorRole } from 'src/app/ModelDTO/DTO/evaluatorRole.ENUM';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';


export class EncuestaEvaluatorModelDTO extends EntityModelDTO<EncuestaEvaluatorDTO> {


   public constructor(protected entityDTO: EncuestaEvaluatorDTO) {
      super(entityDTO);
   }

   public static getEvaluatorRole(): string[] {
      return EntityModelDTO.getEnumArray(EvaluatorRole);
   }

   public setEntityDTO(entityDTO: EncuestaEvaluatorDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get Role(): string { return EvaluatorRole[this.entityDTO.role]; }
   set Role(value: string) { this.notifyChangeDTO('role', EvaluatorRole[value]); }

   get Percentage(): number { return this.entityDTO.percentage; }
   set Percentage(value: number) { this.notifyChangeDTO('percentage', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }
}
