<ngx-skeleton-loader
  count="1"
  appearance="line"
  [theme]="{
    'background-color': '#83c1fa',
    height: '10px',
    'border-radius': '0px'
  }"
  animation="pulse"
></ngx-skeleton-loader>
