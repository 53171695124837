import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';

export class ImageEntityDTO implements IEntityDTO {
  image = '';
  id = 0;
  cacheStamp = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.image != null) {
      this.image = jsonObj.image;
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.cacheStamp != null) {
      this.cacheStamp = jsonObj.cacheStamp;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
