import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { PersonalSancionDTO } from 'src/app/ModelDTO/DTO/personalSancion.DTO';
import { SancionDTO } from 'src/app/ModelDTO/DTO/sancion.DTO';
import { TipoSancionDTO } from 'src/app/ModelDTO/DTO/tipoSancion.DTO';
import { AuditLogEntryDTO, DataDTO, NamedBlobDTO, NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class SancionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getComplianceSancionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/compliance/sancion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getComplianceSancionSancionados(pageNumber: number, pageSize: number): Promise<Array<PersonalSancionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/compliance/sancion/sancionados' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalSancionDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalSancionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getComplianceSancionId(id: number, fechaDesde: Date, fechaHasta: Date, todas: boolean = true): Promise<Array<SancionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/compliance/sancion/' + id + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&todas=' + todas));
         const resJson = res.data;
         const resDTO = new Array<SancionDTO>();
         for (const item of resJson) {
            const itemDTO = new SancionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getComplianceSancionIdIDSANCION(id: number, idSancion: number): Promise<PersonalSancionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/compliance/sancion/' + id + '/' + idSancion));
         if (!res) { return null; }
         const resDTO = new PersonalSancionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertComplianceSancionId(id: number, sancionDTO: FormData): Promise<SancionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/compliance/sancion/' + id, sancionDTO));
         const resDTO = new SancionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteComplianceSancionIdIDSANCION(id: number, iDSANCION: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/compliance/sancion/' + id + '/' + iDSANCION));

   }

   public async getComplianceSancionTipoSancion(): Promise<Array<TipoSancionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/compliance/sancion/tipoSancion'));
         const resJson = res.data;
         const resDTO = new Array<TipoSancionDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoSancionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number): Promise<TipoSancionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION));
         if (!res) { return null; }
         const resDTO = new TipoSancionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertComplianceSancionTipoSancion(tipoSancionDTO: TipoSancionDTO): Promise<TipoSancionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/compliance/sancion/tipoSancion', tipoSancionDTO));
         const resDTO = new TipoSancionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number, tipoSancionDTO: TipoSancionDTO): Promise<TipoSancionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION, tipoSancionDTO));
         const resDTO = new TipoSancionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteComplianceSancionTipoSancionIDTIPOSANCION(iDTIPOSANCION: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/compliance/sancion/tipoSancion/' + iDTIPOSANCION));

   }

   public async getComplianceSancionIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertComplianceSancionIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios', stream));

   }

   public async updateComplianceSancionIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteComplianceSancionIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/compliance/sancion/' + id + '/comentarios/' + iDComentario));

   }

   public async getComplianceSancionExportXLSX(from: Date, to: Date, includeDismissed: boolean = false, namedBlob: NamedBlobDTO = null): Promise<Blob> {
         const res = await lastValueFrom(this.http.get(Constants.apiURL +
'/compliance/sancion/export' + '?from=' + from?.toISOString() +
            '&to=' + to?.toISOString() +
            '&includeDismissed=' + includeDismissed + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

}
