import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { BackendConfigurationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

@Injectable({
   providedIn: 'root'
})
export class ConfigureBackendServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getConfigureBackend(): Promise<BackendConfigurationDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/configureBackend/'));
         if (!res) { return null; }
         const resDTO = new BackendConfigurationDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateConfigureBackendId(id: number, backendConfigurationDTO: BackendConfigurationDTO): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/configureBackend/' + id, backendConfigurationDTO));

   }

   public async deleteConfigureBackendNhibernateCache(headers: HttpHeaders ): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/configureBackend/nhibernate-cache', { headers }));

   }

}
