<div class="d-flex flex-row w-100">
  <ng-container *ngIf="personalModelDTO; else skeletonLoader">
    <div class="overflow-auto flex-shrink-0 sidebar-empleado hidden-sm">
      <div class="d-flex justify-content-center mb-4 p-relative">
        <input
          hidden
          type="file"
          accept="image/jpeg,image/png,image/jpg;capture=camera"
          (change)="fileChangeEvent($event)"
          #fileImage
          id="file"
        />
        <app-circle-image (click)="clickImageInput()" appDragAndDrop (fileDropped)="dropImagen($event)" [imageSource]="profileOwnImageUrl">
        </app-circle-image>
        <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary" *ngIf="canEdit">
          <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
        </button>
      </div>
      <ng-container class="d-flex flex-column">
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.DatosPersonales)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.DatosPersonales"
        >
          {{ 'PROFILE.PERSONAL_INFORMATION' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.DatosLaborales)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.DatosLaborales"
        >
          {{ 'PROFILE.WORKING_INFORMATION' | translate }}
        </a>
        <a
          *ngIf="(vistaEmpresa$ | async) !== true"
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.Company)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.Company"
        >
          {{ 'PROFILE.BUSINESS_DATA' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.Notas)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.Notas"
        >
          {{ 'GENERAL.NOTES' | translate }}
        </a>
        <ng-container *ngIf="vistaEmpresa$ | async">
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureDocumentos'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Documentos)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Documentos"
          >
            {{ 'GENERAL.DOCUMENTS' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureAusenciasEquipo'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Ausencias)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Ausencias"
          >
            {{ 'GENERAL.TIMEOFF' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureEntregas'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Entregas)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Entregas"
          >
            {{ 'GENERAL.DELIVERIES' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureSancion'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Disciplina)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Disciplina"
          >
            {{ 'GENERAL.COMPLIANCE' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureFeedback'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Feedback)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Feedback"
          >
            {{ 'GENERAL.FEEDBACK' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureCapacitaciones'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Capacitaciones)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.Capacitaciones"
          >
            {{ 'GENERAL.TRAININGS' | translate }}
          </a>
        </ng-container>
        <a
          class="list-group-item"
          mat-list-item
          *ngIfFeatureFlag="'featurePoliticas'"
          (click)="selectedItem(SectionsEmployee.Politicas)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.Politicas"
        >
          {{ 'PROFILE.POLICIES' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.Trayectoria)"
          [class.selected-item-sidebar]="currentSection === SectionsEmployee.Trayectoria"
        >
          {{ 'PROFILE.CAREER' | translate }}
        </a>
        <ng-container *ngIf="vistaEmpresa$ | async">
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureCambioRol'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.CambioDeEstado)"
            [class.selected-item-sidebar]="currentSection === SectionsEmployee.CambioDeEstado"
          >
            {{ 'PROFILE.STATUS_CHANGE' | translate }}
          </a>
        </ng-container>
      </ng-container>
    </div>
    <div class="flex-grow-1 p-relative overflow-auto">
      <div class="p-absolute h-100 w-100">
        <div class="d-flex flex-column pad-selection hidden-lg">
          <div class="d-flex justify-content-center mb-4 p-relative">
            <input
              hidden
              type="file"
              accept="image/jpeg,image/png,image/jpg;capture=camera"
              (change)="fileChangeEvent($event)"
              #fileImage
              id="file"
            />
            <app-circle-image
              (click)="clickImageInput()"
              appDragAndDrop
              (fileDropped)="dropImagen($event)"
              [imageSource]="profileOwnImageUrl"
            >
            </app-circle-image>
            <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary" *ngIf="canEdit">
              <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
            </button>
          </div>
          <mat-form-field class="flex-fill gap-1" appearance="outline">
            <mat-select [(ngModel)]="currentSection" (selectionChange)="selectedItem(currentSection)">
              <mat-option [value]="SectionsEmployee.DatosPersonales"> {{ 'PROFILE.PERSONAL_INFORMATION' | translate }}</mat-option>
              <mat-option [value]="SectionsEmployee.DatosLaborales">{{ 'PROFILE.WORKING_INFORMATION' | translate }}</mat-option>
              <mat-option *ngIf="(vistaEmpresa$ | async) !== true" [value]="SectionsEmployee.Company">
                {{ 'PROFILE.BUSINESS_DATA' | translate }}
              </mat-option>
              <mat-option [value]="SectionsEmployee.Notas">{{ 'GENERAL.NOTES' | translate }}</mat-option>
              <ng-container *ngIf="vistaEmpresa$ | async">
                <mat-option *ngIfFeatureFlag="'featureDocumentos'" [value]="SectionsEmployee.Documentos">
                  {{ 'GENERAL.DOCUMENTS' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureAusenciasEquipo'" [value]="SectionsEmployee.Ausencias">
                  {{ 'GENERAL.TIMEOFF' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureEntregas'" [value]="SectionsEmployee.Entregas">
                  {{ 'GENERAL.DELIVERIES' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureSancion'" [value]="SectionsEmployee.Disciplina">
                  {{ 'GENERAL.COMPLIANCE' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureFeedback'" [value]="SectionsEmployee.Feedback">
                  {{ 'GENERAL.FEEDBACK' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureCapacitaciones'" [value]="SectionsEmployee.Capacitaciones">
                  {{ 'GENERAL.TRAININGS' | translate }}
                </mat-option>
              </ng-container>
              <mat-option *ngIfFeatureFlag="'featurePoliticas'" [value]="SectionsEmployee.Politicas">
                {{ 'PROFILE.POLICIES' | translate }}
              </mat-option>
              <mat-option [value]="SectionsEmployee.Trayectoria">{{ 'PROFILE.CAREER' | translate }}</mat-option>
              <ng-container *ngIf="vistaEmpresa$ | async">
                <mat-option *ngIfFeatureFlag="'featureCambioRol'" [value]="SectionsEmployee.CambioDeEstado">
                  {{ 'PROFILE.STATUS_CHANGE' | translate }}</mat-option
                >
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <app-datos-personales-agrupados
          [modoEmpresa]="vistaEmpresa$ | async"
          [modoEdicion]="modoEdicion"
          [personalModel]="personalModelDTO"
          [contactForm]="contactForm"
          *ngIf="currentSection === SectionsEmployee.DatosPersonales"
        >
        </app-datos-personales-agrupados>
        <app-datos-contratacion
          [modoEmpresa]="vistaEmpresa$ | async"
          [modoEdicion]="modoEdicion"
          [personalModel]="personalModelDTO"
          *ngIf="currentSection === SectionsEmployee.DatosLaborales"
        >
        </app-datos-contratacion>
        <app-datos-resumen
          [modoEmpresa]="vistaEmpresa$ | async"
          [modoEdicion]="modoEdicion"
          [personalModel]="personalModelDTO"
          *ngIf="currentSection === SectionsEmployee.Politicas"
        >
        </app-datos-resumen>
        <app-cambio-de-estado
          [modoEmpresa]="vistaEmpresa$ | async"
          [modoEdicion]="modoEdicion"
          [personalModel]="personalModelDTO"
          *ngIf="currentSection === SectionsEmployee.CambioDeEstado"
        >
        </app-cambio-de-estado>
        <app-datos-historicos
          [historial]="historial"
          [modoEmpresa]="vistaEmpresa$ | async"
          [modoEdicion]="modoEdicion"
          [personalModel]="personalModelDTO"
          *ngIf="currentSection === SectionsEmployee.Trayectoria"
        >
        </app-datos-historicos>
        <div class="d-flex flex-row p-6" *ngIf="currentSection === SectionsEmployee.Notas">
          <app-comentarios
            *ngIf="vistaEmpresa$ | async"
            [id]="personalModelDTO.Legajo.id"
            [comentarioHandler]="comentarioHandler"
            [canBeNotified]="[convertToJson(personalModelDTO.Legajo)]"
            cantBeNotifiedKey="NO_NOTIFICATION_TEAMMATE_PROFILE"
          >
          </app-comentarios>
          <app-comentarios-lista
            *ngIf="(vistaEmpresa$ | async) === false"
            class="w-100"
            [id]="personalModelDTO.Legajo.id"
            [comentarioHandler]="comentarioHandler"
          >
          </app-comentarios-lista>
        </div>
        <div class="m-2 pb-2 d-flex-flex-column">
          <div
            class="d-flex flex-row flex-wrap justify-content-between align-items-center"
            *ngIf="currentSection === SectionsEmployee.Entregas"
          >
            <h3>{{ 'GENERAL.DELIVERIES' | translate }}</h3>
            <button type="button" mat-button color="primary" [command]="altaEntregaCmd">{{ 'DELIVERIES.ADD_DELIVERY' | translate }}</button>
          </div>
          <ng-template
            *ngIf="lazyEntregas && currentSection === SectionsEmployee.Entregas"
            [ngComponentOutlet]="lazyEntregas | async"
          ></ng-template>

          <div
            class="d-flex flex-row flex-wrap justify-content-between align-items-center"
            *ngIf="currentSection === SectionsEmployee.Capacitaciones"
          >
            <h3>{{ 'GENERAL.TRAININGS' | translate }}</h3>
            <button type="button" mat-button color="primary" [command]="altaCapacitacionCmd">
              {{ 'TRAINING.ASSIGN_TRAINING' | translate }}
            </button>
          </div>
          <ng-template
            *ngIf="lazyCapacitaciones && currentSection === SectionsEmployee.Capacitaciones"
            [ngComponentOutlet]="lazyCapacitaciones | async"
          ></ng-template>

          <div
            class="d-flex flex-row flex-wrap align-items-center justify-content-between"
            *ngIf="currentSection === SectionsEmployee.Disciplina"
          >
            <h3>Compliance</h3>
            <button type="button" mat-button color="primary" [command]="altaSancionCmd">Registrar</button>
          </div>
          <ng-template
            *ngIf="lazyDisciplina && currentSection === SectionsEmployee.Disciplina"
            [ngComponentOutlet]="lazyDisciplina | async"
          ></ng-template>
          <div
            class="d-flex flex-row flex-wrap align-items-center justify-content-between"
            *ngIf="currentSection === SectionsEmployee.Feedback"
          >
            <h3>Feedback</h3>
            <button type="button" mat-button color="primary" [command]="altaFeedbackCmd">Dar feedback</button>
          </div>
          <ng-template
            *ngIf="lazyFeedback && currentSection === SectionsEmployee.Feedback"
            [ngComponentOutlet]="lazyFeedback | async"
          ></ng-template>
          <div
            class="d-flex flex-row flex-wrap align-items-center justify-content-between"
            *ngIf="currentSection === SectionsEmployee.Ausencias"
          >
            <h3>Ausencias</h3>
            <button type="button" mat-button color="primary" [command]="altaAusenciaCmd">Agregar ausencia</button>
          </div>

          <ng-template
            *ngIf="lazyAusencias && currentSection === SectionsEmployee.Ausencias"
            [ngComponentOutlet]="lazyAusencias | async"
          ></ng-template>

          <ng-template
            *ngIf="lazyDocumentos && currentSection === SectionsEmployee.Documentos"
            [ngComponentOutlet]="lazyDocumentos | async"
          ></ng-template>

          <ng-template
            *ngIf="lazyCompany && currentSection === SectionsEmployee.Company"
            [ngComponentOutlet]="lazyCompany | async"
          ></ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #skeletonLoader>
    <div class="w-25 mt-4 hidden-sm">
      <ngx-skeleton-loader [theme]="{ margin: '1rem 0 0 10px' }" count="8" appearance="line"></ngx-skeleton-loader>
    </div>
    <div class="flex-grow-1">
      <app-skeleton-card [lines]="10" [hideBorder]="true" [pictureTheme]="{ height: '64px', width: '64px' }"></app-skeleton-card>
    </div>
  </ng-template>
</div>
