import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FichandoDTO } from 'src/app/ModelDTO/DTO/fichando.DTO';


export class FichandoModelDTO extends EntityModelDTO<FichandoDTO> {


   public constructor(protected entityDTO: FichandoDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: FichandoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
   }

   get FechaFichaje(): Date { return this.entityDTO.fechaFichaje; }
   set FechaFichaje(value: Date) { this.notifyChangeDTO('fechaFichaje', value); }

   get GeoLocalizacion(): string { return this.entityDTO.geoLocalizacion; }
   set GeoLocalizacion(value: string) { this.notifyChangeDTO('geoLocalizacion', value); }

   get ImageUrl(): string { return this.entityDTO.imageUrl; }
   set ImageUrl(value: string) { this.notifyChangeDTO('imageUrl', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
