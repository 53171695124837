import { InformacionMasivaDTO } from 'src/app/ModelDTO/DTO/informacionMasiva.DTO';
import { IEntityDTO, NameDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';


export class DistribucionMasivaDTO implements IEntityDTO {

   informacionesMasivas: Array<InformacionMasivaDTO>;
   errores: Array<NameDetailDTO>;
   id = 0;

   constructor() {
      this.informacionesMasivas = new Array<InformacionMasivaDTO>();
      this.errores = new Array<NameDetailDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.informacionesMasivas != null) { for (const item of jsonObj.informacionesMasivas) { const itemDTO = new InformacionMasivaDTO(); itemDTO.PrepareDTO(item); this.informacionesMasivas.push(itemDTO); } }
      if (jsonObj.errores != null) { for (const item of jsonObj.errores) { const itemDTO = new NameDetailDTO(); itemDTO.PrepareDTO(item); this.errores.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
