import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalSancionDTO } from 'src/app/ModelDTO/DTO/personalSancion.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';
import { TipoSancionDTO } from 'src/app/ModelDTO/DTO/tipoSancion.DTO';
import { TipoSancionModelDTO } from 'src/app/ModelDTO/tipoSancion.ModelDTO';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';
import { PersonalAusenciaModelDTO } from 'src/app/ModelDTO/personalAusencia.ModelDTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO/attachmentUaaloo.ModelDTO';
import { AttachmentConNuevosFirmantesDTO } from 'src/app/ModelDTO/DTO/attachmentConNuevosFirmantes.DTO';
import { AttachmentConNuevosFirmantesModelDTO } from 'src/app/ModelDTO/attachmentConNuevosFirmantes.ModelDTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { ComentarioModelDTO } from 'src/app/ModelDTO/comentario.ModelDTO';


export class PersonalSancionModelDTO extends EntityModelDTO<PersonalSancionDTO> {

   private personalModel: PersonalLegajoBasicoModelDTO;
   private personalSubscribe: Subscription;
   private tipoModel: TipoSancionModelDTO;
   private tipoSubscribe: Subscription;
   private ausenciaModel: PersonalAusenciaModelDTO;
   private ausenciaSubscribe: Subscription;
   private responsableModel: PersonalLegajoBasicoModelDTO;
   private responsableSubscribe: Subscription;
   private attachmentConNuevosFirmantesModel: AttachmentConNuevosFirmantesModelDTO;
   private attachmentConNuevosFirmantesSubscribe: Subscription;

   public constructor(protected entityDTO: PersonalSancionDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: PersonalSancionDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.personalModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.personal);
      this.personalSubscribe = this.personalModel.changed.subscribe((changed) => this.changed.next(changed));
      this.tipoModel = new TipoSancionModelDTO(this.entityDTO.tipo);
      this.tipoSubscribe = this.tipoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.ausenciaModel = new PersonalAusenciaModelDTO(this.entityDTO.ausencia);
      this.ausenciaSubscribe = this.ausenciaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.responsableModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.responsable);
      this.responsableSubscribe = this.responsableModel.changed.subscribe((changed) => this.changed.next(changed));
      this.attachmentConNuevosFirmantesModel = new AttachmentConNuevosFirmantesModelDTO(this.entityDTO.attachmentConNuevosFirmantes);
      this.attachmentConNuevosFirmantesSubscribe = this.attachmentConNuevosFirmantesModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.personalModel.dispose();
      this.personalSubscribe.unsubscribe();
      this.tipoModel.dispose();
      this.tipoSubscribe.unsubscribe();
      this.ausenciaModel.dispose();
      this.ausenciaSubscribe.unsubscribe();
      this.responsableModel.dispose();
      this.responsableSubscribe.unsubscribe();
      this.attachmentConNuevosFirmantesModel.dispose();
      this.attachmentConNuevosFirmantesSubscribe.unsubscribe();
   }

   get PersonalModel(): PersonalLegajoBasicoModelDTO { return this.personalModel; }
   get Personal(): PersonalLegajoBasicoDTO { return this.personalModel.getEntityDTO(); }
   set Personal(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.personal = value; this.personalModel.setEntityDTO(value); }); }

   get TotalRecibidos(): number { return this.entityDTO.totalRecibidos; }
   set TotalRecibidos(value: number) { this.notifyChangeDTO('totalRecibidos', value); }

   get Observacion(): string { return this.entityDTO.observacion; }
   set Observacion(value: string) { this.notifyChangeDTO('observacion', value); }

   get Fecha(): Date { return this.entityDTO.fecha; }
   set Fecha(value: Date) { this.notifyChangeDTO('fecha', value); }

   get FechaCarga(): Date { return this.entityDTO.fechaCarga; }
   set FechaCarga(value: Date) { this.notifyChangeDTO('fechaCarga', value); }

   get TipoModel(): TipoSancionModelDTO { return this.tipoModel; }
   get Tipo(): TipoSancionDTO { return this.tipoModel.getEntityDTO(); }
   set Tipo(value: TipoSancionDTO) { this.notifyChange(() => { this.entityDTO.tipo = value; this.tipoModel.setEntityDTO(value); }); }

   get AusenciaModel(): PersonalAusenciaModelDTO { return this.ausenciaModel; }
   get Ausencia(): PersonalAusenciaDTO { return this.ausenciaModel.getEntityDTO(); }
   set Ausencia(value: PersonalAusenciaDTO) { this.notifyChange(() => { this.entityDTO.ausencia = value; this.ausenciaModel.setEntityDTO(value); }); }

   get ResponsableModel(): PersonalLegajoBasicoModelDTO { return this.responsableModel; }
   get Responsable(): PersonalLegajoBasicoDTO { return this.responsableModel.getEntityDTO(); }
   set Responsable(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.responsable = value; this.responsableModel.setEntityDTO(value); }); }

   get Adjuntos(): Array<AttachmentUaalooDTO> { return this.entityDTO.adjuntos; }
   set Adjuntos(value: Array<AttachmentUaalooDTO>) { this.notifyChangeDTO('adjuntos', value); }

   get AttachmentConNuevosFirmantesModel(): AttachmentConNuevosFirmantesModelDTO { return this.attachmentConNuevosFirmantesModel; }
   get AttachmentConNuevosFirmantes(): AttachmentConNuevosFirmantesDTO { return this.attachmentConNuevosFirmantesModel.getEntityDTO(); }
   set AttachmentConNuevosFirmantes(value: AttachmentConNuevosFirmantesDTO) { this.notifyChange(() => { this.entityDTO.attachmentConNuevosFirmantes = value; this.attachmentConNuevosFirmantesModel.setEntityDTO(value); }); }

   get Comentarios(): Array<ComentarioDTO> { return this.entityDTO.comentarios; }
   set Comentarios(value: Array<ComentarioDTO>) { this.notifyChangeDTO('comentarios', value); }

   get CantidadComentarios(): number { return this.entityDTO.cantidadComentarios; }
   set CantidadComentarios(value: number) { this.notifyChangeDTO('cantidadComentarios', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
