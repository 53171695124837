import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ResumenHorasTrabajadasDTO } from 'src/app/ModelDTO/DTO/resumenHorasTrabajadas.DTO';

@Injectable({
   providedIn: 'root'
})
export class FichajeResumenServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getFichajesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/fichajes/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getFichajesResumen(fechaDesde: Date, fechaHasta: Date, personalLegajoId: number = 0): Promise<ResumenHorasTrabajadasDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/fichajes/resumen/' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&personalLegajoId=' + personalLegajoId));
         if (!res) { return null; }
         const resDTO = new ResumenHorasTrabajadasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getFichajesResumenXLSX(fechaDesde: Date, fechaHasta: Date, personalLegajoId: number = 0, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/fichajes/resumen/' + '?fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&personalLegajoId=' + personalLegajoId + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

}
