import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoLink } from 'src/app/ModelDTO/DTO/tipoLink.ENUM';


export class LinkPublicoDTO implements IEntityDTO {

   link = '';
   tipoLink: TipoLink;
   empresaId = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.link != null) { this.link = jsonObj.link; }
      if (jsonObj.tipoLink != null) { this.tipoLink = jsonObj.tipoLink as TipoLink; }
      if (jsonObj.empresaId != null) { this.empresaId = jsonObj.empresaId; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
