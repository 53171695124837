import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class EstadisticasRotacionDTO implements IEntityDTO {

   rotacion = 0;
   rotacionNoForzada = 0;
   rotacionForzada = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.rotacion != null) { this.rotacion = jsonObj.rotacion; }
      if (jsonObj.rotacionNoForzada != null) { this.rotacionNoForzada = jsonObj.rotacionNoForzada; }
      if (jsonObj.rotacionForzada != null) { this.rotacionForzada = jsonObj.rotacionForzada; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
