import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { ReclutamientoOfertaDTO } from 'src/app/ModelDTO/DTO/reclutamientoOferta.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';


export class ReclutamientoOfertaFaseDTO implements IEntityDTO {

   oferta: ReclutamientoOfertaDTO;
   responsable: PersonalLegajoDTO;
   nombre = '';
   orden = 0;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.oferta = new ReclutamientoOfertaDTO();
      this.responsable = new PersonalLegajoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.oferta != null) { this.oferta.PrepareDTO(jsonObj.oferta); }
      if (jsonObj.responsable != null) { this.responsable.PrepareDTO(jsonObj.responsable); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.orden != null) { this.orden = jsonObj.orden; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
