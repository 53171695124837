import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-puntaje',
  templateUrl: './select-puntaje.component.html',
  styleUrls: ['./select-puntaje.component.scss'],
})
export class SelectPuntajeComponent implements OnInit {
  @Output() rating: EventEmitter<number> = new EventEmitter<number>();
  private readonly starCount = 5;
  public ratingArr = [];
  public selectedRating: number;

  ngOnInit(): void {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  public onClick(rating: number): void {
    this.selectedRating = rating;
    this.rating.emit(rating);
  }

  public clearSelectRating(): void {
    this.selectedRating = 0;
  }
}
