import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { EmpresaDTO } from 'src/app/ModelDTO/DTO/empresa.DTO';


export class SolicitudBajaEmpresaDTO implements IEntityDTO {

   motivo = '';
   fecha: Date;
   empresa: EmpresaDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.empresa = new EmpresaDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.motivo != null) { this.motivo = jsonObj.motivo; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.empresa != null) { this.empresa.PrepareDTO(jsonObj.empresa); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
