import { Component, Input } from '@angular/core';
import { PersonalEntregaModelDTO, SolicitudEntregaModelDTO } from 'src/app/ModelDTO';

@Component({
  selector: 'app-detalle-entrega',
  templateUrl: './detalle-entrega.component.html',
})
export class DetalleEntregaComponent {
  @Input() public solicitudModel: SolicitudEntregaModelDTO;
  @Input() public entregaModel: PersonalEntregaModelDTO;
  @Input() public status: string;
  @Input() public color: string;
}
