import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'arrayTranslate',
})
export class ArrayTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(array: string[], path: string): translatedArrayElement[] {
    return array?.map((element) => ({ label: this.translateService.instant(`${path}.${element}`), value: element })) ?? [];
  }
}

export interface translatedArrayElement {
  label: string;
  value: string;
}
