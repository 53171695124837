import { Pipe, PipeTransform } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export type ES_UNIT = 'centimeter' | 'meter' | 'kilometer';
export type EN_UNIT = 'feet';
type ConversionLiteral = { [key in EN_UNIT]: (value: number) => number };

const fromCm: ConversionLiteral = {
  feet: (value: number) => value * 0.0328084,
};

@Pipe({
  name: 'unitTranslate',
})
export class UnitTranslatePipe implements PipeTransform {
  constructor(private headerService: HeaderAppService) {}

  public transform(value: number, from: ES_UNIT, to: EN_UNIT): number {
    let convertedValue = value;
    if (this.headerService.currentLanguage === 'en') {
      convertedValue = fromCm[to](value);
      switch (from) {
        case 'meter':
          convertedValue = convertedValue * 100;
          break;
        case 'kilometer':
          convertedValue = convertedValue * 100_000;
          break;
        default:
          convertedValue = value;
          break;
      }
    }
    return Math.round(convertedValue);
  }
}
