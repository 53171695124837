import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { FeatureFlagProviderService } from './feature-flag-provider.service';
import { FeatureFlagService } from './feature-flags.service';

const featureFlagServiceFactory = (provider: FeatureFlagProviderService, headerService: HeaderAppService): FeatureFlagService =>
  new FeatureFlagService(provider.getFeatureFlags, headerService);

export const featureFlagServiceProvider = {
  provide: FeatureFlagService,
  useFactory: featureFlagServiceFactory,
  deps: [FeatureFlagProviderService, HeaderAppService],
};
