import { Injectable } from '@angular/core';
import { SolicitudAusenciaModelDTO } from 'src/app/ModelDTO';
import {
  EstadoAprobacionSolicitud,
  PersonalAusenciaDTO,
  PersonalDTO,
  ResultadoImportacionDTO,
  SolicitudAusenciaDTO,
} from 'src/app/ModelDTO/DTO';
import {
  ExportacionCuentaCorrienteServiceBackend,
  ImportacionCuentaCorrienteServiceBackend,
  SolicitudAusenciaServiceBackend,
} from 'src/app/ServiceBackend';
import { PersonalAusenciaServiceBackend } from 'src/app/ServiceBackend/personalAusencia.ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

@Injectable({
  providedIn: 'root',
})
export class AdministradorAusenciasService {
  public get PersonalAlta(): PersonalDTO {
    return this.personalAlta;
  }
  public set PersonalAlta(p: PersonalDTO) {
    this.personalAlta = p;
  }

  private personalAlta: PersonalDTO;

  constructor(
    private personalAusencia: PersonalAusenciaServiceBackend,
    private solicitudAusenciaService: SolicitudAusenciaServiceBackend,
    private importacionCCService: ImportacionCuentaCorrienteServiceBackend,
    private exportacionCCService: ExportacionCuentaCorrienteServiceBackend,
    private fileDownloaderService: FileDownloaderService
  ) {}

  public async getAusenciaspersonalFiltradas(
    fechaDesde: Date,
    fechaHasta: Date,
    estado?: number,
    idPersonal?: number,
    sinRechazos?: boolean
  ): Promise<PersonalAusenciaDTO[]> {
    return this.personalAusencia.getAusenciasPersonalFiltradas(fechaDesde, fechaHasta, estado, idPersonal, sinRechazos, true);
  }

  public async crearPersonalAusencia(idPersonal: number, formAusencia: FormData): Promise<PersonalAusenciaDTO> {
    return this.personalAusencia.insertAusenciasPersonalIDPersonal(idPersonal, formAusencia);
  }

  public async getProximas(
    pageSize: number,
    days: number,
    estado: EstadoAprobacionSolicitud = EstadoAprobacionSolicitud.No_definido
  ): Promise<Array<PersonalAusenciaDTO>> {
    return this.personalAusencia.getAusenciasSiguientes(estado, pageSize, days);
  }

  public async getSolicitudPorId(id: number): Promise<SolicitudAusenciaModelDTO> {
    const res = await this.solicitudAusenciaService.getSolicitudAusenciaPorSolicitudId(id);
    return new SolicitudAusenciaModelDTO(res);
  }

  public async getSolicitudPorAusencia(idPersonalAusencia: number): Promise<SolicitudAusenciaModelDTO> {
    const res = await this.solicitudAusenciaService.getSolicitudAusenciaIdPersonalAusencia(idPersonalAusencia);
    return new SolicitudAusenciaModelDTO(res);
  }

  public async getAusenciaPorId(id: number): Promise<PersonalAusenciaDTO> {
    const res = await this.personalAusencia.getAusenciasPersonalAusenciaId(id);
    return res;
  }

  public async actualizarEstado(solicitud: SolicitudAusenciaDTO): Promise<SolicitudAusenciaDTO> {
    return this.solicitudAusenciaService.updateSolicitudAusenciaIdEstado(solicitud.id, solicitud);
  }

  public async getSolicitudes(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud = EstadoAprobacionSolicitud.No_definido,
    personalId: number = 0
  ): Promise<Array<SolicitudAusenciaModelDTO>> {
    const response = new Array<SolicitudAusenciaModelDTO>();
    const res = await this.solicitudAusenciaService.getSolicitudAusenciaFiltradas(desde, hasta, 0, 0, estado, personalId);
    res.forEach((x) => response.push(new SolicitudAusenciaModelDTO(x)));
    return response;
  }

  public async exportCurrentAccounts(withoutData: boolean = false): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.exportacionCCService.getExportacionesCuentaCorriente(withoutData, blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public async importCurrentAccounts(formData: FormData): Promise<ResultadoImportacionDTO> {
    const res = await this.importacionCCService.insertImportacionesCuentaCorriente(formData);
    return res;
  }

  public async updateAbsence(id: number, formData: FormData): Promise<PersonalAusenciaDTO> {
    const res = await this.personalAusencia.updateAusenciasPersonalAusenciaId(id, formData);
    return res;
  }

  public dispose(): void {
    this.personalAlta = null;
  }
}
