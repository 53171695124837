/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { PersonalAusenciaBasicDTO } from 'src/app/api/interfaces/personal-ausencia-basic.interface';
import { PersonalAusenciaDTO } from 'src/app/api/interfaces/personal-ausencia.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonalAusenciaBackendService {

   constructor(protected http: HttpClient) { }


   public getAusenciasIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/ausencias/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public insertAusenciasPersonalIDPersonal(iDPersonal: number, personalAusenciaDTO: FormData): Observable<PersonalAusenciaDTO> {
      return this.http.post<PersonalAusenciaDTO>(Constants.apiURL + '/ausencias/personal/' + iDPersonal, personalAusenciaDTO).pipe(map((res) => convertJsonDates(res) as PersonalAusenciaDTO ));
   }

   public updateAusenciasPersonalAusenciaId(id: number, personalAusenciaDTO: FormData): Observable<PersonalAusenciaDTO> {
      return this.http.put<PersonalAusenciaDTO>(Constants.apiURL + '/ausencias/personalAusencia/' + id, personalAusenciaDTO).pipe(map((res) => convertJsonDates(res) as PersonalAusenciaDTO ));
   }

   public getAusenciasPersonalAusenciaId(id: number): Observable<PersonalAusenciaDTO> {
      return this.http.get<PersonalAusenciaDTO>(Constants.apiURL + '/ausencias/personalAusencia/' + id).pipe(map((res) => convertJsonDates(res) as PersonalAusenciaDTO ));
   }

   public getAusenciasPersonalFiltradas(params: { fechaDesde: Date, fechaHasta: Date, estado?: number, idPersonal?: number, sinRechazos?: boolean, filterByTeam?: boolean, incluirBajas?: boolean }, ): Observable<PersonalAusenciaBasicDTO[]> {
      const { fechaDesde, fechaHasta, estado = 0, idPersonal = 0, sinRechazos = false, filterByTeam = true, incluirBajas = false } = params;
      return this.http.get<DataDTO<PersonalAusenciaBasicDTO>>(Constants.apiURL + '/ausencias/personal/filtradas' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&estado=' + estado +
            '&idPersonal=' + idPersonal +
            '&sinRechazos=' + sinRechazos +
            '&filterByTeam=' + filterByTeam +
            '&incluirBajas=' + incluirBajas).pipe(map((res) => convertJsonDates(res.data) as PersonalAusenciaBasicDTO[]));
   }

   public getAusenciasSiguientes(estado: number = 0, pageSize: number = 0, days: number = 0): Observable<PersonalAusenciaDTO[]> {
      return this.http.get<DataDTO<PersonalAusenciaDTO>>(Constants.apiURL + '/ausencias/siguientes' + '?estado=' + estado +
            '&pageSize=' + pageSize +
            '&days=' + days).pipe(map((res) => convertJsonDates(res.data) as PersonalAusenciaDTO[]));
   }

   public getAusenciasPropias(params: { fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, estado?: number, sinRechazos?: boolean }, ): Observable<PersonalAusenciaDTO[]> {
      const { fechaDesde, fechaHasta, pageNumber, pageSize, estado = 0, sinRechazos = false } = params;
      return this.http.get<DataDTO<PersonalAusenciaDTO>>(Constants.apiURL + '/ausencias/propias' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&estado=' + estado +
            '&sinRechazos=' + sinRechazos).pipe(map((res) => convertJsonDates(res.data) as PersonalAusenciaDTO[]));
   }

   public deleteAusenciasIdPersonalIDPersonal(id: number, iDPersonal: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/ausencias/' + id + '/personal/' + iDPersonal);
   }
   public getAusenciasIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/ausencias/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertAusenciasIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/ausencias/' + id + '/comentarios', stream);
   }
   public updateAusenciasIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/ausencias/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteAusenciasIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/ausencias/' + id + '/comentarios/' + iDComentario);
   }}
