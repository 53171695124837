export enum ReclutamientoCampo {
   Email = 0,
   Nombre = 1,
   Apellido = 2,
   CV = 4,
   Foto = 8,
   Carta = 16,
   Telefono = 32,
   Linkedin = 64
 }
