import { AttachmentType, SignatureStatusType } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalLegajoBasicoDTO, AttachmentUaalooDTO, AttachmentSignatureUaalooDTO } from 'src/app/ModelDTO/DTO';
import { AttachmentUaalooModelDTO } from 'src/app/ModelDTO';
import { extensionArchivo } from 'src/app/shared/shared-functions';

export class AttachmentUaalooModelDTOExtended extends AttachmentUaalooModelDTO {
  private rawFile: File;

  constructor(protected entityDTO: AttachmentUaalooDTO) {
    super(entityDTO);
    this.rawFile = null;
  }

  public addSignatureByRequesterId(newSigner: PersonalLegajoBasicoDTO, newStatus: SignatureStatusType, requesterPersonalLegajoId: number) {
    const requesterPersonalLegajo = new PersonalLegajoBasicoDTO();
    requesterPersonalLegajo.id = requesterPersonalLegajoId;
    this.addSignature(newSigner, newStatus, requesterPersonalLegajo, true);
  }

  public addSignature(
    newSigner: PersonalLegajoBasicoDTO,
    newStatus: SignatureStatusType,
    requesterUser: PersonalLegajoBasicoDTO = null,
    notificarPorEmail?: boolean,
  ) {
    const firmaExistente = this.Signatures.find((x) => x.ownerPersonalLegajo.id === newSigner.id);
    if (firmaExistente) {
      return;
    }

    const firmaAEditar = new AttachmentSignatureUaalooDTO();

    firmaAEditar.status = newStatus;
    firmaAEditar.ownerPersonalLegajo = newSigner;
    firmaAEditar.isCreator = this.CreatorPersonalLegajo.id === newSigner.id;
    firmaAEditar.notify = notificarPorEmail;
    if (newStatus === SignatureStatusType.Requested) {
      firmaAEditar.requestDate = new Date();
      firmaAEditar.requesterPersonalLegajo = requesterUser;
    }

    this.Signatures.push(firmaAEditar);
  }

  public removeSignature(signer: PersonalLegajoBasicoDTO) {
    this.Signatures = this.Signatures.filter((x) => x.ownerPersonalLegajo.id !== signer.id);
  }

  public setFromFile(file: File) {
    if (file === null || file === undefined) {
      return;
    }
    this.Type = AttachmentType[AttachmentType.File];
    this.File.fileName = file.name;
    this.rawFile = file;
    this.AllowsDigitalSignature = this.allowsDigitalSignature(file.name);
  }

  get RawFile(): File {
    return this.rawFile;
  }
  set RawFile(value: File) {
    this.rawFile = value;
  }

  get HasCreatorSignature(): boolean {
    return this.Signatures.some((x) => x.isCreator);
  }
  get CreatorSignature(): AttachmentSignatureUaalooDTO {
    return this.Signatures.find((x) => x.isCreator) ?? new AttachmentSignatureUaalooDTO();
  }

  private allowsDigitalSignature(fileName: string): boolean {
    if (fileName === null || fileName === undefined) {
      return false;
    }
    return extensionArchivo(fileName) === '.pdf';
  }
}
