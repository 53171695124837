import { BRANCH } from 'src/environments/branch';

const packageJson = require('../../package.json');

export const environment = {
  production: false,
  serviceWorker: true,
  sentryEnabled: true,
  // eslint-disable-next-line global-require
  appVersion: `${packageJson.version}-${BRANCH.name}`,
  packageName: packageJson.name,
};
