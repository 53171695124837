import { Injectable, Inject } from '@angular/core';
import { DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, Observable, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacionFlat.DTO';
import { NameDetailDTO } from '@api/interfaces/name-detail.interface';

@Injectable({
   providedIn: 'root'
})
export class TipoCapacitacionServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getTipoCapacitacionIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoCapacitacionIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoCapacitacion/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getTipoCapacitacion(): Promise<Array<TipoCapacitacionFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/'));
         const resJson = res.data;
         const resDTO = new Array<TipoCapacitacionFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoCapacitacionFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoCapacitacionId(id: number): Promise<TipoCapacitacionDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tipoCapacitacion/' + id));
         if (!res) { return null; }
         const resDTO = new TipoCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateTipoCapacitacionId(id: number, tipoCapacitacionDTO: TipoCapacitacionDTO): Promise<TipoCapacitacionDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tipoCapacitacion/' + id, tipoCapacitacionDTO));
         const resDTO = new TipoCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertTipoCapacitacion(tipoCapacitacionDTO: TipoCapacitacionDTO): Promise<TipoCapacitacionDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tipoCapacitacion/', tipoCapacitacionDTO));
         const resDTO = new TipoCapacitacionDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getTipoCapacitacionAutoComplete(contiene: string, pageSize: number): Promise<Array<TipoCapacitacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<TipoCapacitacionDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoCapacitacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoCapacitacionPersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Promise<Array<TipoCapacitacionFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/personal/' + idPersonalLegajo));
         const resJson = res.data;
         const resDTO = new Array<TipoCapacitacionFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoCapacitacionFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTipoCapacitacionConvenioIDCONVENIO(idConvenio: number): Promise<Array<TipoCapacitacionFlatDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/convenio/' + idConvenio));
         const resJson = res.data;
         const resDTO = new Array<TipoCapacitacionFlatDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoCapacitacionFlatDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public getTipoCapacitacionCategoriaIDCATEGORIA(iDCATEGORIA: number): Observable<Array<TipoCapacitacionDTO>> {
        return this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/categoria/' + iDCATEGORIA).pipe(map(res =>{
           const resJson = res.data;
           const resDTO = new Array<TipoCapacitacionDTO>();
           for (const item of resJson) {
              const itemDTO = new TipoCapacitacionDTO();
              itemDTO.PrepareDTO(item);
              resDTO.push(itemDTO);
           }
           return resDTO;
         }));
   }

   public async getTipoCapacitacionFullpersonalIDPERSONALLEGAJO(idPersonalLegajo: number): Promise<Array<TipoCapacitacionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tipoCapacitacion/fullpersonal/' + idPersonalLegajo));
         const resJson = res.data;
         const resDTO = new Array<TipoCapacitacionDTO>();
         for (const item of resJson) {
            const itemDTO = new TipoCapacitacionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async deleteTipoCapacitacionId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/tipoCapacitacion/' + id));

   }
}
