import { EstadoAsistencia } from 'src/app/ModelDTO/DTO/estadoAsistencia.ENUM';
import { EstadoFichaje } from 'src/app/ModelDTO/DTO/estadoFichaje.ENUM';
import { FichajeErrorDTO } from 'src/app/ModelDTO/DTO/fichajeError.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';


export class FichajeEstadoFlatDTO implements IEntityDTO {

   personalId = 0;
   personalNombre = '';
   personalApellido = '';
   personalImage = '';
   nombreLicencia = '';
   aliasLicencia = '';
   position = '';
   erroresIngreso: Array<FichajeErrorDTO>;
   erroresSalida: Array<FichajeErrorDTO>;
   tieneJornada: boolean;
   estadoAsistencia: EstadoAsistencia;
   estadoFichaje: EstadoFichaje;
   id = 0;

   constructor() {
    this.erroresIngreso = new Array<FichajeErrorDTO>();
    this.erroresSalida = new Array<FichajeErrorDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personalId != null) { this.personalId = jsonObj.personalId; }
      if (jsonObj.personalNombre != null) { this.personalNombre = jsonObj.personalNombre; }
      if (jsonObj.personalApellido != null) { this.personalApellido = jsonObj.personalApellido; }
      if (jsonObj.personalImage != null) { this.personalImage = jsonObj.personalImage; }
      if (jsonObj.nombreLicencia != null) { this.nombreLicencia = jsonObj.nombreLicencia; }
      if (jsonObj.aliasLicencia != null) { this.aliasLicencia = jsonObj.aliasLicencia; }
      if (jsonObj.position != null) { this.position = jsonObj.position; }
      if (jsonObj.tieneJornada != null) { this.tieneJornada = jsonObj.tieneJornada; }
      if (jsonObj.erroresIngreso != null) { for (const item of jsonObj.erroresIngreso) { const itemDTO = new FichajeErrorDTO(); itemDTO.PrepareDTO(item); this.erroresIngreso.push(itemDTO); } }
      if (jsonObj.erroresSalida != null) { for (const item of jsonObj.erroresSalida) { const itemDTO = new FichajeErrorDTO(); itemDTO.PrepareDTO(item); this.erroresSalida.push(itemDTO); } }
      if (jsonObj.estadoAsistencia != null) { this.estadoAsistencia = jsonObj.estadoAsistencia as EstadoAsistencia; }
      if (jsonObj.estadoFichaje != null) { this.estadoFichaje = jsonObj.estadoFichaje as EstadoFichaje; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
